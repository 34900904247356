import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { booleanStatus } from "../../../../data/institute/institute";
import DateTime from "../../../../UI/DateTime/DateTime";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import MultiDropdown from "../../../../UI/MultiDropdown/MultiDropdown";

function CreateForm({
  events,
  changeCreds,
  eventDescription,
  setEventDescription,
  eventBenefits,
  setEventBenefits,
  onMultiSpeakerChange,
}) {
  const speakerListReducer = useSelector((state) => state.eventSpeaker);
  const { eventSpeakers } = speakerListReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="title">
            Title <span className="text-danger">*</span>
          </Label>
          <Input
            value={events.title}
            onChange={changeCreds}
            type="text"
            required
            name="title"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <DateTime
          label="Start Date"
          date={events.eventStartDate}
          onDateChange={changeCreds}
          name="eventStartDate"
          required
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <DateTime
          label="End Date"
          date={events.eventEndDate}
          startDate={events.eventStartDate}
          onDateChange={changeCreds}
          name="eventEndDate"
          required
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="eventStartTimeString">
            Start Time <span className="text-danger">*</span>
          </Label>
          <Input
            value={events.eventStartTimeString}
            onChange={changeCreds}
            type="time"
            required
            name="eventStartTimeString"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="eventEndTimeString">
            End Time <span className="text-danger">*</span>
          </Label>
          <Input
            value={events.eventEndTimeString}
            onChange={changeCreds}
            type="time"
            required
            name="eventEndTimeString"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="eventPrice">
            Price <span className="text-danger">*</span>
          </Label>
          <Input
            value={events.eventPrice}
            onChange={changeCreds}
            min={0}
            type="number"
            required
            name="eventPrice"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="eventLocation">
            Location <span className="text-danger">*</span>
          </Label>
          <Input
            value={events.eventLocation}
            onChange={changeCreds}
            type="text"
            required
            name="eventLocation"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="isOnline">
            Online <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isOnline"
            id="isOnline"
            required
            value={events.isOnline}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="isActive">
            Active <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isActive"
            id="isActive"
            required
            value={events.isActive}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="speakerID">
            Speaker Name <span className="text-danger">*</span>
          </Label>

          <MultiDropdown
            options={eventSpeakers}
            selectedOption={events.speakerID}
            onMultiSpeakerChange={onMultiSpeakerChange}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="eventDescription">Benefits</Label>
          <CkEditor value={eventBenefits} setDescription={setEventBenefits} />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="eventDescription">Description</Label>
          <CkEditor
            value={eventDescription}
            setDescription={setEventDescription}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateForm;
