import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { addCurrency } from "../../../actions/onlineCourseActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import { booleanStatus } from "../../../data/institute/institute";

var todayDate = new Date();

function CurrencyForm({ history }) {
  const [currency, setCurrency] = useState({
    name: "",
    symbol: "",
    isBaseCurrency: true,
    createdBy: null,
    createdDate: todayDate,
  });

  const currencyAddReducer = useSelector((state) => state.currencyAdd);

  const { currencyAddLoading } = currencyAddReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setCurrency({ ...currency, [event.target.name]: event.target.value });
  };

  const onCreateCourse = (e) => {
    e.preventDefault();
    const courseInfo = {
      name: currency.name,
      symbol: currency.symbol,
      isBaseCurrency: JSON.parse(currency.isBaseCurrency),
      createdBy: null,
      createdDate: todayDate,
    };
    dispatch(
      addCurrency(courseInfo, () => {
        history.push(`/admin/currency`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Add | Currency | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onCreateCourse(e)} className="px-5 py-3">
                <Row className="gutter mb-2 mb-sm-4 mt-5">
                  <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
                    <FormGroup>
                      <Label for="Name">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={currency.name}
                        onChange={changeCreds}
                        type="name"
                        maxLength={400}
                        required
                        name="name"
                        autoComplete="nope"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
                    <FormGroup>
                      <Label for="symbol">
                        Symbol <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={currency.symbol}
                        onChange={changeCreds}
                        type="text"
                        maxLength={400}
                        required
                        name="symbol"
                        autoComplete="nope"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-2 mb-sm-4 mb-md-0 " xs={12} md={3}>
                    <FormGroup>
                      <Label for="type">Base Currency</Label>
                      <Input
                        type="select"
                        name="isBaseCurrency"
                        id="type"
                        required
                        value={currency.isBaseCurrency}
                        onChange={changeCreds}
                      >
                        {booleanStatus?.map((item, id) => (
                          <option key={id} value={item.status}>
                            {item?.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    className="mt-2 mb-sm-4 mb-md-0 d-flex justify-content-center align-items-center"
                    xs={12}
                    md={3}
                  >
                    <Button
                      className="rounded-pill w-75"
                      type="submit"
                      color="secondary-red"
                    >
                      {currencyAddLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: currencyAddLoading,
                            invisible: !currencyAddLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CurrencyForm;
