import React from "react";
import { Collapse } from "reactstrap";
import "./Accordion.scss";

function Accordion({
  title,
  isOpen,
  onToggle,
  children,
  accordionBodyClasses = "",
  bgClass = "#fff",
}) {
  return (
    <div className="mx-3" style={{ cursor: "pointer" }}>
      <div
        className={`${
          isOpen ? "open" : ""
        } accordion-body font-weight-medium   accordion-header d-flex px-3 py-2  justify-content-between align-items-center`}
        onClick={onToggle}
      >
        <span className="d-flex ">{title} </span>
        <span className={` ${isOpen ? "arrow-dw" : "arrow"}`}>
          <i
            className={`fa fa-angle-down  ${
              isOpen ? "fa-angle" : "fa-angle-rotated"
            }`}
          ></i>
        </span>
      </div>
      <Collapse isOpen={isOpen}>
        <div className={`${accordionBodyClasses} ${bgClass}`}>{children}</div>
      </Collapse>
    </div>
  );
}

export default Accordion;
