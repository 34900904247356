import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { listTutors } from "../../../../actions/tutorAction";
import Widget from "../../../../components/Branch/Widget/Widget";
import SearchInput from "../../../../helper/SearchInput";
import s from "../../Tables.module.scss";

const TutorTable = ({ tableName }) => {
  const [tutorTableList, setTutorTableList] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const tutorListState = useSelector((state) => state.tutor);
  const { tutorListLoading, tutorList } = tutorListState;

  useEffect(() => {
    dispatch(listTutors());
  }, [dispatch]);

  useEffect(() => {
    if (tutorList && !tutorListLoading) {
      setTutorTableList(tutorList);
    }
  }, [tutorList, tutorListLoading]);

  const setPage = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredTutors = tutorTableList.filter(
    (item) =>
      (item?.email &&
        item.email.toLowerCase().includes(searchText.toLowerCase())) ||
      (item?.username &&
        item.username.toLowerCase().includes(searchText.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredTutors.length / pageSize);

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchText}
                      handleSearch={handleSearch}
                      placeholder="Search Organisation"
                    />
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className="w-25">Tutor Name</th>
                        <th className="w-25">Email</th>
                        {/* <th className="w-25">Username</th> */}
                        {/* <th className="w-25">Edit</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTutors.length > 0 ? (
                        filteredTutors
                          .slice(
                            currentPage * pageSize,
                            (currentPage + 1) * pageSize
                          )
                          .map((tutor) => (
                            <tr key={tutor.id}>
                              <td>{tutor.userName}</td>
                              <td>{tutor.email}</td>
                              {/* <td>{tutor.userName}</td> */}
                              {/* <td>
                                <Link
                                  to={`/admin/tutors/editTutor/${tutor.id}`}
                                  className="table-action mr-2"
                                >
                                  <i className="eva eva-edit mr-1"></i>
                                </Link>
                              </td> */}
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {filteredTutors.length > 0 && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink
                          onClick={(e) => setPage(e, currentPage - 1)}
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(totalPages)].map((_, i) => (
                        <PaginationItem active={i === currentPage} key={i}>
                          <PaginationLink
                            onClick={(e) => setPage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= totalPages - 1}>
                        <PaginationLink
                          onClick={(e) => setPage(e, currentPage + 1)}
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TutorTable;
