import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { booleanStatus, status } from "../../../../data/institute/institute";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";
import PincodeInput from "../../../../UI/Input/PincodeInput/PincodeInput";

function CreateForm({
  institute,
  instituteImagePath,
  setInstituteImagePath,
  changeCreds,
  intDescription,
  setIntDescription,
  bannerImagePath,
  setBannerImagePath,
}) {
  const instituteCategoryListReducer = useSelector(
    (state) => state.instituteCategory
  );
  const { instituteCategory } = instituteCategoryListReducer;
  const instituteAddReducer = useSelector((state) => state.instituteAdd);
  const { instituteAddLoading } = instituteAddReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="instituteName">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={institute.instituteName}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="instituteName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="address1">Address 1</Label>
          <Input
            maxLength={400}
            value={institute.address1}
            onChange={changeCreds}
            type="text"
            name="address1"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="address 2">Address 2</Label>
          <Input
            value={institute.address2}
            onChange={changeCreds}
            maxLength={200}
            type="text"
            name="address2"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="city">City</Label>
          <Input
            value={institute.city}
            onChange={changeCreds}
            type="text"
            name="city"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="state">State</Label>
          <Input
            value={institute.state}
            onChange={changeCreds}
            type="text"
            name="state"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="country">Country</Label>
          <Input
            value={institute.country}
            onChange={changeCreds}
            type="text"
            name="country"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <PincodeInput
          label="Pin"
          value={institute.pin}
          onChange={changeCreds}
          type="number"
          name="pin"
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="website">Website</Label>
          <Input
            value={institute.website}
            onChange={changeCreds}
            maxLength={100}
            type="url"
            name="website"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="emailId">Email</Label>
          <Input
            value={institute.emailId}
            onChange={changeCreds}
            type="email"
            name="emailId"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="phone1">Phone 1</Label>
          <Input
            value={institute.phone1}
            onChange={changeCreds}
            type="number"
            maxLength={10}
            name="phone1"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="instituteInceptionYear">
            Inception Year <span className="text-danger">*</span>
          </Label>
          <Input
            value={institute.instituteInceptionYear}
            onChange={changeCreds}
            type="date"
            required
            name="instituteInceptionYear"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="type">
            Type <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="instituteTypeID"
            id="type"
            required
            value={institute.instituteTypeID}
            onChange={changeCreds}
          >
            {instituteCategory?.map((item, id) => (
              <option key={id} value={item.id}>
                {" "}
                {item?.instituteType}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="status">Status</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={institute.status}
            onChange={changeCreds}
          >
            {status.map((item, id) => (
              <option key={id} value={item.id}>
                {item.id}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="isFeatured">
            Featured <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isFeatured"
            id="isFeatured"
            required
            value={institute.isFeatured}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="inception year">
            Active <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isActive"
            id="isActive"
            required
            value={institute.isActive}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteShortDescription">Short Description</Label>
          <Input
            value={institute.instituteShortDescription}
            onChange={changeCreds}
            maxLength={500}
            type="textarea"
            name="instituteShortDescription"
            autoComplete="nope"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteDescription">Description</Label>
          <CkEditor value={intDescription} setDescription={setIntDescription} />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={instituteImagePath}
          setPostImage={setInstituteImagePath}
          isLoading={instituteAddLoading}
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={bannerImagePath}
          setPostImage={setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  );
}

export default CreateForm;
