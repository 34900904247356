import React from "react";
import { Col, Row } from "reactstrap";
import StudentTable from "../../tables/components/CounsellorTable/StudentTable";
import Meta from "../../../components/Branch/Meta/Meta";

const Counsellor = () => {
  return (
    <div>
      <Meta title="Students | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <StudentTable tableName="User List" />
        </Col>
      </Row>
    </div>
  );
};

export default Counsellor;
