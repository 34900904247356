import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addCourse } from "../../../../actions/courseActions";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import CreateForm from "./CreateForm";

var todayDate = new Date();

function Create({ history }) {
  const [course, setCourse] = useState({
    courseName: "",
    courseTypeID: parseInt("2"),
    slug: "",
    courseTimeType: "",
    courseDuration: "",
    courseFee: "",
    courseShortDescription: "",
    courseCompletionAward: "",
    courseMode: "",
    courseFeeHigher: "",
    courseFeeLower: "",
    unit: "",
    status: "",
    isFeatured: true,
    isActive: true,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: null,
    createdDate: todayDate,
  });

  const [courseDescription, setCourseDescription] = useState("");
  const [activeTab, setActive] = useState("1");
  const [courseImagePath, setCourseImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });
  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();
  const courseAddReducer = useSelector((state) => state.courseAdd);

  const { courseAddLoading } = courseAddReducer;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setCourse({ ...course, [event.target.name]: event.target.value });
  };

  const onCreateCourse = (e) => {
    e.preventDefault();

    const courseInfo = {
      courseName: course.courseName,
      courseTypeID: course.courseTypeID,
      slug: course.slug.toLowerCase(),
      courseTimeType: course.courseTimeType,
      courseDuration:
        parseInt(course.courseDuration) === 0
          ? 1
          : parseInt(course.courseDuration),
      courseFee:
        parseInt(course.courseFee) === 0 ? 1 : parseInt(course.courseFee),
      courseShortDescription: course.courseShortDescription,
      courseDescription: courseDescription,
      courseCompletionAward: course.courseCompletionAward,
      courseMode: course.courseMode,
      courseFeeHigher:
        parseInt(course.courseFeeHigher) === 0
          ? 1
          : parseInt(course.courseFeeHigher),
      courseFeeLower:
        parseInt(course.courseFeeLower) === 0
          ? 1
          : parseInt(course.courseFeeLower),
      unit: parseInt(course.unit) === 0 ? 1 : parseInt(course.unit),
      status: parseInt(course.status) === 0 ? 1 : parseInt(course.status),
      bannerImagePath: bannerImagePath.myFile,
      isBannerNewImage: bannerImagePath.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      isFeatured: JSON.parse(course.isFeatured),
      isActive: JSON.parse(course.isActive),
      pageTitle: course.pageTitle,
      metaTitle: course.metaTitle,
      metaKeywords: course.metaKeywords,
      metaDescription: course.metaDescription,
      IsNewImage: courseImagePath?.IsNewImage,
      ImageName: courseImagePath?.ImageName,
      ImageType: courseImagePath?.ImageType,
      courseImagePath: courseImagePath?.myFile,
      bannerImagePath: bannerImagePath?.myFile,
      isBannerNewImage: bannerImagePath?.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      createdBy: null,
      createdDate: todayDate,
    };

    dispatch(
      addCourse(courseInfo, () => {
        history.push("/admin/courses");
      })
    );

    setCourseImagePath({
      ...courseImagePath,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form className="p-5" onSubmit={(event) => onCreateCourse(event)}>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <CreateForm
                        course={course}
                        setCourse={setCourse}
                        courseImagePath={courseImagePath}
                        setCourseImagePath={setCourseImagePath}
                        changeCreds={changeCreds}
                        courseDescription={courseDescription}
                        setCourseDescription={setCourseDescription}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Seo seoMeta={course} changeCreds={changeCreds} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={courseAddLoading}
                      >
                        {courseAddLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: courseAddLoading,
                              invisible: !courseAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
