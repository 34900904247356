import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function CreateForm({ student, changeCreds }) {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="StudentName">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={student.StudentName}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="StudentName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="Email">
            Email <span className="text-danger">*</span>
          </Label>
          <Input
            value={student.Email}
            onChange={changeCreds}
            type="email"
            maxLength={400}
            required
            name="Email"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="City">
            City <span className="text-danger">*</span>
          </Label>
          <Input
            value={student.City}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="City"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="Password">
            Password <span className="text-danger">*</span>
          </Label>
          <Input
            value={student.Password}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="Password"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="DateOfBirth">
            Date of Birth <span className="text-danger">*</span>
          </Label>
          <Input
            value={student.DateOfBirth}
            onChange={changeCreds}
            type="date"
            maxLength={400}
            required
            name="DateOfBirth"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateForm;
