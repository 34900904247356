import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CkEditor from "../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../UI/Input/ImageUpload/ImageUpload";

export const PageForm = (props) => {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="pageName">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.pageInfo.pageName}
            onChange={props.changeCreds}
            type="text"
            required
            name="pageName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="Content">Content</Label>
          <CkEditor
            value={props.pageContent}
            setDescription={props.setPageContent}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={props.pageImagePath}
          setPostImage={props.setPageImagePath}
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={props.bannerImagePath}
          setPostImage={props.setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  );
};
