import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editInstructor } from "../../../../actions/onlineCourseActions";
import SocialLink from "../../../../UI/Input/SocialLink/SocialLink";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import EditForm from "./EditForm";

var todayDate = new Date();

function Edit({ isSlug, id }) {
  const [instructor, setInstructor] = useState({
    id: 0,
    name: "",
    title: "",
    email: "",
    fbProfile: "",
    linkedInProfile: "",
    instaprofile: "",
    twitterProfile: "",
    website: "",
    slug: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    modifyBy: "",
    modifiedDate: todayDate,
  });

  const [description, setDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const singleInstructorReducer = useSelector(
    (state) => state.singleInstructor
  );
  const { singleInstructor } = singleInstructorReducer;

  const [instructorImage, setInstructorImage] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const instructorEditReducer = useSelector((state) => state.instructorEdit);
  const { instructorEditLoading } = instructorEditReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setInstructor({ ...instructor, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setInstructor({
      ...instructor,
      name: singleInstructor?.name,
      email: singleInstructor?.email,
      title: singleInstructor?.title,
      fbProfile: singleInstructor?.fb,
      linkedInProfile: singleInstructor?.linkedIn,
      instaprofile: singleInstructor?.instagram,
      twitterProfile: singleInstructor?.twitter,
      slug: singleInstructor?.slug,
      pageTitle: singleInstructor?.pageTitle,
      metaTitle: singleInstructor?.metaTitle,
      metaKeywords: singleInstructor?.metaKeywords,
      metaDescription: singleInstructor?.metaDescription,
    });

    setDescription(singleInstructor?.description);
    setInstructorImage({
      ...instructorImage,
      myFile: singleInstructor?.instructorImage,
    });
  }, [singleInstructor]);

  const createInstructor = (e) => {
    e.preventDefault();

    let intSlug = null;

    if (instructor.slug) {
      intSlug = instructor.slug.toLowerCase();
    } else {
      intSlug = instructor.name.replace(/\s+/g, "-");
    }

    const instructorInfo = {
      id: id,
      name: instructor.name,
      email: instructor.email,
      description: description,
      title: instructor.title,
      instructorImage: instructorImage?.myFile,
      IsNewImage: instructorImage?.IsNewImage,
      ImageName: instructorImage?.ImageName,
      ImageType: instructorImage?.ImageType,
      linkedIn: instructor.linkedInProfile,
      twitter: instructor.twitterProfile,
      fb: instructor.fbProfile,
      instagram: instructor.instaprofile,
      slug: intSlug,
      pageTitle: instructor.pageTitle,
      metaTitle: instructor.metaTitle,
      metaKeywords: instructor.metaKeywords,
      metaDescription: instructor.metaDescription,
      modifyBy: instructor.modifyBy,
      modifiedDate: todayDate,
    };

    dispatch(editInstructor(instructorInfo));
    setInstructorImage({
      ...instructorImage,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form
                className="p-5"
                onSubmit={(event) => createInstructor(event)}
              >
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Social Links
                    </NavLink>
                  </NavItem>
                  {isSlug && (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <EditForm
                      instructor={instructor}
                      setInstructor={setInstructor}
                      description={description}
                      setDescription={setDescription}
                      instructorImage={instructorImage}
                      setInstructorImage={setInstructorImage}
                      changeCreds={changeCreds}
                      isEdited={true}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <SocialLink
                      speaker={instructor}
                      changeCreds={changeCreds}
                      email={false}
                      website={false}
                      instaprofile={true}
                    />
                  </TabPane>
                  {isSlug && (
                    <TabPane tabId="3">
                      <Seo seoMeta={instructor} changeCreds={changeCreds} />
                    </TabPane>
                  )}
                </TabContent>

                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={instructorEditLoading}
                      >
                        {instructorEditLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: instructorEditLoading,
                              invisible: !instructorEditLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
