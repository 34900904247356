import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FormFieldTypeEnum } from "../../helper/PsychometricEnum";
import { getAssessmentById } from "../../api/getAssessmentById";
import Typography from "../typography/Typography";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Widget from "../../components/Branch/Widget/Widget";
import { toast } from "react-toastify";

const Assessment = ({ history }) => {
  const [assessmentData, setAssessmentData] = useState({});
  const [selectData, setSelectedData] = useState("Select the value");
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAssessmentById(id);
        setAssessmentData(data);
      } catch (error) {
        console.error("Error fetching assessment:", error);
        setAssessmentData(null);
      }
    };

    fetchData();
  }, [id]);

  if (!assessmentData) {
    return (
      <div>
        <Typography>Form is either deleted or not available</Typography>
      </div>
    );
  }

  const handleSubmit = () => {
    toast.success(
      "Assessment submitted successfully, kindly co-ordinate with your assessor",
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );
    history.push("/admin/scholastic");
  };
  return (
    <Widget style={{ height: "auto" }} className={"p-2"}>
      <Row
        className="mb-3"
        style={{
          flexDirection: "column",
        }}
      >
        {/* <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}></Col> */}
        <h1 className="ml-4">{assessmentData.title}</h1>
        <p className="ml-4">{assessmentData.formDescription}</p>
      </Row>
      <form onSubmit={handleSubmit}>
        {assessmentData?.fields?.map((formFields) => {
          if (formFields.type === 2) {
            return (
              <>
                <FormGroup tag="fieldset">
                  <legend className="ml-2">
                    {formFields?.question}{" "}
                    {formFields.isRequired ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </legend>
                  <Row>
                    {formFields?.options?.map((radioOptions, index) => (
                      <Col xl={6} className="ml-4 rounded mb-2 border p-2">
                        <FormGroup check key={index}>
                          <Input
                            name={formFields.label}
                            value={radioOptions.score}
                            id={formFields.label}
                            type="radio"
                            required={formFields.isRequired}
                          />
                          <Label check for={formFields.label}>
                            {radioOptions.value}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
              </>
            );
          }
          if (formFields.type === 3) {
            return (
              <>
                <FormGroup tag="fieldset">
                  <legend>
                    {formFields.question}
                    {formFields.isRequired ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </legend>
                  <Row>
                    {formFields?.options?.map((checkOptions, index) => (
                      <Col xl={3}>
                        <FormGroup check>
                          <Input type="checkbox" id={checkOptions.label} />
                          <Label
                            check
                            value={checkOptions.score}
                            for={checkOptions.label}
                          >
                            {checkOptions.value}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
              </>
            );
          }
          if (formFields.type === 4) {
            return (
              <>
                <FormGroup tag="fieldset">
                  <legend>
                    {formFields.question}
                    {formFields.isRequired ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </legend>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={selectData}
                    required={formFields.isRequired}
                    onChange={(ev) => setSelectedData(ev.target.value)}
                  >
                    {formFields?.options?.map((checkOptions, index) => (
                      <option
                        key={checkOptions.score}
                        value={checkOptions.score}
                      >
                        {checkOptions.value}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </>
            );
          }
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
            type="submit"
            color="secondary-red"
          >
            <span className="ml-1">Submit</span>
          </Button>
        </div>
      </form>
    </Widget>
  );
};

export default Assessment;
