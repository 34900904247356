import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  addOnlineCourseQuiz,
  getOnlineCourseQuiz,
} from "../../../../actions/onlineCourseActions";
import { booleanStatus } from "../../../../data/institute/institute";
import Accordion from "../../../../UI/Accordion/Accordion";
import {
  convertToBase64,
  formatBytes,
} from "../../../../UI/Input/ImageUpload/ImageUpload";
import CustomModal from "../../../../UI/Modal/Modal";

const QuizEditForm = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [modalBody] = useState(
    "Are you sure you want to delete this question?"
  );

  const dispatch = useDispatch();

  const quizSectionSaveReducer = useSelector(
    (state) => state.onlineCourseQuizAdd
  );

  const { onlineCourseQuizAddLoading } = quizSectionSaveReducer;

  const onSelectAccordion = (idx) => {
    const tempQuizSection = [...props.quizQuestion];

    tempQuizSection.map((item, index) => {
      if (item.index == idx) {
        props.setQuizQuestion(item);
        if (item.options.length > 0) {
          props.setQuizAnswer(item.options);
        } else {
          props.setQuizAnswer([
            {
              optionName: "",
              isCorrect: false,
            },
            {
              optionName: "",
              isCorrect: false,
            },
            {
              optionName: "",
              isCorrect: false,
            },
            {
              optionName: "",
              isCorrect: false,
            },
          ]);
        }

        window.scroll({
          top: index * 45,
          left: 0,
        });

        item.isSelected = !item.isSelected;
      } else {
        item.isSelected = false;
      }
    });

    props.setQuizQuestion(tempQuizSection);
  };

  //onQuestionChange

  const onQuestionChange = async (event) => {
    let sectionObj = [...props.quizQuestion];
    sectionObj[event.target.dataset.id].isModify = true;

    if (["title"].includes(event.target.name)) {
      sectionObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }

    if (["isResource"].includes(event.target.name)) {
      if (!JSON.parse(event.target.value)) {
        sectionObj[event.target.dataset.id].fileInfo = "";
        sectionObj[event.target.dataset.id].fileType = "";
        sectionObj[event.target.dataset.id].fileName = "";
        sectionObj[event.target.dataset.id].isNewFile = false;
      }
      sectionObj[event.target.dataset.id][event.target.name] = JSON.parse(
        event.target.value
      );
    }

    if (["point"].includes(event.target.name)) {
      sectionObj[event.target.dataset.id][event.target.name] = Number(
        event.target.value
      );
    }

    if (["fileInfo"].includes(event.target.name)) {
      const file = event?.target.files[0];

      if (file) {
        const uploadedImgSize = formatBytes(file?.size);

        if (parseInt(uploadedImgSize) > 10000) {
          // setImgSizeError("Image size should not be greater than 200kb");
        } else {
          const base64 = await convertToBase64(file);

          const replacedBase64 = base64?.split("base64,");

          sectionObj[event.target.dataset.id].fileInfo = replacedBase64[1];
          sectionObj[event.target.dataset.id].fileType = file?.type;
          sectionObj[event.target.dataset.id].fileName = file?.name;
          sectionObj[event.target.dataset.id].isNewFile = true;
        }
      }
    }

    props.setQuizQuestion(sectionObj);
  };
  const onAnswerChange = (event, selQuestion, id) => {
    const sectionAnswer = [...props.quizAnswer];
    let sectionObj = [...props.quizQuestion];

    if (["optionName"].includes(event.target.name)) {
      sectionAnswer[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if ([`isCorrect${id}`].includes(event.target.name)) {
      sectionAnswer.map((ans, i) => (ans.isCorrect = false));
      sectionAnswer[event.target.dataset.id].isCorrect = JSON.parse(
        event.target.checked
      );
    }

    sectionObj.map((item, index) => {
      if (item.index === selQuestion.index) {
        item.isModify = true;
        item.options = props.quizAnswer;
      }
    });

    props.setQuizQuestion(sectionObj);
  };
  const onQuizChange = (event) => {
    props.setQuizSection({
      ...props.quizSection,
      quizName: event.target.value,
    });
  };

  const onSaveQuiz = () => {
    const quizInfo = {
      onlineCourseTopicID: props.quizSection.onlineCourseTopicID,
      quizName: props.quizSection.quizName,
      quizQuestions: props.quizQuestion,
    };

    dispatch(
      addOnlineCourseQuiz(quizInfo, () => {
        dispatch(
          getOnlineCourseQuiz(props.quizSection.onlineCourseTopicID, () => {})
        );
      })
    );
  };

  const removeSectionTopic = (item) => {
    setIsModalOpen(true);
    setActiveQuestion(item);
  };

  const onDeleteCourseTopic = () => {
    const tempSectionObj = [...props.quizQuestion];
    setIsModalOpen(false);
    props.setQuizQuestion(
      tempSectionObj.filter((r, i) => r.index !== activeQuestion.index)
    );
  };

  return (
    <>
      <CustomModal
        modal={isModalOpen}
        modalTitle="Delete"
        isDoSomething={true}
        onModalAction={onDeleteCourseTopic}
        isDisabled={false}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        {modalBody}
      </CustomModal>
      <Row className="accordion-body py-3">
        <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
          <Row className="course-topic-section mx-3 my-3 py-3">
            <Col className="mb-1 mb-sm-3 mb-md-0 pb-3" md={12}>
              <span className="d-flex">
                <span className="d-flex font-weight-bold mr-3">
                  Topic Name:
                </span>

                <span>{props.activeTopic.courseTopicTitle}</span>
              </span>
            </Col>
            <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
              <FormGroup>
                <Label for="quizName">Quiz Name</Label>
                <Input
                  maxLength={200}
                  type="text"
                  name="quizName"
                  defaultValue={props.quizSection.quizName}
                  onChange={(e) => onQuizChange(e)}
                  autoComplete="nothing"
                  rows="2"
                  cols="50"
                />
              </FormGroup>
            </Col>
          </Row>
          {props.quizQuestion?.map((questionItem, questionIndex) => {
            let title = `title-${questionIndex}`;
            let point = `point-${questionIndex}`;
            let isResource = `isResource-${questionIndex}`;
            let fileInfo = `fileInfo-${questionIndex}`;

            return (
              <Accordion
                title={`Question ${questionIndex + 1} : ${questionItem.title}`}
                key={questionIndex}
                isOpen={questionItem.isSelected}
                onToggle={() =>
                  onSelectAccordion(questionItem.index, questionItem)
                }
              >
                <Col
                  className="mb-1 mb-sm-3 mb-md-3"
                  md={12}
                  key={questionIndex}
                >
                  <Row className="course-topic-section py-3">
                    <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                      <FormGroup>
                        <Label for="title">Question</Label>
                        <Input
                          maxLength={200}
                          type="text"
                          name="title"
                          data-id={questionIndex}
                          id={title}
                          defaultValue={questionItem.title}
                          autoComplete="nope"
                          onChange={(e) => onQuestionChange(e, questionItem)}
                        />
                      </FormGroup>
                    </Col>

                    <Col className="mb-1 mb-sm-3 mb-md-0 " md={6}>
                      <FormGroup>
                        <Label for="point">Point</Label>
                        <Input
                          maxLength={200}
                          type="number"
                          name="point"
                          data-id={questionIndex}
                          min={1}
                          id={point}
                          defaultValue={questionItem.point}
                          onChange={(e) => onQuestionChange(e, questionItem)}
                          autoComplete="nope"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="mb-2 mb-sm-4 mb-md-0" md={6}>
                      <FormGroup>
                        <Label for="isResource">Resource</Label>
                        <Input
                          type="select"
                          name="isResource"
                          data-id={questionIndex}
                          id={isResource}
                          defaultValue={questionItem.isResource}
                          onChange={(e) => onQuestionChange(e, questionItem)}
                        >
                          {booleanStatus.map((item, index) => (
                            <option value={item.status} key={index}>
                              {item.title}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    {questionItem.isResource && (
                      <Col className="mb-2 mt-3 mb-sm-4 mb-md-0" md={12}>
                        <FormGroup>
                          <CustomInput
                            maxLength={200}
                            type="file"
                            name="fileInfo"
                            data-id={questionIndex}
                            id={fileInfo}
                            onChange={(e) => onQuestionChange(e, questionItem)}
                            autoComplete="nope"
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                      <Table bordered className="my-4 table-header">
                        <thead>
                          <tr className="">
                            <th className="text-center p-2 base-light-gray ">
                              Answer
                            </th>
                            <th
                              className="text-center p-2 base-light-gray"
                              align="center"
                            >
                              Correct Answer
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.quizAnswer.map((quizItem, quizAnsIndex) => {
                            let optionName = `optionName-${quizAnsIndex}`;
                            let isCorrect = `isCorrect-${quizAnsIndex}`;

                            return (
                              <tr>
                                <td align="center">
                                  <Input
                                    type="text"
                                    name="optionName"
                                    autoComplete="nope"
                                    data-id={quizAnsIndex}
                                    id={optionName}
                                    value={quizItem.optionName}
                                    onChange={(e) =>
                                      onAnswerChange(
                                        e,
                                        questionItem,
                                        questionItem.index
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  align="center"
                                  style={{ verticalAlign: "unset" }}
                                >
                                  <Input
                                    type="radio"
                                    name={"isCorrect" + questionItem.index}
                                    data-id={quizAnsIndex}
                                    id={isCorrect}
                                    style={{ height: "25px", width: "25px" }}
                                    defaultValue={quizItem.isCorrect}
                                    checked={quizItem.isCorrect}
                                    onChange={(e) =>
                                      onAnswerChange(
                                        e,
                                        questionItem,
                                        questionItem.index
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>

                    {!props.isPublished && (
                      <Col
                        className="mb-1 mb-sm-3 mb-md-0 d-flex justify-content-end"
                        md={12}
                      >
                        <Button
                          className=" d-flex justify-content-between align-items-center"
                          style={{ height: "2.1rem" }}
                          color="danger"
                          onClick={() => removeSectionTopic(questionItem)}
                        >
                          Remove Question
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Accordion>
            );
          })}
        </Col>
      </Row>

      <Col className="d-flex justify-content-end align-items-center">
        <Button
          className="my-3 mx-3"
          style={{ height: "2.5rem" }}
          color="success"
          onClick={onSaveQuiz}
          disabled={onlineCourseQuizAddLoading}
        >
          {onlineCourseQuizAddLoading && <Spinner size="sm" />}
          <span className="mx-1">Save Quiz</span>
        </Button>
        <Button
          className="my-3"
          style={{ height: "2.5rem" }}
          color="primary"
          onClick={() => props.add()}
          disabled={onlineCourseQuizAddLoading}
        >
          <span> Add Question</span>
        </Button>
      </Col>
    </>
  );
};
export default QuizEditForm;
