import axios from "axios";
import { toast } from "react-toastify";
import {
  SEO_ADD_FAIL,
  SEO_ADD_REQUEST,
  SEO_ADD_SUCCESS,
  SEO_EDIT_FAIL,
  SEO_EDIT_REQUEST,
  SEO_EDIT_SUCCESS,
  SEO_LIST_FAIL,
  SEO_LIST_REQUEST,
  SEO_LIST_SUCCESS,
  SEO_SINGLE_FAIL,
  SEO_SINGLE_REQUEST,
  SEO_SINGLE_SUCCESS,
} from "../constants/seoConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const listSeo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEO_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Seo/AllSeo`,
      config
    );

    dispatch({
      type: SEO_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEO_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const getSeoById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEO_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Seo/GetSeoByID?id=${id}`,
      config
    );

    dispatch({
      type: SEO_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEO_SINGLE_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addSeo = (addInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SEO_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Seo/Create`,
      addInfo,
      config
    );

    toast.dismiss();
    toast.success("Seo created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SEO_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SEO_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const editSeo = (editInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SEO_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Seo/Update`,
      editInfo,
      config
    );

    toast.dismiss();
    toast.success("Seo edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SEO_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SEO_EDIT_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
