import axios from "axios";
import React, { useEffect } from "react";
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { getUserDetails } from "../../../../helper/getUserDetail";

function EditQuestionForm({ formId, fields, setFields }) {
  useEffect(() => {
    if (fields.length === 0 && formId) {
      fetchQuestions(formId);
    }
  }, [fields, formId]);

  const fetchQuestions = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/GetFormQuestionByID/${id}`,
        config
      );

      // Check the fetched data structure
      if (data.fields) {
        data.fields.forEach((field) => {
          field.options = field.options || [];
        });

        setFields(data.fields);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = fields.map((field, idx) =>
      idx === index ? { ...field, [name]: value } : field
    );
    setFields(updatedFields);
  };

  const handleOptionChange = (fieldIndex, optionIndex, event) => {
    const { name, value } = event.target;
    const updatedFields = fields.map((field, idx) => {
      if (idx === fieldIndex) {
        const updatedOptions = field.options.map((option, optIdx) =>
          optIdx === optionIndex ? { ...option, [name]: value } : option
        );
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const addNewField = () => {
    const newField = {
      fieldId: Date.now(), // Generates proper fieldId
      questionNumber: fields.length + 1,
      sectionNumber: fields.length + 1,
      sectionName: `Section ${fields.length + 1}`,
      question: "",
      type: 1,
      label: "radio",
      isRequired: false,
      options: [],
    };
    setFields([...fields, newField]);
  };

  const addNewOption = (fieldIndex) => {
    const newOption = {
      optionId: Date.now(), // Generates proper optionId
      label: "",
      value: "",
      score: 0,
      formFieldFieldId: fields[fieldIndex].fieldId,
    };
    const updatedFields = fields.map((field, idx) => {
      if (idx === fieldIndex) {
        return { ...field, options: [...field.options, newOption] };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = fields
      .filter((_, idx) => idx !== index)
      .map((field, idx) => ({
        ...field,
        questionNumber: idx + 1,
        sectionNumber: idx + 1,
        sectionName: `Section ${idx + 1}`,
      }));
    setFields(updatedFields);
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const updatedFields = fields.map((field, idx) => {
      if (idx === fieldIndex) {
        const updatedOptions = field.options.filter(
          (_, optIdx) => optIdx !== optionIndex
        );
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setFields(updatedFields);
  };
  return (
    <div>
      {fields.map((field, index) => (
        <FormGroup key={field.fieldId}>
          <Label>Question {field.questionNumber}</Label>
          <Input
            type="text"
            name="question"
            value={field.question}
            onChange={(e) => handleFieldChange(index, e)}
          />
          {field.options.map((option, optIndex) => (
            <Row key={option.optionId}>
              <Col md={4}>
                <Input
                  type="text"
                  name="label"
                  placeholder="Option Label"
                  value={option.label}
                  onChange={(e) => handleOptionChange(index, optIndex, e)}
                />
              </Col>
              <Col md={4}>
                <Input
                  type="text"
                  name="value"
                  placeholder="Option Value"
                  value={option.value}
                  onChange={(e) => handleOptionChange(index, optIndex, e)}
                />
              </Col>
              <Col md={2}>
                <Input
                  type="number"
                  name="score"
                  placeholder="Score"
                  value={option.score}
                  onChange={(e) => handleOptionChange(index, optIndex, e)}
                />
              </Col>
              <Col md={2}>
                <Button
                  color="danger"
                  onClick={() => removeOption(index, optIndex)}
                >
                  Remove Option
                </Button>
              </Col>
            </Row>
          ))}
          <Button color="secondary" onClick={() => addNewOption(index)}>
            Add Option
          </Button>
          <Button color="danger" onClick={() => removeField(index)}>
            Remove Question
          </Button>
        </FormGroup>
      ))}
      <Button color="primary" onClick={addNewField}>
        Add Question
      </Button>
    </div>
  );
}

export default EditQuestionForm;
