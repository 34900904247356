import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import PageTable from "../../tables/components/PageTable/PageTable";

const Page = () => {
  return (
    <div>
      <Meta title="Pages | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <PageTable tableName="Page List" />
        </Col>
      </Row>
    </div>
  );
};

export default Page;
