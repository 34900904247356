import classnames from "classnames";
import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import AdminForm from "../adminList/AdminForm";
import InstructorForm from "../InstructorList/InstructorForm";
import UserForm from "../students/UserForm";
import CounsellorForm from "../CounsellorList/CounsellorForm";

function UserRegister() {
  const [activeTab, setActive] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  return (
    <Row>
      <Meta title="Add | User | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="p-5">
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Student
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Admin
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Online Instructor
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      Counsellor
                    </NavLink>
                  </NavItem>

                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      Exam creator
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <UserForm />
                  </TabPane>
                  <TabPane tabId="2">
                    <AdminForm />
                  </TabPane>
                  <TabPane tabId="3">
                    <InstructorForm />
                  </TabPane>
                  <TabPane tabId="4">
                    <CounsellorForm />
                  </TabPane>
                  {/* <TabPane tabId="4">
                    <ExamCreatorForm />
                  </TabPane> */}
                </TabContent>
              </div>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default UserRegister;
