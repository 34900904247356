export const SPEAKER_ADD_REQUEST = "SPEAKER_ADD_REQUEST";
export const SPEAKER_ADD_SUCCESS = "SPEAKER_ADD_SUCCESS";
export const SPEAKER_ADD_FAIL = "SPEAKER_ADD_FAIL";

export const INSTRUCTOR_ADD_REQUEST = "INSTRUCTOR_ADD_REQUEST";
export const INSTRUCTOR_ADD_SUCCESS = "INSTRUCTOR_ADD_SUCCESS";
export const INSTRUCTOR_ADD_FAIL = "INSTRUCTOR_ADD_FAIL";

export const EVENT_ADD_REQUEST = "EVENT_ADD_REQUEST";
export const EVENT_ADD_SUCCESS = "EVENT_ADD_SUCCESS";
export const EVENT_ADD_FAIL = "EVENT_ADD_FAIL";

export const SPEAKER_LIST_REQUEST = "SPEAKER_LIST_REQUEST";
export const SPEAKER_LIST_SUCCESS = "SPEAKER_LIST_SUCCESS";
export const SPEAKER_LIST_FAIL = "SPEAKER_LIST_FAIL";

export const INSTRUCTOR_LIST_REQUEST = "INSTRUCTOR_LIST_REQUEST";
export const INSTRUCTOR_LIST_SUCCESS = "INSTRUCTOR_LIST_SUCCESS";
export const INSTRUCTOR_LIST_FAIL = "INSTRUCTOR_LIST_FAIL";


export const EVENT_LIST_REQUEST = "EVENT_LIST_REQUEST";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_FAIL = "EVENT_LIST_FAIL";

export const REGISTERED_EVENT_LIST_REQUEST = "REGISTERED_EVENT_LIST_REQUEST";
export const REGISTERED_EVENT_LIST_SUCCESS = "REGISTERED_EVENT_LIST_SUCCESS";
export const REGISTERED_EVENT_LIST_FAIL = "REGISTERED_EVENT_LIST_FAIL";

export const EVENT_EDIT_REQUEST = "EVENT_EDIT_REQUEST";
export const EVENT_EDIT_SUCCESS = "EVENT_EDIT_SUCCESS";
export const EVENT_EDIT_FAIL = "EVENT_EDIT_FAIL";

export const SPEAKER_EDIT_REQUEST = "SPEAKER_EDIT_REQUEST";
export const SPEAKER_EDIT_SUCCESS = "SPEAKER_EDIT_SUCCESS";
export const SPEAKER_EDIT_FAIL = "SPEAKER_EDIT_FAIL";

export const INSTRUCTOR_EDIT_REQUEST = "INSTRUCTOR_EDIT_REQUEST";
export const INSTRUCTOR_EDIT_SUCCESS = "INSTRUCTOR_EDIT_SUCCESS";
export const INSTRUCTOR_EDIT_FAIL = "INSTRUCTOR_EDIT_FAIL";

export const EVENT_SINGLE_REQUEST = "EVENT_SINGLE_REQUEST";
export const EVENT_SINGLE_SUCCESS = "EVENT_SINGLE_SUCCESS";
export const EVENT_SINGLE_FAIL = "EVENT_SINGLE_FAIL";

export const SPEAKER_SINGLE_REQUEST = "SPEAKER_SINGLE_REQUEST";
export const SPEAKER_SINGLE_SUCCESS = "SPEAKER_SINGLE_SUCCESS";
export const SPEAKER_SINGLE_FAIL = "SPEAKER_SINGLE_FAIL";

export const INSTRUCTOR_SINGLE_REQUEST = "INSTRUCTOR_SINGLE_REQUEST";
export const INSTRUCTOR_SINGLE_SUCCESS = "INSTRUCTOR_SINGLE_SUCCESS";
export const INSTRUCTOR_SINGLE_FAIL = "INSTRUCTOR_SINGLE_FAIL";

export const EVENT_ACTIVE_REQUEST = "EVENT_ACTIVE_REQUEST";
export const EVENT_ACTIVE_SUCCESS = "EVENT_ACTIVE_SUCCESS";
export const EVENT_ACTIVE_FAIL = "EVENT_ACTIVE_FAIL";

export const SPEAKER_EVENT_REQUEST = "SPEAKER_EVENT_REQUEST";
export const SPEAKER_EVENT_SUCCESS = "SPEAKER_EVENT_SUCCESS";
export const SPEAKER_EVENT_FAIL = "SPEAKER_EVENT_FAIL";

export const INSTRUCTOR_EVENT_REQUEST = "INSTRUCTOR_EVENT_REQUEST";
export const INSTRUCTOR_EVENT_SUCCESS = "INSTRUCTOR_EVENT_SUCCESS";
export const INSTRUCTOR_EVENT_FAIL = "INSTRUCTOR_EVENT_FAIL";