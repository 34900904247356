import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addOnlineCourse } from "../../../../actions/onlineCourseActions";
import { getUserById } from "../../../../api/getUserById";
import { getRandomCharacters } from "../../../../helper/randomCharacter";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import LearningObjForm from "./LearningObjForm";
import OnlineCourseForm from "./OnlineCourseForm";
import PrerequisiteForm from "./PrerequisiteForm";
import TargetObjForm from "./TargetObjForm";

var todayDate = new Date();

function Create({ history }) {
  const [onlineCourse, setOnlineCourse] = useState({
    slug: "",
    title: "",
    subTitle: "",
    progress: "",
    skillLevel: "",
    studentCount: 0,
    courseLanguage: "",
    captions: true,
    isActive: true,
    lectureNumbers: 0,
    videoDuration: "",
    primaryInstructorID: null,
    certificateGenerator: "",
    additionalFeatures: "",
    promotionalVideoLink: "",
    onlineCourseCategoryID: 1,
    onlineCourseSubCategoryID: 1,
    isPrivate: true,
    isApproved: false,
    isFeatured: true,
    grossAmount: 0,
    saleAmount: 0,
    isCouponAllowed: true,
    currencyID: 1,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    onlineCourseInstituteID: 1,
    createdBy: null,
    createdDate: todayDate,
  });
  const [userInfo, setUserInfo] = useState(null);
  const [onlineCourseDescription, setOnlineCourseDescription] = useState("");
  const [studentContent, setStudentContent] = useState("");
  const [onlineCourseInstructorList, setOnlineCourseInstructorList] = useState(
    []
  );
  const [allInstructorList, setAllInstructorList] = useState([]);
  const [onlineCourseError, setOnlineCourseError] = useState({
    primaryInstructorId: false,
  });

  const [onlineCourseInstructorMulList, setOnlineCourseInstructorMulList] =
    useState([]);

  const [instructorID, setInstructionID] = useState([]);
  const [activeTab, setActive] = useState("1");

  const [onlineCourseImgPath, setOnlineCourseImgPath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [learningObjectiveModal, setLearningObjectModal] = useState({
    learningObjectiveDetails: [
      {
        index: getRandomCharacters(),
        learnObjectiveName: "",
      },
    ],
  });

  const [prerequisiteModel, setPrerequisiteModel] = useState({
    prerequisiteDetails: [
      {
        index: getRandomCharacters(),
        prerequisiteName: "",
      },
    ],
  });

  const [targetGroupModel, setTargetGroupModel] = useState({
    targetGroupDetails: [
      {
        index: getRandomCharacters(),
        targetGroupName: "",
        grades: "",
        locations: "",
        languages: "",
      },
    ],
  });

  const [sectionModel, setSectionModel] = useState({
    sectionDetails: [
      {
        index: getRandomCharacters(),
        sectionTitle: "",
        sectionDescription: "",
      },
    ],
  });

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const onlineInstituteInstructorReducer = useSelector(
    (state) => state.onlineCourseInstructor
  );
  const { onlineCourseInstructor } = onlineInstituteInstructorReducer;

  useEffect(() => {
    getUserById()
      .then((data) => setUserInfo(data.data))
      .catch((error) => setUserInfo(null));
  }, []);

  useEffect(() => {
    if (onlineCourseInstructor && userInfo) {
      if (userInfo.roleName !== "superadmin") {
        const avlInstructors = onlineCourseInstructor.filter(
          (item) => item.value === userInfo?.uid
        );
        setOnlineCourse({
          ...onlineCourse,
          primaryInstructorID: avlInstructors[0].value,
        });
        setOnlineCourseInstructorList(avlInstructors);
      } else {
        setOnlineCourseInstructorList(onlineCourseInstructor);
      }
      setOnlineCourseInstructorMulList(onlineCourseInstructor);
      setAllInstructorList(onlineCourseInstructor);
    }
  }, [onlineCourseInstructor, userInfo]);

  // learningObjectiveDetails;

  const addNewLearningObj = (e) => {
    setLearningObjectModal((prevState) => ({
      learningObjectiveDetails: [
        ...prevState.learningObjectiveDetails,
        {
          index: getRandomCharacters(),
          learnObjectiveName: "",
        },
      ],
    }));
  };

  const deleteLearningObj = (record) => {
    setLearningObjectModal({
      learningObjectiveDetails:
        learningObjectiveModal.learningObjectiveDetails.filter(
          (r) => r !== record
        ),
    });
  };

  // prerequisiteDetails
  const addNewPrerequisiteObj = (e) => {
    setPrerequisiteModel((prevState) => ({
      prerequisiteDetails: [
        ...prevState.prerequisiteDetails,
        {
          index: getRandomCharacters(),
          prerequisiteName: "",
        },
      ],
    }));
  };

  const deletePrerequisiteObj = (record) => {
    setPrerequisiteModel({
      prerequisiteDetails: prerequisiteModel.prerequisiteDetails.filter(
        (r) => r !== record
      ),
    });
  };

  // targetGroupDetails

  const addNewTargetObj = (e) => {
    setTargetGroupModel((prevState) => ({
      targetGroupDetails: [
        ...prevState.targetGroupDetails,
        {
          index: getRandomCharacters(),
          targetGroupName: "",
          grades: "",
          locations: "",
          languages: "",
        },
      ],
    }));
  };

  const deleteTargetObj = (record) => {
    setTargetGroupModel({
      targetGroupDetails: targetGroupModel.targetGroupDetails.filter(
        (r) => r !== record
      ),
    });
  };

  // sectionDetails

  const addNewSectionObj = (e) => {
    setSectionModel((prevState) => ({
      sectionDetails: [
        ...prevState.sectionDetails,
        {
          index: getRandomCharacters(),
          sectionTitle: "",
          sectionDescription: "",
        },
      ],
    }));
  };

  const deleteSectionObj = (record) => {
    setSectionModel({
      sectionDetails: sectionModel.sectionDetails.filter((r) => r !== record),
    });
  };

  ////

  const changeCreds = (event) => {
    setOnlineCourseError({
      ...onlineCourseError,
      primaryInstructorId: false,
    });
    setOnlineCourse({
      ...onlineCourse,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "title") {
      let sectionSlug = event.target.value;
      sectionSlug = sectionSlug.replace(/\s+/g, "-");

      let output = sectionSlug.slice(-1);

      output === "-" && (sectionSlug = sectionSlug.slice(0, -1));

      setOnlineCourse({
        ...onlineCourse,
        title: event.target.value,
        slug: sectionSlug,
      });
    }

    if (event.target.name === "primaryInstructorID") {
      onOnlineCourseInstructorChange(event.target.value);
      if (event.target.value === null) {
        setOnlineCourseError({
          ...onlineCourseError,
          primaryInstructorId: true,
        });
      }
    }
  };

  const onOnlineCourseInstructorChange = (instructorId) => {
    const tempInstructorList = allInstructorList.filter(
      (primaryItem, i) => primaryItem.value !== Number(instructorId)
    );
    setOnlineCourseInstructorMulList(tempInstructorList);
  };

  const onMultipleInstructorChange = (event) => {
    setInstructionID(event);
    if (userInfo.roleName === "superadmin") {
      const filteredPrimaryInstructor = allInstructorList.filter(
        (val) => !event.includes(val)
      );
      setOnlineCourseInstructorList(filteredPrimaryInstructor);
    }
  };

  const handleChange = (event) => {
    if (["learnObjectiveName"].includes(event.target.name)) {
      let learningObj = [...learningObjectiveModal.learningObjectiveDetails];

      learningObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }

    if (["prerequisiteName"].includes(event.target.name)) {
      let prerequisiteObj = [...prerequisiteModel.prerequisiteDetails];
      prerequisiteObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (
      ["targetGroupName", "grades", "locations", "languages"].includes(
        event.target.name
      )
    ) {
      let targetGroupObj = [...targetGroupModel.targetGroupDetails];
      targetGroupObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (["sectionTitle", "sectionDescription"].includes(event.target.name)) {
      let sectionObj = [...sectionModel.sectionDetails];
      sectionObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
  };

  const onlineCourseAddReducer = useSelector((state) => state.onlineCourseAdd);
  const { onlineCourseAddLoading, onlineCourseAdded } = onlineCourseAddReducer;

  const createOnlineCourse = (e) => {
    e.preventDefault();

    const onlineCourseAddInfo = {
      title: onlineCourse.title,
      subTitle: onlineCourse.subTitle,
      description: onlineCourseDescription,
      studentContent: studentContent,
      progress: onlineCourse.progress,
      skillLevel: onlineCourse.skillLevel,
      languages: onlineCourse.courseLanguage,
      captions: JSON.parse(onlineCourse.captions),
      lectureNumbers: JSON.parse(onlineCourse.lectureNumbers),
      videoDuration: onlineCourse.videoDuration,
      certificateGenerator: onlineCourse.certificateGenerator,
      additionalFeatures: onlineCourse.additionalFeatures,
      primaryInstructorID: Number(onlineCourse.primaryInstructorID),
      instructorID: instructorID,
      courseImage: onlineCourseImgPath.myFile,
      isNewImage: onlineCourseImgPath.IsNewImage,
      imageName: onlineCourseImgPath.ImageName,
      imageType: onlineCourseImgPath.ImageType,
      promotionalVideoLink: onlineCourse.promotionalVideoLink,
      onlineCourseCategoryID: JSON.parse(onlineCourse.onlineCourseCategoryID),
      onlineCourseSubCategoryID: JSON.parse(
        onlineCourse.onlineCourseSubCategoryID
      ),
      isPrivate: JSON.parse(onlineCourse.isPrivate),
      isApproved: JSON.parse(onlineCourse.isApproved),
      isActive: JSON.parse(onlineCourse.isActive),
      grossAmount: JSON.parse(onlineCourse.grossAmount),
      saleAmount: JSON.parse(onlineCourse.saleAmount),
      isCouponAllowed: JSON.parse(onlineCourse.isCouponAllowed),
      currencyID: JSON.parse(onlineCourse.currencyID),
      bannerImagePath: bannerImagePath?.myFile,
      isBannerNewImage: bannerImagePath?.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      slug: onlineCourse.slug,
      pageTitle: onlineCourse.pageTitle,
      metaTitle: onlineCourse.metaTitle,
      metaKeywords: onlineCourse.metaKeywords,
      metaDescription: onlineCourse.metaDescription,
      onlineCourseInstituteID: JSON.parse(onlineCourse.onlineCourseInstituteID),
      learningObjectives: learningObjectiveModal.learningObjectiveDetails,
      prerequisites: prerequisiteModel.prerequisiteDetails,
      targetGroups: targetGroupModel.targetGroupDetails,
      createdBy: onlineCourse.createdBy,
      createdDate: onlineCourse.createdDate,
      isFeatured: JSON.parse(onlineCourse.isFeatured),
    };

    dispatch(
      addOnlineCourse(onlineCourseAddInfo, (id) =>
        history.push(`/admin/online-courses/edit/${id}`)
      )
    );
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form
                className="p-5"
                onSubmit={(event) => createOnlineCourse(event)}
                onChange={(e) => handleChange(e)}
              >
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Learning Objectives
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Prerequisites
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        Target Groups
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          toggle("6");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <OnlineCourseForm
                        onlineCourse={onlineCourse}
                        changeCreds={changeCreds}
                        onlineCourseDescription={onlineCourseDescription}
                        setOnlineCourseDescription={setOnlineCourseDescription}
                        studentContent={studentContent}
                        setStudentContent={setStudentContent}
                        onlineCourseImgPath={onlineCourseImgPath}
                        setOnlineCourseImgPath={setOnlineCourseImgPath}
                        instructorID={instructorID}
                        setInstructionID={setInstructionID}
                        onlineCourseInstructorList={onlineCourseInstructorList}
                        onlineCourseInstructorMulList={
                          onlineCourseInstructorMulList
                        }
                        onMultipleInstructorChange={onMultipleInstructorChange}
                        onlineCourseError={onlineCourseError}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <LearningObjForm
                        learningObj={learningObjectiveModal}
                        add={addNewLearningObj}
                        delete={deleteLearningObj}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <PrerequisiteForm
                        prerequisiteObj={prerequisiteModel}
                        add={addNewPrerequisiteObj}
                        delete={deletePrerequisiteObj}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <TargetObjForm
                        targetObj={targetGroupModel}
                        add={addNewTargetObj}
                        delete={deleteTargetObj}
                      />
                    </TabPane>

                    <TabPane tabId="6">
                      <Seo seoMeta={onlineCourse} changeCreds={changeCreds} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={onlineCourseAddLoading}
                      >
                        {onlineCourseAddLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: onlineCourseAddLoading,
                              invisible: !onlineCourseAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
