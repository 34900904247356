import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { courseCategoryList } from "../../../actions/courseActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Create from "../../../components/Main/Course/Create/Create";

const CourseForm = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(courseCategoryList());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Add | Course | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default CourseForm;
