import React from "react";
import Meta from "../../components/Branch/Meta/Meta";
import Create from "../../components/Main/Instructor/Create/Create";

const InstructorForm = ({ history }) => {
  return (
    <div>
      <Meta title="Add | Instructor | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default InstructorForm;
