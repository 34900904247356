import React from "react";
import FooterIcon from "../Icons/FooterIcon";
import s from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>
        2021-2024 All rights reserved. Made with <FooterIcon />{" "}
        <a
          className={s.footerLabel}
          style={{ color: "#6b859e" }}
          href="https://eduberance.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          by Eduberance Education Ventures
        </a>
      </span>
    </div>
  );
};

export default Footer;
