const ASSESSMENTS_LIST_REQUEST = "ASSESSMENTS_LIST_REQUEST";
const ASSESSMENTS_LIST_SUCCESS = "ASSESSMENTS_LIST_SUCCESS";
const ASSESSMENTS_LIST_FAIL = "ASSESSMENTS_LIST_FAIL";

export const ASSESSMENTS_DETAIL_REQUEST = "ASSESSMENTS_DETAIL_REQUEST";
export const ASSESSMENTS_DETAIL_SUCCESS = "ASSESSMENTS_DETAIL_SUCCESS";
export const ASSESSMENTS_DETAIL_FAIL = "ASSESSMENTS_DETAIL_FAIL";


export const ASSESSMENTS_CREATE_REQUEST = "ASSESSMENTS_CREATE_REQUEST";
export const ASSESSMENTS_CREATE_SUCCESS = "ASSESSMENTS_CREATE_SUCCESS";
export const ASSESSMENTS_CREATE_FAIL = "ASSESSMENTS_CREATE_FAIL";


export const ASSESSMENTS_EDIT_REQUEST = "ASSESSMENTS_EDIT_REQUEST";
export const ASSESSMENTS_EDIT_SUCCESS = "ASSESSMENTS_EDIT_SUCCESS";
export const ASSESSMENTS_EDIT_FAIL = "ASSESSMENTS_EDIT_FAIL";

export const ASSESSMENTS_QUESTIONS_REQUEST = "ASSESSMENTS_QUESTIONS_REQUEST";
export const ASSESSMENTS_QUESTIONS_SUCCESS = "ASSESSMENTS_QUESTIONS_SUCCESS";
export const ASSESSMENTS_QUESTIONS_FAIL = "ASSESSMENTS_QUESTIONS_FAIL";


const textProp = {
  placeholder: "enter name",
  label: "name",
  fieldLabel: "name",
  required: false,
  score: 0,
};

const textAreaProp = {
  placeholder: "tell me something about you",
  label: "description",
  fieldLabel: "description",
  required: false,
  score: 0,
};

const radioProp = {
  label: "Enter your question",
  fieldLabel: "radio",
  required: false,
  score: 0,
  radioArrays: [
    {
      value: "Option",
      label: "Option",
      score: 0,
    },
    {
      value: "Option",
      label: "Option",
      score: 0,
    },
  ],
};

const checkProp = {
  label: "Checkbox",
  fieldLabel: "checkbox",
  required: false,
  score: 0,
  multiOptionArrays: [
    {
      value: "Option",
      label: "Option",
      score: 0,
    },
    {
      value: "Option",
      label: "Option",
      score: 0,
    },
  ],
};

const dropDownProp = {
  label: "dropdown",
  fieldLabel: "dropdown",
  required: false,
  score: 0,
  multiOptionArrays: [
    {
      value: "option 1",
      label: "option1",
      score: 0,
    },
    {
      value: "option 2",
      label: "option2",
      score: 0,
    },
  ],
};

export {
  textProp,
  textAreaProp,
  radioProp,
  checkProp,
  dropDownProp,
  ASSESSMENTS_LIST_REQUEST,
  ASSESSMENTS_LIST_SUCCESS,
  ASSESSMENTS_LIST_FAIL,
};
