import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Widget from "../../../Branch/Widget/Widget";
import CreateForm from "./CreateForm";
import Seo from "../../../Branch/Seo/Seo";
import CreateQuestionForm from "./CreateQuestionForm";
import { getRandomCharacters } from "../../../../helper/randomCharacter";
import { AssessmentCreate } from "../../../../actions/assessments";
import {
  textProp,
  dropDownProp,
  textAreaProp,
  radioProp,
  checkProp,
} from "../../../../constants/assessmentConstants";
import { FormFieldTypeEnum } from "../../../../helper/PsychometricEnum";

function Create({ history }) {
  const dispatch = useDispatch();
  const [assessmentForm, setAssessmentForm] = useState({
    title: "",
    shortDescription: "",
    author: "",
    grossAmount: 0,
    saleAmount: 0,
    category: "",
    isActive: true,
    isFeatured: true,
    slug: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
  });

  const [assessmentDescription, setAssessmentDescription] = useState("");
  const [assessmentShortDescription, setAssessmentShortDescription] =
    useState("");
  const [assessmentImagePath, setAssessmentImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
  });
  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
  });

  const [fieldsModel, setFieldsModel] = useState({
    fields: [
      {
        index: getRandomCharacters(),
        question: "",
        label: "",
        placeholder: "",
        type: 0,
        isRequired: false,
        options: [
          {
            index: getRandomCharacters(),
            label: "",
            value: "",
            score: 0,
          },
        ],
      },
    ],
  });

  const [formFields, setFormFields] = useState([
    { type: "text", props: textProp },
  ]);

  const [activeTab, setActiveTab] = useState("1");

  // Createing a function to manage active tabs
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Fields Model
  // Adding new fields function

  const addNewFieldObj = (e) => {
    setFieldsModel((prevState) => ({
      fields: [
        ...prevState.fields,
        {
          index: getRandomCharacters(),
          question: "",
          label: "",
          placeholder: "",
          type: 0,
          isRequired: false,
          options: [
            {
              index: getRandomCharacters(),
              label: "",
              value: "",
              score: 0,
            },
          ],
        },
      ],
    }));
  };

  // Remove fields function

  const removeFieldObj = (index) => {
    setFieldsModel({
      fields: fieldsModel.fields.filter((item) => item !== index),
    });
  };

  // handling change

  const handleChange = (event) => {
    // if (["question", "label", "placeholder", "type", "isRequired", "options", "value", "score"].includes(event.target.name)){
    //   let fields = [...fieldsModel.fields];
    //   fields[event.target.dataset.id][event.target.name] = event.target.value;
    // }
  };

  const fields = formFields.map((data) => {
    return {
      question: data.props.label,
      label: data.props.fieldLabel,
      placeholder: data.props.label,
      type: FormFieldTypeEnum.indexOf(data.type),
      isRequired: data.props.required,
      radioButton: data.type === "radiobutton",
      options: (data.props?.radioArrays || data.props?.multiOptionArrays) ?? [],
    };
  });

  const formData = { fields };

  // Prevent Default state of the form
  const onCreateAssessmentForm = (event) => {
    event.preventDefault();
    const assessmentInfo = {
      title: assessmentForm.title,
      description: assessmentDescription,
      shortDescription: assessmentForm.shortDescription,
      author: assessmentForm.author,
      grossAmount:
        parseInt(assessmentForm.grossAmount) === 0
          ? 1
          : parseInt(assessmentForm.grossAmount),
      saleAmount:
        parseInt(assessmentForm.saleAmount) === 0
          ? 1
          : parseInt(assessmentForm.saleAmount),
      category: assessmentForm.category,
      isActive: JSON.parse(assessmentForm.isActive),
      isFeatured: JSON.parse(assessmentForm.isActive),
      image: assessmentImagePath?.myFile,
      IsNewImage: assessmentImagePath?.IsNewImage,
      ImageType: assessmentImagePath?.ImageType,
      // ImageName: assessmentImagePath?.ImageName,
      coverImage: bannerImagePath?.myFile,
      IsNewImage: bannerImagePath?.IsNewImage,
      ImageType: bannerImagePath?.ImageType,
      // ImageName: bannerImagePath?.ImageName,
      slug: assessmentForm.slug.toLowerCase(),
      metaTitle: assessmentForm.metaTitle,
      metaKeywords: assessmentForm.metaKeywords,
      metaDescription: assessmentForm.metaDescription,
      fields: formData.fields,
    };
    const assessmentData = {
      ...assessmentForm,
      ...assessmentInfo,
      ...formData,
    };
    dispatch(
      AssessmentCreate(assessmentData, () => {
        return history.push("/admin/scholastic");
      })
    );
  };

  const changeCreds = (event) => {
    setAssessmentForm({
      ...assessmentForm,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "title") {
      let sectionSlug = event.target.value;
      sectionSlug = sectionSlug.replace(/\s+/g, "-");

      let output = sectionSlug.slice(-1);

      output === "-" && (sectionSlug = sectionSlug.slice(0, -1));

      setAssessmentForm({
        ...assessmentForm,
        title: event.target.value,
        slug: sectionSlug,
      });
    }
  };

  // Using Reducer to manage state

  const assessmentCreate = useSelector((state) => state.assessmentCreate);
  const { assessmentCreateLoading } = assessmentCreate;

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form
                className="p-5"
                onSubmit={(e) => onCreateAssessmentForm(e)}
                onChange={(e) => handleChange(e)}
              >
                <div>
                  {/* Details Tab */}
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    {/* Form Tab */}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Question
                      </NavLink>
                    </NavItem>
                    {/* Seo Tab */}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Seo
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    {/* Details Tab Data */}
                    <TabPane tabId="1">
                      <CreateForm
                        assessmentForm={assessmentForm}
                        changeCreds={changeCreds}
                        assessmentDescription={assessmentDescription}
                        setAssessmentDescription={setAssessmentDescription}
                        assessmentShortDescription={assessmentShortDescription}
                        setAssessmentShortDescription={
                          setAssessmentShortDescription
                        }
                        assessmentImagePath={assessmentImagePath}
                        setAssessmentImagePath={setAssessmentImagePath}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    {/* Form Tab Data */}
                    <TabPane tabId="2">
                      <CreateQuestionForm
                        fieldsObj={fieldsModel}
                        add={addNewFieldObj}
                        delete={removeFieldObj}
                        formFields={formFields}
                        setFormFields={setFormFields}
                      />
                    </TabPane>
                    {/* Seo Tab Data */}
                    <TabPane tabId="3">
                      <Seo seoMeta={assessmentForm} changeCreds={changeCreds} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={assessmentCreateLoading}
                      >
                        {assessmentCreateLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: assessmentCreateLoading,
                              invisible: !assessmentCreateLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
