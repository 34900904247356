import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import SlugInput from "../../../UI/Input/SlugInput/SlugInput";

function Seo({ seoMeta, changeCreds, isSeo = false }) {
  return (
    <Row className={isSeo ? "mt-0" : "mt-5"}>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="pageTitle">Page Title</Label>
          <Input
            defaultValue={seoMeta.pageTitle}
            onChange={changeCreds}
            maxLength={255}
            type="text"
            name="pageTitle"
            autoComplete="nothing"
          />
        </FormGroup>
      </Col>
      {!isSeo && (
        <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
          <SlugInput
            onChange={changeCreds}
            value={seoMeta.slug}
            name="slug"
            type="text"
            label="Slug"
          />
        </Col>
      )}

      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="metaTitle">Meta Title</Label>
          <Input
            defaultValue={seoMeta.metaTitle}
            onChange={changeCreds}
            maxLength={200}
            type="textarea"
            name="metaTitle"
            autoComplete="nothing"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="metaKeywords">Meta Keywords</Label>
          <Input
            defaultValue={seoMeta.metaKeywords}
            onChange={changeCreds}
            maxLength={500}
            type="textarea"
            name="metaKeywords"
            autoComplete="nothing"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="metaDescription">Meta Description</Label>
          <Input
            defaultValue={seoMeta.metaDescription}
            onChange={changeCreds}
            type="textarea"
            name="metaDescription"
            autoComplete="nothing"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default Seo;
