import {
  SEO_ADD_FAIL,
  SEO_ADD_REQUEST,
  SEO_ADD_SUCCESS,
  SEO_EDIT_FAIL,
  SEO_EDIT_REQUEST,
  SEO_EDIT_SUCCESS,
  SEO_LIST_FAIL,
  SEO_LIST_REQUEST,
  SEO_LIST_SUCCESS,
  SEO_SINGLE_FAIL,
  SEO_SINGLE_REQUEST,
  SEO_SINGLE_SUCCESS,
} from "../constants/seoConstants";

const seoListState = {
  seoLoading: false,
  seoList: null,
  seoListFail: false,
};

export const seoListReducer = (state = seoListState, action) => {
  switch (action.type) {
    case SEO_LIST_REQUEST:
      return { seoLoading: true, seoList: null, seoListFail: false };
    case SEO_LIST_SUCCESS:
      return {
        seoLoading: false,
        seoList: action.payload,
        seoListFail: false,
      };
    case SEO_LIST_FAIL:
      return {
        seoLoading: false,
        seoListFail: action.payload,
        seoList: null,
      };
    default:
      return state;
  }
};
const singleSeoState = {
  singleSeoLoading: false,
  singleSeo: null,
  singleSeoFail: false,
};

export const singleSeoReducer = (state = singleSeoState, action) => {
  switch (action.type) {
    case SEO_SINGLE_REQUEST:
      return { singleSeoLoading: true, singleSeo: null, singleSeoFail: false };
    case SEO_SINGLE_SUCCESS:
      return {
        singleSeoLoading: false,
        singleSeo: action.payload,
        singleSeoFail: false,
      };
    case SEO_SINGLE_FAIL:
      return {
        singleSeoLoading: false,
        singleSeoFail: action.payload,
        singleSeo: null,
      };
    default:
      return state;
  }
};
const seoEditState = {
  seoEditLoading: false,
  seoEdited: null,
  seoEditError: false,
};

export const seoEditReducer = (state = seoEditState, action) => {
  switch (action.type) {
    case SEO_EDIT_REQUEST:
      return { seoEditLoading: true, seoEdited: null, seoEditError: false };
    case SEO_EDIT_SUCCESS:
      return {
        seoEditLoading: false,
        seoEdited: action.payload,
        seoEditError: false,
      };
    case SEO_EDIT_FAIL:
      return {
        seoEditLoading: false,
        seoEditError: action.payload,
        seoEdited: null,
      };
    default:
      return state;
  }
};
const seoAddState = {
  seoAddLoading: false,
  seoAdded: null,
  seoAddError: false,
};

export const seoAddReducer = (state = seoAddState, action) => {
  switch (action.type) {
    case SEO_ADD_REQUEST:
      return { seoAddLoading: true, seoAdded: null, seoAddError: false };
    case SEO_ADD_SUCCESS:
      return {
        seoAddLoading: false,
        seoAdded: action.payload,
        seoAddError: false,
      };
    case SEO_ADD_FAIL:
      return {
        seoAddLoading: false,
        seoAddError: action.payload,
        seoAdded: null,
      };
    default:
      return state;
  }
};
