import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { booleanStatus, status } from "../../../../data/institute/institute";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";

function CreateForm({
  career,
  careerImagePath,
  setCareerImagePath,
  changeCreds,
  careerDescription,
  setCareerDescription,
  bannerImagePath,
  setBannerImagePath,
}) {
  const careerCategoryListReducer = useSelector(
    (state) => state.careerCategory
  );
  const { careerType } = careerCategoryListReducer;
  //   const instituteAddReducer = useSelector((state) => state.instituteAdd);
  //   const { instituteAddLoading } = instituteAddReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="CareerName">
            Career Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={career.CareerName}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="CareerName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="CareerOptionSince">
            Since <span className="text-danger">*</span>
          </Label>
          <Input
            value={career.CareerOptionSince}
            onChange={changeCreds}
            type="number"
            maxLength={4}
            minLength={4}
            min={1}
            required
            name="CareerOptionSince"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="StartingSalary"> Starting Salary </Label>
          <Input
            value={career.StartingSalary}
            onChange={changeCreds}
            type="number"
            min={1}
            maxLength={10}
            required
            name="StartingSalary"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="DisplayOrder">
            {" "}
            Display Order <span className="text-danger">*</span>{" "}
          </Label>
          <Input
            value={career.DisplayOrder}
            onChange={changeCreds}
            type="number"
            required
            min={1}
            name="DisplayOrder"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="CareerStreamID">
            Type <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="CareerStreamID"
            id="CareerStreamID"
            required
            value={career.CareerStreamID}
            onChange={changeCreds}
          >
            {careerType?.map((item, id) => (
              <option key={id} value={item.id}>
                {" "}
                {item?.careerType}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="status">
            Status <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={career.status}
            onChange={changeCreds}
          >
            {status.map((item, id) => (
              <option key={id} value={item.id}>
                {item.id}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="isFeatured">
            Featured <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isFeatured"
            id="isFeatured"
            required
            value={career.isFeatured}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="isActive">
            Active <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isActive"
            id="isActive"
            required
            value={career.isActive}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="IsTrending">
            Trending <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="IsTrending"
            id="IsTrending"
            required
            value={career.IsTrending}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="CareerShortDescription">Short Description</Label>
          <Input
            value={career.CareerShortDescription}
            onChange={changeCreds}
            maxLength={500}
            type="textarea"
            name="CareerShortDescription"
            autoComplete="nope"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteDescription">Description</Label>
          <CkEditor
            value={careerDescription}
            setDescription={setCareerDescription}
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={careerImagePath}
          setPostImage={setCareerImagePath}
          //   isLoading={instituteAddLoading}
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={bannerImagePath}
          setPostImage={setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  );
}

export default CreateForm;
