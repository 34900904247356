import React, { PureComponent } from "react";
import { FormGroup, Input, Label } from "reactstrap";

class PincodeInput extends PureComponent {
  render() {
    const { label, value, type, onChange, name, ...props } = this.props;
    return (
      <FormGroup>
        <Label for="slug">{label}</Label>
        <Input
          value={value?.substring(0, 6)}
          onChange={onChange}
          type={type}
          name={name}
          autoComplete="nothing"
          autoCapitalize="none"
          {...props}
        />
      </FormGroup>
    );
  }
}

export default PincodeInput;
