import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import CurrencyTable from "../../tables/components/CurrencyTable/CurrencyTable";

const Currency = () => {
  return (
    <div>
      <Row>
        <Meta title="Currency | Eduberance" />
        <Col className="pr-grid-col" xs={12} lg={12}>
          <CurrencyTable tableName="Currency List" />
        </Col>
      </Row>
    </div>
  );
};

export default Currency;
