import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import {
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  eventActiveToggle,
  getAllRegisteredEvents,
} from "../../../../actions/eventActions";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import searchIcon from "../../../../assets/tables/searchIcon.svg";
import CustomModal from "../../../../UI/Modal/Modal";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import s from "../../Tables.module.scss";
import SearchInput from "../../../../helper/SearchInput";

const UserDiv = ({ userData }) => {
  const eventListReducer = useSelector((state) => state.eventList);
  const { eventListLoading } = eventListReducer;

  return (
    <>
      {userData && userData?.length > 0 ? (
        <>
          <Table
            className={`table-striped table-borderless table-hover ${s.statesTable}`}
            responsive
          >
            {eventListLoading ? (
              <Skeleton count={5} />
            ) : (
              <>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>City</th>
                    <th>Phone</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {userData !== null &&
                    userData.map((item, index) => (
                      <tr key={uuidv4()}>
                        <td>{item.firstName + " " + item.lastName}</td>
                        <td>{item.email}</td>
                        <td>{item.city}</td>
                        <td>{item.phone}</td>
                        <td>{item.message}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </Table>
        </>
      ) : (
        <p class="headline-4 p-2">No user found</p>
      )}
    </>
  );
};

const EventTable = function () {
  const [events, setEvents] = useState(null);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const eventListReducer = useSelector((state) => state.eventList);
  const { eventListLoading, allEvents, eventListError } = eventListReducer;
  const registerEventListReducer = useSelector(
    (state) => state.registerEventList
  );
  const { registerEventListLoading, registerEventList } =
    registerEventListReducer;

  useEffect(() => {
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (allEvents !== null) {
      setEvents(allEvents);
    }
  }, [eventListLoading, allEvents]);

  useEffect(() => {
    if (events !== null) {
      const firstTablePagesCounts = Math.ceil(events.length / pageSize);
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [events]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };
  const onModalToggle = (id, isUser) => {
    setOpen(!open);
    if (id) {
      dispatch(getAllRegisteredEvents(id));
    }
  };
  const onActive = (status, eventId) => {
    const tempList = [...events];
    tempList.map((item) => item.id === eventId && (item.isActive = !status));

    const foundCourse = tempList.find((element) => element.id === eventId);
    const selectedEvent = {
      id: foundCourse.id,
      IsActive: foundCourse.isActive,
      ModifyBy: "",
      ModifiedDate: new Date(),
    };

    dispatch(eventActiveToggle(selectedEvent));
    setEvents(tempList);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <div>
      <CustomModal
        modal={open}
        className="modal-stretch"
        modalTitle="Event Users"
        toggle={onModalToggle}
      >
        {registerEventListLoading ? (
          "Please wait..."
        ) : (
          <UserDiv userData={registerEventList} />
        )}
      </CustomModal>
      <div className={s.tableTitle}>
        <div />
        <div className="d-flex">
          <SearchInput
            searchText={searchText}
            handleSearch={handleSearch}
            placeholder="Search Event"
          />
          <a href="/#">
            <img className="d-none d-sm-block" src={cloudIcon} alt="Cloud" />
          </a>
          <a href="/#">
            <img src={printerIcon} alt="Printer" />
          </a>
          <a href="/#">
            <img
              className="d-none d-sm-block"
              src={optionsIcon}
              alt="Options"
            />
          </a>
          <a href="/#">
            <img src={funnelIcon} alt="Funnel" />
          </a>
        </div>
      </div>
      <div className="widget-table-overflow">
        <Table
          className={`table-striped table-borderless table-hover table-100 ${s.statesTable}`}
          responsive
        >
          {eventListLoading ? (
            <Skeleton count={pageSize} />
          ) : (
            <>
              <thead>
                <tr>
                  <th className={s.checkboxCol}>
                    <div className="checkbox checkbox-primary">
                      <input
                        className="styled"
                        id="checkbox100"
                        type="checkbox"
                      />
                      <label htmlFor="checkbox100" />
                    </div>
                  </th>
                  <th style={{ width: "16%" }}>Name</th>
                  <th style={{ width: "10%" }}>Users</th>
                  <th style={{ width: "16%" }}>Location</th>
                  <th style={{ width: "18%" }}>Speaker</th>
                  <th style={{ width: "18%" }}>Start Date & Time</th>
                  <th style={{ width: "18%" }}>End Date & Time</th>
                  <th style={{ width: "10%" }}>Online</th>
                  <th style={{ width: "10%" }}>Active</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {events !== null &&
                  events
                    .filter((item) =>
                      Object.values(item).some(
                        (value) =>
                          typeof value === "string" &&
                          value.toLowerCase().includes(searchText.toLowerCase())
                      )
                    )

                    .slice(
                      firstTableCurrentPage * pageSize,
                      (firstTableCurrentPage + 1) * pageSize
                    )
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="checkbox checkbox-primary">
                            <input
                              id={item.id}
                              className="styled"
                              type="checkbox"
                            />
                            <Label for={item.id} />
                          </div>
                        </td>

                        <td>
                          {item.title?.substring(0, 25)}
                          {item.title.length > 25 && <>...</>}
                        </td>
                        <td
                          onClick={() =>
                            onModalToggle(item.id, item?.noOfRegisterUser)
                          }
                          className="cursor-pointer"
                        >
                          <Link to="#" className="table-action">
                            {item?.noOfRegisterUser}
                          </Link>
                        </td>

                        <td>{item.eventLocation}</td>
                        <td>{item.speakerName}</td>
                        <td>
                          {item.eventStartDate} | {item.eventStartTimeString}
                        </td>
                        <td>
                          {item.eventEndDate} | {item.eventEndTimeString}
                        </td>
                        <td>{item.isOnline ? "Yes" : "No"}</td>

                        <td>
                          <ToggleButton
                            value={item.isActive}
                            onToggle={(e) => onActive(e, item.id)}
                          />
                        </td>

                        <td className="d-flex justify-content-end">
                          <Link
                            to={`/admin/events/edit/${item.id}`}
                            className="d-flex align-items-center justify-content-center table-action"
                          >
                            <i className="eva eva-edit mr-1"></i>
                          </Link>
                          <a
                            href={`https://www.eduberance.com/events/${item.slug}`}
                            target="_blank"
                            rel="noreferrer"
                            className="table-action"
                          >
                            <i className="eva eva-eye"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </>
          )}
        </Table>
        {events !== null && (
          <Pagination
            className="pagination-borderless"
            aria-label="Page navigation example"
          >
            <PaginationItem disabled={firstTableCurrentPage <= 0}>
              <PaginationLink
                onClick={(e) => setFirstTablePage(e, firstTableCurrentPage - 1)}
                previous
                href="#top"
              />
            </PaginationItem>
            {[...Array(firstTablePagesCount)].map((page, i) => (
              <PaginationItem active={i === firstTableCurrentPage} key={i}>
                <PaginationLink
                  onClick={(e) => setFirstTablePage(e, i)}
                  href="#top"
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem
              disabled={firstTableCurrentPage >= firstTablePagesCount - 1}
            >
              <PaginationLink
                onClick={(e) => setFirstTablePage(e, firstTableCurrentPage + 1)}
                next
                href="#top"
              />
            </PaginationItem>
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default EventTable;
