import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { addCounsellor } from "../../../actions/counsellorAction";

function CounsellorForm() {
  const [counsellor, setCounsellors] = useState({
    Email: "",
    Name: "",
  });

  const counsellorAdd = useSelector((state) => state.counsellorAdd);
  const { counsellorAddLoading } = counsellorAdd;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setCounsellors({ ...counsellor, [event.target.name]: event.target.value });
  };

  const onRegisterCounsellor = (e) => {
    e.preventDefault();

    const counsellorInfo = {
      email: counsellor.Email,
      password: "123456@Ab",
      name: counsellor.Name,
    };

    dispatch(addCounsellor(counsellorInfo));
  };
  return (
    <Form onSubmit={(e) => onRegisterCounsellor(e)}>
      <Row className="gutter mb-2 mb-sm-4 mt-5">
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="Email">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              value={counsellor.Email}
              onChange={changeCreds}
              type="email"
              maxLength={400}
              required
              name="Email"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="Email">
              Full Name <span className="text-danger">*</span>
            </Label>
            <Input
              value={counsellor.Name}
              onChange={changeCreds}
              type="Name"
              maxLength={400}
              required
              name="Name"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>

        <Col
          className="mt-2 mb-sm-4 mb-md-0 d-flex justify-content-center align-items-center "
          xs={12}
          md={4}
        >
          <Button
            className="rounded-pill w-75"
            type="submit"
            color="secondary-red"
            disabled={counsellorAddLoading}
          >
            {counsellorAddLoading && (
              <Spinner
                className={classNames({
                  "position-relative": true,
                  visible: counsellorAddLoading,
                  invisible: !counsellorAddLoading,
                })}
                size="sm"
              />
            )}
            <span className="ml-1">Submit</span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default CounsellorForm;
