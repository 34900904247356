import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { contactList } from "../../actions/contactAction";
import Meta from "../../components/Branch/Meta/Meta";
import ContactTable from "../tables/components/ContactTable/ContactTable";

const Contacts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contactList());
  }, [dispatch]);

  return (
    <Row>
      <Meta title="Contacts | Eduberance " />
      <Col className="pr-grid-col" xs={12} lg={12}>
        <ContactTable tableName="Contact List" />
      </Col>
    </Row>
  );
};
export default Contacts;
