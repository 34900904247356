import axios from "axios";
import { toast } from "react-toastify";
import {
  COMPANY_ACTIVE_FAIL,
  COMPANY_ACTIVE_REQUEST,
  COMPANY_ACTIVE_SUCCESS,
  COMPANY_ADD_FAIL,
  COMPANY_ADD_REQUEST,
  COMPANY_ADD_SUCCESS,
  COMPANY_EDIT_FAIL,
  COMPANY_EDIT_REQUEST,
  COMPANY_EDIT_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_SINGLE_FAIL,
  COMPANY_SINGLE_REQUEST,
  COMPANY_SINGLE_SUCCESS,
} from "../constants/companyConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const listCompany =
  (isShortId = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: COMPANY_LIST_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      let tempData = [];

      if (isShortId) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/GetAllCompany`,
          config
        );
        tempData = data;
      } else {
        const { data } = await axios.get(
          `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/SuperAdminAllCompany`,
          config
        );
        tempData = data;
      }

      dispatch({
        type: COMPANY_LIST_SUCCESS,
        payload: tempData,
      });
    } catch (error) {
      dispatch({
        type: COMPANY_LIST_FAIL,
        payload: "Sorry! something went wrong",
      });
    }
  };

export const getCompanyById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/SuperAdminGetCompanyByID?id=${id}`,
      config
    );

    dispatch({
      type: COMPANY_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_SINGLE_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addNewCompany = (addInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COMPANY_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/Create`,
      addInfo,
      config
    );

    toast.dismiss();
    toast.success("Company created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COMPANY_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COMPANY_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const editCompany = (editInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COMPANY_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/Update`,
      editInfo,
      config
    );

    toast.dismiss();
    toast.success("Company edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COMPANY_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COMPANY_EDIT_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const companyActiveToggle = (companyActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: COMPANY_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Company/UpdateActiveInactive`,
      companyActiveInfo,
      config
    );

    toast.success(
      `Company ${
        companyActiveInfo.isActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: COMPANY_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COMPANY_ACTIVE_FAIL,
      payload: true,
    });
  }
};
