import axios from "axios";
import { toast } from "react-toastify";
import {
  CAREERS_ACTIVE_FAIL,
  CAREERS_ACTIVE_REQUEST,
  CAREERS_ACTIVE_SUCCESS,
  CAREERS_ADD_FAIL,
  CAREERS_ADD_REQUEST,
  CAREERS_ADD_SUCCESS,
  CAREERS_CATEGORY_LIST_FAIL,
  CAREERS_CATEGORY_LIST_REQUEST,
  CAREERS_CATEGORY_LIST_SUCCESS,
  CAREERS_DATA_POINT_FAIL,
  CAREERS_DATA_POINT_REQUEST,
  CAREERS_DATA_POINT_SUCCESS,
  CAREERS_EDIT_FAIL,
  CAREERS_EDIT_REQUEST,
  CAREERS_EDIT_SUCCESS,
  CAREERS_LIST_FAIL,
  CAREERS_LIST_REQUEST,
  CAREERS_LIST_SUCCESS,
  CAREERS_SINGLE_FAIL,
  CAREERS_SINGLE_REQUEST,
  CAREERS_SINGLE_SUCCESS,
} from "../constants/careerConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const careerList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREERS_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/GetAllCareer`,
      config
    );

    dispatch({
      type: CAREERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_LIST_FAIL,
      payload: true,
    });
  }
};
export const getAllDataPoints = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREERS_DATA_POINT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/GetAllDataPoint`,
      config
    );
    localStorage.setItem("careerDataPoints", JSON.stringify(data));
    dispatch({
      type: CAREERS_DATA_POINT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_DATA_POINT_FAIL,
      payload: true,
    });
  }
};

export const careerCategoryList = () => async (dispatch) => {
  try {
    dispatch({
      type: CAREERS_CATEGORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/GetAllCareerType`,
      config
    );

    dispatch({
      type: CAREERS_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_CATEGORY_LIST_FAIL,
      payload: true,
    });
  }
};

export const addCareer = (careerInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CAREERS_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/CreateCareer`,
      careerInfo,
      config
    );

    toast.dismiss();
    toast.success("Career created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CAREERS_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CAREERS_ADD_FAIL,
      payload: true,
    });
  }
};

export const editCareer = (editCourseInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CAREERS_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/UpdateCareer`,
      editCourseInfo,
      config
    );

    toast.dismiss();

    toast.success("Career edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CAREERS_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CAREERS_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getCareerById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CAREERS_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/GetCareerByID?id=${id}`,
      config
    );

    dispatch({
      type: CAREERS_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const careerActiveToggle = (careerActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: CAREERS_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CAREER_BASE_URL}/api/SuperAdmin/UpdateActiveInactive`,
      careerActiveInfo,
      config
    );

    toast.success(
      `Career ${
        careerActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: CAREERS_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CAREERS_ACTIVE_FAIL,
      payload: true,
    });
  }
};
