import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { careerList } from "../../../actions/careerActions";
import Meta from "../../../components/Branch/Meta/Meta";
import CareerTable from "../../tables/components/CareerTable/CareerTable";

const Careers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(careerList());
  }, [dispatch]);

  return (
    <Row>
      <Meta title="Careers | Eduberance" />
      <Col className="pr-grid-col" xs={12} lg={12}>
        <CareerTable tableName="Career List" />
      </Col>
    </Row>
  );
};
export default Careers;
