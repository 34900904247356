import {
  ORGANISATION_ADD_FAIL,
  ORGANISATION_ADD_REQUEST,
  ORGANISATION_ADD_SUCCESS,
  ORGANISATION_EDIT_FAIL,
  ORGANISATION_EDIT_REQUEST,
  ORGANISATION_EDIT_SUCCESS,
  ORGANISATION_GET_FAIL,
  ORGANISATION_GET_REQUEST,
  ORGANISATION_GET_SUCCESS,
} from "../constants/organisationConstant";

export const organisationReducer = (
  state = {
    organisationList: [],
    organisationEditLoading: false,
    organisationEditError: null,
    organisationEditSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case ORGANISATION_GET_REQUEST:
      return { ...state, organisationListLoading: true };
    case ORGANISATION_GET_SUCCESS:
      return {
        ...state,
        organisationListLoading: false,
        organisationList: action.payload,
      };
    case ORGANISATION_GET_FAIL:
      return {
        ...state,
        organisationListLoading: false,
        organisationListError: action.payload,
      };
    case ORGANISATION_ADD_REQUEST:
      return { ...state, organisationAddLoading: true };
    case ORGANISATION_ADD_SUCCESS:
      return {
        ...state,
        organisationAddLoading: false,
        organisationAddSuccess: true,
        organisationList: [...state.organisationList, action.payload],
      };
    case ORGANISATION_ADD_FAIL:
      return {
        ...state,
        organisationAddLoading: false,
        organisationAddError: action.payload,
      };
    case ORGANISATION_EDIT_REQUEST:
      return {
        ...state,
        organisationEditLoading: true,
        organisationEditError: null,
        organisationEditSuccess: false,
      };
    case ORGANISATION_EDIT_SUCCESS:
      return {
        ...state,
        organisationEditLoading: false,
        organisationEditSuccess: true,
        organisationList: state.organisationList.map((org) =>
          org.id === action.payload.id ? action.payload : org
        ),
      };
    case ORGANISATION_EDIT_FAIL:
      return {
        ...state,
        organisationEditLoading: false,
        organisationEditError: action.payload,
      };
    default:
      return state;
  }
};
