import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editCompany, getCompanyById } from "../../../actions/companyActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import { CompanyForm } from "./CompanyForm";

var todayDate = new Date();

function CompanyEdit() {
  const [companyInfo, setCompanyInfo] = useState({
    companyID: 1,
    companyName: "",
    companyType: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    website: "",
    emailId: "",
    phone1: "",
    companyInceptionYear: "",
    companyShortDescription: "",
    companyTagLine: "",
    createdBy: "",
    createdDate: todayDate,
  });
  const [companyDescription, setCompanyDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const [companyImagePath, setCompanyImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch(getCompanyById(id));
  }, [dispatch]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };
  const changeCreds = (event) => {
    setCompanyInfo({
      ...companyInfo,
      [event.target.name]: event.target.value,
    });
  };

  const editCompanyReducer = useSelector((state) => state.editCompany);
  const { companyEditLoading } = editCompanyReducer;

  const singleCompanyReducer = useSelector((state) => state.singleCompany);
  const { singleCompanyLoading, singleCompany } = singleCompanyReducer;

  useEffect(() => {
    if (singleCompany) {
      setCompanyInfo({
        ...companyInfo,
        companyID: singleCompany?.companyID,
        companyName: singleCompany?.companyName,
        companyType: singleCompany?.companyType,
        address1: singleCompany?.address1,
        address2: singleCompany?.address2,
        city: singleCompany?.city,
        state: singleCompany?.state,
        country: singleCompany?.country,
        pin: singleCompany?.pin,
        website: singleCompany?.website,
        emailId: singleCompany?.emailId,
        phone1: singleCompany?.phone1,
        companyInceptionYear: singleCompany?.companyInceptionYear?.substring(
          0,
          10
        ),
        companyShortDescription: singleCompany?.companyShortDescription,
        companyTagLine: singleCompany?.companyTagLine,
      });

      setCompanyImagePath({
        ...companyImagePath,
        myFile: singleCompany?.companyImagePath,
      });
      setCompanyDescription(singleCompany?.companyDescription);
    }
  }, [singleCompany, id]);

  const onCompanyEdit = (e) => {
    const established = new Date(companyInfo.companyInceptionYear);
    e.preventDefault();
    const info = {
      companyID: companyInfo?.companyID,
      companyName: companyInfo.companyName,
      companyType: companyInfo.companyType,
      companyDescription: companyDescription,
      address1: companyInfo.address1,
      address2: companyInfo.address2,
      city: companyInfo.city,
      state: companyInfo.state,
      country: companyInfo.country,
      pin: companyInfo.pin,
      website: companyInfo.website,
      emailId: companyInfo.emailId,
      phone1: companyInfo.phone1,
      companyInceptionYear: established,
      companyTagLine: companyInfo.companyTagLine,
      companyImagePath: companyImagePath.myFile,
      isNewImage: companyImagePath.IsNewImage,
      imageName: companyImagePath.ImageName,
      imageType: companyImagePath.ImageType,
      companyShortDescription: companyInfo.companyShortDescription,
      createdBy: "",
      createdDate: todayDate,
    };

    dispatch(editCompany(info));
  };

  return (
    <Row>
      <Meta title="Edit | Company | Eduberance" />
      {!singleCompanyLoading && (
        <Breadcrumbs
          url={`/admin/companies/edit/${singleCompany?.companyName}`}
        />
      )}
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onCompanyEdit(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <CompanyForm
                      companyInfo={companyInfo}
                      setCompanyInfo={setCompanyInfo}
                      companyImagePath={companyImagePath}
                      setCompanyImagePath={setCompanyImagePath}
                      changeCreds={changeCreds}
                      companyDescription={companyDescription}
                      setCompanyDescription={setCompanyDescription}
                    />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={companyEditLoading}
                    >
                      {companyEditLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: companyEditLoading,
                            invisible: !companyEditLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CompanyEdit;
