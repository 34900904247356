import axios from "axios";
import { getUserDetails } from "../helper/getUserDetail";

export const getAssessmentById = (id) => {
  const data = axios
    .get(`${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/GetFormQuestionByID/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return {};
    });
  return data;
};


export const deleteAssessmentById = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getUserDetails().token}`,
    },
  };
  const data = axios


    .delete(`${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/DeleteForm/${id}`, config,)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return {};
    });
  return data;
};