import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = JSON.parse(localStorage.getItem("userInfo"));

      if (!currentUser) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (roles && roles.indexOf(currentUser.role) === -1) {
        return <Redirect to={{ pathname: "/admin/dashboard" }} />;
      }

      return <Component {...props} />;
    }}
  />
);
