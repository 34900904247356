import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Seo from "../../../components/Branch/Seo/Seo";

export const SeoForm = (props) => {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="pageUrl">
            URL <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.seoInfo.pageUrl}
            onChange={props.changeCreds}
            type="url"
            required
            name="pageUrl"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <Seo
          seoMeta={props.seoInfo}
          changeCreds={props.changeCreds}
          isSeo={true}
        />
      </Col>
    </Row>
  );
};
