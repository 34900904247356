import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getInstituteById,
  instituteCategoryList,
} from "../../../actions/instituteActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/Institute/Edit/Edit";

const InstituteEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const instituteSingleReducer = useSelector((state) => state.instituteSingle);

  useEffect(() => {
    dispatch(getInstituteById(id));
    dispatch(instituteCategoryList());
  }, [dispatch]);

  const { instituteSingleLoading, instituteSingle } = instituteSingleReducer;

  return (
    <div>
      <Meta title="Edit | Institute | Eduberance" />
      {!instituteSingleLoading && (
        <Breadcrumbs
          url={`/admin/institutes/edit/${instituteSingle?.instituteName}`}
        />
      )}

      <Edit />
    </div>
  );
};

export default InstituteEditForm;
