import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { speakerList } from "../../actions/eventActions";
import Meta from "../../components/Branch/Meta/Meta";
import SpeakerTable from "../tables/components/SpeakerTable/SpeakerTable";

const Speaker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(speakerList());
  }, [dispatch]);

  return (
    <Row>
      <Meta title="Speakers | Eduberance" />
      <Col className="pr-grid-col" xs={12} lg={12}>
        <SpeakerTable tableName="Speaker List" />
      </Col>
    </Row>
  );
};
export default Speaker;
