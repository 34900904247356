export const status = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
];

export const booleanStatus = [
  {
    title: "Yes",
    status: true,
  },
  {
    title: "No",
    status: false,
  },
];
export const recoursesList = [
  {
    title: "Upload a file",
    status: true,
  },
  {
    title: "Attach a link",
    status: false,
  },
];
