import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import TutorTable from "../../tables/components/TutorTable.js/TutorTable";

export default function Tutor() {
  return (
    <div>
      <Meta title="Tutor | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <TutorTable tableName="Tutor List" />
        </Col>
      </Row>
    </div>
  );
}
