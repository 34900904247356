import React, { useEffect } from "react";
import Edit from "../../../components/Main/Assessment/Edit/Edit";
import Meta from "../../../components/Branch/Meta/Meta";
import {
  AssessmentDetail,
  AssessmentQuestions,
} from "../../../actions/assessments";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
function AssessmentEditForm(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const assessmentDetailById = useSelector((state) => state.assessmentDetail);
  const { assessmentDetailLoading, assessmentDetail } = assessmentDetailById;
  const assessmentQuestionsState = useSelector(
    (state) => state.assessmentQuestion
  );
  const { assessmentQuestionsLoading, assessmentQuestions } =
    assessmentQuestionsState;

  useEffect(() => {
    dispatch(AssessmentDetail(id));
    dispatch(AssessmentQuestions(id));
  }, [dispatch, id]);

  return (
    <>
      {!assessmentDetailLoading && (
        <Breadcrumbs
          url={`/admin/scholastic/edit/${assessmentDetail?.title}`}
        />
      )}
      <Meta title="Edit | Assessment | Eduberance" />
      <Edit formDetails={assessmentDetail} />
    </>
  );
}

export default AssessmentEditForm;
