import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_ADD_REQUEST,
  BLOG_ADD_SUCCESS,
  BLOG_ADD_FAIL,
  BLOG_SINGLE_REQUEST,
  BLOG_SINGLE_SUCCESS,
  BLOG_SINGLE_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_EDIT_FAIL,
} from "../constants/blogConstants";

const blogListState = {
  blogListLoading: false,
  blogList: null,
  blogListFail: false,
};

export const blogListReducer = (state = blogListState, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return { blogListLoading: true, blogList: null, blogListFail: false };
    case BLOG_LIST_SUCCESS:
      return {
        blogListLoading: false,
        blogList: action.payload,
        blogListFail: false,
      };
    case BLOG_LIST_FAIL:
      return {
        blogListLoading: false,
        blogListFail: action.payload,
        blogList: null,
      };
    default:
      return state;
  }
};
const singleBlogState = {
  singleBlogLoading: false,
  singleBlog: null,
  singleBlogFail: false,
};

export const singleBlogReducer = (state = singleBlogState, action) => {
  switch (action.type) {
    case BLOG_SINGLE_REQUEST:
      return {
        singleBlogLoading: true,
        singleBlog: null,
        singleBlogFail: false,
      };
    case BLOG_SINGLE_SUCCESS:
      return {
        singleBlogLoading: false,
        singleBlog: action.payload,
        singleBlogFail: false,
      };
    case BLOG_SINGLE_FAIL:
      return {
        singleBlogLoading: false,
        singleBlogFail: action.payload,
        singleBlog: null,
      };

    default:
      return state;
  }
};

const blogEditState = {
  blogEditLoading: false,
  blogEdited: null,
  blogEditFail: false,
};

export const editBlogReducer = (state = blogEditState, action) => {
  switch (action.type) {
    case BLOG_EDIT_REQUEST:
      return { blogEditLoading: true, blogEdited: null, blogEditFail: false };
    case BLOG_EDIT_SUCCESS:
      return {
        blogEditLoading: false,
        blogEdited: action.payload,
        blogEditFail: false,
      };
    case BLOG_EDIT_FAIL:
      return {
        blogEditLoading: false,
        blogEditFail: action.payload,
        blogEdited: null,
      };

    default:
      return state;
  }
};

const blogAddState = {
  blogAddLoading: false,
  blogAdded: null,
  blogAddFail: false,
};

export const addBlogReducer = (state = blogAddState, action) => {
  switch (action.type) {
    case BLOG_ADD_REQUEST:
      return { blogAddLoading: true, blogAdded: null, blogAddFail: false };
    case BLOG_ADD_SUCCESS:
      return {
        blogAddLoading: false,
        blogAdded: action.payload,
        blogAddFail: false,
      };
    case BLOG_ADD_FAIL:
      return {
        blogAddLoading: false,
        blogAddFail: action.payload,
        blogAdded: null,
      };

    default:
      return state;
  }
};
