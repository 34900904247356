import React from "react";
import { memo } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import TextInput from "./TextInput";

function CheckboxInput({ inputData }) {
  const {
    multiOptionArrays,
    fieldIndex,
    changeHandler,
    addMoreOptions,
    removeOptions,
  } = inputData;
  return (
    <FormGroup tag="fieldset">
      {/* <Row style={{ justifyContent: "space-between" }}>
        <Col md={2}>Field Options</Col>
      </Row> */}
      <hr />
      <Row className="justify-content-between align-items-center">
        <Col md={multiOptionArrays.length < 10 ? 11 : 12}>
          <Row
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {multiOptionArrays.map((data, checkIndex) => {
              return (
                <Col
                  key={checkIndex}
                  md={12}
                  className="my-2"
                >
                  <FormGroup check>
                    <Row
                      className="mx-0"
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Col md={11} className="px-0 pr-2">
                        <Row
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Col md={9}>
                            <TextInput
                              inputData={{
                                value: data.value,
                                changeHandler,
                                fieldIndex,
                                dataIndex: checkIndex,
                                optionName: "multiOptionArrays",
                                arrayKey: "value",
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <input
                              type="number"
                              placeholder="score"
                              value={data.score}
                              onChange={(e) =>
                                changeHandler(
                                  e,
                                  fieldIndex,
                                  checkIndex,
                                  "multiOptionArrays",
                                  "score"
                                )
                              }
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {checkIndex > 1 && (
                        <Col
                          md={1}
                          className="px-0"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            color="link"
                            role="button"
                            style={{
                              textDecoration: "none",

                              color: "red",
                            }}
                            className="p-0"
                            title="Delete Field"
                            onClick={() =>
                              removeOptions(
                                fieldIndex,
                                checkIndex,
                                "multiOptionArrays"
                              )
                            }
                          >
                            X
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
        </Col>
        {multiOptionArrays.length < 10 && (
          <Col md={1}>
            <Button
              className="rounded-pill my-3 d-flex justify-content-between align-items-center"
              style={{ height: "2.1rem" }}
              color="primary"
              onClick={() => addMoreOptions(fieldIndex, "multiOptionArrays")}
            >
              <i className="fa fa-plus-circle mr-2" aria-hidden="true" />
            </Button>
          </Col>
        )}
      </Row>
    </FormGroup>
  );
}

export default memo(CheckboxInput);
