import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  addOnlineIntCat,
  onlineIntCatList,
} from "../../../actions/onlineCourseActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";

var todayDate = new Date();

function OnlineInstituteCatAdd({ history }) {
  const [category, setCategory] = useState({
    id: "",
    name: "",
    description: "",
    parentCatID: 1,
    streamID: null,
    modifyBy: "",
    modifiedDate: todayDate,
  });

  const [onlineCategory, setOnlineIntCategory] = useState([]);

  const dispatch = useDispatch();

  const onlineIntCatEditReducer = useSelector((state) => state.onlineIntCatAdd);
  const { onlineIntCatAddLoading } = onlineIntCatEditReducer;

  const onlineIntCatReducer = useSelector((state) => state.onlineIntCat);
  const { onlineIntCatLoading, onlineIntCat } = onlineIntCatReducer;

  useEffect(() => {
    if (onlineIntCat !== null) {
      setOnlineIntCategory(onlineIntCat);
    }
  }, [onlineIntCatLoading, onlineIntCat]);

  useEffect(() => {
    dispatch(onlineIntCatList());
  }, [dispatch]);

  const changeCreds = (event) => {
    setCategory({ ...category, [event.target.name]: event.target.value });
  };

  const onAddOnlineIntCat = (e) => {
    e.preventDefault();
    const onlineIntCatInfo = {
      id: category.id,
      name: category.name,
      description: category.description,
      parentCatID:
        category.parentCatID === "None"
          ? null
          : JSON.parse(category.parentCatID),
      streamID: category.streamID,
      createdBy: category.modifyBy,
      createdDate: todayDate,
    };
    dispatch(
      addOnlineIntCat(onlineIntCatInfo, () => {
        history.push(`/admin//admin/Online-course-categories`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Edit | Currency | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form
                onSubmit={(e) => onAddOnlineIntCat(e)}
                className="px-5 py-3"
              >
                <Row className="gutter mb-2 mb-sm-4 mt-5">
                  <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
                    <FormGroup>
                      <Label for="Name">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={category.name}
                        onChange={changeCreds}
                        type="text"
                        maxLength={400}
                        required
                        name="name"
                        autoComplete="nope"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="mb-2 mb-sm-4 mb-md-0 " xs={12} md={6}>
                    <FormGroup>
                      <Label for="parentCatID">Category</Label>
                      <Input
                        type="select"
                        name="parentCatID"
                        required
                        value={category.parentCatID}
                        onChange={changeCreds}
                      >
                        <option>None</option>
                        {onlineCategory?.map((item, id) => (
                          <option key={id} value={item.id}>
                            {item?.courseCategory}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col className="mb-2 mb-sm-4 mb-md-0 " xs={12} md={12}>
                    <FormGroup>
                      <Label for="description">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={category.description}
                        onChange={changeCreds}
                        type="textarea"
                        required
                        name="description"
                        autoComplete="nope"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={onlineIntCatAddLoading}
                      >
                        {onlineIntCatAddLoading && (
                          <Spinner
                            className={classNames({
                              "position-relative": true,
                              visible: onlineIntCatAddLoading,
                              invisible: !onlineIntCatAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OnlineInstituteCatAdd;
