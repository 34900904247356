// Constants for adding an organisation
export const ORGANISATION_ADD_REQUEST = "ORGANISATION_ADD_REQUEST";
export const ORGANISATION_ADD_SUCCESS = "ORGANISATION_ADD_SUCCESS";
export const ORGANISATION_ADD_FAIL = "ORGANISATION_ADD_FAIL";

// Constants for editing an organisation
export const ORGANISATION_EDIT_REQUEST = "ORGANISATION_EDIT_REQUEST";
export const ORGANISATION_EDIT_SUCCESS = "ORGANISATION_EDIT_SUCCESS";
export const ORGANISATION_EDIT_FAIL = "ORGANISATION_EDIT_FAIL";

// Constants for getting an organisation
export const ORGANISATION_GET_REQUEST = "ORGANISATION_GET_REQUEST";
export const ORGANISATION_GET_SUCCESS = "ORGANISATION_GET_SUCCESS";
export const ORGANISATION_GET_FAIL = "ORGANISATION_GET_FAIL";
