import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import BlogTable from "../../tables/components/BlogTable/BlogTable";

const Blog = () => {
  return (
    <div>
      <Meta title="Blogs | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <BlogTable tableName="Blog List" />
        </Col>
      </Row>
    </div>
  );
};

export default Blog;
