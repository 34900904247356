export const COMPANY_ADD_REQUEST = "COMPANY_ADD_REQUEST";
export const COMPANY_ADD_SUCCESS = "COMPANY_ADD_SUCCESS";
export const COMPANY_ADD_FAIL = "COMPANY_ADD_FAIL";

export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAIL = "COMPANY_LIST_FAIL";

export const COMPANY_EDIT_REQUEST = "COMPANY_EDIT_REQUEST";
export const COMPANY_EDIT_SUCCESS = "COMPANY_EDIT_SUCCESS";
export const COMPANY_EDIT_FAIL = "COMPANY_EDIT_FAIL";

export const COMPANY_SINGLE_REQUEST = "COMPANY_SINGLE_REQUEST";
export const COMPANY_SINGLE_SUCCESS = "COMPANY_SINGLE_SUCCESS";
export const COMPANY_SINGLE_FAIL = "COMPANY_SINGLE_FAIL";

export const COMPANY_ACTIVE_REQUEST = "COMPANY_ACTIVE_REQUEST";
export const COMPANY_ACTIVE_SUCCESS = "COMPANY_ACTIVE_SUCCESS";
export const COMPANY_ACTIVE_FAIL = "COMPANY_ACTIVE_FAIL";
