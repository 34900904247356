import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

const SectionResourceHeader = () => {
  return (
    <Row>
      <Col className=" mb-sm-3 mx-3  mb-md-3" md={12}>
        <Row>
          <Col className=" mb-md-0" md={4}>
            <Label for="resourceLink">Resource name</Label>
          </Col>
          <Col className=" mb-md-0" md={2}>
            <Label for="resourceLink">Select Format</Label>
          </Col>
          <Col className=" mb-md-0" md={4}>
            <Label for="resourceLink">Attach file or link</Label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionResourceHeader;
