export const getUserDetails = () => {
  const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  return userInfoFromStorage;
};
export const getCareerDataPoints = () => {
  const userInfoFromStorage = localStorage.getItem("careerDataPoints")
    ? JSON.parse(localStorage.getItem("careerDataPoints"))
    : null;

  return userInfoFromStorage;
};
