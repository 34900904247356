import React from "react";

import TextInput from "./TextInput";
import RadioInput from "./RadioInput";
import CheckboxInput from "./CheckboxInput";
import { memo } from "react";

function DynamicInput({ inputType, ...inputData }) {
  function checkTypes(type) {
    switch (type) {
      case "text":
        case "textarea":
        return <TextInput inputData={inputData} />;

      case "radiobutton":
        return <RadioInput inputData={inputData} />;

      case "checkbox":
      case "dropdown":
        return <CheckboxInput inputData={inputData} />;

      default:
        break;
    }
  }
  return <>{checkTypes(inputType)}</>;
}

export default memo(DynamicInput);
