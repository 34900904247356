// -- React and related libs
import jwt from "jsonwebtoken";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "./actions/userActions";
import LayoutComponent from "./components/Branch/Layout/Layout";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import isAuthenticated from "./services/authService";
import "./styles/app.scss";


const RedirectRoute = ({ dispatch, component, ...rest }) => {
  if (!isAuthenticated(JSON.parse(localStorage.getItem("userInfo")))) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

const App = (props) => {
  const dispatch = useDispatch();
  toast.configure();
  if (localStorage.getItem("userInfo")) {
    const userObj = JSON.parse(localStorage.getItem("userInfo"));
    jwt.verify(
      userObj.token,
      process.env.REACT_APP_JWT_KEY,
      function (err, decoded) {
        if (err) {
          dispatch(logout());
        }
      }
    );
  }
  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Redirect to="/admin/dashboard" />}
          />
          <Route
            path="/admin"
            exact
            render={() => <Redirect to="/admin/dashboard" />}
          />
          <RedirectRoute
            path="/admin"
            dispatch={props.dispatch}
            component={LayoutComponent}
          />
          <Route path="/login" exact component={Login} />
          <Route path="/error" exact component={ErrorPage} />
          <Route
            path="*"
            exact={true}
            render={() => <Redirect to="/error" />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
