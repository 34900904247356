import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_ADD_REQUEST,
  CURRENCY_ADD_SUCCESS,
  CURRENCY_ADD_FAIL,
  CURRENCY_EDIT_REQUEST,
  CURRENCY_EDIT_SUCCESS,
  CURRENCY_EDIT_FAIL,
  CURRENCY_SINGLE_REQUEST,
  CURRENCY_SINGLE_SUCCESS,
  CURRENCY_SINGLE_FAIL,
  ONLINE_INSTITUTE_ADD_FAIL,
  ONLINE_INSTITUTE_ADD_REQUEST,
  ONLINE_INSTITUTE_ADD_SUCCESS,
  ONLINE_INSTITUTE_EDIT_FAIL,
  ONLINE_INSTITUTE_EDIT_REQUEST,
  ONLINE_INSTITUTE_EDIT_SUCCESS,
  ONLINE_INSTITUTE_LIST_FAIL,
  ONLINE_INSTITUTE_LIST_REQUEST,
  ONLINE_INSTITUTE_LIST_SUCCESS,
  ONLINE_INSTITUTE_SINGLE_FAIL,
  ONLINE_INSTITUTE_SINGLE_REQUEST,
  ONLINE_INSTITUTE_SINGLE_SUCCESS,
  ONLINE_INST_CATEGORY_ADD_REQUEST,
  ONLINE_INST_CATEGORY_ADD_SUCCESS,
  ONLINE_INST_CATEGORY_ADD_FAIL,
  ONLINE_INST_CATEGORY_EDIT_REQUEST,
  ONLINE_INST_CATEGORY_EDIT_SUCCESS,
  ONLINE_INST_CATEGORY_EDIT_FAIL,
  ONLINE_INST_CATEGORY_LIST_REQUEST,
  ONLINE_INST_CATEGORY_LIST_SUCCESS,
  ONLINE_INST_CATEGORY_LIST_FAIL,
  ONLINE_COURSES_LIST_REQUEST,
  ONLINE_COURSES_LIST_SUCCESS,
  ONLINE_COURSES_LIST_FAIL,
  ONLINE_COURSES_SUB_CATEGORY_REQUEST,
  ONLINE_COURSES_SUB_CATEGORY_SUCCESS,
  ONLINE_COURSES_SUB_CATEGORY_FAIL,
  ONLINE_COURSES_ADD_REQUEST,
  ONLINE_COURSES_ADD_SUCCESS,
  ONLINE_COURSES_ADD_FAIL,
  ONLINE_COURSES_EDIT_REQUEST,
  ONLINE_COURSES_EDIT_SUCCESS,
  ONLINE_COURSES_EDIT_FAIL,
  ONLINE_COURSES_SINGLE_REQUEST,
  ONLINE_COURSES_SINGLE_SUCCESS,
  ONLINE_COURSES_SINGLE_FAIL,
  ONLINE_COURSES_SECTION_ADD_REQUEST,
  ONLINE_COURSES_SECTION_ADD_SUCCESS,
  ONLINE_COURSES_SECTION_ADD_FAIL,
  ONLINE_COURSES_SECTION_REMOVE_REQUEST,
  ONLINE_COURSES_SECTION_REMOVE_SUCCESS,
  ONLINE_COURSES_SECTION_REMOVE_FAIL,
  ONLINE_COURSE_INSTITUTE_REQUEST,
  ONLINE_COURSE_INSTITUTE_SUCCESS,
  ONLINE_COURSE_INSTITUTE_FAIL,
  ONLINE_COURSE_CAT_SUB_REQUEST,
  ONLINE_COURSE_CAT_SUB_SUCCESS,
  ONLINE_COURSE_CAT_SUB_FAIL,
  ONLINE_COURSE_INSTRUCTOR_REQUEST,
  ONLINE_COURSE_INSTRUCTOR_SUCCESS,
  ONLINE_COURSE_INSTRUCTOR_FAIL,
  ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST,
  ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS,
  ONLINE_COURSE_INSTRUCTOR_LIST_FAIL,
  ONLINE_COURSE_QUIZ_ADD_REQUEST,
  ONLINE_COURSE_QUIZ_ADD_SUCCESS,
  ONLINE_COURSE_QUIZ_ADD_FAIL,
  ONLINE_COURSE_QUIZ_REQUEST,
  ONLINE_COURSE_QUIZ_SUCCESS,
  ONLINE_COURSE_QUIZ_FAIL,
  ONLINE_COURSE_PUBLISH_REQUEST,
  ONLINE_COURSE_PUBLISH_SUCCESS,
  ONLINE_COURSE_PUBLISH_FAIL,
  ANNOUNCEMENT_PUBLISH_REQUEST,
  ANNOUNCEMENT_PUBLISH_SUCCESS,
  ANNOUNCEMENT_PUBLISH_FAIL,
  ANNOUNCEMENT_GET_REQUEST,
  ANNOUNCEMENT_GET_SUCCESS,
  ANNOUNCEMENT_GET_FAIL,
  INSTRUCTOR_ADD_REQUEST,
  INSTRUCTOR_ADD_SUCCESS,
  INSTRUCTOR_ADD_FAIL,
  INSTRUCTOR_LIST_REQUEST,
  INSTRUCTOR_LIST_SUCCESS,
  INSTRUCTOR_LIST_FAIL,
  INSTRUCTOR_EDIT_REQUEST,
  INSTRUCTOR_EDIT_SUCCESS,
  INSTRUCTOR_EDIT_FAIL,
  INSTRUCTOR_SINGLE_REQUEST,
  INSTRUCTOR_SINGLE_SUCCESS,
  INSTRUCTOR_SINGLE_FAIL,
  ONLINE_COURSE_STUDENTS_REQUEST,
  ONLINE_COURSE_STUDENTS_SUCCESS,
  ONLINE_COURSE_STUDENTS_FAIL,
} from "../constants/onlineCourseConstants";

//******************************************************//
// Currency
//******************************************************//

const currencyState = {
  currencyListLoading: false,
  allCurrency: null,
  currencyListError: false,
};
const currencyAddState = {
  currencyAddLoading: false,
  currencyAdded: null,
  currencyAddError: false,
};

const currencyEditState = {
  currencyEditLoading: false,
  currencyEdited: null,
  currencyEditedError: false,
};

const currencySingleState = {
  singleCurrencyLoading: false,
  singleCurrency: null,
  singleCurrencyError: false,
};

const onlineCourseSectionState = {
  onlineCourseSectionLoading: false,
  onlineCourseSectionAdded: false,
  onlineCourseSectionError: false,
  activeOnlineCourseSection: null,
};

const onlineCourseRemoveSectionState = {
  onlineCourseSectionRemoveLoading: false,
  onlineCourseSectionRemoved: false,
  onlineCourseSectionError: false,
};

export const currencyReducer = (state = currencyState, action) => {
  switch (action.type) {
    case CURRENCY_LIST_REQUEST:
      return {
        currencyListLoading: true,
        allCurrency: null,
        currencyListError: false,
      };
    case CURRENCY_LIST_SUCCESS:
      return {
        currencyListLoading: false,
        allCurrency: action.payload,
        currencyListError: false,
      };
    case CURRENCY_LIST_FAIL:
      return {
        currencyListLoading: false,
        currencyListError: action.payload,
        allCurrency: null,
      };

    default:
      return state;
  }
};

export const currencyAddReducer = (state = currencyAddState, action) => {
  switch (action.type) {
    case CURRENCY_ADD_REQUEST:
      return {
        currencyAddLoading: true,
        currencyAdded: null,
        currencyAddError: false,
      };
    case CURRENCY_ADD_SUCCESS:
      return {
        currencyAddLoading: false,
        currencyAdded: action.payload,
        currencyAddError: false,
      };
    case CURRENCY_ADD_FAIL:
      return {
        currencyAddLoading: false,
        currencyAddError: action.payload,
        currencyAdded: null,
      };

    default:
      return state;
  }
};

export const currencyEditReducer = (state = currencyEditState, action) => {
  switch (action.type) {
    case CURRENCY_EDIT_REQUEST:
      return {
        currencyEditLoading: true,
        currencyEdited: null,
        currencyEditedError: false,
      };
    case CURRENCY_EDIT_SUCCESS:
      return {
        currencyEditLoading: false,
        currencyEdited: action.payload,
        currencyEditedError: false,
      };
    case CURRENCY_EDIT_FAIL:
      return {
        currencyEditLoading: false,
        currencyEditedError: action.payload,
        currencyEdited: null,
      };

    default:
      return state;
  }
};

export const singleCurrencyReducer = (state = currencySingleState, action) => {
  switch (action.type) {
    case CURRENCY_SINGLE_REQUEST:
      return {
        singleCurrencyLoading: true,
        singleCurrency: null,
        singleCurrencyError: false,
      };
    case CURRENCY_SINGLE_SUCCESS:
      return {
        singleCurrencyLoading: false,
        singleCurrency: action.payload,
        singleCurrencyError: false,
      };
    case CURRENCY_SINGLE_FAIL:
      return {
        singleCurrencyLoading: false,
        singleCurrencyError: action.payload,
        singleCurrency: null,
      };

    default:
      return state;
  }
};

//******************************************************//
// Online institute
//******************************************************//

const courseState = {
  onlineIntListLoading: true,
  allOnlineInt: null,
  onlineIntError: false,
};

const onlineIntAddState = {
  onlineIntAddLoading: false,
  onlineIntAdded: null,
  onlineIntAddError: false,
};

const onlineIntEditState = {
  onlineIntEditLoading: false,
  onlineIntEdited: null,
  onlineIntEditError: false,
};

const singleOnlineIntState = {
  singleOnlineIntLoading: false,
  singleOnlineInt: null,
  singleOnlineIntError: false,
};

export const onlineIntReducer = (state = courseState, action) => {
  switch (action.type) {
    case ONLINE_INSTITUTE_LIST_REQUEST:
      return {
        onlineIntListLoading: true,
        allOnlineInt: null,
        onlineIntError: false,
      };
    case ONLINE_INSTITUTE_LIST_SUCCESS:
      return {
        onlineIntListLoading: false,
        allOnlineInt: action.payload,
        onlineIntError: false,
      };
    case ONLINE_INSTITUTE_LIST_FAIL:
      return {
        onlineIntListLoading: false,
        onlineIntError: action.payload,
        allOnlineInt: null,
      };

    default:
      return state;
  }
};

export const onlineIntAddReducer = (state = onlineIntAddState, action) => {
  switch (action.type) {
    case ONLINE_INSTITUTE_ADD_REQUEST:
      return {
        onlineIntAddLoading: true,
        onlineIntAdded: null,
        onlineIntAddError: false,
      };
    case ONLINE_INSTITUTE_ADD_SUCCESS:
      return {
        onlineIntAddLoading: false,
        onlineIntAdded: action.payload,
        onlineIntAddError: false,
      };
    case ONLINE_INSTITUTE_ADD_FAIL:
      return {
        onlineIntAddLoading: false,
        onlineIntAddError: action.payload,
        onlineIntAdded: null,
      };

    default:
      return state;
  }
};

export const onlineIntEditReducer = (state = onlineIntEditState, action) => {
  switch (action.type) {
    case ONLINE_INSTITUTE_EDIT_REQUEST:
      return {
        onlineIntEditLoading: true,
        onlineIntEdited: null,
        onlineIntEditError: false,
      };
    case ONLINE_INSTITUTE_EDIT_SUCCESS:
      return {
        onlineIntEditLoading: false,
        onlineIntEdited: action.payload,
        onlineIntEditError: false,
      };
    case ONLINE_INSTITUTE_EDIT_FAIL:
      return {
        onlineIntEditLoading: false,
        onlineIntEditError: action.payload,
        onlineIntEdited: null,
      };

    default:
      return state;
  }
};

export const singleOnlineIntReducer = (
  state = singleOnlineIntState,
  action
) => {
  switch (action.type) {
    case ONLINE_INSTITUTE_SINGLE_REQUEST:
      return {
        singleOnlineIntLoading: true,
        singleOnlineInt: null,
        singleOnlineIntError: false,
      };
    case ONLINE_INSTITUTE_SINGLE_SUCCESS:
      return {
        singleOnlineIntLoading: false,
        singleOnlineInt: action.payload,
        singleOnlineIntError: false,
      };
    case ONLINE_INSTITUTE_SINGLE_FAIL:
      return {
        singleOnlineIntLoading: false,
        singleOnlineIntError: action.payload,
        singleOnlineInt: null,
      };

    default:
      return state;
  }
};

//******************************************************//
// Online Int Category
//******************************************************//

const onlineIntCatState = {
  onlineIntCatLoading: false,
  onlineIntCat: null,
  onlineIntCatError: false,
};

const onlineIntCatAddState = {
  onlineIntCatAddLoading: false,
  onlineIntCatAdd: null,
  onlineIntCatAddError: false,
};

const onlineIntCatEditState = {
  onlineIntCatEditLoading: false,
  onlineIntCatEdited: null,
  onlineIntCatEditError: false,
};

export const onlineIntCatReducer = (state = onlineIntCatState, action) => {
  switch (action.type) {
    case ONLINE_INST_CATEGORY_LIST_REQUEST:
      return {
        onlineIntCatLoading: true,
        onlineIntCat: null,
        onlineIntCatError: false,
      };
    case ONLINE_INST_CATEGORY_LIST_SUCCESS:
      return {
        onlineIntCatLoading: false,
        onlineIntCat: action.payload,
        onlineIntCatError: false,
      };
    case ONLINE_INST_CATEGORY_LIST_FAIL:
      return {
        onlineIntCatLoading: false,
        onlineIntCatError: action.payload,
        onlineIntCat: null,
      };

    default:
      return state;
  }
};

export const onlineIntCatAddReducer = (
  state = onlineIntCatAddState,
  action
) => {
  switch (action.type) {
    case ONLINE_INST_CATEGORY_ADD_REQUEST:
      return {
        onlineIntCatAddLoading: true,
        onlineIntCatAdd: null,
        onlineIntCatAddError: false,
      };
    case ONLINE_INST_CATEGORY_ADD_SUCCESS:
      return {
        onlineIntCatAddLoading: false,
        onlineIntCatAdd: action.payload,
        onlineIntCatAddError: false,
      };
    case ONLINE_INST_CATEGORY_ADD_FAIL:
      return {
        onlineIntCatAddLoading: false,
        onlineIntCatAddError: action.payload,
        onlineIntCatAdd: null,
      };

    default:
      return state;
  }
};
export const onlineIntCatEditReducer = (
  state = onlineIntCatEditState,
  action
) => {
  switch (action.type) {
    case ONLINE_INST_CATEGORY_EDIT_REQUEST:
      return {
        onlineIntCatEditLoading: true,
        onlineIntCatEdited: null,
        onlineIntCatEditError: false,
      };
    case ONLINE_INST_CATEGORY_EDIT_SUCCESS:
      return {
        onlineIntCatEditLoading: false,
        onlineIntCatEdited: action.payload,
        onlineIntCatEditError: false,
      };
    case ONLINE_INST_CATEGORY_EDIT_FAIL:
      return {
        onlineIntCatEditLoading: false,
        onlineIntCatEditError: action.payload,
        onlineIntCatEdited: null,
      };

    default:
      return state;
  }
};

//******************************************************//
// Online Int Category
//******************************************************//

const onlineCourseListState = {
  onlineCourseListLoading: false,
  allOnlineCourse: null,
  onlineCourseListError: false,
};
const onlineCourseSubCatState = {
  onlineCourseSubCatLoading: false,
  onlineCourseSubCategory: null,
  onlineCourseSubCatError: false,
};
const onlineCourseAddState = {
  onlineCourseAddLoading: false,
  onlineCourseAdded: null,
  onlineCourseAddError: false,
};

export const onlineCourseListReducer = (
  state = onlineCourseListState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_LIST_REQUEST:
      return {
        onlineCourseListLoading: true,
        allOnlineCourse: null,
        onlineCourseListError: false,
      };
    case ONLINE_COURSES_LIST_SUCCESS:
      return {
        onlineCourseListLoading: false,
        allOnlineCourse: action.payload,
        onlineCourseListError: false,
      };
    case ONLINE_COURSES_LIST_FAIL:
      return {
        onlineCourseListLoading: false,
        onlineCourseListError: action.payload,
        allOnlineCourse: null,
      };

    default:
      return state;
  }
};
export const onlineCourseSubCatReducer = (
  state = onlineCourseSubCatState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_SUB_CATEGORY_REQUEST:
      return {
        onlineCourseSubCatLoading: true,
        onlineCourseSubCategory: null,
        onlineCourseSubCatError: false,
      };
    case ONLINE_COURSES_SUB_CATEGORY_SUCCESS:
      return {
        onlineCourseSubCatLoading: false,
        onlineCourseSubCategory: action.payload,
        onlineCourseSubCatError: false,
      };
    case ONLINE_COURSES_SUB_CATEGORY_FAIL:
      return {
        onlineCourseSubCatLoading: false,
        onlineCourseSubCatError: action.payload,
        onlineCourseSubCategory: null,
      };

    default:
      return state;
  }
};

export const onlineCourseAddReducer = (
  state = onlineCourseAddState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_ADD_REQUEST:
      return {
        onlineCourseAddLoading: true,
        onlineCourseAdded: null,
        onlineCourseAddError: false,
      };
    case ONLINE_COURSES_ADD_SUCCESS:
      return {
        onlineCourseAddLoading: false,
        onlineCourseAdded: action.payload,
        onlineCourseAddError: false,
      };
    case ONLINE_COURSES_ADD_FAIL:
      return {
        onlineCourseAddLoading: false,
        onlineCourseAddError: action.payload,
        onlineCourseAdded: null,
      };

    default:
      return state;
  }
};

const onlineCourseEditState = {
  onlineCourseEditLoading: false,
  onlineCourseEdited: null,
  onlineCourseEditError: false,
};

const onlineSingleCourseState = {
  onlineSingleCourseLoading: false,
  singleOnlineCourse: null,
  singleOnlineCourseError: false,
};

export const onlineCourseEditReducer = (
  state = onlineCourseEditState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_EDIT_REQUEST:
      return {
        onlineCourseEditLoading: true,
        onlineCourseEdited: null,
        onlineCourseEditError: false,
      };
    case ONLINE_COURSES_EDIT_SUCCESS:
      return {
        onlineCourseEditLoading: false,
        onlineCourseEdited: action.payload,
        onlineCourseEditError: false,
      };
    case ONLINE_COURSES_EDIT_FAIL:
      return {
        onlineCourseEditLoading: false,
        onlineCourseEditError: action.payload,
        onlineCourseEdited: null,
      };

    default:
      return state;
  }
};

export const singleOnlineCourseReducer = (
  state = onlineSingleCourseState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_SINGLE_REQUEST:
      return {
        onlineSingleCourseLoading: true,
        singleOnlineCourse: null,
        singleOnlineCourseError: false,
      };
    case ONLINE_COURSES_SINGLE_SUCCESS:
      return {
        singleCourseLoading: false,
        singleOnlineCourse: action.payload,
        singleOnlineCourseError: false,
      };
    case ONLINE_COURSES_SINGLE_FAIL:
      return {
        singleCourseLoading: false,
        singleOnlineCourseError: action.payload,
        singleOnlineCourse: null,
      };

    default:
      return state;
  }
};

export const onlineCourseSectionReducer = (
  state = onlineCourseSectionState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_SECTION_ADD_REQUEST:
      return {
        onlineCourseSectionLoading: true,
        onlineCourseSectionAdded: false,
        onlineCourseSectionError: false,
        activeOnlineCourseSection: action.payload,
      };
    case ONLINE_COURSES_SECTION_ADD_SUCCESS:
      return {
        onlineCourseSectionLoading: false,
        onlineCourseSectionAdded: true,
        onlineCourseSectionError: false,
        activeOnlineCourseSection: action.payload,
      };
    case ONLINE_COURSES_SECTION_ADD_FAIL:
      return {
        onlineCourseSectionLoading: false,
        onlineCourseSectionAdded: false,
        onlineCourseSectionError: true,
        activeOnlineCourseSection: action.payload,
      };

    default:
      return state;
  }
};

export const onlineCourseSectionRemoveReducer = (
  state = onlineCourseRemoveSectionState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSES_SECTION_REMOVE_REQUEST:
      return {
        onlineCourseSectionRemoveLoading: true,
        onlineCourseSectionRemoved: false,
        onlineCourseSectionRemovedError: false,
      };
    case ONLINE_COURSES_SECTION_REMOVE_SUCCESS:
      return {
        onlineCourseSectionRemoveLoading: false,
        onlineCourseSectionRemoved: true,
        onlineCourseSectionRemovedError: false,
      };
    case ONLINE_COURSES_SECTION_REMOVE_FAIL:
      return {
        onlineCourseSectionRemoveLoading: false,
        onlineCourseSectionRemoved: false,
        onlineCourseSectionRemovedError: true,
      };

    default:
      return state;
  }
};

const onlineCourseInstituteListState = {
  onlineCourseIntListLoading: false,
  allOnlineCourseIntList: null,
  onlineCourseIntListError: false,
};

export const onlineCourseIntListReducer = (
  state = onlineCourseInstituteListState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_INSTITUTE_REQUEST:
      return {
        onlineCourseIntListLoading: true,
        allOnlineCourseIntList: null,
        onlineCourseIntListError: false,
      };
    case ONLINE_COURSE_INSTITUTE_SUCCESS:
      return {
        onlineCourseIntListLoading: false,
        allOnlineCourseIntList: action.payload,
        onlineCourseIntListError: false,
      };
    case ONLINE_COURSE_INSTITUTE_FAIL:
      return {
        onlineCourseIntListLoading: false,
        onlineCourseIntListError: action.payload,
        allOnlineCourseIntList: null,
      };

    default:
      return state;
  }
};
const onlineCourseIntCatList = {
  onlineIntCatListLoading: false,
  onlineIntCatList: null,
  onlineCourseIntCatError: false,
};

export const onlineCourseIntCatListReducer = (
  state = onlineCourseIntCatList,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_CAT_SUB_REQUEST:
      return {
        onlineIntCatListLoading: true,
        onlineIntCatList: null,
        onlineCourseIntCatError: false,
      };
    case ONLINE_COURSE_CAT_SUB_SUCCESS:
      return {
        onlineIntCatListLoading: false,
        onlineIntCatList: action.payload,
        onlineCourseIntCatError: false,
      };
    case ONLINE_COURSE_CAT_SUB_FAIL:
      return {
        onlineIntCatListLoading: false,
        onlineCourseIntCatError: action.payload,
        onlineIntCatList: null,
      };

    default:
      return state;
  }
};
const onlineCourseInstructorState = {
  onlineCourseInstructorLoading: false,
  onlineCourseInstructor: null,
  onlineCourseInstructorError: false,
};

export const onlineCourseInstructorReducer = (
  state = onlineCourseInstructorState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_INSTRUCTOR_REQUEST:
      return {
        onlineCourseInstructorLoading: true,
        onlineCourseInstructor: null,
        onlineCourseInstructorError: false,
      };
    case ONLINE_COURSE_INSTRUCTOR_SUCCESS:
      return {
        onlineCourseInstructorLoading: false,
        onlineCourseInstructor: action.payload,
        onlineCourseInstructorError: false,
      };
    case ONLINE_COURSE_INSTRUCTOR_FAIL:
      return {
        onlineCourseInstructorLoading: false,
        onlineCourseInstructorError: action.payload,
        onlineCourseInstructor: null,
      };

    default:
      return state;
  }
};

const allOnlineCourseInstructorState = {
  allOnlineCourseInstructorLoading: false,
  allOnlineCourseInstructor: null,
  allOnlineCourseInstructorError: false,
};

export const allOnlineCourseInstructorReducer = (
  state = allOnlineCourseInstructorState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST:
      return {
        allOnlineCourseInstructorLoading: true,
        allOnlineCourseInstructor: null,
        allOnlineCourseInstructorError: false,
      };
    case ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS:
      return {
        allOnlineCourseInstructorLoading: false,
        allOnlineCourseInstructor: action.payload,
        allOnlineCourseInstructorError: false,
      };
    case ONLINE_COURSE_INSTRUCTOR_LIST_FAIL:
      return {
        allOnlineCourseInstructorLoading: false,
        allOnlineCourseInstructor: null,
        allOnlineCourseInstructorError: action.payload,
      };

    default:
      return state;
  }
};
const onlineCourseQuizAddState = {
  onlineCourseQuizAddLoading: false,
  onlineCourseQuizAdded: null,
  onlineCourseQuizAddError: false,
};

export const onlineCourseQuizAddReducer = (
  state = onlineCourseQuizAddState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_QUIZ_ADD_REQUEST:
      return {
        onlineCourseQuizAddLoading: true,
        onlineCourseQuizAdded: null,
        onlineCourseQuizAddError: false,
      };
    case ONLINE_COURSE_QUIZ_ADD_SUCCESS:
      return {
        onlineCourseQuizAddLoading: false,
        onlineCourseQuizAdded: action.payload,
        onlineCourseQuizAddError: false,
      };
    case ONLINE_COURSE_QUIZ_ADD_FAIL:
      return {
        onlineCourseQuizAddLoading: false,
        onlineCourseQuizAdded: null,
        onlineCourseQuizAddError: action.payload,
      };

    default:
      return state;
  }
};
const singleOnlineCourseQuizState = {
  singleOnlineCourseQuizLoading: false,
  singleOnlineCourseQuiz: null,
  onlineCourseQuizError: false,
};

export const singleOnlineCourseQuizReducer = (
  state = singleOnlineCourseQuizState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_QUIZ_REQUEST:
      return {
        singleOnlineCourseQuizLoading: true,
        singleOnlineCourseQuiz: null,
        onlineCourseQuizError: false,
      };
    case ONLINE_COURSE_QUIZ_SUCCESS:
      return {
        singleOnlineCourseQuizLoading: false,
        singleOnlineCourseQuiz: action.payload,
        onlineCourseQuizError: false,
      };
    case ONLINE_COURSE_QUIZ_FAIL:
      return {
        singleOnlineCourseQuizLoading: false,
        singleOnlineCourseQuiz: null,
        onlineCourseQuizError: action.payload,
      };

    default:
      return state;
  }
};
const onlineCoursePublishState = {
  onlineCoursePublishLoading: false,
  singleOnlineCourseQuiz: null,
  singleOnlineCourseError: false,
};

export const onlineCoursePublishReducer = (
  state = onlineCoursePublishState,
  action
) => {
  switch (action.type) {
    case ONLINE_COURSE_PUBLISH_REQUEST:
      return {
        onlineCoursePublishLoading: true,
        onlineCoursePublished: null,
        onlineCoursePublishError: false,
      };
    case ONLINE_COURSE_PUBLISH_SUCCESS:
      return {
        onlineCoursePublishLoading: false,
        onlineCoursePublished: action.payload,
        onlineCoursePublishError: false,
      };
    case ONLINE_COURSE_PUBLISH_FAIL:
      return {
        onlineCoursePublishLoading: false,
        onlineCoursePublished: null,
        onlineCoursePublishError: action.payload,
      };

    default:
      return state;
  }
};

const onlineAnnouncementPublish = {
  announcementEditLoading: false,
  announcementEdited: null,
  announcementEditError: false,
};

export const onlineAnnouncementPublishReducer = (
  state = onlineAnnouncementPublish,
  action
) => {
  switch (action.type) {
    case ANNOUNCEMENT_PUBLISH_REQUEST:
      return {
        announcementEditLoading: true,
        announcementEdited: null,
        announcementEditError: false,
      };
    case ANNOUNCEMENT_PUBLISH_SUCCESS:
      return {
        announcementEditLoading: false,
        announcementEdited: action.payload,
        announcementEditError: false,
      };
    case ANNOUNCEMENT_PUBLISH_FAIL:
      return {
        announcementEditLoading: false,
        announcementEdited: null,
        announcementEditError: action.payload,
      };

    default:
      return state;
  }
};
const singleAnnouncementState = {
  announcementSingleLoading: false,
  singleAnnouncement: null,
  announcementEditError: false,
};

export const singleAnnouncementReducer = (
  state = singleAnnouncementState,
  action
) => {
  switch (action.type) {
    case ANNOUNCEMENT_GET_REQUEST:
      return {
        announcementSingleLoading: true,
        singleAnnouncement: null,
        announcementSingleError: false,
      };
    case ANNOUNCEMENT_GET_SUCCESS:
      return {
        announcementSingleLoading: false,
        singleAnnouncement: action.payload,
        announcementSingleError: false,
      };
    case ANNOUNCEMENT_GET_FAIL:
      return {
        announcementSingleLoading: false,
        singleAnnouncement: null,
        announcementSingleError: action.payload,
      };

    default:
      return state;
  }
};

//******************************************************//
// Online course Instructor
//******************************************************//

const instructorAddState = {
  instructorAddLoading: false,
  instructorAdd: null,
  instructorAddError: false,
};

const instructorListState = {
  instructorListLoading: false,
  sinstructor: null,
  instructorListError: false,
};

const instructorEditState = {
  instructorEditLoading: false,
  instructorEdited: null,
  instructorEditError: false,
};

const singleInstructor = {
  singleInstructorLoading: false,
  singleInstructor: null,
  singleInstructorError: false,
};
// const eventInstructorState = {
//   eventInstructorLoading: false,
//   eventInstructor: null,
//   eventInstructorError: false,
// };

export const instructorAddReducer = (state = instructorAddState, action) => {
  switch (action.type) {
    case INSTRUCTOR_ADD_REQUEST:
      return {
        instructorAddLoading: true,
        instructorAdd: null,
        instructorAddError: false,
      };
    case INSTRUCTOR_ADD_SUCCESS:
      return {
        instructorAddLoading: false,
        instructorAdd: action.payload,
        instructorAddError: false,
      };
    case INSTRUCTOR_ADD_FAIL:
      return {
        instructorAddLoading: false,
        instructorAddError: action.payload,
        instructorAdd: null,
      };

    default:
      return state;
  }
};

export const instructorReducer = (state = instructorListState, action) => {
  switch (action.type) {
    case INSTRUCTOR_LIST_REQUEST:
      return {
        instructorListLoading: true,
        instructor: null,
        instructorListError: false,
      };
    case INSTRUCTOR_LIST_SUCCESS:
      return {
        instructorListLoading: false,
        instructor: action.payload,
        instructorListError: false,
      };
    case INSTRUCTOR_LIST_FAIL:
      return {
        instructorListLoading: false,
        instructorListError: action.payload,
        instructor: null,
      };

    default:
      return state;
  }
};

// export const eventInstructorReducer = (state = eventInstructorState, action) => {
//   switch (action.type) {
//     case INSTRUCTOR_EVENT_REQUEST:
//       return {
//         eventInstructorLoading: true,
//         eventInstructor: null,
//         eventInstructorError: false,
//       };
//     case INSTRUCTOR_EVENT_SUCCESS:
//       return {
//         eventInstructorLoading: false,
//         eventInstructor: action.payload,
//         eventInstructorError: false,
//       };
//     case INSTRUCTOR_EVENT_FAIL:
//       return {
//         eventInstructorLoading: false,
//         eventInstructorError: action.payload,
//         eventInstructor: null,
//       };

//     default:
//       return state;
//   }
// };

export const instructorEditReducer = (state = instructorEditState, action) => {
  switch (action.type) {
    case INSTRUCTOR_EDIT_REQUEST:
      return {
        instructorEditLoading: true,
        instructorEdited: null,
        instructorEditError: false,
      };
    case INSTRUCTOR_EDIT_SUCCESS:
      return {
        instructorEditLoading: false,
        instructorEdited: action.payload,
        instructorEditError: false,
      };
    case INSTRUCTOR_EDIT_FAIL:
      return {
        instructorEditLoading: false,
        instructorEditError: action.payload,
        instructorEdited: null,
      };

    default:
      return state;
  }
};

export const singleInstructorReducer = (state = singleInstructor, action) => {
  switch (action.type) {
    case INSTRUCTOR_SINGLE_REQUEST:
      return {
        singleInstructorLoading: true,
        singleInstructor: null,
        singleInstructorError: false,
      };
    case INSTRUCTOR_SINGLE_SUCCESS:
      return {
        singleInstructorLoading: false,
        singleInstructor: action.payload,
        singleInstructorError: false,
      };
    case INSTRUCTOR_SINGLE_FAIL:
      return {
        singleInstructorLoading: false,
        singleInstructorError: action.payload,
        singleInstructor: null,
      };

    default:
      return state;
  }
};

const registerStudents = {
  registerStudentsLoading: false,
  registerStudents: null,
  registerStudentsError: false,
};

export const registerStudentsReducer = (state = registerStudents, action) => {
  switch (action.type) {
    case ONLINE_COURSE_STUDENTS_REQUEST:
      return {
        registerStudentsLoading: true,
        registerStudents: null,
        registerStudentsError: false,
      };
    case ONLINE_COURSE_STUDENTS_SUCCESS:
      return {
        registerStudentsLoading: false,
        registerStudents: action.payload,
        registerStudentsError: false,
      };
    case ONLINE_COURSE_STUDENTS_FAIL:
      return {
        registerStudentsLoading: false,
        registerStudentsError: action.payload,
        registerStudents: null,
      };

    default:
      return state;
  }
};
