import React from "react";
import { Input } from "reactstrap";
import searchIcon from "../assets/tables/searchIcon.svg";

const SearchInput = ({ searchText, handleSearch, placeholder }) => {
  return (
    <div className="input-group">
      <Input
        className="form-control rounded pe-5"
        type="text"
        placeholder={placeholder}
        value={searchText}
        onChange={handleSearch}
      />
      <img src={searchIcon} alt="Search" />
    </div>
  );
};

export default SearchInput;
