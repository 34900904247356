export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGIN_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";

export const ADMIN_STUDENT_ADD_REQUEST = "ADMIN_STUDENT_ADD_REQUEST";
export const ADMIN_STUDENT_ADD_SUCCESS = "ADMIN_STUDENT_ADD_SUCCESS";
export const ADMIN_STUDENT_ADD_FAIL = "ADMIN_STUDENT_ADD_FAIL";

export const ADMIN_ADD_REQUEST = "ADMIN_STUDENT_REQUEST";
export const ADMIN_ADD_SUCCESS = "ADMIN_STUDENT_SUCCESS";
export const ADMIN_ADD_FAIL = "ADMIN_STUDENT_FAIL";

export const INSTRUCTOR_ADD_REQUEST = "INSTRUCTOR_STUDENT_REQUEST";
export const INSTRUCTOR_ADD_SUCCESS = "INSTRUCTOR_STUDENT_SUCCESS";
export const INSTRUCTOR_ADD_FAIL = "INSTRUCTOR_STUDENT_FAIL";

export const INSTRUCTOR_PASSWORD_UPDATE_REQUEST =
  "INSTRUCTOR_PASSWORD_UPDATE_REQUEST";
export const INSTRUCTOR_PASSWORD_UPDATE_SUCCESS =
  "INSTRUCTOR_PASSWORD_UPDATE_SUCCESS";
export const INSTRUCTOR_PASSWORD_UPDATE_FAIL =
  "INSTRUCTOR_PASSWORD_UPDATE_FAIL";

export const EXAM_CREATOR_ADD_REQUEST = "EXAM_CREATOR_ADD_REQUEST";
export const EXAM_CREATOR_ADD_SUCCESS = "EXAM_CREATOR_ADD_SUCCESS";
export const EXAM_CREATOR_ADD_FAIL = "EXAM_CREATOR_ADD_FAIL";

export const STUDENT_LIST_REQUEST = "STUDENT_LIST_REQUEST";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_FAIL = "STUDENT_LIST_FAIL";
export const STUDENT_LIST_RESET = "STUDENT_LIST_RESET";
