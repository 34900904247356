import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEventSpeakers } from "../../../actions/eventActions.js";
import Meta from "../../../components/Branch/Meta/Meta";
import Create from "../../../components/Main/Event/Create/Create";

const EventForm = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventSpeakers());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Add | Online Institutes | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default EventForm;
