import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";

function EditForm({
  speaker,
  speakerImagePath,
  setSpeakerImagePath,
  changeCreds,
  speakerDescription,
  setSpeakerDescription,
}) {
  const speakerEditReducer = useSelector((state) => state.speakerEdit);
  const { speakerEditLoading } = speakerEditReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="speakerName">
            Speaker Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={speaker.speakerName}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="speakerName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="designation">
            Designation <span className="text-danger">*</span>
          </Label>
          <Input
            value={speaker.designation}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="designation"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="speakerDescription">Description</Label>
          <CkEditor
            value={speakerDescription}
            setDescription={setSpeakerDescription}
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={speakerImagePath}
          setPostImage={setSpeakerImagePath}
          isLoading={false}
        />
      </Col>
    </Row>
  );
}

export default EditForm;
