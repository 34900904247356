import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  InstructorsDropdownList,
  PyschometricDropdownList,
} from "../../../../actions/couponAction";
// import MultiDropdown from "../../../CouponDropDown/MultiDropDown";

const EditForm = () => {
  const [generatedCode, setGeneratedCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [pyschometricOptions, setPyschometricOptions] = useState([]);
  const dispatch = useDispatch();

  const instructorListState = useSelector(
    (state) => state.instructorsDropdownList
  );
  const { instructorLoading, instructorError, allinstructors } =
    instructorListState;

  const pyschometricListState = useSelector(
    (state) => state.pyschometricDropdownList
  );
  const { pyschometricLoading, pyschometricError, pyschometricList } =
    pyschometricListState;

  useEffect(() => {
    dispatch(InstructorsDropdownList());
  }, []);

  useEffect(() => {
    dispatch(PyschometricDropdownList());
  }, []);

  // Function to generate a random 12-digit code
  const generateCode = () => {
    const code = Math.floor(100000000000 + Math.random() * 900000000000);
    setGeneratedCode(code.toString());
  };

  const handleSubmit = () => {
    const couponData = {
      couponCode: generatedCode,
      startDate: startDate,
      expiryDate: expiryDate,
      percentage: percentage,
      // instructorOptions: instructorOptions,
      // pyschometricOptions: pyschometricOptions,
    };
  };
  const jsonStartDate = new Date(startDate);
  const jsonExpiryDate = new Date(expiryDate);
  const couponData = {
    couponCode: generatedCode,
    startDate: jsonStartDate,
    expiryDate: jsonExpiryDate,
    percentage: percentage,
    // instructorOptions: instructorOptions,
    // pyschometricOptions: pyschometricOptions,
  };
  return (
    <Fragment className="border boder-primary">
      <Row className="gutter mt-4 " md={12} lg={12}>
        {/* <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectAssessments">
              Select Assessments <span className="text-danger">*</span>
            </Label>
            <MultiDropdown
              options={pyschometricList}
              setSelectedOptions={setPyschometricOptions}
              selectedOptions={pyschometricOptions}
              loading={pyschometricLoading}
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectCourses">
              Select Courses <span className="text-danger">*</span>
            </Label>
            <MultiDropdown />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectCounsellor">
              Select Counsellors <span className="text-danger">*</span>
            </Label>
            <MultiDropdown />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectInstructor">
              Select Instructors <span className="text-danger">*</span>
            </Label>
            <MultiDropdown
              options={allinstructors}
              setSelectedOptions={setInstructorOptions}
              selectedOptions={instructorOptions}
              loading={instructorLoading}
            />
          </FormGroup>
        </Col> */}

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="Expiry">
              Start Date <span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              maxLength={400}
              required
              name="startDate"
              autoComplete="nope"
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="Expiry">
              Expiry Date <span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              maxLength={400}
              required
              name="expiryDate"
              autoComplete="nope"
              onChange={(e) => setExpiryDate(e.target.value)}
              value={expiryDate}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="gutter ">
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="SetCategory">
              Set Category <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={400}
              required
              name="StudentName"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="SetPercentage">
              Set Percentage <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={400}
              required
              name="StudentName"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="gutter mb-2">
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="description">
              Set Description <span className="text-danger">*</span>
            </Label>
            <Input
              type="multiline"
              maxLength={400}
              required
              name="description"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="gutter mb-2">
        <Col xs={12} md={4} className="mb-2 mb-sm-4 mb-md-0">
          <FormGroup className="mb-0">
            <Label for="code">
              Code <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={12}
              required
              name="code"
              autoComplete="off"
              value={generatedCode} // Bind the generated code state to the input value
              disabled
              className="text-center" // Apply text-center class to center-align text
            />
          </FormGroup>
        </Col>
        <Col
          xs={12}
          md={6}
          className="mb-2 mt-4 mb-md-0 pl-md-1 align-items-center d-flex"
        >
          <FormGroup className="mb-0">
            <Button
              className="rounded-pill d-flex justify-content-center align-items-center w-165"
              type="button" // Change type to "button" to prevent form submission
              color="secondary-red"
              onClick={generateCode} // Call generateCode function on button click
              disabled={generatedCode !== ""} // Disable the button if the generated code is not empty
            >
              <span className="ml-1">Generate</span>
            </Button>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="bg-widget d-flex justify-content-end ">
            <Button
              className="bg-widget rounded-pill d-flex justify-content-center align-items-center w-25"
              type="submit"
              color="secondary-red"
              disabled={false}
              onClick={handleSubmit}
            >
              <span className="ml-1">Submit</span>
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default EditForm;
