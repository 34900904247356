import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Widget from "../../../../components/Branch/Widget/Widget";
import SearchInput from "../../../../helper/SearchInput";
import s from "../../Tables.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCoupons } from "../../../../actions/couponAction";

const CouponTable = ({ tableName }) => {
  const [couponTableList, setCouponTableList] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const couponListState = useSelector((state) => state.couponList);
  const { couponLoading, couponError, couponList } = couponListState;

  useEffect(() => {
    dispatch(getCoupons());
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (couponList !== null && !couponLoading) {
      setCouponTableList(Object.values(couponList));
    }
  }, [couponList, couponLoading]);

  const setPage = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClick = (couponId) => {
    // Implement delete logic here
  };

  const filteredCoupons = couponTableList?.filter((item) =>
    item?.couponCode.toLowerCase().includes(searchText.toLowerCase())
  );

  const totalPages = Math.ceil((filteredCoupons?.length || 0) / pageSize);

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchText}
                      handleSearch={handleSearch}
                      placeholder="Search Coupon"
                    />
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className="w-25">Coupon Code</th>
                        <th className="w-25">Percentage</th>
                        <th className="w-25">Valid From</th>
                        <th className="w-25">Valid To</th>
                        {/* <th className="w-25">Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCoupons?.length > 0 ? (
                        filteredCoupons
                          .slice(
                            currentPage * pageSize,
                            (currentPage + 1) * pageSize
                          )
                          .map((coupon) => (
                            <tr key={coupon?.couponId}>
                              <td>{coupon?.couponCode}</td>
                              <td>{coupon?.discountAmount}%</td>
                              <td>
                                {new Date(coupon?.validFrom).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>

                              <td>
                                {new Date(coupon?.validTo).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              {/* <td className="d-flex align-items-center">
                                <Link
                                  to={`/admin/coupon/editCoupon/${coupon.couponId}`}
                                  className="table-action mr-2"
                                >
                                  <i className="eva eva-edit"></i>
                                </Link>
                                <Link
                                  to={`/admin/coupon/view/${coupon.couponId}`}
                                  className="table-action mr-2"
                                >
                                  <i className="eva eva-eye"></i>
                                </Link>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleClick(coupon.couponId)}
                                >
                                  Delete
                                </button>
                              </td> */}
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {filteredCoupons && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink
                          onClick={(e) => setPage(e, currentPage - 1)}
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(totalPages)].map((_, i) => (
                        <PaginationItem active={i === currentPage} key={i}>
                          <PaginationLink
                            onClick={(e) => setPage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= totalPages - 1}>
                        <PaginationLink
                          onClick={(e) => setPage(e, currentPage + 1)}
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CouponTable;
