export const SEO_ADD_REQUEST = "SEO_ADD_REQUEST";
export const SEO_ADD_SUCCESS = "SEO_ADD_SUCCESS";
export const SEO_ADD_FAIL = "SEO_ADD_FAIL";

export const SEO_LIST_REQUEST = "SEO_LIST_REQUEST";
export const SEO_LIST_SUCCESS = "SEO_LIST_SUCCESS";
export const SEO_LIST_FAIL = "SEO_LIST_FAIL";

export const SEO_EDIT_REQUEST = "SEO_EDIT_REQUEST";
export const SEO_EDIT_SUCCESS = "SEO_EDIT_SUCCESS";
export const SEO_EDIT_FAIL = "SEO_EDIT_FAIL";

export const SEO_SINGLE_REQUEST = "SEO_SINGLE_REQUEST";
export const SEO_SINGLE_SUCCESS = "SEO_SINGLE_SUCCESS";
export const SEO_SINGLE_FAIL = "SEO_SINGLE_FAIL";

export const SEO_ACTIVE_REQUEST = "SEO_ACTIVE_REQUEST";
export const SEO_ACTIVE_SUCCESS = "SEO_ACTIVE_SUCCESS";
export const SEO_ACTIVE_FAIL = "SEO_ACTIVE_FAIL";
