import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import {
  InstructorsDropdownList,
  PyschometricDropdownList,
  addCoupon,
} from "../../../../actions/couponAction";
import MultiDropdown from "../../../CouponDropDown/MultiDropDown";
import classNames from "classnames";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import MultiDropdown from "../../../CouponDropDown/MultiDropDown";

const CreateForm = () => {
  const [generatedCode, setGeneratedCode] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [percentage, setPercentage] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [pyschometricOptions, setPyschometricOptions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const instructorListState = useSelector(
    (state) => state.instructorsDropdownList
  );
  const { instructorLoading, instructorError, allinstructors } =
    instructorListState;

  const pyschometricListState = useSelector(
    (state) => state.pyschometricDropdownList
  );
  const { pyschometricLoading, pyschometricError, pyschometricList } =
    pyschometricListState;

  const couponAdd = useSelector((state) => state.couponAdd);
  const { couponAddLoading } = couponAdd;

  useEffect(() => {
    dispatch(InstructorsDropdownList());
  }, []);

  useEffect(() => {
    dispatch(PyschometricDropdownList());
  }, []);

  // Function to generate a random 12-digit code
  const generateCode = () => {
    const code = Math.floor(100000000000 + Math.random() * 900000000000);
    const codeString = code.toString();
    const formattedCode = codeString.replace(/(\d{3})(?=\d)/g, "$1-");
    setGeneratedCode("EDU-" + formattedCode);
  };

  const handleSubmit = () => {
    const jsonStartDate = new Date(validFrom);
    const jsonExpiryDate = new Date(validTo);
    // convert percentage  to number
    let modifypercentage = parseInt(percentage);

    const couponData = {
      couponCode: generatedCode,
      discountAmount: modifypercentage,
      description: description,
      category: category,
      createdBy: "",
      createdDate: new Date(),
      validFrom: jsonStartDate,
      validTo: jsonExpiryDate,
    };
    dispatch(addCoupon(couponData));
    history.push("/admin/coupon");
  };

  return (
    <Fragment>
      <Row className="gutter mt-4 " md={12} lg={12}>
        {/* <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectAssessments">
              Select Assessments <span className="text-danger">*</span>
            </Label>
            <MultiDropdown
              options={pyschometricList}
              setSelectedOptions={setPyschometricOptions}
              selectedOptions={pyschometricOptions}
              loading={pyschometricLoading}
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectCourses">
              Select Courses <span className="text-danger">*</span>
            </Label>
            <MultiDropdown />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectCounsellor">
              Select Counsellors <span className="text-danger">*</span>
            </Label>
            <MultiDropdown />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="SelectInstructor">
              Select Instructors <span className="text-danger">*</span>
            </Label>
            <MultiDropdown
              options={allinstructors}
              setSelectedOptions={setInstructorOptions}
              selectedOptions={instructorOptions}
              loading={instructorLoading}
            />
          </FormGroup>
        </Col> */}

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
          <FormGroup>
            <Label for="description">
              Set Description <span className="text-danger">*</span>
            </Label>
            <Input
              type="textarea"
              maxLength={400}
              required
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              autoComplete="nope"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="StartDate">
              Start Date <span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              required
              name="validFrom"
              autoComplete="nope"
              onChange={(e) => setValidFrom(e.target.value)}
              className="text-center"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="Expiry">
              Expiry Date <span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              maxLength={400}
              required
              name="validTo"
              autoComplete="nope"
              onChange={(e) => setValidTo(e.target.value)}
              value={validTo}
              className="text-center"
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="SetCategory">
              Set Category <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={400}
              required
              name="category"
              onChange={(e) => setCategory(e.target.value)}
              autoComplete="nope"
              className="text-center"
            />
          </FormGroup>
        </Col>
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
          <FormGroup>
            <Label for="SetPercentage">
              Set Percentage <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={400}
              required
              name="percentage"
              onKeyPress={(e) => {
                const pattern = /[0-9]/;
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault();
                }
              }}
              className="text-center"
              onChange={(e) => setPercentage(e.target.value)}
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="gutter mb-2"></Row>

      <Row className="gutter mb-2">
        <Col xs={12} md={4} className="mb-2 mb-sm-4 mb-md-0">
          <FormGroup className="mb-0">
            <Label for="code">
              Code <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              maxLength={12}
              required
              name="code"
              autoComplete="off"
              value={generatedCode}
              disabled
              className="text-center"
            />
          </FormGroup>
        </Col>
        <Col
          xs={12}
          md={6}
          className="mb-2 mt-4 mb-md-0 pl-md-1 align-items-center d-flex"
        >
          <FormGroup className="mb-0">
            <Button
              className="rounded-pill d-flex justify-content-center align-items-center w-165"
              type="button"
              color="secondary-red"
              onClick={generateCode}
            >
              <span className="ml-1">Generate</span>
            </Button>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="bg-widget d-flex justify-content-end">
            <Button
              className="bg-widget rounded-pill d-flex justify-content-center align-items-center w-25"
              type="submit"
              color="secondary-red"
              disabled={couponAddLoading}
              onClick={handleSubmit}
            >
              {couponAddLoading && (
                <Spinner
                  className={classNames({
                    "position-relative": true,
                    visible: couponAddLoading,
                    invisible: !couponAddLoading,
                  })}
                  size="sm"
                />
              )}
              <span className="ml-1">Submit</span>
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default CreateForm;
