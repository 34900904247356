import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Widget from "../../../Branch/Widget/Widget";
import EditForm from "./EditForm";
import EditQuestionForm from "../Edit/EditQuestionForm";
import Seo from "../../../Branch/Seo/Seo";
import { AssessmentEdit } from "../../../../actions/assessments";
import { toast } from "react-toastify";

function Edit({ formDetails, formId }) {
  const dispatch = useDispatch();

  const [assessmentForm, setAssessmentForm] = useState({
    title: "",
    shortDescription: "",
    author: "",
    grossAmount: 0,
    saleAmount: 0,
    category: "",
    isActive: true,
    isFeatured: true,
    slug: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    description: "",
    formId: 0,
    isNewImage: false,
    image: "",
  });

  const [fields, setFields] = useState([]);

  const [assessmentDescription, setAssessmentDescription] = useState("");
  const [assessmentShortDescription, setAssessmentShortDescription] =
    useState("");
  const [assessmentImagePath, setAssessmentImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });
  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const assessmentEdit = useSelector((state) => state.assessmentEdit);
  const { assessmentEditLoading } = assessmentEdit;

  useEffect(() => {
    if (formDetails) {
      setAssessmentForm({
        formId: formDetails.formId,
        title: formDetails.title,
        shortDescription: formDetails.shortDescription,
        description: formDetails.description,
        author: formDetails.author,
        grossAmount: formDetails.grossAmount,
        saleAmount: formDetails.saleAmount,
        category: formDetails.category,
        isActive: formDetails.isActive,
        isFeatured: formDetails.isFeatured,
        slug: formDetails.slug,
        metaTitle: formDetails.metaTitle,
        metaKeywords: formDetails.metaKeywords,
        metaDescription: formDetails.metaDescription,
        isNewImage: false,
        image: formDetails.image,
      });
      setAssessmentDescription(formDetails.description);
      setAssessmentShortDescription(formDetails.shortDescription);
      setAssessmentImagePath({
        myFile: formDetails.image,
        ImageType: "",
        IsNewImage: false,
        ImageName: "",
      });
      setBannerImagePath({
        myFile: formDetails.coverImage,
        ImageType: "",
        IsNewImage: false,
        ImageName: "",
      });

      if (formDetails.fields && formDetails.fields.length > 0) {
        setFields(formDetails.fields);
      }
    }
  }, [formDetails]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const val = type === "checkbox" ? checked : value;
    setAssessmentForm((prevState) => ({
      ...prevState,
      [name]: val,
    }));

    if (name === "title") {
      let sectionSlug = value.replace(/\s+/g, "-").replace(/-$/, "");
      setAssessmentForm((prevState) => ({
        ...prevState,
        slug: sectionSlug,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Ensure fields are correctly formatted
    const formattedFields = fields.map((field) => {
      const { fieldId, ...restField } = field; // Remove fieldId
      const formattedOptions = field.options.map((option) => {
        const { optionId, formFieldFieldId, ...restOption } = option; // Remove optionId and formFieldFieldId (if needed)
        return restOption;
      });
      return {
        ...restField,
        options: formattedOptions,
      };
    });

    const mergedForm = {
      ...assessmentForm,
      fields: formattedFields,
    };

    dispatch(AssessmentEdit(mergedForm))
      .then((response) => {
        toast.success("Assessment updated successfully!");
        if (response.payload === -1) {
          console.error("Update failed with response:", response);
          toast.error("Assessment update failed!");
        }
      })
      .catch((error) => {
        console.error("Error updating assessment:", error);
      });
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form className="p-5" onSubmit={handleSubmit}>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Questions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Seo
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <EditForm
                        assessmentForm={assessmentForm}
                        changeCreds={handleChange}
                        assessmentDescription={assessmentDescription}
                        setAssessmentDescription={setAssessmentDescription}
                        assessmentShortDescription={assessmentShortDescription}
                        setAssessmentShortDescription={
                          setAssessmentShortDescription
                        }
                        assessmentImagePath={assessmentImagePath}
                        setAssessmentImagePath={setAssessmentImagePath}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <EditQuestionForm
                        fields={fields}
                        setFields={setFields}
                        formId={formId}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <Seo seoMeta={assessmentForm} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={assessmentEditLoading}
                      >
                        {assessmentEditLoading && <Spinner size="sm" />}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
