import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import { careerActiveToggle } from "../../../../actions/careerActions";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import Widget from "../../../../components/Branch/Widget/Widget";
import SearchInput from "../../../../helper/SearchInput";
import s from "../../Tables.module.scss";
var todayDate = new Date();
const CareerTable = function ({ tableName }) {
  const [careerTableList, setCareerTableList] = useState(null);
  const [pageSize, setPageSize] = useState(1);
  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const careerListReducer = useSelector((state) => state.careers);
  const { careerLoading, careers, careerError } = careerListReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    setPageSize(25);
  }, []);

  useEffect(() => {
    if (careers !== null && careerLoading === false) {
      setCareerTableList(careers);
    }
  }, [careerLoading, careers]);

  useEffect(() => {
    if (careerTableList !== null) {
      const firstTablePagesCounts = Math.ceil(
        careerTableList.length / pageSize
      );
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [careerTableList]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const onActive = (status, careerID) => {
    const tempList = [...careerTableList];

    tempList.map(
      (item) => item.careerID === careerID && (item.isActive = !status)
    );

    const foundCareer = tempList.find(
      (element) => element.careerID === careerID
    );
    const selectedCareer = {
      CareerID: foundCareer.careerID,
      IsActive: foundCareer.isActive,
      ModifyBy: "",
      ModifiedDate: todayDate,
    };

    dispatch(careerActiveToggle(selectedCareer));
    setCareerTableList(tempList);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName} </div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchInput}
                      handleSearch={handleSearch}
                      placeholder="Search Career"
                    />
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={cloudIcon}
                        alt="Cloud"
                      />
                    </a>
                    <a href="/#">
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={optionsIcon}
                        alt="Options"
                      />
                    </a>
                    <a href="/#">
                      <img src={funnelIcon} alt="Funnel" />
                    </a>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    {careerLoading ? (
                      <Skeleton count={10} />
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th className={s.checkboxCol}>
                              <div className="checkbox checkbox-primary">
                                <input
                                  className="styled"
                                  id="checkbox100"
                                  type="checkbox"
                                />
                                <label htmlFor="checkbox100" />
                              </div>
                            </th>
                            {/* <th className="w-25">Role</th> */}
                            <th className="w-25">Name</th>
                            <th className="w-25">Since</th>
                            <th className="w-25">Salary</th>
                            <th className="w-25">Active</th>
                          </tr>
                        </thead>

                        <tbody style={{ height: "120px", overflowY: "auto" }}>
                          {careerTableList !== null &&
                            careerTableList
                              .filter((item) =>
                                item.careerName
                                  .toLowerCase()
                                  .includes(searchInput.toLowerCase())
                              )
                              .slice(
                                firstTableCurrentPage * pageSize,
                                (firstTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="checkbox checkbox-primary">
                                      <input
                                        id={item.id}
                                        className="styled"
                                        type="checkbox"
                                      />
                                      <Label for={item.id} />
                                    </div>
                                  </td>
                                  {/* <td className="d-flex align-items-center">
                                    <img
                                      className={s.image}
                                      src={item.careerPath}
                                      alt="User"
                                    />
                                  </td> */}
                                  <td>{item.careerName}</td>
                                  <td>{item.careerOptionSince}</td>
                                  <td>{item.startingSalary}</td>
                                  <td>
                                    <ToggleButton
                                      value={item.isActive}
                                      onToggle={(e) =>
                                        onActive(e, item.careerID)
                                      }
                                    />
                                  </td>
                                  <td className="d-flex justify-content-between">
                                    <Link
                                      to={`/admin/careers/edit/${item.careerID}`}
                                      className="d-flex align-items-center justify-content-center table-action"
                                    >
                                      <i className="eva eva-edit mr-1"></i>
                                    </Link>
                                    <a
                                      href={`https://www.eduberance.com/careers/${item.slug}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="table-action"
                                    >
                                      <i className="eva eva-eye"></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                  {careerTableList !== null && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={firstTableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(firstTablePagesCount)].map((page, i) => (
                        <PaginationItem
                          active={i === firstTableCurrentPage}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => setFirstTablePage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={
                          firstTableCurrentPage >= firstTablePagesCount - 1
                        }
                      >
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CareerTable;
