export const COUPON_ADD_REQUEST = "COUPON_ADD_REQUEST";
export const COUPON_ADD_SUCCESS = "COUPON_ADD_SUCCESS";
export const COUPON_ADD_FAIL = "COUPON_ADD_FAIL";

export const COUPON_LIST_REQUEST = "COUPON_LIST_REQUEST";
export const COUPON_LIST_SUCCESS = "COUPON_LIST_SUCCESS";
export const COUPON_LIST_FAIL = "COUPON_LIST_FAIL";
export const COUPON_LIST_RESET = "COUPON_LIST_RESET";

// coupon dropdown list
export const INSTRUCTOR_DROPDOWN_LIST_REQUEST =
  "INSTRUCTOR_DROPDOWN_LIST_REQUEST";
export const INSTRUCTOR_DROPDOWN_LIST_SUCCESS =
  "INSTRUCTOR_DROPDOWN_LIST_SUCCESS";
export const INSTRUCTOR_DROPDOWN_LIST_FAIL = "INSTRUCTOR_DROPDOWN_LIST_FAIL";
export const INSTRUCTOR_DROPDOWN_LIST_RESET = "INSTRUCTOR_DROPDOWN_LIST_RESET";

export const PYSCHOMETRIC_DROPDOWN_LIST_REQUEST =
  "PYSCHOMETRIC_DROPDOWN_LIST_REQUEST";
export const PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS =
  "PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS";
export const PYSCHOMETRIC_DROPDOWN_LIST_FAIL =
  "PYSCHOMETRIC_DROPDOWN_LIST_FAIL";
export const PYSCHOMETRIC_DROPDOWN_LIST_RESET =
  "PYSCHOMETRIC_DROPDOWN_LIST_RESET";
