export const COURSES_LIST_REQUEST = "COURSES_LIST_REQUEST";
export const COURSES_LIST_SUCCESS = "COURSES_LIST_SUCCESS";
export const COURSES_LIST_FAIL = "COURSES_LIST_FAIL";

export const COURSES_ADD_REQUEST = "COURSES_ADD_REQUEST";
export const COURSES_ADD_SUCCESS = "COURSES_ADD_SUCCESS";
export const COURSES_ADD_FAIL = "COURSES_ADD_FAIL";

export const COURSES_CATEGORY_LIST_REQUEST = "COURSES_CATEGORY_LIST_REQUEST";
export const COURSES_CATEGORY_LIST_SUCCESS = "COURSES_CATEGORY_LIST_SUCCESS";
export const COURSES_CATEGORY_LIST_FAIL = "COURSES_CATEGORY_LIST_FAIL";

export const COURSES_SINGLE_REQUEST = "COURSES_SINGLE_REQUEST";
export const COURSES_SINGLE_SUCCESS = "COURSES_SINGLE_SUCCESS";
export const COURSES_SINGLE_FAIL = "COURSES_SINGLE_FAIL";

export const COURSES_EDIT_REQUEST = "COURSES_EDIT_REQUEST";
export const COURSES_EDIT_SUCCESS = "COURSES_EDIT_SUCCESS";
export const COURSES_EDIT_FAIL = "COURSES_EDIT_FAIL";

export const COURSES_ACTIVE_REQUEST = " COURSES_ACTIVE_REQUEST";
export const COURSES_ACTIVE_SUCCESS = " COURSES_ACTIVE_SUCCESS";
export const COURSES_ACTIVE_FAIL = " COURSES_ACTIVE_FAIL";
