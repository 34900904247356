export const CURRENCY_LIST_REQUEST = "CURRENCY_LIST_REQUEST";
export const CURRENCY_LIST_SUCCESS = "CURRENCY_LIST_SUCCESS";
export const CURRENCY_LIST_FAIL = "CURRENCY_LIST_FAIL";

export const CURRENCY_ADD_REQUEST = "CURRENCY_ADD_REQUEST";
export const CURRENCY_ADD_SUCCESS = "CURRENCY_ADD_SUCCESS";
export const CURRENCY_ADD_FAIL = "CURRENCY_ADD_FAIL";

export const CURRENCY_EDIT_REQUEST = "CURRENCY_EDIT_REQUEST";
export const CURRENCY_EDIT_SUCCESS = "CURRENCY_EDIT_SUCCESS";
export const CURRENCY_EDIT_FAIL = "CURRENCY_EDIT_FAIL";

export const CURRENCY_SINGLE_REQUEST = "CURRENCY_SINGLE_REQUEST";
export const CURRENCY_SINGLE_SUCCESS = "CURRENCY_SINGLE_SUCCESS";
export const CURRENCY_SINGLE_FAIL = "CURRENCY_SINGLE_FAIL";

export const ONLINE_INSTITUTE_LIST_REQUEST = "ONLINE_INSTITUTE_LIST_REQUEST";
export const ONLINE_INSTITUTE_LIST_SUCCESS = "ONLINE_INSTITUTE_LIST_SUCCESS";
export const ONLINE_INSTITUTE_LIST_FAIL = "ONLINE_INSTITUTE_LIST_FAIL";

export const ONLINE_INSTITUTE_ACTIVE_REQUEST =
  "ONLINE_INSTITUTE_ACTIVE_REQUEST";
export const ONLINE_INSTITUTE_ACTIVE_SUCCESS =
  "ONLINE_INSTITUTE_ACTIVE_SUCCESS";
export const ONLINE_INSTITUTE_ACTIVE_FAIL = "ONLINE_INSTITUTE_ACTIVE_FAIL";

export const ONLINE_INSTITUTE_ADD_REQUEST = "ONLINE_INSTITUTE_ADD_REQUEST";
export const ONLINE_INSTITUTE_ADD_SUCCESS = "ONLINE_INSTITUTE_ADD_SUCCESS";
export const ONLINE_INSTITUTE_ADD_FAIL = "ONLINE_INSTITUTE_ADD_FAIL";

export const ONLINE_INSTITUTE_SINGLE_REQUEST =
  "ONLINE_INSTITUTE_SINGLE_REQUEST";
export const ONLINE_INSTITUTE_SINGLE_SUCCESS =
  "ONLINE_INSTITUTE_SINGLE_SUCCESS";
export const ONLINE_INSTITUTE_SINGLE_FAIL = "ONLINE_INSTITUTE_SINGLE_FAIL";

export const ONLINE_INSTITUTE_EDIT_REQUEST = "ONLINE_INSTITUTE_EDIT_REQUEST";
export const ONLINE_INSTITUTE_EDIT_SUCCESS = "ONLINE_INSTITUTE_EDIT_SUCCESS";
export const ONLINE_INSTITUTE_EDIT_FAIL = "ONLINE_INSTITUTE_EDIT_FAIL";

export const ONLINE_INST_CATEGORY_ADD_REQUEST =
  "ONLINE_INST_CATEGORY_ADD_REQUEST";
export const ONLINE_INST_CATEGORY_ADD_SUCCESS =
  "ONLINE_INST_CATEGORY_ADD_SUCCESS";
export const ONLINE_INST_CATEGORY_ADD_FAIL = "ONLINE_INST_CATEGORY_ADD_FAIL";

export const ONLINE_INST_CATEGORY_EDIT_REQUEST =
  "ONLINE_INST_CATEGORY_EDIT_REQUEST";
export const ONLINE_INST_CATEGORY_EDIT_SUCCESS =
  "ONLINE_INST_CATEGORY_EDIT_SUCCESS";
export const ONLINE_INST_CATEGORY_EDIT_FAIL = "ONLINE_INST_CATEGORY_EDIT_FAIL";

export const ONLINE_INST_CATEGORY_LIST_REQUEST =
  "ONLINE_INST_CATEGORY_LIST_REQUEST";
export const ONLINE_INST_CATEGORY_LIST_SUCCESS =
  "ONLINE_INST_CATEGORY_LIST_SUCCESS";
export const ONLINE_INST_CATEGORY_LIST_FAIL = "ONLINE_INST_CATEGORY_LIST_FAIL";

export const ONLINE_COURSES_ACTIVE_REQUEST = "ONLINE_COURSES_ACTIVE_REQUEST";
export const ONLINE_COURSES_ACTIVE_SUCCESS = "ONLINE_COURSES_ACTIVE_SUCCESS";
export const ONLINE_COURSES_ACTIVE_FAIL = "ONLINE_COURSES_ACTIVE_FAIL";

export const ONLINE_COURSES_TOGGLE_REQUEST = "ONLINE_COURSES_TOGGLE_REQUEST";
export const ONLINE_COURSES_TOGGLE_SUCCESS = "ONLINE_COURSES_TOGGLE_SUCCESS";
export const ONLINE_COURSES_TOGGLE_FAIL = "ONLINE_COURSES_TOGGLE_FAIL";

export const ONLINE_COURSES_LIST_REQUEST = "ONLINE_COURSES_LIST_REQUEST";
export const ONLINE_COURSES_LIST_SUCCESS = "ONLINE_COURSES_LIST_SUCCESS";
export const ONLINE_COURSES_LIST_FAIL = "ONLINE_COURSES_LIST_FAIL";

export const ONLINE_COURSES_ADD_REQUEST = "ONLINE_COURSES_ADD_REQUEST";
export const ONLINE_COURSES_ADD_SUCCESS = "ONLINE_COURSES_ADD_SUCCESS";
export const ONLINE_COURSES_ADD_FAIL = "ONLINE_COURSES_ADD_FAIL";

export const ONLINE_COURSES_EDIT_REQUEST = "ONLINE_COURSES_EDIT_REQUEST";
export const ONLINE_COURSES_EDIT_SUCCESS = "ONLINE_COURSES_EDIT_SUCCESS";
export const ONLINE_COURSES_EDIT_FAIL = "ONLINE_COURSES_EDIT_FAIL";

export const ONLINE_COURSES_SINGLE_REQUEST = "ONLINE_COURSES_SINGLE_REQUEST";
export const ONLINE_COURSES_SINGLE_SUCCESS = "ONLINE_COURSES_SINGLE_SUCCESS";
export const ONLINE_COURSES_SINGLE_FAIL = "ONLINE_COURSES_SINGLE_FAIL";

export const ONLINE_COURSES_SUB_CATEGORY_REQUEST =
  "ONLINE_COURSES_SUB_CATEGORY_REQUEST";
export const ONLINE_COURSES_SUB_CATEGORY_SUCCESS =
  "ONLINE_COURSES_SUB_CATEGORY_SUCCESS";
export const ONLINE_COURSES_SUB_CATEGORY_FAIL =
  "ONLINE_COURSES_SUB_CATEGORY_FAIL";

export const ONLINE_COURSES_SECTION_ADD_REQUEST =
  "ONLINE_COURSES_SECTION_ADD_REQUEST";
export const ONLINE_COURSES_SECTION_ADD_SUCCESS =
  "ONLINE_COURSES_SECTION_ADD_SUCCESS";
export const ONLINE_COURSES_SECTION_ADD_FAIL =
  "ONLINE_COURSES_SECTION_ADD_FAIL";

export const ONLINE_COURSES_SECTION_REMOVE_REQUEST =
  "ONLINE_COURSES_SECTION_REMOVE_REQUEST";
export const ONLINE_COURSES_SECTION_REMOVE_SUCCESS =
  "ONLINE_COURSES_SECTION_ADD_SUCCESS";
export const ONLINE_COURSES_SECTION_REMOVE_FAIL =
  "ONLINE_COURSES_SECTION_REMOVE_FAIL";

export const ONLINE_COURSE_INSTITUTE_REQUEST =
  "ONLINE_COURSE_INSTITUTE_REQUEST";
export const ONLINE_COURSE_INSTITUTE_SUCCESS =
  "ONLINE_COURSE_INSTITUTE_SUCCESS";
export const ONLINE_COURSE_INSTITUTE_FAIL = "ONLINE_COURSE_INSTITUTE_FAIL";

export const ONLINE_COURSE_CAT_SUB_REQUEST = "ONLINE_COURSE_CAT_SUB_REQUEST";
export const ONLINE_COURSE_CAT_SUB_SUCCESS = "ONLINE_COURSE_CAT_SUB_SUCCESS";
export const ONLINE_COURSE_CAT_SUB_FAIL = "ONLINE_COURSE_CAT_SUB_FAIL";

export const ONLINE_COURSE_INSTRUCTOR_REQUEST =
  "ONLINE_COURSE_INSTRUCTOR_REQUEST";
export const ONLINE_COURSE_INSTRUCTOR_SUCCESS =
  "ONLINE_COURSE_INSTRUCTOR_SUCCESS";
export const ONLINE_COURSE_INSTRUCTOR_FAIL = "ONLINE_COURSE_INSTRUCTOR_FAIL";

export const ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST =
  "ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST";
export const ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS =
  "ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS";
export const ONLINE_COURSE_INSTRUCTOR_LIST_FAIL =
  "ONLINE_COURSE_INSTRUCTOR_LIST_FAIL";

export const ONLINE_COURSE_QUIZ_ADD_REQUEST = "ONLINE_COURSE_QUIZ_ADD_REQUEST";
export const ONLINE_COURSE_QUIZ_ADD_SUCCESS = "ONLINE_COURSE_QUIZ_ADD_SUCCESS";
export const ONLINE_COURSE_QUIZ_ADD_FAIL = "ONLINE_COURSE_QUIZ_ADD_FAIL";

export const ONLINE_COURSE_QUIZ_REQUEST = "ONLINE_COURSE_QUIZ_REQUEST";
export const ONLINE_COURSE_QUIZ_SUCCESS = "ONLINE_COURSE_QUIZ_SUCCESS";
export const ONLINE_COURSE_QUIZ_FAIL = "ONLINE_COURSE_QUIZ_FAIL";

export const ONLINE_COURSE_PUBLISH_REQUEST = "ONLINE_COURSE_PUBLISH_REQUEST";
export const ONLINE_COURSE_PUBLISH_SUCCESS = "ONLINE_COURSE_PUBLISH_SUCCESS";
export const ONLINE_COURSE_PUBLISH_FAIL = "ONLINE_COURSE_PUBLISH_FAIL";

export const ANNOUNCEMENT_PUBLISH_REQUEST = "ANNOUNCEMENT_PUBLISH_REQUEST";
export const ANNOUNCEMENT_PUBLISH_SUCCESS = "ANNOUNCEMENT_PUBLISH_SUCCESS";
export const ANNOUNCEMENT_PUBLISH_FAIL = "ANNOUNCEMENT_PUBLISH_FAIL";

export const ANNOUNCEMENT_GET_REQUEST = "ANNOUNCEMENT_GET_REQUEST";
export const ANNOUNCEMENT_GET_SUCCESS = "ANNOUNCEMENT_GET_SUCCESS";
export const ANNOUNCEMENT_GET_FAIL = "ANNOUNCEMENT_GET_FAIL";

export const INSTRUCTOR_ADD_REQUEST = "INSTRUCTOR_ADD_REQUEST";
export const INSTRUCTOR_ADD_SUCCESS = "INSTRUCTOR_ADD_SUCCESS";
export const INSTRUCTOR_ADD_FAIL = "INSTRUCTOR_ADD_FAIL";

export const INSTRUCTOR_LIST_REQUEST = "INSTRUCTOR_LIST_REQUEST";
export const INSTRUCTOR_LIST_SUCCESS = "INSTRUCTOR_LIST_SUCCESS";
export const INSTRUCTOR_LIST_FAIL = "INSTRUCTOR_LIST_FAIL";

export const INSTRUCTOR_EDIT_REQUEST = "INSTRUCTOR_EDIT_REQUEST";
export const INSTRUCTOR_EDIT_SUCCESS = "INSTRUCTOR_EDIT_SUCCESS";
export const INSTRUCTOR_EDIT_FAIL = "INSTRUCTOR_EDIT_FAIL";

export const INSTRUCTOR_SINGLE_REQUEST = "INSTRUCTOR_SINGLE_REQUEST";
export const INSTRUCTOR_SINGLE_SUCCESS = "INSTRUCTOR_SINGLE_SUCCESS";
export const INSTRUCTOR_SINGLE_FAIL = "INSTRUCTOR_SINGLE_FAIL";

export const ONLINE_COURSE_STUDENTS_REQUEST = "ONLINE_COURSE_STUDENTS_REQUEST";
export const ONLINE_COURSE_STUDENTS_SUCCESS = "ONLINE_COURSE_STUDENTS_SUCCESS";
export const ONLINE_COURSE_STUDENTS_FAIL = "ONLINE_COURSE_STUDENTS_FAIL";

export const ONLINE_COURSE_APPROVE_REQUEST = "ONLINE_COURSE_APPROVE_REQUEST";
export const ONLINE_COURSE_APPROVE_SUCCESS = "ONLINE_COURSE_APPROVE_SUCCESS";
export const ONLINE_COURSE_APPROVE_FAIL = "ONLINE_COURSE_APPROVE_FAIL";

export const ONLINE_COURSE_CLONE_REQUEST = "ONLINE_COURSE_CLONE_REQUEST";
export const ONLINE_COURSE_CLONE_SUCCESS = "ONLINE_COURSE_CLONE_SUCCESS";
export const ONLINE_COURSE_CLONE_FAIL = "ONLINE_COURSE_CLONE_FAIL";

// export const INSTRUCTOR_EVENT_REQUEST = "INSTRUCTOR_EVENT_REQUEST";
// export const INSTRUCTOR_EVENT_SUCCESS = "INSTRUCTOR_EVENT_SUCCESS";
// export const INSTRUCTOR_EVENT_FAIL = "INSTRUCTOR_EVENT_FAIL";
