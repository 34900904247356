import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  assessmentCreateReducer,
  assessmentDetailReducer,
  assessmentEditReducer,
  assessmentQuestionsReducer,
  assessmentsReducer,
} from "../reducers/assessmentReducers";
import {
  addBlogReducer,
  blogListReducer,
  editBlogReducer,
  singleBlogReducer,
} from "../reducers/blogReducers";
import {
  careerActiveToggleReducer,
  careerAddReducer,
  careerCategoryReducer,
  careerDataPointReducer,
  careerEditReducer,
  careerReducer,
  singleCareerReducer,
} from "../reducers/careerReducers";
import {
  addCompanyReducer,
  companyListReducer,
  editCompanyReducer,
  singleCompanyReducer,
} from "../reducers/companyReducers";
import {
  contactsReducer,
  searchListReducer,
} from "../reducers/contactReducers";
import {
  counsellorAddReducer,
  studentListReducer,
} from "../reducers/counsellorReducers";
import {
  couponAddReducer,
  couponListReducer,
  InstructorsDropdownListReducer,
  PyschometricListReducer,
} from "../reducers/couponReducer";
import {
  courseActiveInactiveReducer,
  courseAddReducer,
  courseCategoryReducer,
  courseEditReducer,
  courseReducer,
  singleCourseReducer,
} from "../reducers/courseReducers";
import {
  eventActiveReducer,
  eventAddReducer,
  eventEditReducer,
  eventListReducer,
  eventSpeakerReducer,
  registerEventListReducer,
  singleEventReducer,
  singleSpeakerReducer,
  speakerAddReducer,
  speakerEditReducer,
  speakerReducer,
} from "../reducers/eventReducers";
import {
  instituteAddReducer,
  instituteCategoryReducer,
  institutesActiveInactiveReducer,
  institutesEditReducer,
  instituteSingleReducer,
  institutesReducers,
  singleOnlineIntCatReducer,
} from "../reducers/instituteReducers";
import navigation from "../reducers/navigation";
import {
  allOnlineCourseInstructorReducer,
  currencyAddReducer,
  currencyEditReducer,
  currencyReducer,
  instructorAddReducer,
  // eventInstructorReducer,
  instructorEditReducer,
  instructorReducer,
  onlineAnnouncementPublishReducer,
  onlineCourseAddReducer,
  onlineCourseEditReducer,
  onlineCourseInstructorReducer,
  onlineCourseIntCatListReducer,
  onlineCourseIntListReducer,
  onlineCourseListReducer,
  onlineCoursePublishReducer,
  onlineCourseQuizAddReducer,
  onlineCourseSectionReducer,
  onlineCourseSectionRemoveReducer,
  onlineCourseSubCatReducer,
  onlineIntAddReducer,
  onlineIntCatAddReducer,
  onlineIntCatEditReducer,
  onlineIntCatReducer,
  onlineIntEditReducer,
  onlineIntReducer,
  registerStudentsReducer,
  singleAnnouncementReducer,
  singleCurrencyReducer,
  singleInstructorReducer,
  singleOnlineCourseQuizReducer,
  singleOnlineCourseReducer,
  singleOnlineIntReducer,
} from "../reducers/onlineCourseReducers";
import { organisationReducer } from "../reducers/organisationReducer";
import {
  addPageReducer,
  editPageReducer,
  pageListReducer,
  singlePageReducer,
} from "../reducers/pageReducers";
import {
  seoAddReducer,
  seoEditReducer,
  seoListReducer,
  singleSeoReducer,
} from "../reducers/seoReducers";
import {
  addAdminByAdminReducer,
  addInstructorByAdminReducer,
  addStudentByAdminReducer,
  adminListReducer,
  examCreatorAddReducer,
  updateIntPasswordReducer,
  userListReducer,
  userLoginReducer,
} from "../reducers/userReducers";
import { tutorReducer } from "../reducers/tutorReducers";

const reducer = combineReducers({
  assessments: assessmentsReducer,
  assessmentCreate: assessmentCreateReducer,
  assessmentEdit: assessmentEditReducer,
  assessmentDetail: assessmentDetailReducer,
  assessmentQuestion: assessmentQuestionsReducer,
  userLogin: userLoginReducer,
  adminList: adminListReducer,
  navigation: navigation,
  users: userListReducer,
  institutes: institutesReducers,
  careers: careerReducer,
  courses: courseReducer,
  instituteCategory: instituteCategoryReducer,
  instituteAdd: instituteAddReducer,
  instituteSingle: instituteSingleReducer,
  institutesEdit: institutesEditReducer,
  courseCategory: courseCategoryReducer,
  courseAdd: courseAddReducer,
  courseEdit: courseEditReducer,
  courseSingle: singleCourseReducer,
  careerDataPoint: careerDataPointReducer,
  careerCategory: careerCategoryReducer,
  careerAdd: careerAddReducer,
  singleCareer: singleCareerReducer,
  institutesActiveInactive: institutesActiveInactiveReducer,
  careerEdit: careerEditReducer,
  careerActiveToggle: careerActiveToggleReducer,
  courseActiveInactive: courseActiveInactiveReducer,
  addStudentByAdmin: addStudentByAdminReducer,
  addAdminByAdmin: addAdminByAdminReducer,
  contacts: contactsReducer,
  speakerAdd: speakerAddReducer,
  instructorAdd: instructorAddReducer,
  eventAdd: eventAddReducer,
  speaker: speakerReducer,
  instructor: instructorReducer,
  eventList: eventListReducer,
  registerEventList: registerEventListReducer,
  eventEdit: eventEditReducer,
  speakerEdit: speakerEditReducer,
  instructorEdit: instructorEditReducer,
  singleEvent: singleEventReducer,
  singleSpeaker: singleSpeakerReducer,
  singleInstructor: singleInstructorReducer,
  eventActive: eventActiveReducer,
  eventSpeaker: eventSpeakerReducer,
  // eventInstructor:eventInstructorReducer,
  currency: currencyReducer,
  currencyAdd: currencyAddReducer,
  currencyEdit: currencyEditReducer,
  singleOnlineInt: singleOnlineIntReducer,
  singleCurrency: singleCurrencyReducer,
  onlineInt: onlineIntReducer,
  onlineIntAdd: onlineIntAddReducer,
  onlineIntEdit: onlineIntEditReducer,
  singleOnlineIntCat: singleOnlineIntCatReducer,
  onlineIntCat: onlineIntCatReducer,
  onlineIntCatAdd: onlineIntCatAddReducer,
  onlineIntCatEdit: onlineIntCatEditReducer,
  onlineCourseList: onlineCourseListReducer,
  onlineCourseSubCat: onlineCourseSubCatReducer,
  onlineCourseAdd: onlineCourseAddReducer,
  onlineCourseEdit: onlineCourseEditReducer,
  singleOnlineCourse: singleOnlineCourseReducer,
  onlineCourseSection: onlineCourseSectionReducer,
  onlineCourseSectionRemove: onlineCourseSectionRemoveReducer,
  onlineCourseIntList: onlineCourseIntListReducer,
  onlineCourseIntCatList: onlineCourseIntCatListReducer,
  onlineCourseInstructor: onlineCourseInstructorReducer,
  allOnlineCourseInstructor: allOnlineCourseInstructorReducer,
  addInstructorByAdmin: addInstructorByAdminReducer,
  onlineCourseQuizAdd: onlineCourseQuizAddReducer,
  singleOnlineCourseQuiz: singleOnlineCourseQuizReducer,
  onlineCoursePublish: onlineCoursePublishReducer,
  pageList: pageListReducer,
  singlePage: singlePageReducer,
  editPage: editPageReducer,
  addPage: addPageReducer,
  onlineAnnouncementPublish: onlineAnnouncementPublishReducer,
  singleAnnouncement: singleAnnouncementReducer,
  blogList: blogListReducer,
  singleBlog: singleBlogReducer,
  editBlog: editBlogReducer,
  addBlog: addBlogReducer,
  companyList: companyListReducer,
  singleCompany: singleCompanyReducer,
  editCompany: editCompanyReducer,
  addCompany: addCompanyReducer,
  examCreatorAdd: examCreatorAddReducer,
  seoList: seoListReducer,
  singleSeo: singleSeoReducer,
  seoEdit: seoEditReducer,
  seoAdd: seoAddReducer,
  registerStudents: registerStudentsReducer,
  searchList: searchListReducer,
  updateIntPassword: updateIntPasswordReducer,
  counsellorAdd: counsellorAddReducer,
  studentList: studentListReducer,
  instructorsDropdownList: InstructorsDropdownListReducer,
  pyschometricDropdownList: PyschometricListReducer,
  couponAdd: couponAddReducer,
  couponList: couponListReducer,
  organisation: organisationReducer,
  tutor: tutorReducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
