import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  currencyList,
  getAllOnlineInstitute,
  getOnlineInstructorList,
  getOnlineIntCatList,
  getOnlineSubCategoryList,
  onlineCourseList,
  onlineIntCatList,
} from "../../../actions/onlineCourseActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Create from "../../../components/Main/OnlineCourse/Create/Create";

const OnlineCourseAdd = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currencyList());
    dispatch(onlineIntCatList());
    dispatch(onlineCourseList());
    dispatch(getOnlineSubCategoryList());
    dispatch(getAllOnlineInstitute());
    dispatch(getOnlineIntCatList());
    dispatch(getOnlineInstructorList());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Add | Online Course | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default OnlineCourseAdd;
