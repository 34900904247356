import React from "react";
import Meta from "../../../components/Branch/Meta/Meta";
import CouponTable from "../../tables/components/CouponTable/CouponTable";
import { Col, Row } from "reactstrap";

const Coupons = () => {
  return (
    <div>
      <Meta title="Coupons | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <CouponTable tableName="Coupon List" />
        </Col>
      </Row>
    </div>
  );
};

export default Coupons;
