import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  addOnlineCourseSection,
  getOnlineCourseById,
} from "../../../../actions/onlineCourseActions";
import {
  booleanStatus,
  recoursesList,
} from "../../../../data/institute/institute";
import { getRandomCharacters } from "../../../../helper/randomCharacter";
import Accordion from "../../../../UI/Accordion/Accordion";
import { editorConfig } from "../../../../UI/Input/CkEditor/CkEditor";
import {
  convertToBase64,
  formatBytes,
} from "../../../../UI/Input/ImageUpload/ImageUpload";
import CustomModal from "../../../../UI/Modal/Modal";
import SectionResourceHeader from "./SectionResourceHeader";

const SectionEditForm = (props) => {
  const [sectionDetails, setSectionDetails] = useState([]);
  const [modalBody] = useState("Are you sure you want to delete this topic?");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveSection, setIsSaveSection] = useState(false);
  const [topicId, setTopicId] = useState({});
  const dispatch = useDispatch();

  const onlineCourseSingleReducer = useSelector(
    (state) => state.singleOnlineCourse
  );
  const { singleOnlineCourse } = onlineCourseSingleReducer;

  const courseSectionSaveReducer = useSelector(
    (state) => state.onlineCourseSection
  );

  const { onlineCourseSectionLoading } = courseSectionSaveReducer;

  const courseSectionRemoveReducer = useSelector(
    (state) => state.onlineCourseSectionRemove
  );

  const { onlineCourseSectionRemoveLoading } = courseSectionRemoveReducer;
  const quizSingleReducer = useSelector(
    (state) => state.singleOnlineCourseQuiz
  );

  const { singleOnlineCourseQuizLoading } = quizSingleReducer;

  useEffect(() => {
    const tempSection = [...props.sectionObj.sectionDetails];
    tempSection.map((item, i) => (item.sectionName = `Section ${i + 1} `));
    setSectionDetails(tempSection);
  }, [props.sectionObj, singleOnlineCourse]);

  const onSelectAccordion = (idx) => {
    const tempSectionDetails = [...sectionDetails];

    tempSectionDetails.map((item, index) => {
      if (item.index === idx) {
        props.setSectionTopic(item.courseTopics);
        props.setActiveSection(item);
        if (item.isSelected) {
          window.scroll({
            top: index * 45,
            left: 0,
          });
        }
        item.isSelected = !item.isSelected;
      } else {
        item.isSelected = false;
        if (item.courseTopics.length > 0) {
          item.courseTopics.map(
            (topicItem, topicIndex) => (topicItem.isSelected = false)
          );
        }
      }
    });

    setSectionDetails(tempSectionDetails);

    props.sectionObj.sectionDetails.map((item, index) => {
      if (item.index === props.activeSection.index) {
        item.courseTopics = props.sectionTopic;
      }
    });
  };

  //onSelectedNestAccordion
  const onSelectedNestAccordion = (idx, topicItem) => {
    let tempSectionTopic = [...props.sectionTopic];
    tempSectionTopic.map((item, index) => {
      item.index == idx
        ? (item.isSelected = !item.isSelected)
        : (item.isSelected = false);
    });

    if (topicItem.sectionResources.length > 0) {
      props.setTopicResources(topicItem.sectionResources);
    } else {
      props.setTopicResources([
        {
          index: getRandomCharacters(),
          resourceID: 0,
          topicResourceName: "",
          isFile: true,
          fileInfo: "",
          resourceLink: "",
          fileType: "",
          fileName: "",
          isNewFile: false,
          isSelected: true,
        },
        {
          index: getRandomCharacters(),
          resourceID: 0,
          topicResourceName: "",
          isFile: true,
          fileInfo: "",
          resourceLink: "",
          fileType: "",
          fileName: "",
          isNewFile: false,
          isSelected: true,
        },
        {
          index: getRandomCharacters(),
          resourceID: 0,
          topicResourceName: "",
          isFile: true,
          fileInfo: "",
          resourceLink: "",
          fileType: "",
          fileName: "",
          isNewFile: false,
          isSelected: true,
        },
      ]);
    }
    props.setSectionTopic(tempSectionTopic);
  };

  const addSectionTopic = (selectedSection) => {
    const tempSectionTopic = [...props.sectionTopic];
    tempSectionTopic.map((item, ikd) => (item.isSelected = false));
    props.setSectionTopic((prevState) => [
      ...prevState,
      {
        index: Math.random(),
        topicID: 0,
        isSelected: true,
        courseTopicTitle: "",
        isEdited: false,
        courseTopicDescription: "",
        studentTopicContent: "",
        isLive: false,
        liveTopicLink: "",
        videoLink: "",
        sectionResources: [],
      },
    ]);
    props.setTopicResources([
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        resourceLink: "",
        fileType: "",
        fileName: "",
        isNewFile: false,
        isSelected: true,
      },
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        resourceLink: "",
        fileType: "",
        fileName: "",
        isNewFile: false,
        isSelected: true,
      },
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        resourceLink: "",
        fileType: "",
        fileName: "",
        isNewFile: false,
        isSelected: true,
      },
    ]);
  };

  const onTopicDescriptionChange = (event, idx) => {
    let sectionDetails = [...props.sectionTopic];
    sectionDetails[idx].courseTopicDescription = event.target.getContent();
  };
  const onStudentTopicContentChange = (event, idx) => {
    let sectionDetails = [...props.sectionTopic];
    sectionDetails[idx].studentTopicContent = event.target.getContent();
  };

  const onResourceChange = async (event, selectedTopic, selSection) => {
    let sectionObj = [...props.sectionTopic];
    const tempTopicResources = [...props.topicResources];
    const sectionModalTemp = { ...props.sectionObj };

    if (["resourceLink", "topicResourceName"].includes(event.target.name)) {
      tempTopicResources[event.target.dataset.id][event.target.name] =
        event.target.value;
    }

    if (["isFile"].includes(event.target.name)) {
      tempTopicResources[event.target.dataset.id][event.target.name] =
        JSON.parse(event.target.value);
      props.setTopicResources(tempTopicResources);
    }

    if (["fileInfo"].includes(event.target.name)) {
      const file = event?.target.files[0];

      if (file) {
        const uploadedImgSize = formatBytes(file?.size);

        if (parseInt(uploadedImgSize) > 10000) {
          // setImgSizeError("Image size should not be greater than 200kb");
        } else {
          const base64 = await convertToBase64(file);

          const replacedBase64 = base64?.split("base64,");

          tempTopicResources[event.target.dataset.id].fileInfo =
            replacedBase64[1];
          tempTopicResources[event.target.dataset.id].fileType = file?.type;
          tempTopicResources[event.target.dataset.id].fileName = file?.name;
          tempTopicResources[event.target.dataset.id].isNewFile = true;
        }
      }
    }

    props.sectionTopic.map((sectionTopic, mainSectionIdx) => {
      if (sectionTopic.index === selectedTopic.index) {
        sectionTopic.sectionResources = tempTopicResources;
      }
    });

    sectionModalTemp.sectionDetails.map((mainSectionItem, mainSectionIdx) => {
      if (mainSectionItem.index === selSection.index) {
        mainSectionItem.courseTopics = sectionObj;
      }
    });
  };

  const removeSectionTopic = (topicItem, val) => {
    setIsModalOpen(true);
    const tempTopicItem = {
      index: val.index,
      courseTopics: topicItem,
    };

    setTopicId(tempTopicItem);
  };
  const onDeleteCourseTopic = () => {
    const tempSectionObj = { ...props.sectionObj };
    props.setSectionTopic(
      props.sectionTopic.filter((r, i) => r !== topicId.courseTopics)
    );
    setIsModalOpen(false);
    tempSectionObj.sectionDetails.map((item, index) => {
      if (item.index === topicId.index) {
        item.courseTopics = props.sectionTopic.filter(
          (r, i) => r !== topicId.courseTopics
        );
      }
    });

    props.setSectionModel(tempSectionObj);
  };
  const onDeleteResource = (resourceItem, selectedSection, selTopic) => {
    props.sectionObj.sectionDetails.map((itemSel, index) => {
      if (itemSel.selectionSl === selectedSection.selectionSl) {
        itemSel.courseTopics.map((topicSel, topicI) => {
          if (topicSel === selTopic) {
            topicSel.sectionResources = props.topicResources.filter(
              (r, i) => r !== resourceItem
            );
          }
        });
      }
    });
    props.setTopicResources(
      props.topicResources.filter((r, i) => r !== resourceItem)
    );
  };

  const onTopicChange = (event, selSection) => {
    let sectionObj = [...props.sectionTopic];
    const sectionModalTemp = { ...props.sectionObj };
    if (
      [
        "courseTopicTitle",
        "courseTopicDescription",
        "liveTopicLink",
        "videoLink",
      ].includes(event.target.name)
    ) {
      sectionObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (["isLive"].includes(event.target.name)) {
      sectionObj[event.target.dataset.id][event.target.name] = JSON.parse(
        event.target.value
      );
    }

    sectionModalTemp.sectionDetails.map((mainSectionItem, mainSectionIdx) => {
      if (mainSectionItem.index === selSection.index) {
        mainSectionItem.courseTopics = sectionObj;
      }
    });

    props.setSectionModel(sectionModalTemp);
  };

  const handleClick = async (file) => {
    window.open(file, "_blank");
  };

  const onSaveSection = () => {
    setIsSaveSection(false);
    onSaveAllSection();
  };

  const onSaveAllSection = () => {
    setIsSaveSection(true);
    const courseSectionInfo = {
      onlineCourseID: singleOnlineCourse.id,
      sectionDetails: props.sectionObj.sectionDetails,
    };

    dispatch(
      addOnlineCourseSection(courseSectionInfo, true, () => {
        dispatch(getOnlineCourseById(singleOnlineCourse.id));
      })
    );
  };

  return (
    <>
      <CustomModal
        modal={isModalOpen}
        modalTitle="Delete"
        isDoSomething={true}
        onModalAction={onDeleteCourseTopic}
        isDisabled={false}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        {modalBody}
      </CustomModal>
      <div className="pt-5">
        {sectionDetails &&
          sectionDetails?.map((val, idx) => {
            let sectionTitle = `sectionTitle-${idx}`;

            return (
              <Accordion
                title={`${val.sectionName} : ${val.sectionTitle}`}
                key={idx}
                isOpen={val.isSelected}
                accordionBodyClasses="mx-3"
                onToggle={() => onSelectAccordion(val.index)}
              >
                <div>
                  <Row className="accordion-body  py-3 ">
                    <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                      <Row className="course-topic-section mx-3 my-3 py-3">
                        <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                          <FormGroup>
                            <Label for="sectionTitle">Section name</Label>
                            <Input
                              maxLength={200}
                              type="text"
                              name="sectionTitle"
                              data-id={idx}
                              id={sectionTitle}
                              defaultValue={val.sectionTitle}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {props.sectionTopic.map((topicItem, topicIndex) => {
                        let courseTopicTitle = `courseTopicTitle-${topicIndex}`;
                        let isLive = `isLive-${topicIndex}`;
                        let liveTopicLink = `liveTopicLink-${topicIndex}`;
                        let videoLink = `videoLink-${topicIndex}`;

                        return (
                          <Accordion
                            title={`Topic ${topicIndex + 1} : ${
                              topicItem.courseTopicTitle
                            }`}
                            key={topicIndex}
                            isOpen={topicItem.isSelected}
                            onToggle={() =>
                              onSelectedNestAccordion(
                                topicItem.index,
                                topicItem
                              )
                            }
                          >
                            <Col
                              className="mb-1 mb-sm-3 mb-md-3"
                              md={12}
                              key={topicIndex}
                            >
                              <Row className="course-topic-section py-3">
                                <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                                  <FormGroup>
                                    <Label for="courseTopicTitle">
                                      Topic Name
                                    </Label>
                                    <Input
                                      maxLength={200}
                                      type="text"
                                      name="courseTopicTitle"
                                      data-id={topicIndex}
                                      id={courseTopicTitle}
                                      defaultValue={topicItem.courseTopicTitle}
                                      autoComplete="off"
                                      onChange={(e) => onTopicChange(e, val)}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col className="mb-1 mb-sm-3 mb-md-0 " md={12}>
                                  <FormGroup>
                                    <Label for="courseTopicDescription">
                                      Topic Description
                                    </Label>

                                    <Editor
                                      initialValue={
                                        topicItem.courseTopicDescription
                                      }
                                      cloudChannel="dev"
                                      init={editorConfig}
                                      apiKey={process.env.REACT_APP_EDITOR_KEY}
                                      onChange={(e) =>
                                        onTopicDescriptionChange(e, topicIndex)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="mb-1 mb-sm-3 mb-md-0 " md={12}>
                                  <FormGroup>
                                    <Label for="studentTopicContent">
                                      Content
                                    </Label>
                                    <Editor
                                      initialValue={
                                        topicItem.studentTopicContent
                                      }
                                      cloudChannel="dev"
                                      init={editorConfig}
                                      apiKey={process.env.REACT_APP_EDITOR_KEY}
                                      onChange={(e) =>
                                        onStudentTopicContentChange(
                                          e,
                                          topicIndex
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="mb-1 mb-sm-3 mb-md-0 " md={12}>
                                  <FormGroup>
                                    <Label for="videoLink">Video Link</Label>
                                    <Input
                                      maxLength={200}
                                      type="text"
                                      name="videoLink"
                                      data-id={topicIndex}
                                      id={videoLink}
                                      defaultValue={topicItem.videoLink}
                                      onChange={(e) => onTopicChange(e, val)}
                                      autoComplete="off"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
                                  <FormGroup>
                                    <Label for="isLive">Live</Label>
                                    <Input
                                      type="select"
                                      name="isLive"
                                      data-id={topicIndex}
                                      id={isLive}
                                      defaultValue={topicItem.isLive}
                                      onChange={(e) => onTopicChange(e, val)}
                                    >
                                      {booleanStatus.map((item, index) => (
                                        <option value={item.status} key={index}>
                                          {item.title}
                                        </option>
                                      ))}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col className="mb-1 mb-sm-3 mb-md-0 " md={12}>
                                  <FormGroup>
                                    <Label for="liveTopicLink">
                                      Live Topic Link
                                    </Label>
                                    <Input
                                      maxLength={200}
                                      type="text"
                                      name="liveTopicLink"
                                      data-id={topicIndex}
                                      id={liveTopicLink}
                                      defaultValue={topicItem.liveTopicLink}
                                      onChange={(e) => onTopicChange(e, val)}
                                      autoComplete="off"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="m-0 p-0 py-3" md={12}>
                                  <SectionResourceHeader />
                                  {props.topicResources.map(
                                    (resourceItem, resourceIndex) => {
                                      let fileInfo = `fileInfo-${resourceIndex}`;
                                      let resourceLink = `resourceLink-${resourceIndex}`;
                                      let isFile = `isFile-${resourceIndex}`;
                                      let topicResourceName = `topicResourceName-${resourceIndex}`;

                                      return (
                                        <Row key={resourceIndex}>
                                          <Col
                                            className="mb-sm-3 mx-3 mb-md-3"
                                            md={12}
                                          >
                                            <Row>
                                              <Col
                                                className="mb-1 mb-md-0"
                                                md={4}
                                              >
                                                <FormGroup>
                                                  <Input
                                                    type="text"
                                                    name="topicResourceName"
                                                    autoCapitalize="Off"
                                                    data-id={resourceIndex}
                                                    id={topicResourceName}
                                                    autoComplete="off"
                                                    defaultValue={
                                                      resourceItem.topicResourceName
                                                    }
                                                    onChange={(e) =>
                                                      onResourceChange(
                                                        e,
                                                        topicItem,
                                                        val
                                                      )
                                                    }
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col
                                                className="mb-1 mb-md-0"
                                                md={2}
                                              >
                                                <FormGroup>
                                                  <Input
                                                    type="select"
                                                    name="isFile"
                                                    data-id={resourceIndex}
                                                    id={isFile}
                                                    value={resourceItem.isFile}
                                                    onChange={(e) =>
                                                      onResourceChange(
                                                        e,
                                                        topicItem,
                                                        val
                                                      )
                                                    }
                                                  >
                                                    {recoursesList.map(
                                                      (item, index) => (
                                                        <option
                                                          value={item.status}
                                                          key={index}
                                                        >
                                                          {item.title}
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                </FormGroup>
                                              </Col>

                                              {resourceItem.isFile ? (
                                                <Col
                                                  className="mb-1 mb-md-0"
                                                  md={5}
                                                >
                                                  <Row>
                                                    <Col
                                                      className="mb-1 mb-md-0 "
                                                      md={11}
                                                    >
                                                      <FormGroup>
                                                        <CustomInput
                                                          maxLength={200}
                                                          type="file"
                                                          name="fileInfo"
                                                          data-id={
                                                            resourceIndex
                                                          }
                                                          id={fileInfo}
                                                          onChange={(e) =>
                                                            onResourceChange(
                                                              e,
                                                              topicItem,
                                                              val
                                                            )
                                                          }
                                                          autoComplete="off"
                                                        />
                                                      </FormGroup>
                                                    </Col>

                                                    <Col
                                                      md={1}
                                                      className="p-0 m-0"
                                                    >
                                                      <a
                                                        href={
                                                          resourceItem.fileInfo
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="resources-download btn-primary d-flex justify-content-center align-items-center"
                                                      >
                                                        <i className="eva eva-download text-white"></i>
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              ) : (
                                                <Col
                                                  className="mb-1 mb-md-0"
                                                  md={5}
                                                >
                                                  <Row>
                                                    <Col
                                                      className="mb-1 mb-md-0"
                                                      md={11}
                                                    >
                                                      <FormGroup>
                                                        <Input
                                                          maxLength={200}
                                                          type="text"
                                                          name="resourceLink"
                                                          data-id={
                                                            resourceIndex
                                                          }
                                                          id={resourceLink}
                                                          defaultValue={
                                                            resourceItem.resourceLink
                                                          }
                                                          onChange={(e) =>
                                                            onResourceChange(
                                                              e,
                                                              topicItem,
                                                              val
                                                            )
                                                          }
                                                          autoComplete="off"
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col
                                                      md={1}
                                                      className="p-0 m-0"
                                                    >
                                                      <a
                                                        href={
                                                          resourceItem.resourceLink
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="resources-download btn-primary d-flex justify-content-center align-items-center"
                                                      >
                                                        <i
                                                          className="fa fa-external-link"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        ></i>
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              )}

                                              <Col
                                                className={`d-flex  mb-1 mb-md-0 align-items-start ${
                                                  props.topicResources
                                                    ?.length ===
                                                    resourceIndex + 1 &&
                                                  "flex-column justify-content-center"
                                                } `}
                                                md={1}
                                              >
                                                {!props.isPublished && (
                                                  <FormGroup>
                                                    <div
                                                      className="m-0 resources-remove btn-danger d-flex justify-content-center align-items-center"
                                                      onClick={() =>
                                                        onDeleteResource(
                                                          resourceItem,
                                                          val,
                                                          topicItem
                                                        )
                                                      }
                                                    >
                                                      <h3 className="m-0 p-0">
                                                        &minus;
                                                      </h3>
                                                    </div>
                                                  </FormGroup>
                                                )}
                                                {props.topicResources
                                                  ?.length ===
                                                  resourceIndex + 1 && (
                                                  <div
                                                    onClick={
                                                      props.addSectionResources
                                                    }
                                                    className="resources-add mt-3 d-flex btn-primary  justify-content-center align-items-center"
                                                  >
                                                    <i
                                                      className="eva eva-plus"
                                                      style={{
                                                        fontSize: "24px",
                                                      }}
                                                      aria-hidden="true"
                                                    />
                                                  </div>
                                                )}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                </Col>

                                <Col
                                  className="mb-1 mb-sm-3 mb-md-0 d-flex"
                                  md={12}
                                >
                                  {topicItem.isEdited && (
                                    <Button
                                      className="my-3  mx-3 d-flex align-items-center"
                                      style={{ height: "2.1rem" }}
                                      color="secondary"
                                      disabled={singleOnlineCourseQuizLoading}
                                      onClick={() =>
                                        props.onQuizToggle(topicItem)
                                      }
                                    >
                                      {singleOnlineCourseQuizLoading && (
                                        <Spinner size="sm" />
                                      )}

                                      <span className="mx-1">
                                        Add or Edit Quiz
                                      </span>
                                    </Button>
                                  )}
                                  {!props.isPublished && (
                                    <Button
                                      className="my-3 d-flex justify-content-between align-items-center"
                                      style={{ height: "2.1rem" }}
                                      color="danger"
                                      onClick={() =>
                                        removeSectionTopic(topicItem, val)
                                      }
                                    >
                                      Remove Topic
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Accordion>
                        );
                      })}

                      <Col className="d-flex">
                        <Button
                          className="my-3 d-flex justify-content-between align-items-center"
                          style={{ height: "2.1rem" }}
                          color="info"
                          onClick={() => onSaveSection(val)}
                          disabled={onlineCourseSectionLoading}
                        >
                          {onlineCourseSectionLoading && !isSaveSection && (
                            <Spinner size="sm" />
                          )}
                          <span className="mx-1"> Save Section & Topic</span>
                        </Button>
                        <Button
                          className="my-3 d-flex justify-content-between align-items-center mx-2"
                          style={{ height: "2.1rem" }}
                          color="primary"
                          onClick={() => addSectionTopic(val)}
                        >
                          Add Topic
                        </Button>

                        {!props.isPublished && (
                          <Button
                            className="my-3 d-flex justify-content-between align-items-center"
                            style={{ height: "2.1rem" }}
                            color="danger"
                            onClick={() => props.delete(val)}
                          >
                            {onlineCourseSectionRemoveLoading && (
                              <Spinner
                                className={classNames({
                                  "position-relative mr-2": true,
                                  visible: onlineCourseSectionRemoveLoading,
                                  invisible: !onlineCourseSectionRemoveLoading,
                                })}
                                size="sm"
                              />
                            )}
                            Remove Section
                          </Button>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </div>
              </Accordion>
            );
          })}

        <Col className="d-flex justify-content-end align-items-center">
          <Button
            className="my-3  mx-3"
            style={{ height: "2.5rem" }}
            color="success"
            onClick={onSaveAllSection}
            disabled={onlineCourseSectionLoading}
          >
            {onlineCourseSectionLoading && isSaveSection && (
              <Spinner size="sm" />
            )}
            <span className="mx-1"> Save All Section</span>
          </Button>
          <Button
            className="my-3"
            style={{ height: "2.5rem" }}
            color="primary"
            onClick={() => props.add(sectionDetails.length)}
            disabled={onlineCourseSectionLoading}
          >
            <span> Add Section</span>
          </Button>
        </Col>
      </div>
    </>
  );
};
export default SectionEditForm;
