import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addSeo } from "../../../actions/seoAction";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import { SeoForm } from "./SeoForm";

var todayDate = new Date();

function SeoAdd({ history }) {
  const [seoInfo, setSeoInfo] = useState({
    pageUrl: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: "",
    createdDate: todayDate,
  });

  const [activeTab, setActive] = useState("1");

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setSeoInfo({
      ...seoInfo,
      [event.target.name]: event.target.value,
    });
  };

  const seoAddReducer = useSelector((state) => state.seoAdd);
  const { seoAddLoading } = seoAddReducer;

  const onSeoAdd = (e) => {
    e.preventDefault();
    const info = {
      pageUrl: seoInfo.pageUrl,
      pageTitle: seoInfo.pageTitle,
      metaTitle: seoInfo.metaTitle,
      metaKeywords: seoInfo.metaKeywords,
      metaDescription: seoInfo.metaDescription,
      createdBy: "",
      createdDate: todayDate,
    };
    dispatch(
      addSeo(info, () => {
        // history.push(`/admin/pages`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Add | Page | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onSeoAdd(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <SeoForm
                      seoInfo={seoInfo}
                      setSeoInfo={setSeoInfo}
                      changeCreds={changeCreds}
                    />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={seoAddLoading}
                    >
                      {seoAddLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: seoAddLoading,
                            invisible: !seoAddLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SeoAdd;
