import {
  TUTOR_ADD_FAIL,
  TUTOR_ADD_REQUEST,
  TUTOR_ADD_SUCCESS,
  TUTOR_EDIT_FAIL,
  TUTOR_EDIT_REQUEST,
  TUTOR_EDIT_SUCCESS,
  TUTOR_GET_FAIL,
  TUTOR_GET_REQUEST,
  TUTOR_GET_SUCCESS,
} from "../constants/tutorConstants";

export const tutorReducer = (
  state = {
    tutorList: [],
    tutorAddLoading: false,
    tutorAddError: null,
    tutorAddSuccess: false,
    tutorEditLoading: false,
    tutorEditError: null,
    tutorEditSuccess: false,
    tutorListLoading: false,
    tutorListError: null,
  },
  action
) => {
  switch (action.type) {
    case TUTOR_GET_REQUEST:
      return { ...state, tutorListLoading: true };
    case TUTOR_GET_SUCCESS:
      return {
        ...state,
        tutorListLoading: false,
        tutorList: action.payload,
      };
    case TUTOR_GET_FAIL:
      return {
        ...state,
        tutorListLoading: false,
        tutorListError: action.payload,
      };
    case TUTOR_ADD_REQUEST:
      return { ...state, tutorAddLoading: true };
    case TUTOR_ADD_SUCCESS:
      return {
        ...state,
        tutorAddLoading: false,
        tutorAddSuccess: true,
        tutorList: [...state.tutorList, action.payload],
      };
    case TUTOR_ADD_FAIL:
      return {
        ...state,
        tutorAddLoading: false,
        tutorAddError: action.payload,
      };
    case TUTOR_EDIT_REQUEST:
      return {
        ...state,
        tutorEditLoading: true,
        tutorEditError: null,
        tutorEditSuccess: false,
      };
    case TUTOR_EDIT_SUCCESS:
      return {
        ...state,
        tutorEditLoading: false,
        tutorEditSuccess: true,
        tutorList: state.tutorList.map((tutor) =>
          tutor.id === action.payload.id ? action.payload : tutor
        ),
      };
    case TUTOR_EDIT_FAIL:
      return {
        ...state,
        tutorEditLoading: false,
        tutorEditError: action.payload,
      };
    default:
      return state;
  }
};
