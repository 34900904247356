import axios from "axios";
import { toast } from "react-toastify";
import {
  ORGANISATION_ADD_FAIL,
  ORGANISATION_ADD_REQUEST,
  ORGANISATION_ADD_SUCCESS,
  ORGANISATION_EDIT_FAIL,
  ORGANISATION_EDIT_REQUEST,
  ORGANISATION_EDIT_SUCCESS,
  ORGANISATION_GET_FAIL,
  ORGANISATION_GET_REQUEST,
  ORGANISATION_GET_SUCCESS,
} from "../constants/organisationConstant";
import { getUserDetails } from "../helper/getUserDetail";

// Action to list organisations
export const listOrganisations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_GET_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/GetUserByCreator/organisation`,
      config
    );

    dispatch({ type: ORGANISATION_GET_SUCCESS, payload: data });
  } catch (error) {
    toast.dismiss();
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: ORGANISATION_GET_FAIL,
      payload: errorMessage,
    });
  }
};

// Action to add an organisation
export const addOrganisation = (organisation) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_ADD_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterOrganisationBySuperAdmin`,
      organisation,
      config
    );

    dispatch(listOrganisations());

    toast.dismiss();
    toast.success("Organisation added successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({ type: ORGANISATION_ADD_SUCCESS, payload: data });
  } catch (error) {
    toast.dismiss();
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: ORGANISATION_ADD_FAIL,
      payload: errorMessage,
    });
  }
};

// Action to edit an organisation
export const editOrganisation =
  (organisation) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_EDIT_REQUEST });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/UpdateOrganisation/${organisation.id}`,
        organisation,
        config
      );

      toast.dismiss();
      toast.success("Organisation edited successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      dispatch({ type: ORGANISATION_EDIT_SUCCESS, payload: data });
    } catch (error) {
      toast.dismiss();
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });

      dispatch({
        type: ORGANISATION_EDIT_FAIL,
        payload: errorMessage,
      });
    }
  };
