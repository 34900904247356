import axios from "axios";
import { toast } from "react-toastify";
import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_ADD_REQUEST,
  CURRENCY_ADD_SUCCESS,
  CURRENCY_ADD_FAIL,
  CURRENCY_EDIT_REQUEST,
  CURRENCY_EDIT_SUCCESS,
  CURRENCY_EDIT_FAIL,
  CURRENCY_SINGLE_REQUEST,
  CURRENCY_SINGLE_SUCCESS,
  CURRENCY_SINGLE_FAIL,
  ONLINE_INSTITUTE_ADD_FAIL,
  ONLINE_INSTITUTE_ADD_REQUEST,
  ONLINE_INSTITUTE_ADD_SUCCESS,
  ONLINE_INSTITUTE_EDIT_FAIL,
  ONLINE_INSTITUTE_EDIT_REQUEST,
  ONLINE_INSTITUTE_EDIT_SUCCESS,
  ONLINE_INSTITUTE_LIST_FAIL,
  ONLINE_INSTITUTE_LIST_REQUEST,
  ONLINE_INSTITUTE_LIST_SUCCESS,
  ONLINE_INSTITUTE_SINGLE_FAIL,
  ONLINE_INSTITUTE_SINGLE_REQUEST,
  ONLINE_INSTITUTE_SINGLE_SUCCESS,
  ONLINE_INSTITUTE_ACTIVE_REQUEST,
  ONLINE_INSTITUTE_ACTIVE_SUCCESS,
  ONLINE_INSTITUTE_ACTIVE_FAIL,
  ONLINE_INST_CATEGORY_ADD_REQUEST,
  ONLINE_INST_CATEGORY_ADD_SUCCESS,
  ONLINE_INST_CATEGORY_ADD_FAIL,
  ONLINE_INST_CATEGORY_EDIT_REQUEST,
  ONLINE_INST_CATEGORY_EDIT_SUCCESS,
  ONLINE_INST_CATEGORY_EDIT_FAIL,
  ONLINE_INST_CATEGORY_LIST_REQUEST,
  ONLINE_INST_CATEGORY_LIST_SUCCESS,
  ONLINE_INST_CATEGORY_LIST_FAIL,
  ONLINE_COURSES_ACTIVE_REQUEST,
  ONLINE_COURSES_ACTIVE_SUCCESS,
  ONLINE_COURSES_ACTIVE_FAIL,
  ONLINE_COURSES_LIST_REQUEST,
  ONLINE_COURSES_LIST_SUCCESS,
  ONLINE_COURSES_LIST_FAIL,
  ONLINE_COURSES_SUB_CATEGORY_REQUEST,
  ONLINE_COURSES_SUB_CATEGORY_SUCCESS,
  ONLINE_COURSES_SUB_CATEGORY_FAIL,
  ONLINE_COURSES_ADD_REQUEST,
  ONLINE_COURSES_ADD_SUCCESS,
  ONLINE_COURSES_ADD_FAIL,
  ONLINE_COURSES_EDIT_REQUEST,
  ONLINE_COURSES_EDIT_SUCCESS,
  ONLINE_COURSES_EDIT_FAIL,
  ONLINE_COURSES_SINGLE_REQUEST,
  ONLINE_COURSES_SINGLE_SUCCESS,
  ONLINE_COURSES_SINGLE_FAIL,
  ONLINE_COURSES_SECTION_ADD_REQUEST,
  ONLINE_COURSES_SECTION_ADD_SUCCESS,
  ONLINE_COURSES_SECTION_ADD_FAIL,
  ONLINE_COURSES_SECTION_REMOVE_REQUEST,
  ONLINE_COURSES_SECTION_REMOVE_SUCCESS,
  ONLINE_COURSES_SECTION_REMOVE_FAIL,
  ONLINE_COURSE_INSTITUTE_REQUEST,
  ONLINE_COURSE_INSTITUTE_SUCCESS,
  ONLINE_COURSE_INSTITUTE_FAIL,
  ONLINE_COURSE_CAT_SUB_REQUEST,
  ONLINE_COURSE_CAT_SUB_SUCCESS,
  ONLINE_COURSE_CAT_SUB_FAIL,
  ONLINE_COURSE_INSTRUCTOR_REQUEST,
  ONLINE_COURSE_INSTRUCTOR_SUCCESS,
  ONLINE_COURSE_INSTRUCTOR_FAIL,
  ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST,
  ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS,
  ONLINE_COURSE_INSTRUCTOR_LIST_FAIL,
  ONLINE_COURSE_QUIZ_ADD_REQUEST,
  ONLINE_COURSE_QUIZ_ADD_SUCCESS,
  ONLINE_COURSE_QUIZ_ADD_FAIL,
  ONLINE_COURSE_QUIZ_REQUEST,
  ONLINE_COURSE_QUIZ_SUCCESS,
  ONLINE_COURSE_QUIZ_FAIL,
  ONLINE_COURSE_PUBLISH_REQUEST,
  ONLINE_COURSE_PUBLISH_SUCCESS,
  ONLINE_COURSE_PUBLISH_FAIL,
  ANNOUNCEMENT_PUBLISH_REQUEST,
  ANNOUNCEMENT_PUBLISH_SUCCESS,
  ANNOUNCEMENT_PUBLISH_FAIL,
  ANNOUNCEMENT_GET_REQUEST,
  ANNOUNCEMENT_GET_SUCCESS,
  ANNOUNCEMENT_GET_FAIL,
  INSTRUCTOR_ADD_REQUEST,
  INSTRUCTOR_ADD_SUCCESS,
  INSTRUCTOR_ADD_FAIL,
  INSTRUCTOR_LIST_REQUEST,
  INSTRUCTOR_LIST_SUCCESS,
  INSTRUCTOR_LIST_FAIL,
  INSTRUCTOR_EDIT_REQUEST,
  INSTRUCTOR_EDIT_SUCCESS,
  INSTRUCTOR_EDIT_FAIL,
  INSTRUCTOR_SINGLE_REQUEST,
  INSTRUCTOR_SINGLE_SUCCESS,
  INSTRUCTOR_SINGLE_FAIL,
  ONLINE_COURSE_STUDENTS_REQUEST,
  ONLINE_COURSE_STUDENTS_SUCCESS,
  ONLINE_COURSE_STUDENTS_FAIL,
  ONLINE_COURSE_APPROVE_REQUEST,
  ONLINE_COURSE_APPROVE_SUCCESS,
  ONLINE_COURSE_APPROVE_FAIL,
  ONLINE_COURSE_CLONE_REQUEST,
  ONLINE_COURSE_CLONE_SUCCESS,
  ONLINE_COURSE_CLONE_FAIL,
  ONLINE_COURSES_TOGGLE_REQUEST,
  ONLINE_COURSES_TOGGLE_SUCCESS,
  ONLINE_COURSES_TOGGLE_FAIL,
} from "../constants/onlineCourseConstants";
import { getUserDetails } from "../helper/getUserDetail";

//******************************************************//
// Currency
//******************************************************//

export const currencyList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/Currency/GetAllCurrency`,
      config
    );
    dispatch({
      type: CURRENCY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_LIST_FAIL,
      payload: true,
    });
  }
};

export const addCurrency = (currencyInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CURRENCY_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/Currency/CreateCurrency`,
      currencyInfo,
      config
    );

    toast.dismiss();

    toast.success("Career created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CURRENCY_ADD_SUCCESS,
      payload: data,
    });

    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CURRENCY_ADD_FAIL,
      payload: true,
    });
  }
};

export const editCurrency = (currencyInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CURRENCY_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/Currency/UpdateCurrency`,
      currencyInfo,
      config
    );

    toast.dismiss();

    toast.success("Career edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: CURRENCY_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: CURRENCY_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getCurrencyById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CURRENCY_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/Currency/GetCurrencyByID?id=${id}`,
      config
    );

    dispatch({
      type: CURRENCY_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_SINGLE_FAIL,
      payload: true,
    });
  }
};

//******************************************************//
// Online institute
//******************************************************//
export const onlineIntList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_INSTITUTE_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/GetAllOnlineCourseInstitute`,
      config
    );

    dispatch({
      type: ONLINE_INSTITUTE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_INSTITUTE_LIST_FAIL,
      payload: true,
    });
  }
};

export const addOnlineInt =
  (onlineIntInfo, useCallBack) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_INSTITUTE_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/Create`,
        onlineIntInfo,
        config
      );

      toast.dismiss();
      toast.success("Online Institute created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_INSTITUTE_ADD_SUCCESS,
        payload: data,
      });

      useCallBack();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_INSTITUTE_ADD_FAIL,
        payload: true,
      });
    }
  };

export const editOnlineInt = (onlineIntInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_INSTITUTE_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/Update`,
      onlineIntInfo,
      config
    );

    toast.dismiss();

    toast.success("Online Institute edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_INSTITUTE_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_INSTITUTE_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getOnlineIntById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_INSTITUTE_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/GetOnlineCourseInstituteByID?id=${id}`,
      config
    );

    dispatch({
      type: ONLINE_INSTITUTE_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_INSTITUTE_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const onlineIntActiveToggle = (intActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();

    dispatch({
      type: ONLINE_INSTITUTE_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/UpdateActiveInactive`,
      intActiveInfo,
      config
    );

    toast.success(
      `Online Institute ${
        intActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: ONLINE_INSTITUTE_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_INSTITUTE_ACTIVE_FAIL,
      payload: true,
    });
  }
};

//******************************************************//
// Online Course Category
//******************************************************//
export const addOnlineIntCat =
  (onlineIntCat, useCallback) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_INST_CATEGORY_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/Create`,
        onlineIntCat,
        config
      );

      toast.dismiss();

      toast.success("Online Course Category created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_INST_CATEGORY_ADD_SUCCESS,
        payload: data,
      });

      useCallback();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_INST_CATEGORY_ADD_FAIL,
        payload: true,
      });
    }
  };

export const editOnlineIntCat = (onlineIntCatEditInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_INST_CATEGORY_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/Update`,
      onlineIntCatEditInfo,
      config
    );

    toast.dismiss();

    toast.success("Online Institute edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_INST_CATEGORY_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_INST_CATEGORY_EDIT_FAIL,
      payload: true,
    });
  }
};

export const onlineIntCatList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_INST_CATEGORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/SuperAdmin/GetAllCourseCategory`,
      config
    );

    dispatch({
      type: ONLINE_INST_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_INST_CATEGORY_LIST_FAIL,
      payload: true,
    });
  }
};

export const onlineCourseActiveToggle =
  (onlineCourseCatActive) => async (dispatch) => {
    try {
      toast.dismiss();
      dispatch({
        type: ONLINE_COURSES_ACTIVE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/UpdateActiveInactive`,
        onlineCourseCatActive,
        config
      );

      toast.success(
        `Online course category ${
          onlineCourseCatActive.IsActive ? "activated" : "deactivated"
        } successfully`,
        {
          position: toast.POSITION.RIGHT_CENTER,
        }
      );

      dispatch({
        type: ONLINE_COURSES_ACTIVE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSES_ACTIVE_FAIL,
        payload: true,
      });
    }
  };
export const onlineCourseList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_COURSES_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/GetAllCourse`,
      config
    );

    dispatch({
      type: ONLINE_COURSES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSES_LIST_FAIL,
      payload: true,
    });
  }
};
export const getOnlineSubCategoryList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_COURSES_SUB_CATEGORY_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/SuperAdmin/GetAllCourseSubCategory`,
      config
    );

    dispatch({
      type: ONLINE_COURSES_SUB_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSES_SUB_CATEGORY_FAIL,
      payload: true,
    });
  }
};

export const getAllOnlineInstitute = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_COURSE_INSTITUTE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstitute/GetAllOnlineCourseInstitute`,
      config
    );

    dispatch({
      type: ONLINE_COURSE_INSTITUTE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_INSTITUTE_FAIL,
      payload: true,
    });
  }
};

export const addOnlineCourse =
  (courseInfo, useCallBack) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSES_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/Create`,
        courseInfo,
        config
      );
      useCallBack(data);

      toast.dismiss();

      toast.success("Course created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSES_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSES_ADD_FAIL,
        payload: true,
      });
    }
  };

export const editOnlineCourse = (editOnlineCourseInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_COURSES_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/Update`,
      editOnlineCourseInfo,
      config
    );

    toast.dismiss();

    toast.success("Course edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_COURSES_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_COURSES_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getOnlineCourseById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_COURSES_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/GetCourseByID?id=${id}`,
      config
    );

    dispatch({
      type: ONLINE_COURSES_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSES_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const addOnlineCourseSection =
  (courseSectionInfo, isSaveAll = false, useCallback) =>
  async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSES_SECTION_ADD_REQUEST,
        data: isSaveAll ? null : courseSectionInfo.sectionSl,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      if (isSaveAll) {
        const { data } = await axios.put(
          `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/SaveAllSection`,
          courseSectionInfo,
          config
        );
        dispatch({
          type: ONLINE_COURSES_SECTION_ADD_SUCCESS,
          payload: null,
        });
      } else {
        const { data } = await axios.put(
          `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/SaveSection`,
          courseSectionInfo,
          config
        );
        dispatch({
          type: ONLINE_COURSES_SECTION_ADD_SUCCESS,
          payload: courseSectionInfo.sectionSl,
        });
      }

      useCallback();

      toast.dismiss();

      toast.success("Course section created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      // if (data) {
      //   document.location.href = "/admin/online-courses";
      // }
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSES_SECTION_ADD_FAIL,
        data: isSaveAll ? null : courseSectionInfo.sectionSl,
      });
    }
  };

export const removeOnlineCourseSection =
  (sectionId, useCallBack) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSES_SECTION_REMOVE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/RemoveSection?sectionSl=${sectionId}`,
        config
      );

      useCallBack();

      toast.dismiss();

      toast.success("Course section removed successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSES_SECTION_REMOVE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSES_SECTION_REMOVE_FAIL,
        payload: true,
      });
    }
  };

export const getOnlineIntCatList = () => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_COURSE_CAT_SUB_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/GetAllCourseCategory`,
      config
    );

    dispatch({
      type: ONLINE_COURSE_CAT_SUB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_CAT_SUB_FAIL,
      payload: true,
    });
  }
};
export const getOnlineInstructorList = () => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstructor/GetInstructor`,
      config
    );

    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_FAIL,
      payload: true,
    });
  }
};
export const getALLInstructor = () => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstructor/GetAllInstructor`,
      config
    );

    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_INSTRUCTOR_LIST_FAIL,
      payload: true,
    });
  }
};

export const addOnlineCourseQuiz =
  (quizInfo, useCallBack) => async (dispatch) => {
    try {
      dispatch({
        type: ONLINE_COURSE_QUIZ_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/SaveQuiz`,
        quizInfo,
        config
      );

      toast.dismiss();

      toast.success("Online course quiz created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSE_QUIZ_ADD_SUCCESS,
        payload: data,
      });

      useCallBack();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSE_QUIZ_ADD_FAIL,
        payload: true,
      });
    }
  };
export const getOnlineCourseQuiz = (id, useCallBack) => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_COURSE_QUIZ_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/GetQuizByTopicID?id=${id}`,
      config
    );

    useCallBack();

    dispatch({
      type: ONLINE_COURSE_QUIZ_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_QUIZ_FAIL,
      payload: true,
    });
  }
};

export const onlineCoursePublish =
  (onlineCourseInfo, useCallBack) => async (dispatch) => {
    try {
      dispatch({
        type: ONLINE_COURSE_PUBLISH_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/PublishedCourse`,
        onlineCourseInfo,
        config
      );

      toast.dismiss();

      toast.success("Online course published successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSE_PUBLISH_SUCCESS,
        payload: data,
      });

      // useCallBack();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSE_PUBLISH_FAIL,
        payload: true,
      });
    }
  };
export const announcementPublish =
  (announcementInfo, useCallBack) => async (dispatch) => {
    try {
      dispatch({
        type: ANNOUNCEMENT_PUBLISH_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/SaveAllAnnouncement`,
        announcementInfo,
        config
      );
      // useCallBack();
      toast.dismiss();

      toast.success("Announcement published successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ANNOUNCEMENT_PUBLISH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ANNOUNCEMENT_PUBLISH_FAIL,
        payload: true,
      });
    }
  };
export const getAnnouncement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ANNOUNCEMENT_GET_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/GetAnnouncement?id=${id}`,
      config
    );

    dispatch({
      type: ANNOUNCEMENT_GET_SUCCESS,
      payload: data,
    });

    // useCallBack();
  } catch (error) {
    dispatch({
      type: ANNOUNCEMENT_GET_FAIL,
      payload: true,
    });
  }
};

export const addInstructor =
  (instructorInfo, useCallback) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: INSTRUCTOR_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterInstructor`,
        instructorInfo,
        config
      );

      toast.dismiss();
      toast.success("Instructor created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: INSTRUCTOR_ADD_SUCCESS,
        payload: data,
      });

      useCallback();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: INSTRUCTOR_ADD_FAIL,
        payload: true,
      });
    }
  };

export const instructorList = () => async (dispatch) => {
  try {
    dispatch({
      type: INSTRUCTOR_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstructor/GetInstructor`,
      config
    );

    dispatch({
      type: INSTRUCTOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSTRUCTOR_LIST_FAIL,
      payload: true,
    });
  }
};

export const editInstructor = (editInstructorInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: INSTRUCTOR_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseInstructor/Update`,
      editInstructorInfo,
      config
    );

    toast.dismiss();

    toast.success("Instructor edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: INSTRUCTOR_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: INSTRUCTOR_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getInstructorById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INSTRUCTOR_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseInstructor/GetByID?id=${id}`,
      config
    );

    dispatch({
      type: INSTRUCTOR_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSTRUCTOR_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const getAllRegisterStudents = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ONLINE_COURSE_STUDENTS_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/GetUserByCourseID?id=${id}`,
      config
    );

    dispatch({
      type: ONLINE_COURSE_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_COURSE_STUDENTS_FAIL,
      payload: true,
    });
  }
};

export const approveOnlineCourse =
  (info, useCallBack) => async (dispatch, getState) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSE_APPROVE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/ApprovedCourse`,
        info,
        config
      );
      useCallBack();
      toast.dismiss();
      toast.success("Course Approved successfully!", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: ONLINE_COURSE_APPROVE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSE_APPROVE_FAIL,
        payload: true,
      });
    }
  };

export const cloneCourse = (info) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_COURSE_CLONE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/CloneCourse`,
      info,
      config
    );

    toast.dismiss();
    toast.success("Course cloned successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ONLINE_COURSE_CLONE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ONLINE_COURSE_CLONE_FAIL,
      payload: true,
    });
  }
};

export const onlineCourseToggle =
  (onlineCourseCatActive) => async (dispatch) => {
    try {
      toast.dismiss();
      dispatch({
        type: ONLINE_COURSES_TOGGLE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/SuperAdminOnlineCourse/UpdateActiveInactive`,
        onlineCourseCatActive,
        config
      );

      toast.success(
        `Online course  ${
          onlineCourseCatActive.IsActive ? "activated" : "deactivated"
        } successfully`,
        {
          position: toast.POSITION.RIGHT_CENTER,
        }
      );

      dispatch({
        type: ONLINE_COURSES_TOGGLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: ONLINE_COURSES_TOGGLE_FAIL,
        payload: true,
      });
    }
  };
