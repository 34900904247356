import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  courseCategoryList,
  getCourseById,
} from "../../../actions/courseActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/Course/Edit/Edit";

const CourseEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const courseSingleReducer = useSelector((state) => state.courseSingle);
  const { singleCourseLoading, singleCourse } = courseSingleReducer;

  useEffect(() => {
    dispatch(getCourseById(id));
    dispatch(courseCategoryList());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Edit | Course | Eduberance" />
      {!singleCourseLoading && (
        <Breadcrumbs url={`/admin/courses/edit/${singleCourse?.courseName}`} />
      )}
      <Edit />
    </div>
  );
};

export default CourseEditForm;
