export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_FAIL = "BLOG_LIST_FAIL";

export const BLOG_ADD_REQUEST = "BLOG_ADD_REQUEST";
export const BLOG_ADD_SUCCESS = "BLOG_ADD_SUCCESS";
export const BLOG_ADD_FAIL = "BLOG_ADD_FAIL";

export const BLOG_CATEGORY_LIST_REQUEST = "BLOG_CATEGORY_LIST_REQUEST";
export const BLOG_CATEGORY_LIST_SUCCESS = "BLOG_CATEGORY_LIST_SUCCESS";
export const BLOG_CATEGORY_LIST_FAIL = "BLOG_CATEGORY_LIST_FAIL";

export const BLOG_SINGLE_REQUEST = "BLOG_SINGLE_REQUEST";
export const BLOG_SINGLE_SUCCESS = "BLOG_SINGLE_SUCCESS";
export const BLOG_SINGLE_FAIL = "BLOG_SINGLE_FAIL";

export const BLOG_EDIT_REQUEST = "BLOG_EDIT_REQUEST";
export const BLOG_EDIT_SUCCESS = "BLOG_EDIT_SUCCESS";
export const BLOG_EDIT_FAIL = "BLOG_EDIT_FAIL";

export const BLOG_ACTIVE_REQUEST = " BLOG_ACTIVE_REQUEST";
export const BLOG_ACTIVE_SUCCESS = " BLOG_ACTIVE_SUCCESS";
export const BLOG_ACTIVE_FAIL = " BLOG_ACTIVE_FAIL";
