import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import {
  Col,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { onlineCourseActiveToggle } from "../../../../actions/onlineCourseActions";
import { listPage, pageActiveToggle } from "../../../../actions/pageAction";
import { listSeo } from "../../../../actions/seoAction";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import searchIcon from "../../../../assets/tables/searchIcon.svg";
import Widget from "../../../../components/Branch/Widget/Widget";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import s from "../../Tables.module.scss";
import SearchInput from "../../../../helper/SearchInput";

var todayDate = new Date();

const SeoTable = function ({ tableName }) {
  const [seoTableList, setSeoTableList] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [filteredSeoTableList, setFilteredSeoTableList] = useState(null);

  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const onlineSeoListReducer = useSelector((state) => state.seoList);
  const { seoLoading, seoList } = onlineSeoListReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listSeo());
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (seoList) {
      setSeoTableList(seoList);
    }
  }, [seoLoading, seoList]);

  useEffect(() => {
    if (seoTableList !== null) {
      const firstTablePagesCounts = Math.ceil(seoTableList.length / pageSize);
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [seoTableList]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const onActive = (status, id) => {
    const tempList = [...seoTableList];
    tempList.map((item) => item.id === id && (item.isActive = !status));
    const foundPage = tempList.find((element) => element.id === id);

    const selectedPage = {
      id: foundPage.id,
      IsActive: foundPage.isActive,
      ModifyBy: "",
      ModifiedDate: todayDate,
    };

    dispatch(pageActiveToggle(selectedPage));
    setSeoTableList(tempList);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleFilter = () => {
    const filteredList = seoList.filter((item) =>
      item.pageUrl.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredSeoTableList(filteredList);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchInput}
                      handleSearch={handleSearch}
                      placeholder="Search"
                    />
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={cloudIcon}
                        alt="Cloud"
                      />
                    </a>
                    <a href="/#">
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a onClick={handleFilter}>
                      <img
                        className="d-none d-sm-block"
                        src={optionsIcon}
                        alt="Options"
                      />
                    </a>
                    <a href="/#">
                      <img src={funnelIcon} alt="Funnel" />
                    </a>
                  </div>
                </div>
                {seoLoading && <Skeleton count={pageSize} />}
                {!seoLoading && (
                  <div className="widget-table-overflow">
                    <Table
                      className={`table-striped table-borderless table-hover ${s.statesTable}`}
                      responsive
                    >
                      <thead>
                        <tr>
                          <th className={s.checkboxCol}>
                            <div className="checkbox checkbox-primary">
                              <input
                                className="styled"
                                id="checkbox100"
                                type="checkbox"
                              />
                              <label htmlFor="checkbox100" />
                            </div>
                          </th>
                          <th>Name</th>
                          <th>Active</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredSeoTableList !== null &&
                          filteredSeoTableList
                            .slice(
                              firstTableCurrentPage * pageSize,
                              (firstTableCurrentPage + 1) * pageSize
                            )
                            .map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="checkbox checkbox-primary">
                                    <input
                                      id={item.id}
                                      className="styled"
                                      type="checkbox"
                                    />
                                    <label></label>
                                  </div>
                                </td>
                                <td>{item?.pageUrl}</td>
                                <td>
                                  <ToggleButton
                                    value={item.isActive}
                                    onToggle={(e) => onActive(e, item.id)}
                                  />
                                </td>
                                <td className="d-flex justify-content-end">
                                  <Link
                                    to={`/admin/seo/edit/${item.id}`}
                                    className="d-flex align-items-center justify-content-end table-action"
                                  >
                                    <i className="eva eva-edit mr-1"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                    {filteredSeoTableList !== null && (
                      <Pagination
                        className="pagination-borderless"
                        aria-label="Page navigation example"
                      >
                        <PaginationItem disabled={firstTableCurrentPage <= 0}>
                          <PaginationLink
                            onClick={(e) =>
                              setFirstTablePage(e, firstTableCurrentPage - 1)
                            }
                            previous
                            href="#top"
                          />
                        </PaginationItem>
                        {[...Array(firstTablePagesCount)].map((page, i) => (
                          <PaginationItem
                            active={i === firstTableCurrentPage}
                            key={i}
                          >
                            <PaginationLink
                              onClick={(e) => setFirstTablePage(e, i)}
                              href="#top"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            firstTableCurrentPage >= firstTablePagesCount - 1
                          }
                        >
                          <PaginationLink
                            onClick={(e) =>
                              setFirstTablePage(e, firstTableCurrentPage + 1)
                            }
                            next
                            href="#top"
                          />
                        </PaginationItem>
                      </Pagination>
                    )}
                  </div>
                )}
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SeoTable;
