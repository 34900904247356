import React from "react";
import Edit from "../../../components/Main/Coupon/Edit/Edit";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Meta from "../../../components/Branch/Meta/Meta";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";

const CoupounsEditForm = () => {
  const { id } = useParams();
  return (
    <>
      {/* {id && <Breadcrumbs url={`admin/coupon/edit/${id}`} />} */}
      <Meta title="Edit | Coupon | Eduberance" />
      <Edit />
    </>
  );
};

export default CoupounsEditForm;
