import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  editOnlineInt,
  getOnlineIntById,
} from "../../../actions/onlineCourseActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import SocialLink from "../../../UI/Input/SocialLink/SocialLink";

var todayDate = new Date();

function OnlineInstituteEdit() {
  const [onlineInstitute, setOnlineInstitute] = useState({
    id: "",
    name: "",
    title: "",
    description: "",
    linkedInProfile: "",
    twitterProfile: "",
    fbProfile: "",
    email: "",
    website: "",
    modifyBy: null,
    modifiedDate: todayDate,
  });
  const [activeTab, setActive] = useState("1");
  const dispatch = useDispatch();

  let { id } = useParams();

  const onlineIntSingleReducer = useSelector((state) => state.singleOnlineInt);
  const { singleOnlineInt, singleOnlineIntLoading, singleOnlineIntError } =
    onlineIntSingleReducer;

  const onlineIntReducer = useSelector((state) => state.onlineIntEdit);
  const { onlineIntEditLoading, onlineIntEdited } = onlineIntReducer;

  useEffect(() => {
    dispatch(getOnlineIntById(id));
  }, [dispatch]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  useEffect(() => {
    if (singleOnlineInt) {
      setOnlineInstitute({
        id: singleOnlineInt?.id,
        name: singleOnlineInt?.name,
        title: singleOnlineInt?.title,
        description: singleOnlineInt?.description,
        linkedInProfile: singleOnlineInt?.linkedInProfile,
        twitterProfile: singleOnlineInt?.twitterProfile,
        fbProfile: singleOnlineInt?.fbProfile,
        email: singleOnlineInt?.email,
        website: singleOnlineInt?.website,
        modifyBy: singleOnlineInt?.modifyBy,
        modifiedDate: singleOnlineInt?.modifiedDate,
      });
    }
  }, [singleOnlineInt]);

  const changeCreds = (event) => {
    setOnlineInstitute({
      ...onlineInstitute,
      [event.target.name]: event.target.value,
    });
  };

  const onEditOnlineInt = (e) => {
    e.preventDefault();
    const currencyInfo = {
      id: onlineInstitute?.id,
      name: onlineInstitute?.name,
      title: onlineInstitute?.title,
      email: onlineInstitute?.email,
      description: onlineInstitute?.description,
      linkedInProfile: onlineInstitute?.linkedInProfile,
      twitterProfile: onlineInstitute?.twitterProfile,
      fbProfile: onlineInstitute?.fbProfile,
      website: onlineInstitute?.website,
      modifyBy: onlineInstitute?.modifyBy,
      modifiedDate: onlineInstitute?.modifiedDate,
    };
    dispatch(editOnlineInt(currencyInfo));
  };

  return (
    <div>
      <Meta title="Edit | Online Institute | Eduberance" />
      {!singleOnlineIntLoading && (
        <Breadcrumbs
          url={`/admin/online-institutes/edit/${singleOnlineInt?.name}`}
        />
      )}
      <Row>
        <Col className="pr-grid-col" xs={12}>
          <Row className="gutter mb-2 mb-sm-4">
            <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
              <Widget>
                <Form
                  onSubmit={(e) => onEditOnlineInt(e)}
                  className="px-5 py-3"
                >
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === "1" })}
                        onClick={() => toggle("1")}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === "2" })}
                        onClick={() => toggle("2")}
                      >
                        Social Links
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row className="gutter mb-2 mb-sm-4 mt-5">
                        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                          <FormGroup>
                            <Label for="name">
                              Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={onlineInstitute.name}
                              onChange={changeCreds}
                              type="text"
                              maxLength={400}
                              required
                              name="name"
                              autoComplete="nope"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                          <FormGroup>
                            <Label for="title">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={onlineInstitute.title}
                              onChange={changeCreds}
                              type="text"
                              maxLength={400}
                              required
                              name="title"
                              autoComplete="nope"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                          <FormGroup>
                            <Label for="email">
                              Email <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={onlineInstitute.email}
                              onChange={changeCreds}
                              type="text"
                              maxLength={400}
                              required
                              name="email"
                              autoComplete="nope"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
                          <FormGroup>
                            <Label for="description">
                              Description <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={onlineInstitute.description}
                              onChange={changeCreds}
                              type="textarea"
                              maxLength={400}
                              required
                              name="description"
                              autoComplete="nope"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <SocialLink
                        speaker={onlineInstitute}
                        changeCreds={changeCreds}
                        email={false}
                        website={false}
                      />
                    </TabPane>
                  </TabContent>

                  <Row>
                    <Col sm={12}>
                      <div className="bg-widget d-flex justify-content-end">
                        <Button
                          className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                          type="submit"
                          color="secondary-red"
                          disabled={onlineIntEditLoading}
                        >
                          {onlineIntEditLoading && (
                            <Spinner
                              className={classNames({
                                "position-relative": true,
                                visible: onlineIntEditLoading,
                                invisible: !onlineIntEditLoading,
                              })}
                              size="sm"
                            />
                          )}
                          <span className="ml-1">Submit</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default OnlineInstituteEdit;
