import axios from "axios";
import { toast } from "react-toastify";
import {
  TUTOR_ADD_FAIL,
  TUTOR_ADD_REQUEST,
  TUTOR_ADD_SUCCESS,
  TUTOR_EDIT_FAIL,
  TUTOR_EDIT_REQUEST,
  TUTOR_EDIT_SUCCESS,
  TUTOR_GET_FAIL,
  TUTOR_GET_REQUEST,
  TUTOR_GET_SUCCESS,
} from "../constants/tutorConstants";
import { getUserDetails } from "../helper/getUserDetail";

// Action to list tutors
export const listTutors = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TUTOR_GET_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/GetUserByCreator/tutor`,
      config
    );

    dispatch({ type: TUTOR_GET_SUCCESS, payload: data });
  } catch (error) {
    toast.dismiss();
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: TUTOR_GET_FAIL,
      payload: errorMessage,
    });
  }
};

// Action to add a tutor
export const addTutor = (tutor) => async (dispatch, getState) => {
  try {
    dispatch({ type: TUTOR_ADD_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterTutor`,
      tutor,
      config
    );

    dispatch(listTutors());

    toast.dismiss();
    toast.success("Tutor added successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({ type: TUTOR_ADD_SUCCESS, payload: data });
  } catch (error) {
    toast.dismiss();
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: TUTOR_ADD_FAIL,
      payload: errorMessage,
    });
  }
};

// Action to edit a tutor
export const editTutor = (tutor) => async (dispatch, getState) => {
  try {
    dispatch({ type: TUTOR_EDIT_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/UpdateTutor/${tutor.id}`,
      tutor,
      config
    );

    toast.dismiss();
    toast.success("Tutor edited successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({ type: TUTOR_EDIT_SUCCESS, payload: data });
  } catch (error) {
    toast.dismiss();
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: TUTOR_EDIT_FAIL,
      payload: errorMessage,
    });
  }
};
