import React from 'react'
import { Col, FormGroup, Label, Row, Input } from 'reactstrap'
import { booleanStatus } from '../../../../data/institute/institute'
import CkEditor from '../../../../UI/Input/CkEditor/CkEditor'
import ImageUpload from '../../../../UI/Input/ImageUpload/ImageUpload'

const CreateForm = function CreateForm({
  assessmentForm,
  changeCreds,
  assessmentDescription,
  setAssessmentDescription,
  assessmentImagePath,
  setAssessmentImagePath,
  bannerImagePath,
  setBannerImagePath,

}) {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
      <FormGroup>
        <Label for="title">
          Title <span className="text-danger">*</span>
        </Label>
        <Input
          value={assessmentForm?.title}
          onChange={changeCreds}
          type="text"
          maxLength={200}
          required
          name="title"
          autoComplete="nope"
        />
      </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="author">
            Author <span className="text-danger">*</span>
          </Label>
          <Input 
            value={assessmentForm?.author}
            onChange={changeCreds}
            type="text"
            required
            name="author"
            autoComplete="nope"
            />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="grossAmount">Gross Amount <span className="text-danger">*</span></Label>
          <Input
          value={assessmentForm?.grossAmount}
          onChange={changeCreds}
          type="number"
          required
          min={0}
          name="grossAmount"
          autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}> 
        <FormGroup>
          <Label for="saleAmount">Sale Amount <span className="text-danger">*</span></Label>
          <Input
          value={assessmentForm?.saleAmount}
          onChange={changeCreds}
          type="number"
          required
          min={0}
          name="saleAmount"
          autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="isActive">
            Active <span className="text-danger">*</span>
          </Label>
          <Input
          type="select"
          name="isActive"
          id="isActive"
          onChange={changeCreds}
          value={assessmentForm?.isActive}
          >
            {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
            </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="isFeatured">
            Featured <span className="text-danger">*</span>
          </Label>
          <Input
          type="select"
          name="isFeatured"
          id="isFeatured"
          onChange={changeCreds}
          value={assessmentForm?.isFeatured}
          >
            {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
            </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="category">
          Category <span className="text-danger">*</span>
          </Label>
          <Input 
            value={assessmentForm?.category}
            onChange={changeCreds}
            type="text"
            required
            name="category"
            autoComplete="nope"
            />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="shortDescription">Short Description <span className="text-danger">*</span></Label>
          <Input
            value={assessmentForm?.shortDescription}
            onChange={changeCreds}
            maxLength={500}
            type="textarea"
            name="shortDescription"
            autoComplete="nope"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="description">Description <span className="text-danger">*</span></Label> <Label for="description"></Label>
          <CkEditor
          value={assessmentDescription}
          setDescription={setAssessmentDescription}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={assessmentImagePath}
          setPostImage={setAssessmentImagePath}
          imgTitle="Browse a card image"
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0 " xs={12} md={6}>
        <ImageUpload
          postImage={bannerImagePath}
          setPostImage={setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  )
}

export default CreateForm