import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "-");

const DateTime = ({ label, date, setDate, name, onDateChange, ...props }) => {
  return (
    <FormGroup>
      <Label for="eventDate">
        {label} {props.required && <span className="text-danger">*</span>}
      </Label>
      <Input
        value={date}
        onChange={onDateChange}
        min={props.startDate ? props.startDate : utc}
        type="date"
        name={name}
        {...props}
        autoComplete="nope"
      />
    </FormGroup>
  );
};

export default DateTime;
