import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventById, getEventSpeakers } from "../../../actions/eventActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/Event/Edit/Edit";

const EventEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const eventSingleReducer = useSelector((state) => state.singleEvent);
  const { singleEventLoading, singleEvent } = eventSingleReducer;

  useEffect(() => {
    dispatch(getEventById(id));
    dispatch(getEventSpeakers());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Edit | Event | Eduberance" />
      {!singleEventLoading && (
        <Breadcrumbs
          url={`/admin/events/edit/${singleEvent?.title?.substring(0, 100)}`}
        />
      )}
      <Edit />
    </div>
  );
};

export default EventEditForm;
