// -- React and related libs
// -- Third Party Libs
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router";
import Contacts from "../../../pages/contacts/Contacts";
import Dashboard from "../../../pages/dashboard/Dashboard";
import Blog from "../../../pages/forms/Blog/Blog";
import BlogAdd from "../../../pages/forms/Blog/BlogAdd";
import BlogEdit from "../../../pages/forms/Blog/BlogEdit";
import Page from "../../../pages/forms/Page/Page";
import PageAdd from "../../../pages/forms/Page/PageAdd";
import PageEdit from "../../../pages/forms/Page/PageEdit";
import SearchList from "../../../pages/forms/Search/Search";
import CareerEditForm from "../../../pages/forms/careers/CareerEditForm";
import CareerForm from "../../../pages/forms/careers/CareerForm";
import Careers from "../../../pages/forms/careers/Careers";
import Company from "../../../pages/forms/company/Company";
import CompanyAdd from "../../../pages/forms/company/CompanyAdd";
import CompanyEdit from "../../../pages/forms/company/CompanyEdit";
import CourseEditForm from "../../../pages/forms/courses/CourseEditForm";
import CourseForm from "../../../pages/forms/courses/CourseForm";
import Courses from "../../../pages/forms/courses/Courses";
import Currency from "../../../pages/forms/currency/Currency";
import CurrencyEditForm from "../../../pages/forms/currency/CurrencyEditForml";
import CurrencyForm from "../../../pages/forms/currency/CurrencyForm";
import Event from "../../../pages/forms/event/Event";
import EventEditForm from "../../../pages/forms/event/EventEditForm";
import EventForm from "../../../pages/forms/event/EventForm";
import Institute from "../../../pages/forms/institute/Institute";
import InstituteEditForm from "../../../pages/forms/institute/InstituteEditForm";
import InstituteForm from "../../../pages/forms/institute/InstituteForm";
import OnlineCourse from "../../../pages/forms/onlineCourse/OnlineCourse";
import OnlineCourseAdd from "../../../pages/forms/onlineCourse/OnlineCourseAdd";
import OnlineCourseEdit from "../../../pages/forms/onlineCourse/OnlineCourseEdit";
import OnlineInstitute from "../../../pages/forms/onlineInstitute/OnlineInstitute";
import OnlineInstituteAdd from "../../../pages/forms/onlineInstitute/OnlineInstituteAdd";
import OnlineInstituteEdit from "../../../pages/forms/onlineInstitute/OnlineInstituteEdit";
import OnlineInstituteCat from "../../../pages/forms/onlineInstituteCat/OnlineInstituteCat";
import OnlineInstituteCatAdd from "../../../pages/forms/onlineInstituteCat/OnlineInstituteCatAdd";
import OnlineInstituteCatEdit from "../../../pages/forms/onlineInstituteCat/OnlineInstituteCatEdit";
import Seo from "../../../pages/forms/seo/Seo";
import SeoAdd from "../../../pages/forms/seo/SeoAdd";
import SeoEdit from "../../../pages/forms/seo/SeoEdit";
import InstructorEditForm from "../../../pages/instructor/InstructorEditForm";
import InstructorForm from "../../../pages/instructor/InstructorForm";
import Instructor from "../../../pages/instructor/instructor";
import Notifications from "../../../pages/notifications/Notifications";
import Speaker from "../../../pages/speaker/Speaker";
import SpeakerForm from "../../../pages/speaker/SpeakerForm";
import SpeakerEditForm from "../../../pages/speaker/speakerEditForm";
import Tables from "../../../pages/tables/Tables";
import Typography from "../../../pages/typography/Typography";
import Charts from "../../../pages/uielements/charts/Charts";
import Icons from "../../../pages/uielements/icons/IconsPage";
import Maps from "../../../pages/uielements/maps/google/GoogleMapPage";
import AdminList from "../../../pages/users/adminList/AdminList";
import MyProfile from "../../../pages/users/myProfile/MyProfile";
import Users from "../../../pages/users/students/Users";
import UserRegister from "../../../pages/users/userRegister/userRegister";
import { PrivateRoute } from "../../../services/PrivateRoute";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Footer from "../Footer/Footer";
// -- Custom Components
import Assessment from "../../../pages/assessment/Assessment";
import StudentForm from "../../../pages/counsellor/form/StudentForm/StudentForm";
import AssessmentEditForm from "../../../pages/forms/AssessmentForm/AssessmentEditForm";
import AssessmentForm from "../../../pages/forms/AssessmentForm/AssessmentForm";
import Assessments from "../../../pages/forms/AssessmentForm/Assessments";
import Counsellor from "../../../pages/forms/CounsellorForm/Counsellor";
import CounsellorCreate from "../../../pages/forms/CounsellorForm/CounsellorCreate";
import Coupons from "../../../pages/forms/CouponsForm/Coupons";
import CouponsForm from "../../../pages/forms/CouponsForm/CouponsForm";
import CoupounsEditForm from "../../../pages/forms/CouponsForm/CoupounsEditForm";
import OrganisationEditForm from "../../../pages/forms/OrganisationForm/OrganisationEditForm";
import OrganisationForm from "../../../pages/forms/OrganisationForm/OrganisationForm";
import Organisations from "../../../pages/forms/OrganisationForm/Organisation";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import s from "./Layout.module.scss";
import Tutor from "../../../pages/forms/TutorForm/Tutor";
import TutorForm from "../../../pages/forms/TutorForm/TutorForm";
import TutorEditForm from "../../../pages/forms/TutorForm/TutorEditForm";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          {!props.location.pathname.includes("edit") && (
            <Breadcrumbs url={props.location.pathname} />
          )}

          <Switch>
            <Route
              path="/admin"
              exact
              render={() => <Redirect to="admin/dashboard" />}
            />
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route
              path="/admin/my-profile"
              roles={["superadmin"]}
              component={MyProfile}
            />
            <PrivateRoute
              path="/admin/students"
              roles={["superadmin"]}
              component={Users}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/scholastic"
              exact
              component={Assessments}
            />
            <PrivateRoute
              path="/admin/students"
              roles={["superadmin"]}
              component={Users}
            />
            <PrivateRoute
              path="/admin/admin-users"
              roles={["superadmin"]}
              component={AdminList}
            />
            <PrivateRoute
              path="/admin/searches"
              roles={["superadmin"]}
              exact
              component={SearchList}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/courses"
              exact
              component={Courses}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/careers"
              exact
              component={Careers}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/institutes"
              exact
              component={Institute}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/events"
              exact
              component={Event}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/speakers"
              exact
              component={Speaker}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/instructor"
              exact
              component={Instructor}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/user/add"
              exact
              component={UserRegister}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/contacts"
              exact
              component={Contacts}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/currency"
              exact
              component={Currency}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/companies"
              exact
              component={Company}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/pages"
              exact
              component={Page}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/blogs"
              exact
              component={Blog}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/seo"
              exact
              component={Seo}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/Online-course-categories"
              exact
              component={OnlineInstituteCat}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/online-institutes"
              exact
              component={OnlineInstitute}
            />
            <Route
              path="/admin/online-courses"
              exact
              component={OnlineCourse}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/institutes/add"
              exact
              component={InstituteForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/scholastic/add"
              exact
              component={AssessmentForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/scholastic/edit/:id"
              exact
              component={AssessmentEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/scholastic/:id"
              exact
              component={Assessment}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/institutes/edit/:id"
              exact
              component={InstituteEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/Online-course-categories/add"
              exact
              component={OnlineInstituteCatAdd}
            />
            <Route
              path="/admin/Online-courses/add"
              exact
              component={OnlineCourseAdd}
            />
            <Route path="/admin/counsellor" exact component={Counsellor} />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/coupon"
              exact
              component={Coupons}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/Online-course-categories/edit/:id"
              exact
              component={OnlineInstituteCatEdit}
            />
            <Route
              path="/admin/Online-courses/edit/:id"
              exact
              component={OnlineCourseEdit}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/course/add"
              exact
              component={CourseForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/courses/edit/:id"
              exact
              component={CourseEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/career/add"
              exact
              component={CareerForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/careers/edit/:id"
              exact
              component={CareerEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/events/add"
              exact
              component={EventForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/events/edit/:id"
              exact
              component={EventEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/speakers/add"
              exact
              component={SpeakerForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/speakers/edit/:id"
              exact
              component={SpeakerEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/instructor/add"
              exact
              component={InstructorForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/counsellor/add"
              exact
              component={CounsellorCreate}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/instructor/edit/:id"
              exact
              component={InstructorEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/currency/add"
              exact
              component={CurrencyForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/currency/edit/:id"
              exact
              component={CurrencyEditForm}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/online-institutes/add"
              exact
              component={OnlineInstituteAdd}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/online-institutes-category/add"
              exact
              component={OnlineInstituteCatAdd}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/online-institutes/edit/:id"
              exact
              component={OnlineInstituteEdit}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/companies/add"
              exact
              component={CompanyAdd}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/companies/edit/:id"
              exact
              component={CompanyEdit}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/pages/add"
              exact
              component={PageAdd}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/pages/edit/:id"
              exact
              component={PageEdit}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/blogs/add"
              exact
              component={BlogAdd}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/blogs/edit/:id"
              exact
              component={BlogEdit}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/seo/add"
              exact
              component={SeoAdd}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/seo/edit/:id"
              exact
              component={SeoEdit}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/typography"
              exact
              component={Typography}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/tables"
              exact
              component={Tables}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/notifications"
              exact
              component={Notifications}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/ui-elements"
              exact
              render={() => <Redirect to={"/admin/ui-elements/charts"} />}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/ui-elements/charts"
              exact
              component={Charts}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/ui-elements/icons"
              exact
              component={Icons}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/ui-elements/maps"
              exact
              component={Maps}
            />
            <Route
              path="/admin/counsellor/addStudent"
              exact
              component={StudentForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/coupon/addCoupon"
              exact
              component={CouponsForm}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/coupon/editCoupon/:id"
              exact
              component={CoupounsEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/coupon/addCoupon"
              exact
              component={CoupounsEditForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/organisations"
              exact
              component={Organisations}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/organisations/addOrganisation"
              exact
              component={OrganisationForm}
            />
            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/organisations/editOrganisation/:id"
              exact
              component={OrganisationEditForm}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/tutors"
              exact
              component={Tutor}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/tutors/addTutor"
              exact
              component={TutorForm}
            />

            <PrivateRoute
              roles={["superadmin"]}
              path="/admin/tutors/editTutor/:id"
              exact
              component={TutorEditForm}
            />
            <Route
              path="*"
              exact={true}
              render={() => <Redirect to="/error" />}
            />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
