import {
  INSTITUTE_ADD_FAIL,
  INSTITUTE_ADD_REQUEST,
  INSTITUTE_ADD_SUCCESS,
  INSTITUTE_CATEGORY_LIST_FAIL,
  INSTITUTE_CATEGORY_LIST_REQUEST,
  INSTITUTE_CATEGORY_LIST_SUCCESS,
  INSTITUTE_EDIT_FAIL,
  INSTITUTE_EDIT_REQUEST,
  INSTITUTE_EDIT_SUCCESS,
  INSTITUTE_LIST_FAIL,
  INSTITUTE_LIST_REQUEST,
  INSTITUTE_LIST_SUCCESS,
  INSTITUTE_SINGLE_FAIL,
  INSTITUTE_SINGLE_REQUEST,
  INSTITUTE_SINGLE_SUCCESS,
  ONLINE_INT_CATEGORY_SINGLE_REQUEST,
  ONLINE_INT_CATEGORY_SINGLE_SUCCESS,
  ONLINE_INT_CATEGORY_SINGLE_FAIL,
} from "../constants/instituteConstants";

const institutes = {
  instituteLoading: false,
  institutes: null,
  instituteListError: false,
};

const instituteCategoryState = {
  instituteCategoryLoading: false,
  instituteCategory: null,
  instituteCategoryError: false,
};

const instituteAddState = {
  instituteAddLoading: false,
  instituteAdded: false,
  instituteAddError: false,
};

const instituteSingleState = {
  instituteSingleLoading: false,
  instituteSingle: null,
  instituteSingleError: false,
};

const instituteEditState = {
  instituteEditLoading: false,
  instituteEdit: null,
  instituteEditError: false,
};
const intActiveState = {
  intActiveLoading: false,
  intActiveStatus: null,
  intActiveError: false,
};

const singleOnlineIntCatState = {
  singleOnlineIntCatLoading: false,
  singleOnlineIntCat: null,
  singleOnlineIntCatError: false,
};

export const institutesReducers = (state = institutes, action) => {
  switch (action.type) {
    case INSTITUTE_LIST_REQUEST:
      return {
        instituteLoading: true,
        institutes: null,
        instituteListError: false,
      };
    case INSTITUTE_LIST_SUCCESS:
      return {
        instituteLoading: false,
        institutes: action.payload,
        instituteListError: false,
      };
    case INSTITUTE_LIST_FAIL:
      return {
        instituteLoading: false,
        instituteListError: action.payload,
        institutes: null,
      };

    default:
      return state;
  }
};

export const instituteCategoryReducer = (
  state = instituteCategoryState,
  action
) => {
  switch (action.type) {
    case INSTITUTE_CATEGORY_LIST_REQUEST:
      return {
        instituteCategoryLoading: true,
        instituteCategory: null,
        instituteCategoryError: false,
      };
    case INSTITUTE_CATEGORY_LIST_SUCCESS:
      return {
        instituteCategoryLoading: false,
        instituteCategory: action.payload,
        instituteCategoryError: false,
      };
    case INSTITUTE_CATEGORY_LIST_FAIL:
      return {
        instituteCategoryLoading: false,
        instituteCategoryError: action.payload,
        instituteCategory: null,
      };

    default:
      return state;
  }
};

export const instituteAddReducer = (state = instituteAddState, action) => {
  switch (action.type) {
    case INSTITUTE_ADD_REQUEST:
      return {
        instituteAddLoading: true,
        instituteAdded: false,
        instituteAddError: false,
      };
    case INSTITUTE_ADD_SUCCESS:
      return {
        instituteAddLoading: false,
        instituteAdded: action.payload,
        instituteAddError: false,
      };
    case INSTITUTE_ADD_FAIL:
      return {
        instituteAddLoading: false,
        instituteAddError: action.payload,
        instituteAdded: false,
      };

    default:
      return state;
  }
};
export const instituteSingleReducer = (
  state = instituteSingleState,
  action
) => {
  switch (action.type) {
    case INSTITUTE_SINGLE_REQUEST:
      return {
        instituteSingleLoading: true,
        instituteSingle: null,
        instituteSingleError: false,
      };
    case INSTITUTE_SINGLE_SUCCESS:
      return {
        instituteSingleLoading: false,
        instituteSingle: action.payload,
        instituteSingleError: false,
      };
    case INSTITUTE_SINGLE_FAIL:
      return {
        instituteSingleLoading: false,
        instituteSingleError: action.payload,
        instituteSingle: null,
      };

    default:
      return state;
  }
};
export const institutesEditReducer = (state = instituteEditState, action) => {
  switch (action.type) {
    case INSTITUTE_EDIT_REQUEST:
      return {
        instituteEditLoading: true,
        instituteEdit: null,
        instituteEditError: false,
      };
    case INSTITUTE_EDIT_SUCCESS:
      return {
        instituteEditLoading: false,
        instituteEdit: action.payload,
        instituteEditError: false,
      };
    case INSTITUTE_EDIT_FAIL:
      return {
        instituteEditLoading: false,
        instituteEditError: action.payload,
        instituteEdit: null,
      };

    default:
      return state;
  }
};
export const institutesActiveInactiveReducer = (
  state = intActiveState,
  action
) => {
  switch (action.type) {
    case INSTITUTE_EDIT_REQUEST:
      return {
        intActiveState: true,
        instituteEdit: null,
        intActiveError: false,
      };
    case INSTITUTE_EDIT_SUCCESS:
      return {
        intActiveState: false,
        instituteEdit: action.payload,
        intActiveError: false,
      };
    case INSTITUTE_EDIT_FAIL:
      return {
        intActiveState: false,
        intActiveError: action.payload,
        instituteEdit: null,
      };

    default:
      return state;
  }
};

export const singleOnlineIntCatReducer = (
  state = singleOnlineIntCatState,
  action
) => {
  switch (action.type) {
    case ONLINE_INT_CATEGORY_SINGLE_REQUEST:
      return {
        singleOnlineIntCatLoading: true,
        singleOnlineIntCat: null,
        singleOnlineIntCatError: false,
      };
    case ONLINE_INT_CATEGORY_SINGLE_SUCCESS:
      return {
        singleOnlineIntCatLoading: false,
        singleOnlineIntCat: action.payload,
        singleOnlineIntCatError: false,
      };
    case ONLINE_INT_CATEGORY_SINGLE_FAIL:
      return {
        singleOnlineIntCatLoading: false,
        singleOnlineIntCatError: action.payload,
        singleOnlineIntCat: null,
      };

    default:
      return state;
  }
};
