import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addInstitute } from "../../../../actions/instituteActions";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import CreateForm from "./CreateForm";

var todayDate = new Date();

function Create({ history }) {
  const [institute, setInstitute] = useState({
    instituteName: "",
    instituteTypeID: parseInt("2"),
    slug: "",
    instituteDescription: "",
    instituteShortDescription: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    website: "",
    emailId: "",
    phone1: "",
    instituteInceptionYear: "",
    status: parseInt("1"),
    isFeatured: true,
    isActive: true,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: null,
    createdDate: todayDate,
  });

  const [intDescription, setIntDescription] = useState("");
  const [activeTab, setActive] = useState("1");
  const [instituteImagePath, setInstituteImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });
  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const instituteAddReducer = useSelector((state) => state.instituteAdd);

  const dispatch = useDispatch();

  const { instituteAddLoading, instituteAdded, instituteAddError } =
    instituteAddReducer;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setInstitute({ ...institute, [event.target.name]: event.target.value });
  };

  const createInstitute = (e) => {
    const established = new Date(institute.instituteInceptionYear);

    e.preventDefault();

    const instituteInfo = {
      instituteName: institute.instituteName,
      instituteTypeID: parseInt(institute.instituteTypeID),
      slug: institute.slug.toLowerCase(),
      instituteDescription: intDescription,
      instituteShortDescription: institute.instituteShortDescription,
      address1: institute.address1,
      address2: institute.address2,
      city: institute.city,
      state: institute.state,
      country: institute.country,
      pin: institute.pin,
      website: institute.website,
      emailId: institute.emailId,
      phone1: institute.phone1,
      instituteInceptionYear: established,
      status: parseInt(institute.status),
      isFeatured: JSON.parse(institute.isFeatured),
      isActive: JSON.parse(institute.isActive),
      pageTitle: institute.pageTitle,
      metaTitle: institute.metaTitle,
      metaKeywords: institute.metaKeywords,
      instituteImagePath: instituteImagePath?.myFile,
      IsNewImage: instituteImagePath?.IsNewImage,
      ImageName: instituteImagePath?.ImageName,
      ImageType: instituteImagePath?.ImageType,
      bannerImagePath: bannerImagePath?.myFile,
      isBannerNewImage: bannerImagePath?.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      metaDescription: institute.metaDescription,
      createdBy: null,
      createdDate: todayDate,
    };

    dispatch(
      addInstitute(instituteInfo, () => {
        history.push(`/admin/institutes`);
      })
    );
    setInstituteImagePath({
      ...instituteImagePath,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form
                className="p-5"
                onSubmit={(event) => createInstitute(event)}
              >
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <CreateForm
                        institute={institute}
                        setInstitute={setInstitute}
                        instituteImagePath={instituteImagePath}
                        setInstituteImagePath={setInstituteImagePath}
                        changeCreds={changeCreds}
                        intDescription={intDescription}
                        setIntDescription={setIntDescription}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Seo seoMeta={institute} changeCreds={changeCreds} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={instituteAddLoading}
                      >
                        {instituteAddLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: instituteAddLoading,
                              invisible: !instituteAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
