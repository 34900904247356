import {
  COURSES_ACTIVE_FAIL,
  COURSES_ACTIVE_REQUEST,
  COURSES_ACTIVE_SUCCESS,
  COURSES_ADD_FAIL,
  COURSES_ADD_REQUEST,
  COURSES_ADD_SUCCESS,
  COURSES_CATEGORY_LIST_FAIL,
  COURSES_CATEGORY_LIST_REQUEST,
  COURSES_CATEGORY_LIST_SUCCESS,
  COURSES_EDIT_FAIL,
  COURSES_EDIT_REQUEST,
  COURSES_EDIT_SUCCESS,
  COURSES_LIST_FAIL,
  COURSES_LIST_REQUEST,
  COURSES_LIST_SUCCESS,
  COURSES_SINGLE_FAIL,
  COURSES_SINGLE_REQUEST,
  COURSES_SINGLE_SUCCESS,
} from "../constants/courseConstants";

const courseState = {
  coursesListLoading: false,
  courses: null,
  coursesListError: false,
};

const courseTypeState = {
  courseTypeLoading: false,
  courseType: null,
  courseTypeError: false,
};

const courseAddState = {
  courseAddLoading: false,
  courseAdded: null,
  courseAddError: false,
};

const courseEditState = {
  courseEditLoading: false,
  courseEdited: null,
  courseEditError: false,
};

const singleCourseState = {
  singleCourseLoading: false,
  singleCourse: null,
  singleCourseError: false,
};

const courseActiveState = {
  courseActiveLoading: false,
  courseActiveStatus: null,
  courseActiveError: false,
};

export const courseReducer = (state = courseState, action) => {
  switch (action.type) {
    case COURSES_LIST_REQUEST:
      return {
        coursesListLoading: true,
        courses: null,
        coursesListError: false,
      };
    case COURSES_LIST_SUCCESS:
      return {
        coursesListLoading: false,
        courses: action.payload,
        coursesListError: false,
      };
    case COURSES_LIST_FAIL:
      return {
        coursesListLoading: false,
        coursesListError: action.payload,
        courses: null,
      };

    default:
      return state;
  }
};

export const courseCategoryReducer = (state = courseTypeState, action) => {
  switch (action.type) {
    case COURSES_CATEGORY_LIST_REQUEST:
      return {
        courseTypeLoading: true,
        courseType: null,
        courseTypeError: false,
      };
    case COURSES_CATEGORY_LIST_SUCCESS:
      return {
        courseTypeLoading: false,
        courseType: action.payload,
        courseTypeError: false,
      };
    case COURSES_CATEGORY_LIST_FAIL:
      return {
        courseTypeLoading: false,
        courseTypeError: action.payload,
        courseType: null,
      };

    default:
      return state;
  }
};

export const courseAddReducer = (state = courseAddState, action) => {
  switch (action.type) {
    case COURSES_ADD_REQUEST:
      return {
        courseAddLoading: true,
        courseAdded: null,
        courseAddError: false,
      };
    case COURSES_ADD_SUCCESS:
      return {
        courseAddLoading: false,
        courseAdded: action.payload,
        courseAddError: false,
      };
    case COURSES_ADD_FAIL:
      return {
        courseAddLoading: false,
        courseAddError: action.payload,
        courseAdded: null,
      };

    default:
      return state;
  }
};

export const courseEditReducer = (state = courseEditState, action) => {
  switch (action.type) {
    case COURSES_EDIT_REQUEST:
      return {
        courseEditLoading: true,
        courseEdited: null,
        courseEditError: false,
      };
    case COURSES_EDIT_SUCCESS:
      return {
        courseEditLoading: false,
        courseEdited: action.payload,
        courseEditError: false,
      };
    case COURSES_EDIT_FAIL:
      return {
        courseEditLoading: false,
        courseEditError: action.payload,
        courseEdited: null,
      };

    default:
      return state;
  }
};

export const singleCourseReducer = (state = singleCourseState, action) => {
  switch (action.type) {
    case COURSES_SINGLE_REQUEST:
      return {
        singleCourseLoading: true,
        singleCourse: null,
        singleCourseError: false,
      };
    case COURSES_SINGLE_SUCCESS:
      return {
        singleCourseLoading: false,
        singleCourse: action.payload,
        singleCourseError: false,
      };
    case COURSES_SINGLE_FAIL:
      return {
        singleCourseLoading: false,
        singleCourseError: action.payload,
        singleCourse: null,
      };

    default:
      return state;
  }
};

export const courseActiveInactiveReducer = (
  state = courseActiveState,
  action
) => {
  switch (action.type) {
    case COURSES_ACTIVE_REQUEST:
      return {
        courseActiveLoading: true,
        courseActiveStatus: null,
        courseActiveError: false,
      };
    case COURSES_ACTIVE_SUCCESS:
      return {
        courseActiveLoading: false,
        courseActiveStatus: action.payload,
        courseActiveError: false,
      };
    case COURSES_ACTIVE_FAIL:
      return {
        courseActiveLoading: false,
        courseActiveError: action.payload,
        courseActiveStatus: null,
      };

    default:
      return state;
  }
};
