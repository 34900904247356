import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addPage } from "../../../actions/pageAction";
import Meta from "../../../components/Branch/Meta/Meta";
import Seo from "../../../components/Branch/Seo/Seo";
import Widget from "../../../components/Branch/Widget/Widget";
import { PageForm } from "./PageForm";

var todayDate = new Date();

function PageAdd({ history }) {
  const [pageInfo, setPageInfo] = useState({
    slug: "",
    pageName: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: "",
    createdDate: todayDate,
  });

  const [pageContent, setPageContent] = useState("");
  const [activeTab, setActive] = useState("1");

  const [pageImagePath, setPageImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setPageInfo({
      ...pageInfo,
      [event.target.name]: event.target.value,
    });
  };

  const pageAddedReducer = useSelector((state) => state.addPage);
  const { pageAddLoading } = pageAddedReducer;

  const onPageAdd = (e) => {
    e.preventDefault();
    const info = {
      slug: pageInfo.slug,
      pageName: pageInfo.pageName,
      featuredImage: pageImagePath.myFile,
      isNewImage: pageImagePath.IsNewImage,
      imageName: pageImagePath.ImageName,
      imageType: pageImagePath.ImageType,
      pageContent: pageContent,
      pageTitle: pageInfo.pageTitle,
      metaTitle: pageInfo.metaTitle,
      metaKeywords: pageInfo.metaKeywords,
      metaDescription: pageInfo.metaDescription,
      createdBy: "",
      createdDate: pageInfo.createdDate,
    };
    dispatch(
      addPage(info, () => {
        history.push(`/admin/pages`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Add | Page | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onPageAdd(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "2" })}
                      onClick={() => toggle("2")}
                    >
                      SEO
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <PageForm
                      pageInfo={pageInfo}
                      setPageInfo={setPageInfo}
                      pageImagePath={pageImagePath}
                      setPageImagePath={setPageImagePath}
                      changeCreds={changeCreds}
                      pageContent={pageContent}
                      setPageContent={setPageContent}
                      bannerImagePath={bannerImagePath}
                      setBannerImagePath={setBannerImagePath}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Seo seoMeta={pageInfo} changeCreds={changeCreds} />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={pageAddLoading}
                    >
                      {pageAddLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: pageAddLoading,
                            invisible: !pageAddLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PageAdd;
