import React, { useState } from "react";

import Select from "react-select";

const MultiDropdown = ({
  options = "",
  selectedOption,
  onMultiSpeakerChange,
}) => {
  return (
    <Select
      isMulti
      value={selectedOption}
      onChange={onMultiSpeakerChange}
      options={options}
      menuColor="red"
    />
  );
};

export default MultiDropdown;
