import * as Icons from "@material-ui/icons";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { FaClone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  cloneCourse,
  editOnlineCourse,
  getOnlineCourseById,
  getOnlineCourseQuiz,
  onlineCoursePublish,
  removeOnlineCourseSection,
} from "../../../../actions/onlineCourseActions";
import { getUserById } from "../../../../api/getUserById";
import { ONLINE_COURSES_ADD_SUCCESS } from "../../../../constants/onlineCourseConstants";
import { getRandomCharacters } from "../../../../helper/randomCharacter";
import CustomModal from "../../../../UI/Modal/Modal";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import PrerequisiteForm from "..//Create/PrerequisiteForm";
import TargetObjForm from "..//Create/TargetObjForm";
import LearningObjForm from "../Create/LearningObjForm";
import OnlineCourseForm from "../Create/OnlineCourseForm";
import Announcement from "./Announcement";
import QuizEditForm from "./QuizEditForm";
import SectionEditForm from "./SectionEditForm";

var todayDate = new Date();

const questionInitialState = [
  {
    title: "",
    questionID: 1,
    index: 1,
    isSelected: true,
    point: 1,
    fileInfo: "",
    fileType: "",
    fileName: "",
    isModify: false,
    isNewFile: false,
    isResource: false,
    options: [],
  },
];

const ansInitialState = [
  {
    optionName: "",
    isCorrect: false,
  },
  {
    optionName: "",
    isCorrect: false,
  },
  {
    optionName: "",
    isCorrect: false,
  },
  {
    optionName: "",
    isCorrect: false,
  },
];

const initialTopicResourceState = [
  {
    index: getRandomCharacters(),
    resourceID: 0,
    topicResourceName: "",
    isFile: true,
    fileInfo: "",
    resourceLink: "",
    fileType: "",
    fileName: "",
    isNewFile: false,
    isSelected: true,
  },
  {
    index: getRandomCharacters(),
    resourceID: 0,
    topicResourceName: "",
    isFile: true,
    fileInfo: "",
    resourceLink: "",
    fileType: "",
    fileName: "",
    isNewFile: false,
    isSelected: true,
  },
  {
    index: getRandomCharacters(),
    resourceID: 0,
    topicResourceName: "",
    isFile: true,
    fileInfo: "",
    resourceLink: "",
    fileType: "",
    fileName: "",
    isNewFile: false,
    isSelected: true,
  },
];

const sectionModalInit = {
  sectionDetails: [
    {
      sectionSl: 1,
      index: Math.random(),
      sectionName: "Section 1",
      sectionTitle: "",
      isEdited: false,
      isSelected: false,
      courseTopics: [],
    },
  ],
};

function Edit({ id }) {
  const [modalBody, setModalBody] = useState({
    modalTitle: "Delete",
    modalDescription: "Are you sure you want to delete this section?",
  });
  const [open, setOpen] = useState({
    isOpen: false,

    activeSection: "",
  });
  const [recordId, setRecordId] = useState({
    sectionRecordId: "",
  });

  const [isSectionDelete, setIsSectionDelete] = useState(false);

  const [onlineCourse, setOnlineCourse] = useState({
    id: "",
    slug: "",
    title: "",
    subTitle: "",
    progress: "",
    skillLevel: "",
    studentCount: 0,
    courseLanguage: "",
    captions: true,
    isActive: true,
    isPublished: false,
    lectureNumbers: 0,
    videoDuration: "",
    certificateGenerator: "",
    additionalFeatures: "",
    promotionalVideoLink: "",
    onlineCourseCategoryID: 1,
    primaryInstructorID: "",
    onlineCourseSubCategoryID: 1,
    isPrivate: true,
    isApproved: true,
    grossAmount: 0,
    saleAmount: 0,
    isCouponAllowed: true,
    currencyID: 1,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    onlineCourseInstituteID: 1,
    modifyBy: null,
    modifyDate: todayDate,
    isFeatured: false,
  });
  const [userInfo, setUserInfo] = useState(null);
  const [onlineCourseDescription, setOnlineCourseDescription] = useState("");
  const [studentContent, setStudentContent] = useState("");
  const [instructorID, setInstructionID] = useState("");
  const [onlineCourseInstructorList, setOnlineCourseInstructorList] = useState(
    []
  );

  const [allInstructorList, setAllInstructorList] = useState([]);
  const [editOnlineInstructionList, setEditOnlineInstructionList] = useState(
    []
  );

  const [onlineCourseError, setOnlineCourseError] = useState({
    primaryInstructorId: false,
  });

  const [onlineCourseInstructorMulList, setOnlineCourseInstructorMulList] =
    useState([]);

  const [activeTab, setActive] = useState("1");

  const [onlineCourseImgPath, setOnlineCourseImgPath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [learningObjectiveModal, setLearningObjectModal] = useState({
    learningObjectiveDetails: [
      {
        index: getRandomCharacters(),
        learnObjectiveName: "",
      },
    ],
  });

  const [prerequisiteModel, setPrerequisiteModel] = useState({
    prerequisiteDetails: [
      {
        index: getRandomCharacters(),
        prerequisiteName: "",
      },
    ],
  });

  const [targetGroupModel, setTargetGroupModel] = useState({
    targetGroupDetails: [
      {
        index: getRandomCharacters(),
        targetGroupName: "",
        grades: "",
        locations: "",
        languages: "",
      },
    ],
  });

  const [sectionModel, setSectionModel] = useState({
    sectionDetails: [
      {
        sectionSl: 1,
        index: Math.random(),
        sectionName: "Section 1",
        sectionTitle: "",
        isEdited: false,
        isSelected: false,
        courseTopics: [],
      },
    ],
  });

  const [topicResources, setTopicResources] = useState(
    initialTopicResourceState
  );

  const [activeTopic, setActiveTopic] = useState({});

  const [quizSection, setQuizSection] = useState({
    quizName: "Quiz",
    sectionId: "",
  });

  const [quizQuestion, setQuizQuestion] = useState(questionInitialState);
  const [quizAnswer, setQuizAnswer] = useState(ansInitialState);
  const [sectionTopic, setSectionTopic] = useState([]);
  const [activeSection, setActiveSection] = useState({});

  const dispatch = useDispatch();

  // reducer list
  const onlineCoursePublishReducer = useSelector(
    (state) => state.onlineCoursePublish
  );
  const { onlineCoursePublishLoading } = onlineCoursePublishReducer;

  const onlineCourseEditReducer = useSelector(
    (state) => state.onlineCourseEdit
  );
  const { onlineCourseEditLoading } = onlineCourseEditReducer;

  const onlineCourseSingleReducer = useSelector(
    (state) => state.singleOnlineCourse
  );
  const { singleOnlineCourse } = onlineCourseSingleReducer;

  const onlineInstituteInstructorReducer = useSelector(
    (state) => state.onlineCourseInstructor
  );
  const { onlineCourseInstructor } = onlineInstituteInstructorReducer;

  const onlineCourseSingleQuizReducer = useSelector(
    (state) => state.singleOnlineCourseQuiz
  );
  const { singleOnlineCourseQuiz } = onlineCourseSingleQuizReducer;

  const onlineCourseAddReducer = useSelector((state) => state.onlineCourseAdd);
  const { onlineCourseAdded } = onlineCourseAddReducer;

  useEffect(() => {
    if (singleOnlineCourseQuiz) {
      setQuizSection({
        ...quizSection,
        quizName: singleOnlineCourseQuiz?.quizName,
        onlineCourseTopicID: singleOnlineCourseQuiz?.onlineCourseTopicID,
      });

      if (singleOnlineCourseQuiz?.quizQuestions.length > 0) {
        setQuizQuestion(singleOnlineCourseQuiz?.quizQuestions);
      } else {
        setQuizQuestion(questionInitialState);
        setQuizAnswer(ansInitialState);
      }
    }
  }, [singleOnlineCourseQuiz]);

  useEffect(() => {
    getUserById()
      .then((data) => setUserInfo(data.data))
      .catch((error) => setUserInfo(null));
  }, []);

  useEffect(() => {
    if (onlineCourseInstructor && userInfo) {
      if (userInfo.roleName !== "superadmin") {
        const avlInstructors = onlineCourseInstructor.filter(
          (item) => item.value === userInfo?.uid
        );
        setOnlineCourse({
          ...onlineCourse,
          primaryInstructorID: avlInstructors[0].value,
        });
        setOnlineCourseInstructorList(avlInstructors);
      } else {
        setOnlineCourseInstructorList(onlineCourseInstructor);
      }
      setOnlineCourseInstructorMulList(onlineCourseInstructor);
      setAllInstructorList(onlineCourseInstructor);
    }
  }, [onlineCourseInstructor, userInfo]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.roleName === "superadmin") {
        const tempAllInstructorList = [...allInstructorList];
        if (singleOnlineCourse) {
          tempAllInstructorList.map((item, idx) => {
            singleOnlineCourse.instructorID.map((subItem, subIndex) => {
              if (item.value === subItem.value) {
                tempAllInstructorList.splice(idx, 1);
              }
            });
          });
          setOnlineCourseInstructorList(tempAllInstructorList);
        }
      }
    }
  }, [singleOnlineCourse, allInstructorList, userInfo]);

  useEffect(() => {
    if (onlineCourseAdded) {
      toggle("5");
      dispatch({
        type: ONLINE_COURSES_ADD_SUCCESS,
        payload: null,
      });
      setSectionModel(sectionModalInit);
    }
  }, [dispatch, onlineCourseAdded]);

  const changeCreds = (event) => {
    setOnlineCourseError({
      ...onlineCourseError,
      primaryInstructorId: false,
    });
    setOnlineCourse({
      ...onlineCourse,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "primaryInstructorID") {
      onOnlineCourseInstructorChange(event.target.value);
      if (event.target.value === null) {
        setOnlineCourseError({
          ...onlineCourseError,
          primaryInstructorId: true,
        });
      }
    }
  };

  const onOnlineCourseInstructorChange = (instructorId) => {
    const tempInstructorList = allInstructorList.filter(
      (primaryItem, i) => primaryItem.value !== Number(instructorId)
    );

    setOnlineCourseInstructorMulList(tempInstructorList);
  };

  const onMultipleInstructorChange = (event) => {
    setInstructionID(event);
    if (userInfo.roleName === "superadmin") {
      const filteredPrimaryInstructor = allInstructorList.filter(
        (val) => !event.includes(val)
      );
      setOnlineCourseInstructorList(filteredPrimaryInstructor);
    }
  };

  useEffect(() => {
    if (singleOnlineCourse !== null) {
      const tempSection = [...singleOnlineCourse?.sections];
      tempSection.map((item, i) => (item.isEdited = true));

      setOnlineCourse({
        ...onlineCourse,
        id: singleOnlineCourse?.id,
        slug: singleOnlineCourse?.slug,
        title: singleOnlineCourse?.title,
        subTitle: singleOnlineCourse?.subTitle,
        progress: singleOnlineCourse?.progress,
        skillLevel: singleOnlineCourse?.skillLevel,
        studentCount: singleOnlineCourse?.studentCount,
        primaryInstructorID: singleOnlineCourse?.primaryInstructorID,
        courseLanguage: singleOnlineCourse?.languages,
        captions: singleOnlineCourse?.captions,
        isActive: singleOnlineCourse?.isActive,
        isPublished: singleOnlineCourse?.isPublished,
        lectureNumbers: singleOnlineCourse?.lectureNumbers,
        videoDuration: singleOnlineCourse?.videoDuration,
        certificateGenerator: singleOnlineCourse?.certificateGenerator,
        additionalFeatures: singleOnlineCourse?.additionalFeatures,
        promotionalVideoLink: singleOnlineCourse?.promotionalVideoLink,
        onlineCourseCategoryID: singleOnlineCourse?.onlineCourseCategoryID,
        onlineCourseSubCategoryID:
          singleOnlineCourse?.onlineCourseSubCategoryID,
        isPrivate: singleOnlineCourse?.isPrivate,
        isApproved: singleOnlineCourse?.isApproved,
        isFeatured: JSON.parse(singleOnlineCourse?.isFeatured),
        grossAmount: singleOnlineCourse?.grossAmount,
        saleAmount: singleOnlineCourse?.saleAmount,
        isCouponAllowed: singleOnlineCourse?.isCouponAllowed,
        currencyID: singleOnlineCourse?.currencyID,
        pageTitle: singleOnlineCourse?.pageTitle,
        metaTitle: singleOnlineCourse?.metaTitle,
        metaKeywords: singleOnlineCourse?.metaKeywords,
        metaDescription: singleOnlineCourse?.metaDescription,
        onlineCourseInstituteID: singleOnlineCourse?.onlineCourseInstituteID,
      });

      setStudentContent(singleOnlineCourse?.studentContent);
      setInstructionID(singleOnlineCourse.instructorID);
      onOnlineCourseInstructorChange(singleOnlineCourse?.primaryInstructorID);
      setEditOnlineInstructionList(singleOnlineCourse.instructorID);
      setOnlineCourseImgPath({
        ...onlineCourseImgPath,
        myFile: singleOnlineCourse?.courseImage,
      });

      if (singleOnlineCourse?.learningObjectives?.length > 0) {
        setLearningObjectModal({
          learningObjectiveDetails: singleOnlineCourse.learningObjectives,
        });
      }
      if (singleOnlineCourse?.prerequisites?.length > 0) {
        setPrerequisiteModel({
          prerequisiteDetails: singleOnlineCourse.prerequisites,
        });
      }
      if (singleOnlineCourse?.targetGroups?.length > 0) {
        setTargetGroupModel({
          targetGroupDetails: singleOnlineCourse.targetGroups,
        });
      }
      if (singleOnlineCourse?.sections?.length > 0) {
        setSectionModel({ sectionDetails: tempSection });
      }
      setOnlineCourseDescription(singleOnlineCourse?.description);

      if (singleOnlineCourse?.onlineCourseImgPath) {
        setOnlineCourseImgPath({
          ...onlineCourseImgPath,
          myFile: singleOnlineCourse?.onlineCourseImgPath,
        });
      }
    }

    setBannerImagePath({
      ...bannerImagePath,
      myFile: singleOnlineCourse?.bannerImagePath,
    });
  }, [singleOnlineCourse, onlineCourseInstructor]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  // sectionDetails

  const addNewSectionObj = (index, sectionSl) => {
    setSectionTopic([]);
    sectionModel.sectionDetails.map((item, index) => {
      item.courseTopics.map(
        (topicItem, topicIndex) => (topicItem.isSelected = false)
      );
    });
    setTopicResources([
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        resourceLink: "",
        fileType: "",
        fileName: "",
        isNewImage: false,
        isSelected: true,
      },
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        fileType: "",
        fileName: "",
        isNewImage: false,
        resourceLink: "",
        isSelected: true,
      },
      {
        index: getRandomCharacters(),
        resourceID: 0,
        topicResourceName: "",
        isFile: true,
        fileInfo: "",
        fileType: "",
        fileName: "",
        isNewImage: false,
        resourceLink: "",
        isSelected: true,
      },
    ]);
    const sectionModalTemp = [...sectionModel.sectionDetails];

    sectionModalTemp.map((item, ikd) => (item.isSelected = false));

    setSectionModel((prevState) => ({
      sectionDetails: [
        ...prevState.sectionDetails,
        {
          sectionSl: 0,
          index: Math.random(),
          sectionName: `Section ${index + 1}`,
          isEdited: false,
          isSelected: true,
          sectionTitle: "",
          courseTopics: [],
        },
      ],
    }));

    setActiveSection({
      sectionSl: sectionModel.sectionDetails.length + 1,
      sectionName: `Section ${index + 1}`,
      isEdited: false,
      isSelected: true,
      sectionTitle: "",
      courseTopics: [],
    });
  };
  const addSectionResources = () => {
    setTopicResources((prevState) => [
      ...prevState,
      {
        index: getRandomCharacters(),
        topicResourceName: "",
        resourceID: 0,
        isFile: true,
        fileInfo: "",
        resourceLink: "",
        fileType: "",
        fileName: "",
        isNewFile: false,
        isSelected: true,
      },
    ]);
  };

  //add new quiz question

  const addNewQuestion = () => {
    quizQuestion.map((item, i) => (item.isSelected = false));

    setQuizAnswer([
      {
        optionName: "",
        isCorrect: false,
      },
      {
        optionName: "",
        isCorrect: false,
      },
      {
        optionName: "",
        isCorrect: false,
      },
      {
        optionName: "",
        isCorrect: false,
      },
    ]);
    setQuizQuestion((prevState) => [
      ...prevState,
      {
        title: "",
        questionID: 0,
        index: quizQuestion.length + 1,
        isSelected: true,
        point: 1,
        isModify: false,
        fileInfo: "",
        fileType: "",
        fileName: "",
        isNewFile: false,
        isResource: false,
        options: [],
      },
    ]);
  };

  // learningObjectiveDetails;

  const addNewLearningObj = (e) => {
    setLearningObjectModal((prevState) => ({
      learningObjectiveDetails: [
        ...prevState.learningObjectiveDetails,
        {
          index: getRandomCharacters(),
          learnObjectiveName: "",
        },
      ],
    }));
  };

  const deleteLearningObj = (record) => {
    setLearningObjectModal({
      learningObjectiveDetails:
        learningObjectiveModal.learningObjectiveDetails.filter(
          (r) => r !== record
        ),
    });
  };

  // prerequisiteDetails
  const addNewPrerequisiteObj = (e) => {
    setPrerequisiteModel((prevState) => ({
      prerequisiteDetails: [
        ...prevState.prerequisiteDetails,
        {
          index: getRandomCharacters(),
          prerequisiteName: "",
        },
      ],
    }));
  };

  const deletePrerequisiteObj = (record) => {
    setPrerequisiteModel({
      prerequisiteDetails: prerequisiteModel.prerequisiteDetails.filter(
        (r) => r !== record
      ),
    });
  };

  // targetGroupDetails

  const addNewTargetObj = (e) => {
    setTargetGroupModel((prevState) => ({
      targetGroupDetails: [
        ...prevState.targetGroupDetails,
        {
          index: getRandomCharacters(),
          targetGroupName: "",
          grades: "",
          locations: "",
          languages: "",
        },
      ],
    }));
  };

  const deleteTargetObj = (record) => {
    setTargetGroupModel({
      targetGroupDetails: targetGroupModel.targetGroupDetails.filter(
        (r) => r !== record
      ),
    });
  };

  ////

  const handleChange = (event) => {
    if (["learnObjectiveName"].includes(event.target.name)) {
      let learningObj = [...learningObjectiveModal.learningObjectiveDetails];

      learningObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }

    if (["prerequisiteName"].includes(event.target.name)) {
      let prerequisiteObj = [...prerequisiteModel.prerequisiteDetails];
      prerequisiteObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (
      ["targetGroupName", "grades", "locations", "languages"].includes(
        event.target.name
      )
    ) {
      let targetGroupObj = [...targetGroupModel.targetGroupDetails];
      targetGroupObj[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (["sectionTitle"].includes(event.target.name)) {
      let sectionObj = [...sectionModel.sectionDetails];
      sectionObj[event.target.dataset.id][event.target.name] =
        event.target.value;

      setActiveSection({
        ...activeSection,
        sectionTitle: event.target.value,
      });
    }
  };

  const onQuizRedirection = (selectedTopic) => {
    setActiveTopic(selectedTopic);
    setQuizQuestion(questionInitialState);
    setQuizAnswer(ansInitialState);
    dispatch(
      getOnlineCourseQuiz(selectedTopic.topicID, () => {
        toggle("7");
        window.scrollTo(0, 0);
      })
    );
  };

  const createOnlineCourse = (e) => {
    e.preventDefault();

    const onlineCourseAddInfo = {
      id: onlineCourse.id,
      title: onlineCourse.title,
      subTitle: onlineCourse.subTitle,
      description: onlineCourseDescription,
      studentContent: studentContent,
      progress: onlineCourse.progress,
      skillLevel: onlineCourse.skillLevel,
      primaryInstructorID: Number(onlineCourse.primaryInstructorID),
      instructorID: instructorID,
      languages: onlineCourse.courseLanguage,
      captions: JSON.parse(onlineCourse.captions),
      lectureNumbers: JSON.parse(onlineCourse.lectureNumbers),
      videoDuration: onlineCourse.videoDuration,
      certificateGenerator: onlineCourse.certificateGenerator,
      additionalFeatures: onlineCourse.additionalFeatures,
      courseImage: onlineCourseImgPath.myFile,
      isNewImage: onlineCourseImgPath.IsNewImage,
      imageName: onlineCourseImgPath.ImageName,
      imageType: onlineCourseImgPath.ImageType,
      promotionalVideoLink: onlineCourse.promotionalVideoLink,
      onlineCourseCategoryID: JSON.parse(onlineCourse.onlineCourseCategoryID),
      onlineCourseSubCategoryID: JSON.parse(
        onlineCourse.onlineCourseSubCategoryID
      ),
      isPrivate: JSON.parse(onlineCourse.isPrivate),
      isApproved: JSON.parse(onlineCourse.isApproved),
      isActive: JSON.parse(onlineCourse.isActive),
      grossAmount: JSON.parse(onlineCourse.grossAmount),
      isFeatured: JSON.parse(onlineCourse.isFeatured),
      saleAmount: JSON.parse(onlineCourse.saleAmount),
      isCouponAllowed: JSON.parse(onlineCourse.isCouponAllowed),
      currencyID: JSON.parse(onlineCourse.currencyID),
      slug: onlineCourse.slug,
      pageTitle: onlineCourse.pageTitle,
      metaTitle: onlineCourse.metaTitle,
      metaKeywords: onlineCourse.metaKeywords,
      metaDescription: onlineCourse.metaDescription,
      onlineCourseInstituteID: JSON.parse(onlineCourse.onlineCourseInstituteID),
      learningObjectives: learningObjectiveModal.learningObjectiveDetails,
      prerequisites: prerequisiteModel.prerequisiteDetails,
      targetGroups: targetGroupModel.targetGroupDetails,
      modifyBy: onlineCourse.modifyBy,
      modifyDate: onlineCourse.modifyDate,
    };
    if (onlineCourse.primaryInstructorID) {
      dispatch(editOnlineCourse(onlineCourseAddInfo));
    }
  };

  const deleteSectionObj = (record) => {
    setModalBody({
      modalTitle: "Delete",
      modalDescription: "Are you sure you want to delete this section?",
    });
    setOpen({
      ...open,
      isOpen: !open.isOpen,
      activeSection: "SE",
    });
    setRecordId({
      ...recordId,
      sectionRecordId: record,
    });
  };

  const onModalToggle = () => {
    setOpen({
      ...open,
      isOpen: !open.isOpen,
    });
  };
  const onModalToggleAction = () => {
    setOpen({
      ...open,
      isOpen: false,
    });

    if (open.activeSection === "SE") {
      setIsSectionDelete(true);

      dispatch(
        removeOnlineCourseSection(recordId?.sectionRecordId.sectionSl, () => {
          dispatch(getOnlineCourseById(singleOnlineCourse.id));
        })
      );
    }
    if (open.activeSection === "OC") {
      const onlineCoursePubInfo = {
        onlineCourseID: onlineCourse.id,
      };

      dispatch(onlineCoursePublish(onlineCoursePubInfo));
    }
    if (open.activeSection === "CO") {
      const onlineCoursePubInfo = {
        onlineCourseID: onlineCourse.id,
      };

      dispatch(cloneCourse(onlineCoursePubInfo));
    }
  };

  const onPublishedCourse = () => {
    setModalBody({
      modalTitle: "Publish Course",
      modalDescription:
        "Are you sure you want to publish the course? you won't be able to delete any data further.",
    });

    setOpen({
      ...open,
      isOpen: !open.isOpen,
      activeSection: "OC",
    });
  };

  const onCloneCourse = () => {
    setOpen({
      ...open,
      isOpen: !open.isOpen,
      activeSection: "CO",
    });
    setModalBody({
      modalTitle: "Clone Course",
      modalDescription: "Are you sure you want to clone the course?",
    });
  };

  return (
    <Row>
      <CustomModal
        modal={open.isOpen}
        modalTitle={modalBody.modalTitle}
        isDoSomething={true}
        onModalAction={onModalToggleAction}
        isDisabled={false}
        toggle={onModalToggle}
      >
        {modalBody.modalDescription}
      </CustomModal>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              {userInfo?.roleName === "superadmin" && (
                <Col>
                  {!onlineCourse.isPublished && (
                    <Col
                      className="mb-1 mb-sm-3 mb-md-0 d-flex justify-content-end"
                      md={12}
                    >
                      <Button
                        className="my-4 d-flex align-items-center"
                        color="info"
                        onClick={onCloneCourse}
                        disabled={onlineCoursePublishLoading}
                      >
                        <FaClone />
                        <span className="ml-2">Clone</span>
                      </Button>
                      <Button
                        className="my-4 mx-4 d-flex align-items-center"
                        color="primary"
                        onClick={() => onPublishedCourse()}
                        disabled={onlineCoursePublishLoading}
                      >
                        {onlineCoursePublishLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <Icons.Backup />
                        )}

                        <span className="ml-2">Publish</span>
                      </Button>
                    </Col>
                  )}
                </Col>
              )}

              <Form
                className="p-5"
                onSubmit={(event) => createOnlineCourse(event)}
                onChange={(e) => handleChange(e)}
              >
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Learning Objectives
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Prerequisites
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        Target Groups
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          toggle("6");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          toggle("5");
                        }}
                      >
                        Sections
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "7" })}
                      >
                        Quiz
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "8" })}
                        onClick={() => {
                          toggle("8");
                        }}
                      >
                        Announcement
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <OnlineCourseForm
                        onlineCourse={onlineCourse}
                        changeCreds={changeCreds}
                        onlineCourseDescription={onlineCourseDescription}
                        setOnlineCourseDescription={setOnlineCourseDescription}
                        onlineCourseImgPath={onlineCourseImgPath}
                        setOnlineCourseImgPath={setOnlineCourseImgPath}
                        instructorID={instructorID}
                        studentContent={studentContent}
                        setStudentContent={setStudentContent}
                        setInstructionID={setInstructionID}
                        onlineCourseInstructorList={onlineCourseInstructorList}
                        onlineCourseInstructorMulList={
                          onlineCourseInstructorMulList
                        }
                        onMultipleInstructorChange={onMultipleInstructorChange}
                        onlineCourseError={onlineCourseError}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <LearningObjForm
                        learningObj={learningObjectiveModal}
                        add={addNewLearningObj}
                        delete={deleteLearningObj}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <PrerequisiteForm
                        prerequisiteObj={prerequisiteModel}
                        add={addNewPrerequisiteObj}
                        delete={deletePrerequisiteObj}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <TargetObjForm
                        targetObj={targetGroupModel}
                        add={addNewTargetObj}
                        delete={deleteTargetObj}
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <SectionEditForm
                        sectionObj={sectionModel}
                        setSectionModel={setSectionModel}
                        add={addNewSectionObj}
                        delete={deleteSectionObj}
                        sectionTopic={sectionTopic}
                        setSectionTopic={setSectionTopic}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        topicResources={topicResources}
                        setTopicResources={setTopicResources}
                        addSectionResources={addSectionResources}
                        onQuizToggle={(id) => onQuizRedirection(id)}
                        isPublished={onlineCourse?.isPublished}
                      />
                    </TabPane>
                    <TabPane tabId="6">
                      <Seo seoMeta={onlineCourse} changeCreds={changeCreds} />
                    </TabPane>
                    <TabPane tabId="7">
                      <QuizEditForm
                        activeTopic={activeTopic}
                        quizSection={quizSection}
                        setQuizSection={setQuizSection}
                        quizQuestion={quizQuestion}
                        setQuizQuestion={setQuizQuestion}
                        quizAnswer={quizAnswer}
                        setQuizAnswer={setQuizAnswer}
                        add={addNewQuestion}
                        isPublished={onlineCourse?.isPublished}
                      />
                    </TabPane>
                    <TabPane tabId="8">
                      <Announcement />
                    </TabPane>
                  </TabContent>
                </div>
                {activeTab !== "5" && activeTab !== "7" && activeTab !== "8" && (
                  <Row>
                    <Col sm={12}>
                      <div className="bg-widget d-flex justify-content-end">
                        <Button
                          className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                          type="submit"
                          color="secondary-red"
                          disabled={onlineCourseEditLoading}
                        >
                          {onlineCourseEditLoading && (
                            <Spinner
                              className={classnames({
                                "position-relative": true,
                                visible: onlineCourseEditLoading,
                                invisible: !onlineCourseEditLoading,
                              })}
                              size="sm"
                            />
                          )}
                          <span className="ml-1">Submit</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
