import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row, Spinner } from "reactstrap";
import { addStudent } from "../../../actions/userActions";
import CreateForm from "../../../components/Main/User/CreateForm/CreateForm";

var todayDate = new Date();

function UserForm() {
  const [student, setStudents] = useState({
    Email: "",
    City: "",
    Password: "",
    StudentName: "",
    DateOfBirth: "",
    createdDate: todayDate,
  });

  const studentAddReducer = useSelector((state) => state.addStudentByAdmin);
  const { studentAddLoading } = studentAddReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setStudents({ ...student, [event.target.name]: event.target.value });
  };

  const onRegisterStudent = (e) => {
    e.preventDefault();
    const jsonDateOfBirth = new Date(student.DateOfBirth);
    const studentInfo = {
      Email: student.Email,
      City: student.City,
      Password: student.Password,
      StudentName: student.StudentName,
      DateOfBirth: jsonDateOfBirth,
    };

    dispatch(addStudent(studentInfo));
  };

  return (
    <Row>
      <Form onSubmit={(e) => onRegisterStudent(e)}>
        <Col className="pr-grid-col" xs={12}>
          <Row className="gutter mb-2 mb-sm-4">
            <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
              <CreateForm student={student} changeCreds={changeCreds} />
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <div className="bg-widget d-flex justify-content-end">
            <Button
              className="rounded-pill d-flex justify-content-center align-items-center w-25"
              type="submit"
              color="secondary-red"
              disabled={studentAddLoading}
            >
              {studentAddLoading && (
                <Spinner
                  className={classNames({
                    "position-relative": true,
                    visible: studentAddLoading,
                    invisible: !studentAddLoading,
                  })}
                  size="sm"
                />
              )}
              <span className="ml-1">Submit</span>
            </Button>
          </div>
        </Col>
      </Form>
    </Row>
  );
}

export default UserForm;
