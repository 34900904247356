import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  careerCategoryList,
  getAllDataPoints,
} from "../../../actions/careerActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Create from "../../../components/Main/Career/Create/Create";

const CareerForm = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(careerCategoryList());
    dispatch(getAllDataPoints());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Add | Career | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default CareerForm;
