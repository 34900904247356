import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import SearchTable from "../../tables/components/SearchTable/SearchTable";

const SearchList = () => {
  return (
    <div>
      <Meta title="Online Institutes | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <SearchTable tableName="Search List" />
        </Col>
      </Row>
    </div>
  );
};

export default SearchList;
