import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import Widget from "../../../../components/Branch/Widget/Widget";
import SearchInput from "../../../../helper/SearchInput";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import {
  AssessmentList,
  AssesmentDelete,
} from "../../../../actions/assessments";
import { deleteAssessmentById } from "../../../../api/getAssessmentById";
import s from "../../Tables.module.scss";
const AssessmentTable = function ({ tableName }) {
  const [assessmentsTableList, setAssessmentsTableList] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [modalBody] = useState("Are you sure you want to delete this form?");
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");

  const assessmentReducer = useSelector((state) => state.assessments);
  const { assessmentsListLoading, assessments, assessmentsListError } =
    assessmentReducer;

  useEffect(() => {
    dispatch(AssessmentList());
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (assessments !== null) {
      setAssessmentsTableList(assessments);
    }
  }, [assessmentsListLoading, assessments]);

  useEffect(() => {
    if (assessmentsTableList !== null) {
      const firstTablePagesCounts = Math.ceil(
        assessmentsTableList.length / pageSize
      );
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [assessmentsTableList]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const handleClick = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this form?"
    );
    if (isConfirmed) {
      deleteAssessmentById(id);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  if(assessmentsListLoading){
    return <Spinner />
  }

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchText}
                      handleSearch={handleSearch}
                      placeholder="Search Scholastic"
                    />

                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={cloudIcon}
                        alt="Cloud"
                      />
                    </a>
                    <a href="/#">
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={optionsIcon}
                        alt="Options"
                      />
                    </a>
                    <a href="/#">
                      <img src={funnelIcon} alt="Funnel" />
                    </a>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    {assessmentsListLoading ? (
                      <Skeleton count={pageSize} />
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th className={s.checkboxCol}>
                              <div className="checkbox checkbox-primary">
                                <input
                                  className="styled"
                                  id="checkbox100"
                                  type="checkbox"
                                />
                                <label htmlFor="checkbox100" />
                              </div>
                            </th>
                            {/* <th className="w-25">Role</th> */}
                            <th className="w-33">Form Name</th>
                            <th className="w-33">Short Description</th>
                            {/* <th className="w-25">Stream</th> */}
                            <th className="w-33">View</th>
                            <th className="w-33">Edit</th>
                            {/* <th className="w-25">STATE</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {assessmentsTableList !== null &&
                            assessmentsTableList
                              .filter((item) =>
                                item.title
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              )
                              .slice(
                                firstTableCurrentPage * pageSize,
                                (firstTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="checkbox checkbox-primary">
                                      <input
                                        id={item.formId}
                                        className="styled"
                                        type="checkbox"
                                      />
                                      <Label for={item.id} />
                                    </div>
                                  </td>
                                  <td>{item.title}</td>
                                  <td>{item.shortDescription}</td>

                                  <td className="d-flex justify-content-between">
                                    <a
                                      href={`https://www.eduberance.com/scholastic/test-${item.formId}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="table-action"
                                    >
                                      <i className="eva eva-eye"></i>
                                    </a>

                                    {/* <button
                                      className="btn btn-danger"
                                      onClick={() => handleClick(item.formId)}
                                    >
                                      Delete
                                    </button> */}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin/scholastic/edit/${item.formId}`}
                                      className="table-action"
                                    >
                                      <i className="eva eva-edit mr-1"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                  {assessmentsTableList !== null && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={firstTableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(firstTablePagesCount)].map((page, i) => (
                        <PaginationItem
                          active={i === firstTableCurrentPage}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => setFirstTablePage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={
                          firstTableCurrentPage >= firstTablePagesCount - 1
                        }
                      >
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AssessmentTable;
