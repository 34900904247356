import {
  CONTACTS_LIST_REQUEST,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_LIST_FAIL,
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_FAIL,
} from "../constants/contactConstants";

const contactsState = {
  contactLoading: false,
  allContacts: null,
  contactError: false,
};

export const contactsReducer = (state = contactsState, action) => {
  switch (action.type) {
    case CONTACTS_LIST_REQUEST:
      return { contactLoading: true, allContacts: null, contactError: false };
    case CONTACTS_LIST_SUCCESS:
      return {
        contactLoading: false,
        allContacts: action.payload,
        contactError: false,
      };
    case CONTACTS_LIST_FAIL:
      return {
        contactLoading: false,
        contactError: action.payload,
        allContacts: null,
      };

    default:
      return state;
  }
};

const searchResultsState = {
  searchListLoading: false,
  searchList: null,
  searchListError: false,
};

export const searchListReducer = (state = searchResultsState, action) => {
  switch (action.type) {
    case SEARCH_LIST_REQUEST:
      return {
        searchListLoading: true,
        searchList: null,
        searchListError: false,
      };
    case SEARCH_LIST_SUCCESS:
      return {
        searchListLoading: false,
        searchList: action.payload,
        searchListError: false,
      };
    case SEARCH_LIST_FAIL:
      return {
        searchListLoading: false,
        searchListError: action.payload,
        searchList: null,
      };

    default:
      return state;
  }
};
