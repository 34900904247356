
import Create from "../../../components/Main/Assessment/Create/Create";

const AssesmentForm = ({history}) => {
  return(

    <Create history={history}/>
  )
}
export default AssesmentForm
