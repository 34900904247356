import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editCourse } from "../../../../actions/courseActions";
// import { editInstitute } from "../../../actions/instituteActions";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import EditForm from "./EditForm";

var todayDate = new Date();

function Edit() {
  const [course, setCourse] = useState({
    courseName: "",
    courseTypeID: parseInt("2"),
    slug: "",
    courseTimeType: "",
    courseDuration: "",
    courseFee: "",
    courseShortDescription: "",
    courseCompletionAward: "",
    courseMode: "",
    courseFeeHigher: "",
    courseFeeLower: "",
    unit: "",
    status: "",
    isFeatured: false,
    isActive: false,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    ModifyBy: null,
    ModifiedDate: todayDate,
  });

  const [courseDescription, setCourseDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const [courseImagePath, setCourseImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const courseSingleReducer = useSelector((state) => state.courseSingle);
  const courseEditReducer = useSelector((state) => state.courseEdit);

  const { singleCourse } = courseSingleReducer;
  const { courseEditLoading } = courseEditReducer;

  const changeCreds = (event) => {
    setCourse({ ...course, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (singleCourse !== null) {
      setCourse({
        ...course,
        CourseID: singleCourse?.courseID,
        courseName: singleCourse?.courseName,
        courseTypeID: singleCourse?.courseTypeID,
        slug: singleCourse?.slug.toLowerCase(),
        courseTimeType: singleCourse?.courseTimeType,
        courseDuration: singleCourse?.courseDuration,
        courseFee: singleCourse?.courseFee,
        courseShortDescription: singleCourse?.courseShortDescription,
        courseCompletionAward: singleCourse?.courseCompletionAward,
        courseMode: singleCourse?.courseMode,
        courseFeeHigher: singleCourse?.courseFeeHigher,
        courseFeeLower: singleCourse?.courseFeeLower,
        unit: singleCourse?.unit,
        status: singleCourse?.status,
        isFeatured: singleCourse?.isFeatured,
        isActive: singleCourse?.isActive,
        pageTitle: singleCourse?.pageTitle,
        metaTitle: singleCourse?.metaTitle,
        metaKeywords: singleCourse?.metaKeywords,
        metaDescription: singleCourse?.metaDescription,
        ModifyBy: null,
        ModifiedDate: todayDate,
      });

      if (singleCourse?.courseImagePath) {
        setCourseImagePath({
          ...courseImagePath,
          myFile: singleCourse?.courseImagePath,
        });
      }

      setBannerImagePath({
        ...bannerImagePath,
        myFile: singleCourse?.bannerImagePath,
      });
    }
  }, [singleCourse]);

  const onEditCourse = (e) => {
    e.preventDefault();
    const editCourseInfo = {
      courseName: course.courseName,
      CourseID: course.CourseID,
      CourseTypeID: course.courseTypeID,
      slug: course.slug,
      courseTimeType: course.courseTimeType,
      courseDuration:
        parseInt(course.courseDuration) === 0
          ? 1
          : parseInt(course.courseDuration),
      courseFee:
        parseInt(course.courseFee) === 0 ? 1 : parseInt(course.courseFee),
      courseShortDescription: course.courseShortDescription,
      courseDescription: courseDescription,
      courseCompletionAward: course.courseCompletionAward,
      courseMode: course.courseMode,
      courseFeeHigher:
        parseInt(course.courseFeeHigher) === 0
          ? 1
          : parseInt(course.courseFeeHigher),
      courseFeeLower:
        parseInt(course.courseFeeLower) === 0
          ? 1
          : parseInt(course.courseFeeLower),
      unit: parseInt(course.unit) === 0 ? 1 : parseInt(course.unit),
      status: parseInt(course.status) === 0 ? 1 : parseInt(course.status),
      isFeatured: JSON.parse(course.isFeatured),
      isActive: JSON.parse(course.isActive),
      pageTitle: course.pageTitle,
      metaTitle: course.metaTitle,
      metaKeywords: course.metaKeywords,
      metaDescription: course.metaDescription,
      courseImagePath: courseImagePath?.myFile,
      IsNewImage: courseImagePath?.IsNewImage,
      ImageName: courseImagePath?.ImageName,
      ImageType: courseImagePath?.ImageType,
      bannerImagePath: bannerImagePath?.myFile,
      isBannerNewImage: bannerImagePath?.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      ModifyBy: null,
      ModifiedDate: todayDate,
    };

    dispatch(editCourse(editCourseInfo));

    setCourseImagePath({
      ...courseImagePath,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form className="p-5" onSubmit={(event) => onEditCourse(event)}>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <EditForm
                        course={course}
                        setCourse={setCourse}
                        courseImagePath={courseImagePath}
                        setCourseImagePath={setCourseImagePath}
                        changeCreds={changeCreds}
                        courseDescription={courseDescription}
                        setCourseDescription={setCourseDescription}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Seo seoMeta={course} changeCreds={changeCreds} />
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={courseEditLoading}
                      >
                        {courseEditLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: courseEditLoading,
                              invisible: !courseEditLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
