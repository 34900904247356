import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { booleanStatus } from "../../../../data/institute/institute";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";
import MultiDropdown from "../../../../UI/MultiDropdown/MultiDropdown";

function OnlineCourseForm({
  onlineCourse,
  onlineCourseImgPath,
  setOnlineCourseImgPath,
  changeCreds,
  onlineCourseDescription,
  setOnlineCourseDescription,
  instructorID,
  setInstructionID,
  onlineCourseInstructorList,
  onlineCourseInstructorMulList,
  onMultipleInstructorChange,
  ...props
}) {
  const userObj = JSON.parse(localStorage.getItem("userInfo"));

  const allOnlineCourseInstituteReducer = useSelector(
    (state) => state.onlineCourseIntList
  );
  const { allOnlineCourseIntList } = allOnlineCourseInstituteReducer;

  const onlineIntCatReducer = useSelector((state) => state.onlineIntCat);
  const { onlineIntCat } = onlineIntCatReducer;

  const currencyListReducer = useSelector((state) => state.currency);
  const { allCurrency } = currencyListReducer;

  const onlineIntSubCatReducer = useSelector(
    (state) => state.onlineCourseSubCat
  );
  const { onlineCourseSubCategory } = onlineIntSubCatReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="title">
            Title <span className="text-danger">*</span>
          </Label>
          <Input
            value={onlineCourse.title}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="title"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="subTitle">
            Sub Title <span className="text-danger">*</span>
          </Label>
          <Input
            value={onlineCourse.subTitle}
            onChange={changeCreds}
            type="text"
            required
            name="subTitle"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="skillLevel">Skill Level</Label>
          <Input
            value={onlineCourse.skillLevel}
            onChange={changeCreds}
            type="text"
            name="skillLevel"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="courseLanguage">Languages</Label>
          <Input
            value={onlineCourse.courseLanguage}
            onChange={changeCreds}
            type="text"
            name="courseLanguage"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="promotionalVideoLink">Promotional Video Link</Label>
          <Input
            value={onlineCourse.promotionalVideoLink}
            onChange={changeCreds}
            type="link"
            name="promotionalVideoLink"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="primaryInstructorID">Primary Instructor</Label>{" "}
          <span className="text-danger">*</span>
          <Input
            type="select"
            name="primaryInstructorID"
            id="primaryInstructorID"
            required
            value={onlineCourse.primaryInstructorID}
            onChange={changeCreds}
          >
            <option selected>Select</option>
            {onlineCourseInstructorList?.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
          </Input>
          {props.onlineCourseError.primaryInstructorId && (
            <div className="text-danger mt-1">Instructor is required</div>
          )}
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="speakerID">Other Instructor</Label>

          <MultiDropdown
            options={onlineCourseInstructorMulList}
            selectedOption={instructorID}
            onMultiSpeakerChange={(e) => onMultipleInstructorChange(e, false)}
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="grossAmount">
            Gross Amount <span className="text-danger">*</span>
          </Label>
          <Input
            value={onlineCourse.grossAmount}
            onChange={changeCreds}
            type="number"
            min={0}
            required
            name="grossAmount"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="saleAmount">
            Sale Amount <span className="text-danger">*</span>
          </Label>
          <Input
            value={onlineCourse.saleAmount}
            onChange={changeCreds}
            type="number"
            min={0}
            required
            name="saleAmount"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      {userObj.role === "superadmin" && (
        <>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="captions">
                Captions <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="captions"
                id="captions"
                required
                value={onlineCourse.captions}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="isPrivate">
                Private <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="isPrivate"
                id="isPrivate"
                required
                value={onlineCourse.isPrivate}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="isApproved">
                Approved <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="isApproved"
                id="isApproved"
                required
                value={onlineCourse.isApproved}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="isActive">
                Active <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="isActive"
                id="isActive"
                required
                value={onlineCourse.isActive}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="isCouponAllowed">
                Coupon Allowed <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="isCouponAllowed"
                id="isCouponAllowed"
                required
                value={onlineCourse.isCouponAllowed}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="onlineCourseCategoryID">
                Category <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="onlineCourseCategoryID"
                id="onlineCourseCategoryID"
                value={onlineCourse.onlineCourseCategoryID}
                onChange={changeCreds}
              >
                {onlineIntCat?.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item?.courseCategory}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="onlineCourseSubCategoryID">
                Sub Category <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="onlineCourseSubCategoryID"
                id="onlineCourseSubCategoryID"
                value={onlineCourse.onlineCourseSubCategoryID}
                onChange={changeCreds}
              >
                {onlineCourseSubCategory?.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item?.courseCategory}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="onlineCourseInstituteID">
                Institute <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="onlineCourseInstituteID"
                id="onlineCourseInstituteID"
                value={onlineCourse.onlineCourseInstituteID}
                onChange={changeCreds}
              >
                {allOnlineCourseIntList?.map(
                  (item, index) =>
                    item.isActive && (
                      <option value={item.id} key={index}>
                        {item?.name}
                      </option>
                    )
                )}
              </Input>
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
            <FormGroup>
              <Label for="isFeatured">
                Featured <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="isFeatured"
                id="isFeatured"
                required
                value={onlineCourse.isFeatured}
                onChange={changeCreds}
              >
                {booleanStatus.map((item, index) => (
                  <option value={item.status} key={index}>
                    {item.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </>
      )}
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="currencyID">
            Currency <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="currencyID"
            id="currencyID"
            value={onlineCourse.currency}
            onChange={changeCreds}
          >
            {allCurrency?.map((item, index) => (
              <option value={item.id} key={index}>
                {item?.symbol}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="additionalFeatures">Additional Features</Label>
          <Input
            value={onlineCourse.additionalFeatures}
            onChange={changeCreds}
            name="additionalFeatures"
            type="textarea"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteDescription">Student Content</Label>
          <CkEditor
            value={props.studentContent}
            setDescription={props.setStudentContent}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteDescription">Description</Label>
          <CkEditor
            value={onlineCourseDescription}
            setDescription={setOnlineCourseDescription}
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={onlineCourseImgPath}
          setPostImage={setOnlineCourseImgPath}
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={props.bannerImagePath}
          setPostImage={props.setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  );
}

export default OnlineCourseForm;
