import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editEvent } from "../../../../actions/eventActions";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import EditForm from "./EditForm";

var todayDate = new Date();

function Edit() {
  const [events, setEvent] = useState({
    id: "",
    title: "",
    eventStartTimeString: "",
    isActive: true,
    eventEndTimeString: "",
    eventStartDate: "",
    eventEndDate: "",
    isOnline: true,
    eventPrice: 1,
    eventLocation: "",
    eventLink: "",
    speakerID: 1,
    slug: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    modifyBy: null,
    modifiedDate: todayDate,
  });

  const [eventDescription, setEventDescription] = useState("");
  const [eventBenefits, setEventBenefits] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [linkDescription, setLinkDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const onMultiSelectSpeaker = (selectedOption) => {
    setEvent({ ...events, speakerID: selectedOption });
  };

  const eventEditReducer = useSelector((state) => state.eventEdit);
  const { eventEditLoading } = eventEditReducer;

  const eventSingleReducer = useSelector((state) => state.singleEvent);
  const { singleEvent } = eventSingleReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    if (singleEvent !== null) {
      setEvent({
        id: singleEvent?.id,
        title: singleEvent?.title,
        eventStartTimeString: singleEvent?.eventStartTimeString,
        eventEndTimeString: singleEvent?.eventEndTimeString,
        isActive: singleEvent?.isActive,
        eventStartDate: singleEvent?.eventStartDate,
        eventEndDate: singleEvent?.eventEndDate,
        isActive: singleEvent?.isActive,
        isOnline: singleEvent?.isOnline,
        eventPrice: singleEvent?.eventPrice,
        eventLocation: singleEvent?.eventLocation,
        eventLink: singleEvent?.eventLink,
        speakerID: singleEvent?.speakerID,
        slug: singleEvent?.slug,
        pageTitle: singleEvent?.pageTitle,
        metaTitle: singleEvent?.metaTitle,
        metaKeywords: singleEvent?.metaKeywords,
        metaDescription: singleEvent?.metaDescription,
        modifyBy: null,
        modifiedDate: todayDate,
      });
      setLinkDescription(singleEvent?.linkDescription);
      setEventDescription(singleEvent?.description);
      setEventBenefits(singleEvent?.eventBenefits);
      setEventDate(singleEvent?.eventDate);
    }
  }, [singleEvent]);

  const changeCreds = (event) => {
    setEvent({ ...events, [event.target.name]: event.target.value });
  };

  const createSpeaker = (e) => {
    e.preventDefault();
    const evtFirstDate = new Date(events.eventStartDate);
    const evtEndDate = new Date(events.eventEndDate);

    const eventInfo = {
      id: events.id,
      title: events.title,
      description: eventDescription,
      isActive: JSON.parse(events.isActive),
      eventStartTimeString: events.eventStartTimeString,
      eventEndTimeString: events.eventEndTimeString,
      eventStartDate: evtFirstDate,
      eventEndDate: evtEndDate,
      isOnline: JSON.parse(events.isOnline),
      eventPrice: JSON.parse(events.eventPrice),
      eventLocation: events.eventLocation,
      eventLink: events.eventLink,
      linkDescription: linkDescription,
      eventBenefits: eventBenefits,
      speakerID: events.speakerID,
      slug: events.slug,
      pageTitle: events?.pageTitle,
      metaTitle: events?.metaTitle,
      metaKeywords: events?.metaKeywords,
      metaDescription: events?.metaDescription,
      modifyBy: null,
      modifiedDate: todayDate,
    };

    dispatch(editEvent(eventInfo));
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form
                className="p-5"
                onSubmit={(events) => createSpeaker(events)}
              >
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Links
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Seo
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <EditForm
                      events={events}
                      setEvent={setEvent}
                      changeCreds={changeCreds}
                      eventDescription={eventDescription}
                      setEventDescription={setEventDescription}
                      eventBenefits={eventBenefits}
                      setEventBenefits={setEventBenefits}
                      onMultiSpeakerChange={onMultiSelectSpeaker}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Col className="mb-2 mb-sm-4 mb-md-0 mt-5" md={12}>
                      <FormGroup>
                        <Label for="eventLink">Event Link</Label>
                        <Input
                          value={events.eventLink}
                          onChange={changeCreds}
                          type="url"
                          name="eventLink"
                          autoComplete="nope"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-2 mb-sm-4 mb-md-0 mt-5" md={12}>
                      <FormGroup>
                        <Label for="instituteDescription">
                          Link Description
                        </Label>
                        <CkEditor
                          value={linkDescription}
                          setDescription={setLinkDescription}
                        />
                      </FormGroup>
                    </Col>
                  </TabPane>
                  <TabPane tabId="3">
                    <Seo seoMeta={events} changeCreds={changeCreds} />
                  </TabPane>
                </TabContent>

                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={eventEditLoading}
                      >
                        {eventEditLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: eventEditLoading,
                              invisible: !eventEditLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
