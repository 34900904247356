import React from "react";
import { memo } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { FormFieldTypeEnum } from "../../helper/PsychometricEnum";

function HigherOrderInputField({
  childStates,
  childHandlers,
  removeField,
  fieldIndex,
  children,
  inputType,
  className,
}) {
  const {
    label: labelState,
    placeholder: placeHolderState,
    required: requiredState,
  } = childStates;
  const {
    labelPlace: labelPlaceChange,
    required: requiredChange,
  } = childHandlers;

  return (
    <div className={className}>
      <p className="mb-1" >
        {" "}
        Question Type <strong>{inputType.charAt(0).toUpperCase() + inputType.slice(1)}</strong>
      </p>
      <hr />
      <Row
        className="gutter mb-2 mb-sm-4 mt-2 "
        style={{ position: "relative" }}
      >
        <Col
          className="mb-2 mb-sm-4 mb-md-0"
          xs={12}
          md={placeHolderState !== undefined ? 5 : 6}
        >
          <FormGroup md={12}>
            <Label for="label" className="d-block">
              Question <span className="text-danger">*</span>
            </Label>
            <Input
              value={labelState}
              onChange={(event) => labelPlaceChange(event, fieldIndex, 'label')}
              type="text"
              maxLength={400}
              required
              name="label"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
        {placeHolderState !== undefined && (
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={7} md={5}>
            <FormGroup>
              <Label for="placeholder">
                Placeholder <span className="text-danger">*</span>
              </Label>
              <Input
                value={placeHolderState}
                onChange={(event) => labelPlaceChange(event, fieldIndex, 'placeholder')}
                type="text"
                maxLength={400}
                required
                name="placeholder"
                autoComplete="nope"
              />
            </FormGroup>
          </Col>
        )}
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={5} md={6}>
          <FormGroup>
            <Label for="isRequiredField">
              Compulsory Question? <span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="isRequiredField"
              required
              value={requiredState}
              onChange={() => requiredChange(fieldIndex)}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Input>
          </FormGroup>
        </Col>
        <Button
          color="link"
          role="button"
          style={{
            textDecoration: "none",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            color: "red",
          }}
          className="p-0"
          title="Delete Field"
          onClick={() => removeField(fieldIndex)}
        >
          X
        </Button>
      </Row>
      {children && (
        <Row className="">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
            <FormGroup>{children}</FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default memo(HigherOrderInputField);
