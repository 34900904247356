import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import {
  announcementPublish,
  getAnnouncement,
} from "../../../../actions/onlineCourseActions";
import Accordion from "../../../../UI/Accordion/Accordion";
import { editorConfig } from "../../../../UI/Input/CkEditor/CkEditor";
import CustomModal from "../../../../UI/Modal/Modal";

var todayDate = new Date();

const Announcement = (props) => {
  const [announceModal, setAnnounceModel] = useState([
    {
      id: 0,
      date: todayDate,
      isSelected: false,
      title: "",
      details: "",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeAnnouncementId, setActiveAnnouncementId] = useState("");
  const [modalBody] = useState(
    "Are you sure you want to delete this announcement?"
  );

  let { id } = useParams();

  const dispatch = useDispatch();

  const singleAnnouncementReducer = useSelector(
    (state) => state.singleAnnouncement
  );

  const { singleAnnouncement } = singleAnnouncementReducer;

  useEffect(() => {
    if (singleAnnouncement) {
      if (singleAnnouncement?.announcementDetails?.length > 0) {
        setAnnounceModel(singleAnnouncement?.announcementDetails);
      }
    }
  }, [singleAnnouncement]);

  useEffect(() => {
    dispatch(getAnnouncement(Number(id)));
  }, [dispatch]);

  const announcePublishReducer = useSelector(
    (state) => state.onlineAnnouncementPublish
  );

  const { announcementEditLoading } = announcePublishReducer;

  const addAnnouncement = () => {
    const tempAnnouncement = [...announceModal];

    tempAnnouncement.map((item, index) => (item.isSelected = false));
    setAnnounceModel(tempAnnouncement);
    setAnnounceModel((prevState) => [
      ...prevState,
      {
        date: todayDate,
        id: 0,
        isSelected: true,
        title: "",
        details: "",
      },
    ]);
  };
  const onActiveAnnouncementId = (idx) => {
    setIsModalOpen(true);
    setActiveAnnouncementId(idx);
  };

  const onDeleteAnnouncement = () => {
    const tempAnnouncement = [...announceModal];
    setAnnounceModel(
      tempAnnouncement.filter((r, i) => i !== activeAnnouncementId)
    );
    setIsModalOpen(false);
  };

  const onSelectAccordion = (idx) => {
    const tempAnnouncement = [...announceModal];

    tempAnnouncement.map((item, index) => {
      if (index == idx) {
        item.isSelected = !item.isSelected;
      } else {
        item.isSelected = false;
      }
    });
    setAnnounceModel(tempAnnouncement);
  };

  const onAnnouncementChange = (event) => {
    const sectionAnnouncement = [...announceModal];

    if (["title"].includes(event.target.name)) {
      sectionAnnouncement[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    setAnnounceModel(sectionAnnouncement);
  };
  const onAnnouncementDetailChange = (event, idx) => {
    const sectionAnnouncement = [...announceModal];
    sectionAnnouncement[idx].details = event;
  };

  const onSaveAnnouncement = () => {
    const announcementInfo = {
      onlineCourseID: Number(id),
      announcementDetails: announceModal,
    };

    dispatch(
      announcementPublish(announcementInfo, () => {
        dispatch(getAnnouncement(Number(id)));
      })
    );
  };

  return (
    <>
      <CustomModal
        modal={isModalOpen}
        modalTitle="Delete"
        isDoSomething={true}
        onModalAction={onDeleteAnnouncement}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        {modalBody}
      </CustomModal>
      <Row className="py-3">
        <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
          {announceModal?.map((announcementItem, announceIdx) => {
            let title = `title-${announceIdx}`;

            return (
              <Accordion
                title={`Announcement ${announceIdx + 1} : ${
                  announcementItem.title
                }`}
                key={announceIdx}
                isOpen={announcementItem.isSelected}
                onToggle={() => onSelectAccordion(announceIdx)}
              >
                <div className="course-topic-section  py-3">
                  <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <Input
                        type="text"
                        name="title"
                        data-id={announceIdx}
                        id={title}
                        defaultValue={announcementItem.title}
                        onChange={(e) => onAnnouncementChange(e)}
                        autoComplete="nothing"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="mb-1 mb-sm-3 mb-md-0 " md={12}>
                    <FormGroup>
                      <Label for="answer">Details</Label>
                      <Editor
                        initialValue={announcementItem.details}
                        cloudChannel="dev"
                        init={editorConfig}
                        apiKey={process.env.REACT_APP_EDITOR_KEY}
                        onEditorChange={(evt) =>
                          onAnnouncementDetailChange(evt, announceIdx)
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col
                    className="mb-1 mb-sm-3 mb-md-0 d-flex justify-content-end"
                    md={12}
                  >
                    <Button
                      className=" d-flex justify-content-between align-items-center"
                      style={{ height: "2.1rem" }}
                      color="danger"
                      onClick={() => onActiveAnnouncementId(announceIdx)}
                    >
                      Remove
                    </Button>
                  </Col>
                </div>
              </Accordion>
            );
          })}
        </Col>
      </Row>

      <Col className="d-flex justify-content-end align-items-center">
        <Button
          className="my-3 mx-3"
          style={{ height: "2.5rem" }}
          color="success"
          onClick={onSaveAnnouncement}
          disabled={announcementEditLoading}
        >
          {announcementEditLoading && <Spinner size="sm" />}
          <span className="mx-1">Save</span>
        </Button>
        <Button
          className="my-3"
          style={{ height: "2.5rem" }}
          color="primary"
          onClick={() => addAnnouncement()}
          //   disabled={onlineCourseQuizAddLoading}
        >
          <span> Add Announcement</span>
        </Button>
      </Col>
    </>
  );
};
export default Announcement;
