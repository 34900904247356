import axios from "axios";
import {
  ASSESSMENTS_LIST_FAIL,
  ASSESSMENTS_LIST_REQUEST,
  ASSESSMENTS_LIST_SUCCESS,
  ASSESSMENTS_CREATE_REQUEST,
  ASSESSMENTS_CREATE_SUCCESS,
  ASSESSMENTS_CREATE_FAIL,
  ASSESSMENTS_EDIT_REQUEST,
  ASSESSMENTS_EDIT_SUCCESS,
  ASSESSMENTS_EDIT_FAIL,
  ASSESSMENTS_DETAIL_REQUEST,
  ASSESSMENTS_DETAIL_SUCCESS,
  ASSESSMENTS_DETAIL_FAIL,
  ASSESSMENTS_QUESTIONS_REQUEST,
  ASSESSMENTS_QUESTIONS_SUCCESS,
  ASSESSMENTS_QUESTIONS_FAIL,
} from "../constants/assessmentConstants";
import { toast } from "react-toastify";
import { getUserDetails } from "../helper/getUserDetail";



export const AssessmentList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSESSMENTS_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/GetAllForm`,
      config,
    );

    dispatch({
      type: ASSESSMENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ASSESSMENTS_LIST_FAIL,
      payload: true,
    });
  }
};

export const AssessmentCreate =
  (assessmentInfo, callback) => async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_CREATE_REQUEST,
      })

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/CreateForm`,
        assessmentInfo,
        config,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails().token}`
          },
        }
      );
      dispatch({
        type: ASSESSMENTS_CREATE_SUCCESS,
        payload: data,
      })

      callback();
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_CREATE_FAIL,
        payload: true
      })
    }
  };


  export const AssessmentDetail = (id) => async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_DETAIL_REQUEST,
      })
      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      }
      const { data } = await axios.get(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/GetFormByidQuery/${id}`,
        config,
      );
      dispatch({
        type: ASSESSMENTS_DETAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_DETAIL_FAIL,
        payload: true
      })
      toast.error(error.response.data.message);
    }
  }

  export const AssessmentQuestions = (id) => async (dispatch) => {
    try{
      dispatch({
        type: ASSESSMENTS_QUESTIONS_REQUEST,
      })
      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      }
      const { data } = await axios.get(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/GetFormQuestionByID/${id}`,
        config,
      );
      dispatch({
        type: ASSESSMENTS_QUESTIONS_SUCCESS,
        payload: data,
      })
    }
    catch (error) {
      dispatch({
        type: ASSESSMENTS_QUESTIONS_FAIL,
        payload: true
      })
      toast.error(error.response.data.message);
    }
  }

  export const AssessmentEdit = (editAssessmentInfo) => async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_EDIT_REQUEST,
      })
      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      }
      const { data } = await axios.put(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/UpdateForm`,
        editAssessmentInfo,
        config,
      );
      dispatch({
        type: ASSESSMENTS_EDIT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_EDIT_FAIL,
        payload: true
      })
      toast.error(error.response.data.message);
    }
  }

  export const AssesmentDelete = (id) => async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/api/Form/DeleteForm/${id}`
      );
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
