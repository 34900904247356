import cn from "classnames";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Accord from "../../../UI/Accord/Accord";
import { changeActiveSidebarItem } from "../../../actions/navigation.js";
import {
  adminSidebar,
  counsellorSidebar,
  eventSidebarItems,
  onlineInstituteSidebarItems,
  studentSidebarItems,
} from "../../../data/header/header";
import SofiaLogo from "../Icons/SofiaLogo.js";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import s from "./Sidebar.module.scss";
import OrganizationIcon from "../../../assets/OrganisationIcon.svg";
import CouponIconActive from "../../../assets/OrganisationIcon.svg";

const Sidebar = (props) => {
  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false);
  const [activeUser, setActiveUser] = useState({ role: "" });
  const [sidebarItem, setSidebarItem] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (currentUser !== null) {
      setActiveUser(currentUser);
      if (currentUser.role === "superadmin") {
        setSidebarItem(adminSidebar);
      } else {
        setSidebarItem([]);
      }
    }
  }, []);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true);
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
      <header className={s.logo}>
        <SofiaLogo />
      </header>
      <li className={s.website}>
        <a
          href="https://www.eduberance.com"
          target="_blank"
          rel="noreferrer"
          className={s.websiteLink}
        >
          <i className="fa fa-external-link"></i>
          <span className={s.webText}>Website</span>
        </a>
      </li>

      <ul className={s.nav}>
        {sidebarItem.length > 0 &&
          sidebarItem.map((item, index) => (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header={item.title}
              isHeader
              iconName={
                item.iconClass !== undefined ? (
                  <i className={item.iconClass} />
                ) : (
                  <img
                    src={
                      props.activeItem === item.name
                        ? CouponIconActive
                        : OrganizationIcon
                    }
                    alt="SystemIcons"
                    className={s.systemIcons}
                    width="20"
                    height="20"
                  />
                )
              }
              link={item.link}
              index={item.name}
              key={index}
            />
          ))}
        {activeUser.role === "courseinstructor" && (
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Courses"
            isHeader
            iconName={<i className="eva eva-book-outline" />}
            link="/admin/Online-courses"
            index="Courses"
          />
        )}

        {activeUser.role === "counsellor" && (
          <Fragment>
            {counsellorSidebar.map((item, index) => (
              <LinksGroup
                key={index} // Assuming a unique identifier exists in each item object
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header={item?.title}
                isHeader
                iconName={<i className={item?.iconClass} />}
                link={item?.link}
                index={item?.name}
              />
            ))}
          </Fragment>
        )}
        {activeUser.role === "superadmin" && (
          <>
            <Accord title="Users">
              {studentSidebarItems.map((item, index) => (
                <LinksGroup
                  onActiveSidebarItemChange={(activeItem) =>
                    props.dispatch(changeActiveSidebarItem(activeItem))
                  }
                  activeItem={props.activeItem}
                  header={item.title}
                  isHeader
                  iconName={<i className={item.iconClass} />}
                  link={item.link}
                  index={item.name}
                  key={index}
                />
              ))}
            </Accord>
            <Accord title="Events">
              {eventSidebarItems.map((item, index) => (
                <LinksGroup
                  onActiveSidebarItemChange={(activeItem) =>
                    props.dispatch(changeActiveSidebarItem(activeItem))
                  }
                  activeItem={props.activeItem}
                  header={item.title}
                  isHeader
                  iconName={<i className={item.iconClass} />}
                  link={item.link}
                  index={item.name}
                  key={index}
                />
              ))}
            </Accord>
            <Accord title="Online Courses">
              {onlineInstituteSidebarItems.map((item, index) => (
                <LinksGroup
                  onActiveSidebarItemChange={(activeItem) =>
                    props.dispatch(changeActiveSidebarItem(activeItem))
                  }
                  activeItem={props.activeItem}
                  header={item.title}
                  isHeader
                  iconName={<i className={item.iconClass} />}
                  link={item.link}
                  index={item.name}
                  key={index}
                />
              ))}
            </Accord>

            <Accord title="Admin">
              <AdminSidebar />
            </Accord>
          </>
        )}
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
