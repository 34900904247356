import {
  COUPON_ADD_FAIL,
  COUPON_ADD_REQUEST,
  COUPON_ADD_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  INSTRUCTOR_DROPDOWN_LIST_FAIL,
  INSTRUCTOR_DROPDOWN_LIST_REQUEST,
  INSTRUCTOR_DROPDOWN_LIST_SUCCESS,
  PYSCHOMETRIC_DROPDOWN_LIST_FAIL,
  PYSCHOMETRIC_DROPDOWN_LIST_REQUEST,
  PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS,
} from "../constants/couponConstant";

export const InstructorsDropdownListReducer = (
  state = {
    instructorLoading: true,
    allinstructors: [],
    instructorError: null,
  },
  action
) => {
  switch (action.type) {
    case INSTRUCTOR_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        instructorLoading: true,
        allinstructors: [],
        instructorError: null,
      };
    case INSTRUCTOR_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        instructorLoading: false,
        allinstructors: action.payload,
        instructorError: null,
      };
    case INSTRUCTOR_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        instructorLoading: false,
        allinstructors: [],
        instructorError: action.payload,
      };
    default:
      return state;
  }
};

// pyschometricList reducer
export const PyschometricListReducer = (
  state = {
    pyschometricLoading: true,
    pyschometricList: [],
    pyschometricError: null,
  },
  action
) => {
  switch (action.type) {
    case PYSCHOMETRIC_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        pyschometricLoading: true,
        pyschometricList: [],
        pyschometricError: null,
      };
    case PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        pyschometricLoading: false,
        pyschometricList: action.payload,
        pyschometricError: null,
      };
    case PYSCHOMETRIC_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        pyschometricLoading: false,
        pyschometricList: [],
        pyschometricError: action.payload,
      };
    default:
      return state;
  }
};

// add coupon reducer
const couponAddState = {
  couponAddLoading: false,
  couponAdd: null,
  couponAddError: false,
};

export const couponAddReducer = (state = couponAddState, action) => {
  switch (action.type) {
    case COUPON_ADD_REQUEST:
      return {
        ...state,
        couponAddLoading: true,
        couponAdd: null,
        couponAddError: false,
      };
    case COUPON_ADD_SUCCESS:
      return {
        ...state,
        couponAddLoading: false,
        couponAdd: action.payload,
        couponAddError: false,
      };
    case COUPON_ADD_FAIL:
      return {
        ...state,
        couponAddLoading: false,
        couponAddError: action.payload,
        couponAdd: null,
      };

    default:
      return state;
  }
};

const couponListState = {
  couponLoading: true,
  couponList: [],
  couponError: null,
};

export const couponListReducer = (state = couponListState, action) => {
  switch (action.type) {
    case COUPON_LIST_REQUEST:
      return {
        couponLoading: true,
        couponList: [],
        couponError: null,
      };
    case COUPON_LIST_SUCCESS:
      return {
        couponLoading: false,
        couponList: action.payload,
        couponError: null,
      };
    case COUPON_LIST_FAIL:
      return {
        couponLoading: false,
        couponError: action.payload,
        couponList: [],
      };

    default:
      return state;
  }
};
