import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import classNames from "classnames";
import { addOrganisation } from "../../../../actions/organisationAction";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateForm = () => {
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const organisationAdd = useSelector((state) => state.organisation);
  const { organisationAddLoading } = organisationAdd;

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const validate = () => {
    const newErrors = {};
    // if (!name) newErrors.name = "Organisation name is required.";
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!username) newErrors.username = "Username is required.";
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      newErrors.password =
        "Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    }
    return newErrors;
  };

  const handleSubmit = () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const organisationData = {
        // name,
        email,
        username,
        password,
      };
      dispatch(addOrganisation(organisationData));
      history.push("/admin/organisations");
    }
  };

  return (
    <Fragment>
      <Row className="gutter mt-4" md={12} lg={12}>
        {/* <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="name">
              Organisation Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              required
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="nope"
              invalid={!!errors.name}
            />
            {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
          </FormGroup>
        </Col> */}

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="username">
              Username <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              required
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="nope"
              invalid={!!errors.username}
            />
            {errors.username && <FormFeedback>{errors.username}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="email">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              required
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="nope"
              invalid={!!errors.email}
            />
            {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="password">
              Password <span className="text-danger">*</span>
            </Label>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                required
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="nope"
                invalid={!!errors.password}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="bg-widget d-flex justify-content-end">
            <Button
              className="bg-widget rounded-pill d-flex justify-content-center align-items-center w-25"
              type="submit"
              color="secondary-red"
              disabled={organisationAddLoading}
              onClick={handleSubmit}
            >
              {organisationAddLoading && (
                <Spinner
                  className={classNames({
                    "position-relative": true,
                    visible: organisationAddLoading,
                    invisible: !organisationAddLoading,
                  })}
                  size="sm"
                />
              )}
              <span className="ml-1">Submit</span>
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CreateForm;
