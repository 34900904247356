import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

const TargetObjForm = (props) => {
  const [targetCandidateDetails, setTargetCandidateDetails] = useState([]);

  useEffect(() => {
    setTargetCandidateDetails(props.targetObj.targetGroupDetails);
  }, [props.targetObj, targetCandidateDetails]);
  return (
    <>
      {targetCandidateDetails &&
        targetCandidateDetails?.map((val, idx) => {
          let targetGroupName = `targetGroupName-${idx}`;
          let grades = `grades-${idx}`;
          let locations = `locations-${idx}`;
          let languages = `languages-${idx}`;

          return (
            <Row className="mt-4" key={idx}>
              <>
                <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                  <FormGroup>
                    <Label for="targetGroupName">Name</Label>
                    <Input
                      maxLength={200}
                      type="text"
                      name="targetGroupName"
                      data-id={idx}
                      defaultValue={val.targetGroupName}
                      id={targetGroupName}
                      autoComplete="nothing"
                      rows="2"
                      cols="50"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1 mb-sm-3 mb-md-0" md={4}>
                  <FormGroup>
                    <Label for="grades">Grades</Label>
                    <Input
                      maxLength={200}
                      type="text"
                      name="grades"
                      data-id={idx}
                      id={grades}
                      defaultValue={val.grades}
                      autoComplete="nothing"
                      rows="2"
                      cols="50"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1 mb-sm-3 mb-md-0" md={4}>
                  <FormGroup>
                    <Label for="locations">Locations</Label>
                    <Input
                      maxLength={200}
                      type="text"
                      name="locations"
                      data-id={idx}
                      id={locations}
                      defaultValue={val.locations}
                      autoComplete="nothing"
                      rows="2"
                      cols="50"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1 mb-sm-3 mb-md-0" md={4}>
                  <FormGroup>
                    <Label for="languages">Languages</Label>
                    <Input
                      maxLength={200}
                      type="text"
                      name="languages"
                      data-id={idx}
                      id={languages}
                      autoComplete="nothing"
                      defaultValue={val.languages}
                      rows="2"
                      cols="50"
                    />
                  </FormGroup>
                </Col>
                <Col className="d-flex">
                  {idx + 1 === targetCandidateDetails?.length && (
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-between align-items-center mr-2"
                      style={{ height: "2.1rem" }}
                      color="primary"
                      onClick={() => props.add()}
                    >
                      <i
                        className="fa fa-plus-circle mr-2"
                        aria-hidden="true"
                      />
                    </Button>
                  )}

                  {idx !== 0 && (
                    <>
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-between align-items-center"
                        style={{ height: "2.1rem" }}
                        color="danger"
                        onClick={() => props.delete(val)}
                      >
                        <i className="fa fa-minus" aria-hidden="true" />
                      </Button>
                    </>
                  )}
                </Col>
              </>
            </Row>
          );
        })}
    </>
  );
};
export default TargetObjForm;
