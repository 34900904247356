import axios from "axios";
import { toast } from "react-toastify";
import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_STUDENT_ADD_REQUEST,
  ADMIN_STUDENT_ADD_SUCCESS,
  ADMIN_STUDENT_ADD_FAIL,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_SUCCESS,
  ADMIN_ADD_FAIL,
  INSTRUCTOR_ADD_REQUEST,
  INSTRUCTOR_ADD_SUCCESS,
  INSTRUCTOR_ADD_FAIL,
  EXAM_CREATOR_ADD_REQUEST,
  EXAM_CREATOR_ADD_SUCCESS,
  EXAM_CREATOR_ADD_FAIL,
  INSTRUCTOR_PASSWORD_UPDATE_REQUEST,
  INSTRUCTOR_PASSWORD_UPDATE_SUCCESS,
  INSTRUCTOR_PASSWORD_UPDATE_FAIL,
} from "../constants/userConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const login = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/Login`,
      userData,
      config
    );

    localStorage.setItem("userInfo", JSON.stringify(data));

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error?.response?.data?.message,
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Sorry! something went wrong.",
      });
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT,
    });
    document.location.href = "/login";
    let key;
    let keysToRemove = ["userInfo"];

    for (key of keysToRemove) {
      localStorage.removeItem(key);
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: true,
    });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/GetAllStudents`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const listAdmin = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/GetAllSuperAdmins`,
      config
    );

    dispatch({
      type: ADMIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const addStudent = (studentInfo) => async (dispatch, getState) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ADMIN_STUDENT_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    toast.dismiss();
    toast.success("Student created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterStudentByAdmin`,
      studentInfo,
      config
    );

    dispatch({
      type: ADMIN_STUDENT_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ADMIN_STUDENT_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addAdmin = (addInfo) => async (dispatch, getState) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ADMIN_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterSuperAdminByAdmin`,
      addInfo,
      config
    );
    toast.dismiss();
    toast.success("Admin created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: ADMIN_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: ADMIN_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const addInstructor = (addInfo) => async (dispatch, getState) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: INSTRUCTOR_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterInstructor`,
      addInfo,
      config
    );
    toast.dismiss();

    toast.success("Instructor created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: INSTRUCTOR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    if (error?.response?.data) {
      toast.warning(error?.response?.data.message, {
        position: toast.POSITION.RIGHT_CENTER,
      });
    } else {
      toast.warning("Sorry! something went wrong!", {
        position: toast.POSITION.RIGHT_CENTER,
      });
    }

    dispatch({
      type: INSTRUCTOR_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const updateInstructorPassword =
  (info, callBack) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: INSTRUCTOR_PASSWORD_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/UpdatePassword`,
        info,
        config
      );
      toast.dismiss();

      toast.success("Password updated successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      callBack();
      dispatch({
        type: INSTRUCTOR_PASSWORD_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toast.dismiss();
      toast.warning("Sorry! something went wrong!", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: INSTRUCTOR_PASSWORD_UPDATE_FAIL,
        payload: "Sorry! something went wrong",
      });
    }
  };
export const addExamCreator = (addInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EXAM_CREATOR_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_EXAM_BASE_URL}/api/ExamCreator/create`,
      addInfo,
      config
    );

    toast.dismiss();

    toast.success("Exam Creator added successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EXAM_CREATOR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    if (error?.response?.data) {
      toast.warning(error?.response?.data.message, {
        position: toast.POSITION.RIGHT_CENTER,
      });
    } else {
      toast.warning("Sorry! something went wrong!", {
        position: toast.POSITION.RIGHT_CENTER,
      });
    }
    dispatch({
      type: EXAM_CREATOR_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
