import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function SocialLink({
  speaker,
  instructor,
  changeCreds,
  linkedInProfile,
  twitterProfile,
  fbProfile,
  instaprofile,
  email,
  website,
  Link,
}) {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      {fbProfile && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="fbProfile">Facebook</Label>
            <Input
              value={speaker.fbProfile}
              onChange={changeCreds}
              type="url"
              name="fbProfile"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
        
      )}
      {instaprofile && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="instaprofile">Instagram</Label>
            <Input
              value={speaker.instaprofile}
              onChange={changeCreds}
              type="url"
              name="instaprofile"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}

      {linkedInProfile && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="linkedInProfile">Linked In</Label>
            <Input
              value={speaker.linkedInProfile}
              onChange={changeCreds}
              type="url"
              name="linkedInProfile"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}
      {twitterProfile && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="twitterProfile">Twitter</Label>
            <Input
              value={speaker.twitterProfile}
              onChange={changeCreds}
              type="url"
              name="twitterProfile"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}

      {email && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              value={speaker.email}
              onChange={changeCreds}
              type="email"
              name="email"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}
      {website && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="website">Website</Label>
            <Input
              value={speaker.website}
              onChange={changeCreds}
              type="url"
              name="website"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}
      {Link && (
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
          <FormGroup>
            <Label for="Link">Link</Label>
            <Input
              value={speaker.Link}
              onChange={changeCreds}
              type="url"
              name="Link"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
      )}
    </Row>
    
  );
}

export default SocialLink;

SocialLink.defaultProps = {
  linkedInProfile: true,
  twitterProfile: true,
  fbProfile: true,
  instaprofile: true,
  email: true,
  website: true,
  Link: false,
};
