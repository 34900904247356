import React from "react";
import { memo } from "react";
import { Input } from "reactstrap";

function TextInput({ inputData }) {
  const {
    placeholder,
    size,
    name,
    customClass,
    customStyle,
    value,
    fieldIndex,
    changeHandler,
    dataIndex,
    optionName,
    arrayKey
  } = inputData;
  return (
    <>
      <Input
        type="text"
        placeholder={placeholder}
        name={name}
        bsSize={size}
        style={customStyle}
        className={customClass}
        value={value}
        onChange={(e) => changeHandler(e, fieldIndex, dataIndex, optionName, arrayKey)}
      />
    </>
  );
}

export default memo(TextInput);
