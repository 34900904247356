import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInstructorById } from "../../../actions/onlineCourseActions";
import { getUserById } from "../../../api/getUserById";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/Instructor/Edit/Edit";

const MyProfile = ({ history }) => {
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserById()
      .then((data) => {
        if (data.data.roleName === "superadmin") {
          history.push("/admin/dashboard");
        } else {
          dispatch(getInstructorById(data.data.uid));
          setUserInfo(data.data);
        }
      })
      .catch((error) => setUserInfo(null));
  }, []);

  return (
    <div>
      <Meta title="Edit | my-profile | Eduberance" />
      {userInfo && <Edit isSlug={false} id={userInfo.uid} />}
    </div>
  );
};

export default MyProfile;
