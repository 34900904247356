import { object } from "prop-types";

const FormFieldTypeEnum = [
  // "text",
  // "textarea",
  "Select Option",
  "radiobutton",
  "checkbox",
  // "dropdown",
];

// const FORM_FIELD_TYPE_ENUM = object.freeze({

//   radiobutton: "Radio Button",
//   checkbox: "Check Box",

// })

export { FormFieldTypeEnum };
