export const CAREERS_LIST_REQUEST = "CAREERS_LIST_REQUEST";
export const CAREERS_LIST_SUCCESS = "CAREERS_LIST_SUCCESS";
export const CAREERS_LIST_FAIL = "CAREERS_LIST_FAIL";

export const CAREERS_CATEGORY_LIST_REQUEST = "CAREERS_CATEGORY_LIST_REQUEST";
export const CAREERS_CATEGORY_LIST_SUCCESS = "CAREERS_CATEGORY_LIST_SUCCESS";
export const CAREERS_CATEGORY_LIST_FAIL = "CAREERS_CATEGORY_LIST_FAIL";

export const CAREERS_DATA_POINT_REQUEST = "CAREERS_DATA_POINT_REQUEST";
export const CAREERS_DATA_POINT_SUCCESS = "CAREERS_DATA_POINT_SUCCESS";
export const CAREERS_DATA_POINT_FAIL = "CAREERS_DATA_POINT_FAIL";

export const CAREERS_ADD_REQUEST = "CAREERS_ADD_REQUEST";
export const CAREERS_ADD_SUCCESS = "CAREERS_ADD_SUCCESS";
export const CAREERS_ADD_FAIL = "CAREERS_ADD_FAIL";

export const CAREERS_EDIT_REQUEST = "CAREERS_EDIT_REQUEST";
export const CAREERS_EDIT_SUCCESS = "CAREERS_EDIT_SUCCESS";
export const CAREERS_EDIT_FAIL = "CAREERS_EDIT_FAIL";

export const CAREERS_SINGLE_REQUEST = "CAREERS_SINGLE_REQUEST";
export const CAREERS_SINGLE_SUCCESS = "CAREERS_SINGLE_SUCCESS";
export const CAREERS_SINGLE_FAIL = "CAREERS_SINGLE_FAIL";

export const CAREERS_ACTIVE_REQUEST = "CAREERS_ACTIVE_REQUEST";
export const CAREERS_ACTIVE_SUCCESS = "CAREERS_ACTIVE_SUCCESS";
export const CAREERS_ACTIVE_FAIL = "CAREERS_ACTIVE_FAIL";
