import { Editor } from "@tinymce/tinymce-react";
import React from "react";

export const editorConfig = {
  selector: "textarea",
  height: 200,
  menubar: true,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks  fullscreen",
    "insertdatetime media table  hr code",
  ],
  toolbar:
    "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code",
  powerpaste_allow_local_images: true,
  powerpaste_word_import: "prompt",
  powerpaste_html_import: "prompt",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  branding: false,
};

const CkEditor = ({ value, setDescription }) => {
  const tinyEditorChange = (evt) => {
    setDescription(evt);
  };

  return (
    <Editor
      value={value}
      apiKey={process.env.REACT_APP_EDITOR_KEY}
      cloudChannel="dev"
      init={editorConfig}
      onEditorChange={(evt) => tinyEditorChange(evt)}
    />
  );
};
export default CkEditor;
