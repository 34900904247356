import axios from "axios";
import { toast } from "react-toastify";
import {
  INSTITUTE_ADD_FAIL,
  INSTITUTE_ADD_REQUEST,
  INSTITUTE_ADD_SUCCESS,
  INSTITUTE_CATEGORY_LIST_FAIL,
  INSTITUTE_CATEGORY_LIST_REQUEST,
  INSTITUTE_CATEGORY_LIST_SUCCESS,
  INSTITUTE_EDIT_FAIL,
  INSTITUTE_EDIT_REQUEST,
  INSTITUTE_EDIT_SUCCESS,
  INSTITUTE_LIST_FAIL,
  INSTITUTE_LIST_REQUEST,
  INSTITUTE_LIST_SUCCESS,
  INSTITUTE_SINGLE_FAIL,
  INSTITUTE_SINGLE_REQUEST,
  INSTITUTE_SINGLE_SUCCESS,
  INSTITUTE_ACTIVE_REQUEST,
  INSTITUTE_ACTIVE_SUCCESS,
  INSTITUTE_ACTIVE_FAIL,
  ONLINE_INT_CATEGORY_SINGLE_REQUEST,
  ONLINE_INT_CATEGORY_SINGLE_SUCCESS,
  ONLINE_INT_CATEGORY_SINGLE_FAIL,
} from "../constants/instituteConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const instituteList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSTITUTE_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/SuperAdmin/SuperAdminAllInstitute`,
      config
    );

    dispatch({
      type: INSTITUTE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSTITUTE_LIST_FAIL,
      payload: true,
    });
  }
};

export const instituteCategoryList = () => async (dispatch) => {
  try {
    dispatch({
      type: INSTITUTE_CATEGORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/InstituteType/SuperAdminInstituteType`,
      config
    );

    dispatch({
      type: INSTITUTE_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSTITUTE_CATEGORY_LIST_FAIL,
      payload: true,
    });
  }
};

export const addInstitute =
  (instituteInfo, useCallback) => async (dispatch) => {
    try {
      toast.success("Please wait...", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: INSTITUTE_ADD_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${getUserDetails().token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/SuperAdmin/CreateInstitute`,
        instituteInfo,
        config
      );

      toast.dismiss();
      toast.success("Institute created successfully", {
        position: toast.POSITION.RIGHT_CENTER,
      });

      dispatch({
        type: INSTITUTE_ADD_SUCCESS,
        payload: data,
      });

      useCallback();
    } catch (error) {
      toast.dismiss();
      toast.error("Sorry! something went wrong.", {
        position: toast.POSITION.RIGHT_CENTER,
      });
      dispatch({
        type: INSTITUTE_ADD_FAIL,
        payload: true,
      });
    }
  };
export const editInstitute = (instituteInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: INSTITUTE_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/SuperAdmin/UpdateInstitute`,
      instituteInfo,
      config
    );

    toast.dismiss();

    toast.success("Institute edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: INSTITUTE_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: INSTITUTE_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getInstituteById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INSTITUTE_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/SuperAdmin/SuperAdminGetInstituteByID?id=${id}`,
      config
    );

    dispatch({
      type: INSTITUTE_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSTITUTE_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const intActiveToggle = (intActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: INSTITUTE_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_INSTITUTE_BASE_URL}/api/SuperAdmin/UpdateActiveInactive`,
      intActiveInfo,
      config
    );

    toast.success(
      `Institute ${
        intActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: INSTITUTE_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: INSTITUTE_ACTIVE_FAIL,
      payload: true,
    });
  }
};

export const getOnlineIntCatById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONLINE_INT_CATEGORY_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/CourseCategory/GetCourseCategoryMasterByID?id=${id}`,
      config
    );

    dispatch({
      type: ONLINE_INT_CATEGORY_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONLINE_INT_CATEGORY_SINGLE_FAIL,
      payload: true,
    });
  }
};
