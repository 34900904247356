import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  careerCategoryList,
  getAllDataPoints,
  getCareerById,
} from "../../../actions/careerActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/Career/Edit/Edit";

const CourseEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const careerSingleReducer = useSelector((state) => state.singleCareer);
  const { singleCareerLoading, singleCareer } = careerSingleReducer;

  useEffect(() => {
    dispatch(getCareerById(id));
    dispatch(careerCategoryList());
    dispatch(getAllDataPoints());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Edit | Career | Eduberance" />
      {!singleCareerLoading && (
        <Breadcrumbs url={`/admin/careers/edit/${singleCareer?.careerName}`} />
      )}
      <Edit />
    </div>
  );
};

export default CourseEditForm;
