import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editSeo, getSeoById } from "../../../actions/seoAction";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import { SeoForm } from "./SeoForm";

var todayDate = new Date();

function SeoEdit({ history }) {
  const [seoInfo, setSeoInfo] = useState({
    id: "",
    pageUrl: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: "",
    createdDate: todayDate,
  });

  const [activeTab, setActive] = useState("1");

  const dispatch = useDispatch();

  let { id } = useParams();

  useEffect(() => {
    dispatch(getSeoById(id));
  }, [dispatch]);

  const seoEditReducer = useSelector((state) => state.seoEdit);
  const { seoEditLoading } = seoEditReducer;

  const singleSeoReducer = useSelector((state) => state.singleSeo);
  const { singleSeo } = singleSeoReducer;

  useEffect(() => {
    if (singleSeo) {
      setSeoInfo({
        ...seoInfo,
        id: singleSeo.id,
        pageUrl: singleSeo.pageUrl,
        pageTitle: singleSeo.pageTitle,
        metaTitle: singleSeo.metaTitle,
        metaKeywords: singleSeo.metaKeywords,
        metaDescription: singleSeo.metaDescription,
      });
    }
  }, [singleSeo, id]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setSeoInfo({
      ...seoInfo,
      [event.target.name]: event.target.value,
    });
  };

  const onSeoEdit = (e) => {
    e.preventDefault();
    const info = {
      id: seoInfo.id,
      pageUrl: seoInfo.pageUrl,
      pageTitle: seoInfo.pageTitle,
      metaTitle: seoInfo.metaTitle,
      metaKeywords: seoInfo.metaKeywords,
      metaDescription: seoInfo.metaDescription,
      createdBy: "",
      createdDate: todayDate,
    };
    dispatch(editSeo(info));
  };

  return (
    <Row>
      <Meta title="Add | Page | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onSeoEdit(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <SeoForm
                      seoInfo={seoInfo}
                      setSeoInfo={setSeoInfo}
                      changeCreds={changeCreds}
                    />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={seoEditLoading}
                    >
                      {seoEditLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: seoEditLoading,
                            invisible: !seoEditLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SeoEdit;
