import axios from "axios";
import { getUserDetails } from "../helper/getUserDetail";

let userData = null;

export const getUserById = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getUserDetails().token}`,
    },
  };
  const data = axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/GetUserInfo`,
    config
  );

  return data;
};
