import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { addAdmin } from "../../../actions/userActions";

function AdminForm() {
  const [student, setStudents] = useState({
    Email: "",
    Password: "",
  });

  const adminAddReducer = useSelector((state) => state.addAdminByAdmin);
  const { adminAddLoading } = adminAddReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setStudents({ ...student, [event.target.name]: event.target.value });
  };

  const onRegisterAdmin = (e) => {
    e.preventDefault();

    const adminInfo = {
      Email: student.Email,
      Password: student.Password,
    };

    dispatch(addAdmin(adminInfo));
  };
  return (
    <Form onSubmit={(e) => onRegisterAdmin(e)}>
      <Row className="gutter mb-2 mb-sm-4 mt-5">
        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="Email">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              value={student.Email}
              onChange={changeCreds}
              type="email"
              maxLength={400}
              required
              name="Email"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
          <FormGroup>
            <Label for="Password">
              Password <span className="text-danger">*</span>
            </Label>
            <Input
              value={student.Password}
              onChange={changeCreds}
              type="text"
              maxLength={400}
              required
              name="Password"
              autoComplete="nope"
            />
          </FormGroup>
        </Col>
        <Col
          className="mt-2 mb-sm-4 mb-md-0 d-flex justify-content-center align-items-center "
          xs={12}
          md={4}
        >
          <Button
            className="rounded-pill w-75"
            type="submit"
            color="secondary-red"
            disabled={adminAddLoading}
          >
            {adminAddLoading && (
              <Spinner
                className={classNames({
                  "position-relative": true,
                  visible: adminAddLoading,
                  invisible: !adminAddLoading,
                })}
                size="sm"
              />
            )}
            <span className="ml-1">Submit</span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default AdminForm;
