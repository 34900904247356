// Constants for adding a tutor
export const TUTOR_ADD_REQUEST = "TUTOR_ADD_REQUEST";
export const TUTOR_ADD_SUCCESS = "TUTOR_ADD_SUCCESS";
export const TUTOR_ADD_FAIL = "TUTOR_ADD_FAIL";

// Constants for editing a tutor
export const TUTOR_EDIT_REQUEST = "TUTOR_EDIT_REQUEST";
export const TUTOR_EDIT_SUCCESS = "TUTOR_EDIT_SUCCESS";
export const TUTOR_EDIT_FAIL = "TUTOR_EDIT_FAIL";

// Constants for getting a tutor
export const TUTOR_GET_REQUEST = "TUTOR_GET_REQUEST";
export const TUTOR_GET_SUCCESS = "TUTOR_GET_SUCCESS";
export const TUTOR_GET_FAIL = "TUTOR_GET_FAIL";
