import {
  ASSESSMENTS_LIST_FAIL,
  ASSESSMENTS_LIST_REQUEST,
  ASSESSMENTS_LIST_SUCCESS,
  ASSESSMENTS_CREATE_REQUEST,
  ASSESSMENTS_CREATE_SUCCESS,
  ASSESSMENTS_CREATE_FAIL,
  ASSESSMENTS_EDIT_REQUEST,
  ASSESSMENTS_EDIT_SUCCESS,
  ASSESSMENTS_EDIT_FAIL,
  ASSESSMENTS_DETAIL_REQUEST,
  ASSESSMENTS_DETAIL_SUCCESS,
  ASSESSMENTS_DETAIL_FAIL,
  ASSESSMENTS_QUESTIONS_REQUEST,
  ASSESSMENTS_QUESTIONS_SUCCESS,
  ASSESSMENTS_QUESTIONS_FAIL,
} from "../constants/assessmentConstants";

const assessmentState = {
  assessmentsListLoading: false,
  assessments: null,
  assessmentsListError: false,
};

export const assessmentsReducer = (state = assessmentState, action) => {
  switch (action.type) {
    case ASSESSMENTS_LIST_REQUEST:
      return {
        assessmentsListLoading: true,
        assessments: null,
        assessmentsListError: false,
      };
    case ASSESSMENTS_LIST_SUCCESS:
      return {
        assessmentsListLoading: false,
        assessments: action.payload,
        assessmentsListError: false,
      };
    case ASSESSMENTS_LIST_FAIL:
      return {
        assessmentsListLoading: false,
        assessmentsListError: action.payload,
        assessments: null,
      };

    default:
      return state;
  }
};


// Create Assessment Reducer

const assessmentCreateState = {
  assessmentCreateLoading: false,
  assessmentCreateSuccess: null,
  assessmentCreateError: false,
};

export const assessmentCreateReducer = (state = assessmentCreateState, action) => {
  switch (action.type) {
    case ASSESSMENTS_CREATE_REQUEST:
      return {
        assessmentCreateLoading: true,
        assessmentCreateSuccess: null,
        assessmentCreateError: false,
      };
    case ASSESSMENTS_CREATE_SUCCESS:
      return {
        assessmentCreateLoading: false,
        assessmentCreateSuccess: action.payload,
        assessmentCreateError: false,
      };
    case ASSESSMENTS_CREATE_FAIL:
      return {
        assessmentCreateLoading: false,
        assessmentCreateError: action.payload,
        assessmentCreateSuccess: null,
      };
    default:
      return state;
  }
}

// ASSESSMENT QUESTION REDUCER

const assessmentQuestionsState = {
  assessmentQuestionsLoading: false,
  assessmentQuestions: null,
  assessmentQuestionsError: false,
};

export const assessmentQuestionsReducer = (state = assessmentQuestionsState, action) => {
  switch (action.type) {
    case ASSESSMENTS_QUESTIONS_REQUEST:
      return {
        assessmentQuestionsLoading: true,
        assessmentQuestions: null,
        assessmentQuestionsError: false,
      };
    case ASSESSMENTS_QUESTIONS_SUCCESS:
      return {
        assessmentQuestionsLoading: false,
        assessmentQuestions: action.payload,
        assessmentQuestionsError: false,
      };
    case ASSESSMENTS_QUESTIONS_FAIL:
      return {
        assessmentQuestionsLoading: false,
        assessmentQuestionsError: action.payload,
        assessmentQuestions: null,
      };
    default:
      return state;
  }
}


const assessmentEditState = {
  assessmentEditLoading: false,
  assessmentEditSuccess: null,
  assessmentEditError: false,
};


export const assessmentEditReducer = (state = assessmentEditState, action) => {
  switch (action.type) {
    case ASSESSMENTS_EDIT_REQUEST:
      return {
        assessmentEditLoading: true,
        assessmentEditSuccess: null,
        assessmentEditError: false,
      };
    case ASSESSMENTS_EDIT_SUCCESS:
      return {
        assessmentEditLoading: false,
        assessmentEditSuccess: action.payload,
        assessmentEditError: false,
      };
    case ASSESSMENTS_EDIT_FAIL:
      return {
        assessmentEditLoading: false,
        assessmentEditError: action.payload,
        assessmentEditSuccess: null,
      };
    default:
      return state;
  }
}

const assessmentDetailState = {
  assessmentDetailLoading: false,
  assessmentDetail: null,
  assessmentDetailError: false,
};

export const assessmentDetailReducer = (state = assessmentDetailState, action) => {
  switch (action.type) {
    case ASSESSMENTS_DETAIL_REQUEST:
      return {
        assessmentDetailLoading: true,
        assessmentDetail: null,
        assessmentDetailError: false,
      };
    case ASSESSMENTS_DETAIL_SUCCESS:
      return {
        assessmentDetailLoading: false,
        assessmentDetail: action.payload,
        assessmentDetailError: false,
      };
    case ASSESSMENTS_DETAIL_FAIL:
      return {
        assessmentDetailLoading: false,
        assessmentDetailError: action.payload,
        assessmentDetail: null,
      };
    default:
      return state;
  }
}
