import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInstructorById } from "../../actions/onlineCourseActions";
import Breadcrumbs from "../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../components/Branch/Meta/Meta";
import Edit from "../../components/Main/Instructor/Edit/Edit";

const InstructorEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const instructorSingleReducer = useSelector(
    (state) => state.singleInstructor
  );
  const { singleInstructorLoading, singleInstructor } = instructorSingleReducer;

  useEffect(() => {
    dispatch(getInstructorById(id));
  }, [dispatch]);

  return (
    <div>
      <Meta title="Edit | instructor | Eduberance" />
      {!singleInstructorLoading && (
        <Breadcrumbs url={`/admin/instructor/edit/${singleInstructor?.name}`} />
      )}
      <Edit isSlug={true} id={id} />
    </div>
  );
};

export default InstructorEditForm;
