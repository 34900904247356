import axios from "axios";
import { toast } from "react-toastify";
import {
  SPEAKER_ADD_REQUEST,
  SPEAKER_ADD_SUCCESS,
  SPEAKER_ADD_FAIL,
  EVENT_ADD_REQUEST,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAIL,
  SPEAKER_LIST_REQUEST,
  SPEAKER_LIST_SUCCESS,
  SPEAKER_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  REGISTERED_EVENT_LIST_REQUEST,
  REGISTERED_EVENT_LIST_SUCCESS,
  REGISTERED_EVENT_LIST_FAIL,
  EVENT_EDIT_REQUEST,
  EVENT_EDIT_SUCCESS,
  EVENT_EDIT_FAIL,
  SPEAKER_EDIT_REQUEST,
  SPEAKER_EDIT_SUCCESS,
  SPEAKER_EDIT_FAIL,
  EVENT_SINGLE_REQUEST,
  EVENT_SINGLE_SUCCESS,
  EVENT_SINGLE_FAIL,
  SPEAKER_SINGLE_REQUEST,
  SPEAKER_SINGLE_SUCCESS,
  SPEAKER_SINGLE_FAIL,
  EVENT_ACTIVE_REQUEST,
  EVENT_ACTIVE_SUCCESS,
  EVENT_ACTIVE_FAIL,
  SPEAKER_EVENT_REQUEST,
  SPEAKER_EVENT_SUCCESS,
  SPEAKER_EVENT_FAIL,
} from "../constants/eventConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const addSpeaker = (speakerInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SPEAKER_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Speaker/CreateSpeaker`,
      speakerInfo,
      config
    );

    toast.dismiss();
    toast.success("Speaker created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SPEAKER_ADD_SUCCESS,
      payload: data,
    });

    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SPEAKER_ADD_FAIL,
      payload: true,
    });
  }
};

export const addEvent = (eventInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EVENT_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Event/CreateEvent`,
      eventInfo,
      config
    );

    toast.dismiss();

    toast.success("Event created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EVENT_ADD_SUCCESS,
      payload: data,
    });
    if (data) {
      document.location.href = "/admin/events";
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: EVENT_ADD_FAIL,
      payload: true,
    });
  }
};

export const speakerList = () => async (dispatch) => {
  try {
    dispatch({
      type: SPEAKER_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Speaker/GetAllSpeaker`,
      config
    );

    dispatch({
      type: SPEAKER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPEAKER_LIST_FAIL,
      payload: true,
    });
  }
};


export const getEventSpeakers = () => async (dispatch) => {
  try {
    dispatch({
      type: SPEAKER_EVENT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Speaker/GetSpeakers`,
      config
    );

    dispatch({
      type: SPEAKER_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPEAKER_EVENT_FAIL,
      payload: true,
    });
  }
};


export const getAllEvents = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Event/GetAllEvent`,
      config
    );

    dispatch({
      type: EVENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_LIST_FAIL,
      payload: true,
    });
  }
};
export const getAllRegisteredEvents = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REGISTERED_EVENT_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/EventRegistration/GetRegisterEventByID?id=${id}`,
      config
    );

    dispatch({
      type: REGISTERED_EVENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REGISTERED_EVENT_LIST_FAIL,
      payload: true,
    });
  }
};

export const editEvent = (editEventInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EVENT_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Event/UpdateEvent`,
      editEventInfo,
      config
    );

    toast.dismiss();

    toast.success("Event edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: EVENT_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: EVENT_EDIT_FAIL,
      payload: true,
    });
  }
};

export const editSpeaker = (editSpeakerInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SPEAKER_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Speaker/UpdateSpeaker`,
      editSpeakerInfo,
      config
    );

    toast.dismiss();

    toast.success("Speaker edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: SPEAKER_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: SPEAKER_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getEventById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Event/GetEventByID?id=${id}`,
      config
    );

    dispatch({
      type: EVENT_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const getSpeakerById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SPEAKER_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Speaker/GetSpeakerByID?id=${id}`,
      config
    );

    dispatch({
      type: SPEAKER_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPEAKER_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const eventActiveToggle = (eventActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: EVENT_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_EVENT_BASE_URL}/api/Event/UpdateActiveInactive`,
      eventActiveInfo,
      config
    );

    toast.success(
      `Course ${
        eventActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: EVENT_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! Something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: EVENT_ACTIVE_FAIL,
      payload: true,
    });
  }
};
