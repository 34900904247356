import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { login } from "../../actions/userActions";
import loginImage from "../../assets/loginImage.svg";
import Footer from "../../components/Branch/Footer/Footer";
import SofiaLogo from "../../components/Branch/Icons/SofiaLogo.js";
import Widget from "../../components/Branch/Widget/Widget";
import hasToken from "../../services/authService";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import classNames from "classnames";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Login = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [validationError, setValidationError] = useState({
    email: false,
    password: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

  const userLogin = useSelector((state) => state.userLogin);
  const { loginLoading, loggedInUserInfo, loginError } = userLogin;

  useEffect(() => {
    if (loggedInUserInfo) {
      props.history.push("/admin/dashboard");
    }
  }, [props.history, loggedInUserInfo]);

  const doLogin = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setValidationError({
        ...state,
        [e.target.name]: `${capitalizeFirstLetter(e.target.name)} is required`,
      });
    }

    if (state.email && state.password !== "") {
      dispatch(login({ Username: state.email, Password: state.password }));
    }
  };

  const changeCreds = (event) => {
    if (event.target.value === "") {
      setValidationError({
        ...validationError,
        [event.target.name]: `${capitalizeFirstLetter(
          event.target.name
        )} is required`,
      });
    } else {
      setValidationError({
        ...validationError,
        [event.target.name]: false,
      });
    }
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { from } = props.location.state || {
    from: { pathname: "/admin/dashboard" },
  };

  if (hasToken(JSON.parse(localStorage.getItem("loggedInUserInfo")))) {
    return <Redirect to={from} />;
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Login</p>
                <div className="logo-block mb-2">
                  <SofiaLogo />
                </div>
              </div>

              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={state.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                  {validationError.email && (
                    <div className="text-danger mt-1">
                      {validationError.email}
                    </div>
                  )}
                </FormGroup>

                <FormGroup className="my-3">
                  <FormText>Password</FormText>
                  <div className="d-flex align-items-center position-relative justify-content-end">
                    <Input
                      id="password"
                      className="input-transparent pl-3 pr-5"
                      value={state.password}
                      onChange={(event) => changeCreds(event)}
                      type={passwordVisible ? "text" : "password"}
                      required
                      name="password"
                      placeholder="Password"
                      autoComplete="on"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="bg-transparent border-0 position-absolute h-auto  d-flex align-items-center"
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {validationError.password && (
                    <div className="text-danger mt-1">
                      {validationError.password}
                    </div>
                  )}
                </FormGroup>

                {loginError && (
                  <div className="text-danger text-center mt-1">
                    {loginError}
                  </div>
                )}

                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3 d-flex justify-content-between align-items-center"
                    type="submit"
                    color="secondary-red"
                    disabled={loginLoading}
                  >
                    {loginLoading && (
                      <Spinner
                        className={classNames({
                          "position-relative": true,
                          visible: loginLoading,
                          invisible: !loginLoading,
                        })}
                        size="sm"
                      />
                    )}
                    <span className="ml-1">Login</span>
                  </Button>
                </div>
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={loginImage} alt="Error page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Login;
