import {
  SPEAKER_ADD_REQUEST,
  SPEAKER_ADD_SUCCESS,
  SPEAKER_ADD_FAIL,
  EVENT_ADD_REQUEST,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAIL,
  SPEAKER_LIST_REQUEST,
  SPEAKER_LIST_SUCCESS,
  SPEAKER_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  REGISTERED_EVENT_LIST_REQUEST,
  REGISTERED_EVENT_LIST_SUCCESS,
  REGISTERED_EVENT_LIST_FAIL,
  EVENT_EDIT_REQUEST,
  EVENT_EDIT_SUCCESS,
  EVENT_EDIT_FAIL,
  SPEAKER_EDIT_REQUEST,
  SPEAKER_EDIT_SUCCESS,
  SPEAKER_EDIT_FAIL,
  EVENT_SINGLE_REQUEST,
  EVENT_SINGLE_SUCCESS,
  EVENT_SINGLE_FAIL,
  SPEAKER_SINGLE_REQUEST,
  SPEAKER_SINGLE_SUCCESS,
  SPEAKER_SINGLE_FAIL,
  EVENT_ACTIVE_REQUEST,
  EVENT_ACTIVE_SUCCESS,
  EVENT_ACTIVE_FAIL,
  SPEAKER_EVENT_REQUEST,
  SPEAKER_EVENT_SUCCESS,
  SPEAKER_EVENT_FAIL,
} from "../constants/eventConstants";

const speakerAddState = {
  speakerAddLoading: false,
  speakerAdd: null,
  speakerAddError: false,
};
const eventAddState = {
  eventAddLoading: false,
  eventAdded: null,
  eventAddError: false,
};

const speakerListState = {
  speakerListLoading: false,
  speakers: null,
  speakerListError: false,
};

const eventListState = {
  eventListLoading: false,
  allEvents: null,
  eventListError: false,
};

const registerEventListState = {
  registerEventLoading: false,
  registerEventList: null,
  registerEventListError: false,
};

const eventEditState = {
  eventEditLoading: false,
  eventEdited: null,
  eventEditError: false,
};

const speakerEditState = {
  speakerEditLoading: false,
  speakerEdited: null,
  speakerEditError: false,
};

const singleEventState = {
  singleEventLoading: false,
  singleEvent: null,
  singleEventError: false,
};
const singleSpeaker = {
  singleSpeakerLoading: false,
  singleSpeaker: null,
  singleSpeakerError: false,
};
const eventSpeakerState = {
  eventSpeakerLoading: false,
  eventSpeakers: null,
  eventSpeakerError: false,
};

const eventActiveState = {
  eventActiveLoading: false,
  eventActiveStatus: null,
  eventActiveError: false,
};

export const speakerAddReducer = (state = speakerAddState, action) => {
  switch (action.type) {
    case SPEAKER_ADD_REQUEST:
      return {
        speakerAddLoading: true,
        speakerAdd: null,
        speakerAddError: false,
      };
    case SPEAKER_ADD_SUCCESS:
      return {
        speakerAddLoading: false,
        speakerAdd: action.payload,
        speakerAddError: false,
      };
    case SPEAKER_ADD_FAIL:
      return {
        speakerAddLoading: false,
        speakerAddError: action.payload,
        speakerAdd: null,
      };

    default:
      return state;
  }
};

export const eventAddReducer = (state = eventAddState, action) => {
  switch (action.type) {
    case EVENT_ADD_REQUEST:
      return {
        eventAddLoading: true,
        eventAdded: null,
        eventAddError: false,
      };
    case EVENT_ADD_SUCCESS:
      return {
        eventAddLoading: false,
        eventAdded: action.payload,
        eventAddError: false,
      };
    case EVENT_ADD_FAIL:
      return {
        eventAddLoading: false,
        eventAddError: action.payload,
        eventAdded: null,
      };

    default:
      return state;
  }
};

export const speakerReducer = (state = speakerListState, action) => {
  switch (action.type) {
    case SPEAKER_LIST_REQUEST:
      return {
        speakerListLoading: true,
        speakers: null,
        speakerListError: false,
      };
    case SPEAKER_LIST_SUCCESS:
      return {
        speakerListLoading: false,
        speakers: action.payload,
        speakerListError: false,
      };
    case SPEAKER_LIST_FAIL:
      return {
        speakerListLoading: false,
        speakerListError: action.payload,
        speakers: null,
      };

    default:
      return state;
  }
};

export const eventSpeakerReducer = (state = eventSpeakerState, action) => {
  switch (action.type) {
    case SPEAKER_EVENT_REQUEST:
      return {
        eventSpeakerLoading: true,
        eventSpeakers: null,
        eventSpeakerError: false,
      };
    case SPEAKER_EVENT_SUCCESS:
      return {
        eventSpeakerLoading: false,
        eventSpeakers: action.payload,
        eventSpeakerError: false,
      };
    case SPEAKER_EVENT_FAIL:
      return {
        eventSpeakerLoading: false,
        eventSpeakerError: action.payload,
        eventSpeakers: null,
      };

    default:
      return state;
  }
};

export const eventListReducer = (state = eventListState, action) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return {
        eventListLoading: true,
        allEvents: null,
        eventListError: false,
      };
    case EVENT_LIST_SUCCESS:
      return {
        eventListLoading: false,
        allEvents: action.payload,
        eventListError: false,
      };
    case EVENT_LIST_FAIL:
      return {
        eventListLoading: false,
        eventListError: action.payload,
        allEvents: null,
      };

    default:
      return state;
  }
};
export const registerEventListReducer = (
  state = registerEventListState,
  action
) => {
  switch (action.type) {
    case REGISTERED_EVENT_LIST_REQUEST:
      return {
        registerEventListLoading: true,
        registerEventList: null,
        registerEventListError: false,
      };
    case REGISTERED_EVENT_LIST_SUCCESS:
      return {
        registerEventListLoading: false,
        registerEventList: action.payload,
        registerEventListError: false,
      };
    case REGISTERED_EVENT_LIST_FAIL:
      return {
        registerEventListLoading: false,
        registerEventListError: action.payload,
        registerEventList: null,
      };

    default:
      return state;
  }
};

export const eventEditReducer = (state = eventEditState, action) => {
  switch (action.type) {
    case EVENT_EDIT_REQUEST:
      return {
        eventEditLoading: true,
        eventEdited: null,
        eventEditError: false,
      };
    case EVENT_EDIT_SUCCESS:
      return {
        eventEditLoading: false,
        eventEdited: action.payload,
        eventEditError: false,
      };
    case EVENT_EDIT_FAIL:
      return {
        eventEditLoading: false,
        eventEditError: action.payload,
        eventEdited: null,
      };

    default:
      return state;
  }
};

export const speakerEditReducer = (state = speakerEditState, action) => {
  switch (action.type) {
    case SPEAKER_EDIT_REQUEST:
      return {
        speakerEditLoading: true,
        speakerEdited: null,
        speakerEditError: false,
      };
    case SPEAKER_EDIT_SUCCESS:
      return {
        speakerEditLoading: false,
        speakerEdited: action.payload,
        speakerEditError: false,
      };
    case SPEAKER_EDIT_FAIL:
      return {
        speakerEditLoading: false,
        speakerEditError: action.payload,
        speakerEdited: null,
      };

    default:
      return state;
  }
};

export const singleEventReducer = (state = singleEventState, action) => {
  switch (action.type) {
    case EVENT_SINGLE_REQUEST:
      return {
        singleEventLoading: true,
        singleEvent: null,
        singleEventError: false,
      };
    case EVENT_SINGLE_SUCCESS:
      return {
        singleEventLoading: false,
        singleEvent: action.payload,
        singleEventError: false,
      };
    case EVENT_SINGLE_FAIL:
      return {
        singleEventLoading: false,
        singleEventError: action.payload,
        singleEvent: null,
      };

    default:
      return state;
  }
};

export const singleSpeakerReducer = (state = singleSpeaker, action) => {
  switch (action.type) {
    case SPEAKER_SINGLE_REQUEST:
      return {
        singleSpeakerLoading: true,
        singleSpeaker: null,
        singleSpeakerError: false,
      };
    case SPEAKER_SINGLE_SUCCESS:
      return {
        singleSpeakerLoading: false,
        singleSpeaker: action.payload,
        singleSpeakerError: false,
      };
    case SPEAKER_SINGLE_FAIL:
      return {
        singleSpeakerLoading: false,
        singleSpeakerError: action.payload,
        singleSpeaker: null,
      };

    default:
      return state;
  }
};

export const eventActiveReducer = (state = eventActiveState, action) => {
  switch (action.type) {
    case EVENT_ACTIVE_REQUEST:
      return {
        eventActiveLoading: true,
        eventActiveStatus: null,
        eventActiveError: false,
      };
    case EVENT_ACTIVE_SUCCESS:
      return {
        eventActiveLoading: false,
        eventActiveStatus: action.payload,
        eventActiveError: false,
      };
    case EVENT_ACTIVE_FAIL:
      return {
        eventActiveLoading: false,
        eventActiveError: action.payload,
        eventActiveStatus: null,
      };

    default:
      return state;
  }
};
