import axios from "axios";
import {
  COUPON_ADD_FAIL,
  COUPON_ADD_REQUEST,
  COUPON_ADD_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  INSTRUCTOR_DROPDOWN_LIST_FAIL,
  INSTRUCTOR_DROPDOWN_LIST_REQUEST,
  INSTRUCTOR_DROPDOWN_LIST_SUCCESS,
  PYSCHOMETRIC_DROPDOWN_LIST_FAIL,
  PYSCHOMETRIC_DROPDOWN_LIST_REQUEST,
  PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS,
} from "../constants/couponConstant";
import { getUserDetails } from "../helper/getUserDetail";
import { toast } from "react-toastify";

export const InstructorsDropdownList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSTRUCTOR_DROPDOWN_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_ONLINE_COURSE_BASE_URL}/api/OnlineCourseInstructor/GetAllInstructor`,
      config
    );
    const names = data.map((item) => ({
      label: item?.name,
      id: item?.userID,
    }));

    dispatch({
      type: INSTRUCTOR_DROPDOWN_LIST_SUCCESS,
      payload: names,
    });
  } catch (error) {
    dispatch({
      type: INSTRUCTOR_DROPDOWN_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

//pyschometricDropdownList
export const PyschometricDropdownList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PYSCHOMETRIC_DROPDOWN_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PSYCHOMETRIC_BASE_URL}/api/Psychometric/GetAllPsychometric`,
      config
    );
    const names = data.map((item) => ({
      label: item?.title,
      id: item?.psychometricId,
    }));

    dispatch({
      type: PYSCHOMETRIC_DROPDOWN_LIST_SUCCESS,
      payload: names,
    });
  } catch (error) {
    dispatch({
      type: PYSCHOMETRIC_DROPDOWN_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addCoupon = (addInfo) => async (dispatch, getState) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COUPON_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_COUPON_BASE_URL}/api/Coupons/CreateCoupon`,
      addInfo,
      config
    );
    toast.dismiss();

    toast.success("Coupon created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COUPON_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    if (error?.response?.data) {
      toast.warning(error?.response?.data.message, {
        position: toast.POSITION.RIGHT_CENTER,
      });
    } else {
      toast.warning("Sorry! something went wrong!", {
        position: toast.POSITION.RIGHT_CENTER,
      });
    }

    dispatch({
      type: COUPON_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

// get all coupons

export const getCoupons = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COUPON_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COUPON_BASE_URL}/api/Coupons/GetAllCoupons`,
      config
    );

    dispatch({
      type: COUPON_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COUPON_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
