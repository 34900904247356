import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, FormGroup, Row, Label, Input, Form, Button } from 'reactstrap';
import Widget from '../../../Branch/Widget/Widget';
import { radioProp, checkProp, textAreaProp, textProp, dropDownProp } from "../../../../constants/assessmentConstants";
import { FormFieldTypeEnum } from "../../../../helper/PsychometricEnum";
import { useDispatch } from "react-redux";
import { AssessmentCreate } from "../../../../actions/assessments";
import DynamicInput from "../../../../UI/DynamicInput/DynamicInput";
import HigherOrderInputField from "../../../../UI/DynamicInput/HigherOrderInputField";


function CreateQuestionForm({
  formFields,
  setFormFields,
  ...props
}) {

  // Always call useState unconditionally at the top level




  const [fieldType, setFieldType] = useState(0);

  const radioPropData = {
    changeHandler: handleRadioArrayChange,
    addMoreOptions: addOptionstoArray,
    removeOptions: removeOptionsfromArray,
    labelChangeHandler: handleFieldLabelChange,
    ...radioProp,
  };

  const checkPropData = {
    changeHandler: handleRadioArrayChange,
    addMoreOptions: addOptionstoArray,
    removeOptions: removeOptionsfromArray,
    labelChangeHandler: handleFieldLabelChange,
    ...checkProp,
  };

  const dropDownPropData = {
    changeHandler: handleRadioArrayChange,
    addMoreOptions: addOptionstoArray,
    removeOptions: removeOptionsfromArray,
    labelChangeHandler: handleFieldLabelChange,
    ...dropDownProp,
  };

  const inputTypes = {
    text: { props: textProp },
    textarea: { props: textAreaProp },
    radiobutton: { props: radioPropData },
    checkbox: { props: checkPropData },
    dropdown: { props: dropDownPropData },
  };

  const addFields = (fieldTypeEvent) => {
    const enumDataIndex = fieldTypeEvent.target.value;
    setFormFields((pre) => [
      ...pre,
      {
        type: FormFieldTypeEnum[enumDataIndex],
        ...inputTypes[FormFieldTypeEnum[enumDataIndex]],
      },
    ]);
    setFieldType(0);
  };

  const removeFields = (fieldIndex) => {
    setFormFields((pre) => pre.filter((_data, index) => index !== fieldIndex));
  };

  const handleLabelPlaceChange = (e, fieldIndex, key) => {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? { ...data, props: { ...data.props, [key]: e.target.value } }
          : data
      )
    );
  };

  function handleFieldLabelChange(e, fieldIndex) {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? {
            ...data,
            props: {
              ...data.props,
              fieldLabel: e.target.value.replace(" ", ""),
            },
          }
          : data
      )
    );
  }

  function handleRadioArrayChange(
    e,
    fieldIndex,
    dataIndex,
    optionKey,
    arrayKey
  ) {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? {
            ...data,
            props: {
              ...data.props,
              [optionKey]: data.props[optionKey].map((radioData, index) =>
                index === dataIndex
                  ? {
                    ...radioData,
                    [arrayKey]: e.target.value,
                    label:
                      arrayKey === "value"
                        ? e?.target?.value.replaceAll(" ", "")
                        : radioData.label,
                  }
                  : radioData
              ),
            },
          }
          : data
      )
    );
  }

  function addOptionstoArray(
    fieldIndex,
    optionKeys,
    optionValue = "Option"
  ) {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? {
            ...data,
            props: {
              ...data.props,
              [optionKeys]: [
                ...data.props[optionKeys],
                { value: optionValue },
              ],
            },
          }
          : data
      )
    );
  }

  function removeOptionsfromArray(fieldIndex, dataIndex, optionKeys) {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? {
            ...data,
            props: {
              ...data.props,
              [optionKeys]: data.props[optionKeys].filter(
                (_data, index) => index !== dataIndex
              ),
            },
          }
          : data
      )
    );
  }

  const handleRequiredChange = (fieldIndex) => {
    setFormFields((pre) =>
      pre.map((data, index) =>
        fieldIndex === index
          ? {
            ...data,
            props: { ...data.props, required: !data.props.required },
          }
          : data
      )
    );
  };


  const childChangeHandlerChildren = {
    labelPlace: handleLabelPlaceChange,
    required: handleRequiredChange,
  };

  return (
    <Row >
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget style={{ height: "auto" }} className={"mb-3"}>
              <Row
                className="px-5 py-2"
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <FormGroup >
                  <Label for="isRequiredField">
                    Add Fields <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="isRequiredField"
                    value={fieldType}
                    required
                    onChange={(e) => addFields(e)}
                  >
                    {FormFieldTypeEnum.map((formField, index) => {
                      return (
                        <option value={index} key={index}>
                          {formField.charAt(0).toUpperCase() + formField.slice(1)}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Row>
            </Widget>

            {formFields.map((fieldData, formindex) => {
              return (
                <Widget style={{ height: "auto" }} key={formindex}>
                  <HigherOrderInputField
                    className="py-3 px-5 mb-2 border shadow-sm rounded"
                    childStates={{ ...fieldData.props }}
                    childHandlers={childChangeHandlerChildren}
                    fieldIndex={formindex}
                    inputType={fieldData.type}
                    removeField={removeFields}
                  >

                    {fieldData.type !== "text" &&
                      fieldData.type !== "textarea" && (
                        <DynamicInput
                          inputType={fieldData.type}
                          fieldIndex={formindex}
                          {...fieldData.props}
                        />
                      )}
                      <Col md={6}>
                      <FormGroup>
                      <Label for="isRequiredField">
                        Add Question <span className="text-danger float-right">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="isRequiredField"
                        value={fieldType}
                        required
                        onChange={(e) => addFields(e)}
                      >
                        {FormFieldTypeEnum.map((formField, index) => {
                          return (
                            <option value={index} key={index}>
                              {formField.charAt(0).toUpperCase() + formField.slice(1)}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                      </Col>
                    
                  </HigherOrderInputField>

                </Widget>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
            </div>

          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CreateQuestionForm;