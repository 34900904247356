import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import AdminTable from "../../tables/components/UserTable/AdminTable";

const AdminList = () => {
  return (
    <div>
      <Meta title="Admin's | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <AdminTable tableName="Admin" />
        </Col>
      </Row>
    </div>
  );
};

export default AdminList;
