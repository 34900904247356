import React, { useState } from "react";
import { Collapse } from "reactstrap";
import "./Accord.scss";

function Accord({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ cursor: "pointer" }}>
      <div
        className={`${
          isOpen ? "open" : ""
        } d-flex px-3 py-2  justify-content-between align-items-center`}
        onClick={toggle}
      >
        <h6 className="title d-flex ">{title} </h6>
        <span className={` ${isOpen ? "arrow-dw" : "arrow"}`}>
          <i
            className={`fa fa-angle-down  ${
              isOpen ? "fa-angle" : "fa-angle-rotated"
            }`}
          ></i>
        </span>
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}

export default Accord;
