import React from "react";
import { memo } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import TextInput from "./TextInput";

function RadioInput({ inputData }) {
  const {
    radioArrays,
    fieldIndex,
    changeHandler,
    addMoreOptions,
    removeOptions,
    labelChangeHandler,
    fieldLabel,
  } = inputData;

  return (
    <FormGroup tag="fieldset">
      {/* <Row style={{ justifyContent: "space-between" }}>
        <Col md={6}>Field Options</Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          md={6}
        >
          <Row
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Col md={6}>
              <Label for="label" className="pr-2">
                Label for radio
              </Label>
            </Col>
            <Col md={8}>
              <TextInput
                inputData={{
                  value: fieldLabel,
                  changeHandler: labelChangeHandler,
                  fieldIndex,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <hr /> */}
      <Row className="justify-content-between align-items-center">
        {radioArrays.map((data, radioIndex) => {
          return (
            <Col key={radioIndex} md={12} className="mb-2 ">
              <FormGroup check>
                <Row
                  className="mx-0"
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col md={11} className="px-0">
                    <Row
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Col md={9}>
                        <TextInput
                          inputData={{
                            value: data.value,
                            changeHandler,
                            fieldIndex,
                            dataIndex: radioIndex,
                            optionName: "radioArrays",
                            arrayKey: "value",
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <input
                          type="number"
                          placeholder="score"
                          value={data.score}
                          onChange={(e) =>
                            changeHandler(
                              e,
                              fieldIndex,
                              radioIndex,
                              "radioArrays",
                              "score"
                            )
                          }
                          style={{
                            width: "-webkit-fill-available",
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {radioIndex > 1 && (
                    <Col
                      md={1}
                      className="px-0"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="link"
                        role="button"
                        style={{
                          textDecoration: "none",

                          color: "red",
                        }}
                        className="p-0"
                        title="Delete Field"
                        onClick={() =>
                          removeOptions(fieldIndex, radioIndex, "radioArrays")
                        }
                      >
                        X
                      </Button>
                    </Col>
                  )}
                </Row>
              </FormGroup>
            </Col>
          );
        })}
        {radioArrays.length < 5 && (
          <Col className="pl-3  ">
            <Button
              className="rounded my-3 d-flex justify-content-between align-items-center "
              style={{ height: "2.1rem" }}
              color="primary"
              onClick={() => addMoreOptions(fieldIndex, "radioArrays")}
            >
              <i className="fa fa-plus-circle mr-2" aria-hidden="true" />
            </Button>
          </Col>

        )}
      </Row>
    </FormGroup>
  );
}

export default memo(RadioInput);
