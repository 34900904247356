import axios from "axios";
import {
  CONTACTS_LIST_REQUEST,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_LIST_FAIL,
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_FAIL,
} from "../constants/contactConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const contactList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACTS_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Contact/GetAllContact`,
      config
    );

    dispatch({
      type: CONTACTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTS_LIST_FAIL,
      payload: true,
    });
  }
};
export const getSearchResults = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/SiteSearch/GetAllSiteSearch`,
      config
    );

    dispatch({
      type: SEARCH_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_LIST_FAIL,
      payload: true,
    });
  }
};
