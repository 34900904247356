import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSpeakerById } from "../../actions/eventActions";
import Breadcrumbs from "../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../components/Branch/Meta/Meta";
import Edit from "../../components/Main/Speaker/Edit/Edit";

const SpeakerEditForm = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const speakerSingleReducer = useSelector((state) => state.singleSpeaker);
  const { singleSpeakerLoading, singleSpeaker } = speakerSingleReducer;

  useEffect(() => {
    dispatch(getSpeakerById(id));
  }, [dispatch]);

  return (
    <div>
      <Meta title="Edit | Speaker | Eduberance" />
      {!singleSpeakerLoading && (
        <Breadcrumbs
          url={`/admin/speakers/edit/${singleSpeaker?.speakerName}`}
        />
      )}
      <Edit />
    </div>
  );
};

export default SpeakerEditForm;
