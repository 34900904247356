import axios from "axios";
import { toast } from "react-toastify";
import { getUserDetails } from "../helper/getUserDetail";
import {
  COUNSELLOR_ADD_FAIL,
  COUNSELLOR_ADD_REQUEST,
  COUNSELLOR_ADD_SUCCESS,
} from "../constants/counsellorConstants";
import {
  STUDENT_LIST_FAIL,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
} from "../constants/userConstants";

export const addCounsellor = (addInfo) => async (dispatch, getState) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COUNSELLOR_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/Authenticate/RegisterCounsellor`,
      addInfo,
      config
    );
    toast.dismiss();

    toast.success("Counsellor created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COUNSELLOR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    if (error?.response?.data) {
      toast.warning(error?.response?.data.message, {
        position: toast.POSITION.RIGHT_CENTER,
      });
    } else {
      toast.warning("Sorry! something went wrong!", {
        position: toast.POSITION.RIGHT_CENTER,
      });
    }

    dispatch({
      type: COUNSELLOR_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const studentsList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDENT_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COUNSELLOR_STUDENT_BASE_URL}/api/Student/ByCreator`,
      config
    );

    dispatch({
      type: STUDENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
