export const getRandomCharacters = () => {
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  var characterLength = characters.length;

  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characterLength));
  }

  return result;
};

export const capitalizeWords = (string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};
