import {
  CAREERS_ACTIVE_FAIL,
  CAREERS_ACTIVE_REQUEST,
  CAREERS_ACTIVE_SUCCESS,
  CAREERS_ADD_FAIL,
  CAREERS_ADD_REQUEST,
  CAREERS_ADD_SUCCESS,
  CAREERS_CATEGORY_LIST_FAIL,
  CAREERS_CATEGORY_LIST_REQUEST,
  CAREERS_CATEGORY_LIST_SUCCESS,
  CAREERS_DATA_POINT_FAIL,
  CAREERS_DATA_POINT_REQUEST,
  CAREERS_DATA_POINT_SUCCESS,
  CAREERS_EDIT_FAIL,
  CAREERS_EDIT_REQUEST,
  CAREERS_EDIT_SUCCESS,
  CAREERS_LIST_FAIL,
  CAREERS_LIST_REQUEST,
  CAREERS_LIST_SUCCESS,
  CAREERS_SINGLE_FAIL,
  CAREERS_SINGLE_REQUEST,
  CAREERS_SINGLE_SUCCESS,
} from "../constants/careerConstants";

const careerLoginState = {
  careerLoading: false,
  careers: null,
  careerError: false,
};
const careerDataPointState = {
  careerDataPointLoading: false,
  careerDatePointList: null,
  careerDataPointError: false,
};

const careerTypeState = {
  careerTypeLoading: false,
  careerType: null,
  careerTypeError: false,
};

const careerAddState = {
  careerAddLoading: false,
  careerAdded: null,
  careerAddError: false,
};

const careerEditState = {
  careerEditLoading: false,
  careerEdit: null,
  careerEditError: false,
};

const singleCareerState = {
  singleCareerLoading: false,
  singleCareer: null,
  singleCareerError: false,
};

const careerActiveState = {
  careerActiveLoading: false,
  careerActiveStatus: null,
  careerActiveError: false,
};

export const careerReducer = (state = careerLoginState, action) => {
  switch (action.type) {
    case CAREERS_LIST_REQUEST:
      return {
        careerLoading: true,
        careers: null,
        careerError: false,
      };
    case CAREERS_LIST_SUCCESS:
      return {
        careerLoading: false,
        careers: action.payload,
        careerError: false,
      };
    case CAREERS_LIST_FAIL:
      return {
        careerLoading: false,
        careerError: action.payload,
        careers: null,
      };

    default:
      return state;
  }
};
export const careerDataPointReducer = (
  state = careerDataPointState,
  action
) => {
  switch (action.type) {
    case CAREERS_DATA_POINT_REQUEST:
      return {
        careerDataPointLoading: true,
        careerDatePointList: null,
        careerDataPointError: false,
      };
    case CAREERS_DATA_POINT_SUCCESS:
      return {
        careerDataPointLoading: false,
        careerDatePointList: action.payload,
        careerDataPointError: false,
      };
    case CAREERS_DATA_POINT_FAIL:
      return {
        careerDataPointLoading: false,
        careerDataPointError: action.payload,
        careerDatePointList: null,
      };

    default:
      return state;
  }
};

export const careerCategoryReducer = (state = careerTypeState, action) => {
  switch (action.type) {
    case CAREERS_CATEGORY_LIST_REQUEST:
      return {
        careerTypeLoading: true,
        careerType: null,
        careerTypeError: false,
      };
    case CAREERS_CATEGORY_LIST_SUCCESS:
      return {
        careerTypeLoading: false,
        careerType: action.payload,
        careerTypeError: false,
      };
    case CAREERS_CATEGORY_LIST_FAIL:
      return {
        careerTypeLoading: false,
        careerTypeError: action.payload,
        careerType: null,
      };

    default:
      return state;
  }
};

export const careerAddReducer = (state = careerAddState, action) => {
  switch (action.type) {
    case CAREERS_ADD_REQUEST:
      return {
        careerAddLoading: true,
        careerAdded: null,
        careerAddError: false,
      };
    case CAREERS_ADD_SUCCESS:
      return {
        careerAddLoading: false,
        careerAdded: action.payload,
        careerAddError: false,
      };
    case CAREERS_ADD_FAIL:
      return {
        careerAddLoading: false,
        careerAddError: action.payload,
        careerAdded: null,
      };

    default:
      return state;
  }
};

export const careerEditReducer = (state = careerEditState, action) => {
  switch (action.type) {
    case CAREERS_EDIT_REQUEST:
      return {
        careerEditLoading: true,
        careerEdit: null,
        careerEditError: false,
      };
    case CAREERS_EDIT_SUCCESS:
      return {
        careerEditLoading: false,
        careerEdit: action.payload,
        careerEditError: false,
      };
    case CAREERS_EDIT_FAIL:
      return {
        careerEditLoading: false,
        careerEditError: action.payload,
        careerEdit: null,
      };

    default:
      return state;
  }
};

export const singleCareerReducer = (state = singleCareerState, action) => {
  switch (action.type) {
    case CAREERS_SINGLE_REQUEST:
      return {
        singleCareerLoading: true,
        singleCareer: null,
        singleCareerError: false,
      };
    case CAREERS_SINGLE_SUCCESS:
      return {
        singleCareerLoading: false,
        singleCareer: action.payload,
        singleCareerError: false,
      };
    case CAREERS_SINGLE_FAIL:
      return {
        singleCareerLoading: false,
        singleCareerError: action.payload,
        singleCareer: null,
      };

    default:
      return state;
  }
};

export const careerActiveToggleReducer = (
  state = careerActiveState,
  action
) => {
  switch (action.type) {
    case CAREERS_ACTIVE_REQUEST:
      return {
        careerActiveLoading: true,
        careerActiveStatus: null,
        careerActiveError: false,
      };
    case CAREERS_ACTIVE_SUCCESS:
      return {
        careerActiveLoading: false,
        careerActiveStatus: action.payload,
        careerActiveError: false,
      };
    case CAREERS_ACTIVE_FAIL:
      return {
        careerActiveLoading: false,
        careerActiveError: action.payload,
        careerActiveStatus: null,
      };

    default:
      return state;
  }
};
