import axios from "axios";
import { toast } from "react-toastify";
import {
  BLOG_ACTIVE_FAIL,
  BLOG_ACTIVE_REQUEST,
  BLOG_ACTIVE_SUCCESS,
  BLOG_ADD_FAIL,
  BLOG_ADD_REQUEST,
  BLOG_ADD_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_SINGLE_FAIL,
  BLOG_SINGLE_REQUEST,
  BLOG_SINGLE_SUCCESS,
} from "../constants/blogConstants";

import { getUserDetails } from "../helper/getUserDetail";

export const listBlog = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BLOG_BASE_URL}/api/Blog/AllBlog`,
      config
    );

    dispatch({
      type: BLOG_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const getBlogById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BLOG_BASE_URL}/api/Blog/GetBlogByID?id=${id}`,
      config
    );

    dispatch({
      type: BLOG_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_SINGLE_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addBlog = (addInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BLOG_BASE_URL}/api/Blog/Create`,
      addInfo,
      config
    );

    toast.dismiss();
    toast.success("Blog created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: BLOG_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const editBlog = (editInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_BLOG_BASE_URL}/api/Blog/Update`,
      editInfo,
      config
    );

    toast.dismiss();

    toast.success("Blog edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_EDIT_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const blogActiveToggle = (blogActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: BLOG_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_BLOG_BASE_URL}/api/Blog/UpdateActiveInactive`,
      blogActiveInfo,
      config
    );

    toast.success(
      `Page ${
        blogActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: BLOG_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: BLOG_ACTIVE_FAIL,
      payload: true,
    });
  }
};
