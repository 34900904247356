import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import UserTable from "../../../pages/tables/components/UserTable/UserTable";

const Users = () => {
  return (
    <div>
      <Meta title="Students | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <UserTable tableName="User List" />
        </Col>
      </Row>
    </div>
  );
};

export default Users;
