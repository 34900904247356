import {
  COMPANY_ADD_REQUEST,
  COMPANY_ADD_SUCCESS,
  COMPANY_ADD_FAIL,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_EDIT_REQUEST,
  COMPANY_EDIT_SUCCESS,
  COMPANY_EDIT_FAIL,
  COMPANY_SINGLE_REQUEST,
  COMPANY_SINGLE_SUCCESS,
  COMPANY_SINGLE_FAIL,
} from "../constants/companyConstants";

const companyListState = {
  companyListLoading: false,
  companyList: null,
  companyListFail: false,
};

export const companyListReducer = (state = companyListState, action) => {
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
      return {
        companyListLoading: true,
        companyList: null,
        companyListFail: false,
      };
    case COMPANY_LIST_SUCCESS:
      return {
        companyListLoading: false,
        companyList: action.payload,
        companyListFail: false,
      };
    case COMPANY_LIST_FAIL:
      return {
        companyListLoading: false,
        companyListFail: action.payload,
        companyList: null,
      };
    default:
      return state;
  }
};
const singleCompanyState = {
  singleCompanyLoading: false,
  singleCompany: null,
  singleCompanyFail: false,
};

export const singleCompanyReducer = (state = singleCompanyState, action) => {
  switch (action.type) {
    case COMPANY_SINGLE_REQUEST:
      return {
        singleCompanyLoading: true,
        singleCompany: null,
        singleCompanyFail: false,
      };
    case COMPANY_SINGLE_SUCCESS:
      return {
        singleCompanyLoading: false,
        singleCompany: action.payload,
        singleCompanyFail: false,
      };
    case COMPANY_SINGLE_FAIL:
      return {
        singleCompanyLoading: false,
        singleCompanyFail: action.payload,
        singleCompany: null,
      };

    default:
      return state;
  }
};

const companyEditState = {
  companyEditLoading: false,
  companyEdited: null,
  companyEditFail: false,
};

export const editCompanyReducer = (state = companyEditState, action) => {
  switch (action.type) {
    case COMPANY_EDIT_REQUEST:
      return {
        companyEditLoading: true,
        companyEdited: null,
        companyEditFail: false,
      };
    case COMPANY_EDIT_SUCCESS:
      return {
        companyEditLoading: false,
        companyEdited: action.payload,
        companyEditFail: false,
      };
    case COMPANY_EDIT_FAIL:
      return {
        companyEditLoading: false,
        companyEditFail: action.payload,
        companyEdited: null,
      };

    default:
      return state;
  }
};

const companyAddState = {
  companyAddLoading: false,
  companyAdded: null,
  companyAddFail: false,
};

export const addCompanyReducer = (state = companyAddState, action) => {
  switch (action.type) {
    case COMPANY_ADD_REQUEST:
      return {
        companyAddLoading: true,
        companyAdded: null,
        companyAddFail: false,
      };
    case COMPANY_ADD_SUCCESS:
      return {
        companyAddLoading: false,
        companyAdded: action.payload,
        companyAddFail: false,
      };
    case COMPANY_ADD_FAIL:
      return {
        companyAddLoading: false,
        companyAddFail: action.payload,
        companyAdded: null,
      };

    default:
      return state;
  }
};
