import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  currencyList,
  getAllOnlineInstitute,
  getOnlineCourseById,
  getOnlineInstructorList,
  getOnlineIntCatList,
  getOnlineSubCategoryList,
  onlineCourseList,
  onlineIntCatList,
} from "../../../actions/onlineCourseActions";
import Breadcrumbs from "../../../components/Branch/Breadbrumbs/Breadcrumbs";
import Meta from "../../../components/Branch/Meta/Meta";
import Edit from "../../../components/Main/OnlineCourse/Edit/Edit";
import { ONLINE_COURSES_SINGLE_FAIL } from "../../../constants/onlineCourseConstants";
// import {  getEventInstructor } from "../../../actions/onlineCourseActions";

const OnlineCourseEdit = ({ history }) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const onlineCourseSingleReducer = useSelector(
    (state) => state.singleOnlineCourse
  );
  const {
    onlineSingleCourseLoading,
    singleOnlineCourse,
    singleOnlineCourseError,
  } = onlineCourseSingleReducer;

  useEffect(() => {
    dispatch(getOnlineCourseById(id));
    dispatch(currencyList());
    dispatch(onlineIntCatList());
    dispatch(onlineCourseList());
    dispatch(getOnlineSubCategoryList());
    dispatch(getAllOnlineInstitute());
    dispatch(getOnlineIntCatList());
    dispatch(getOnlineInstructorList());
    // dispatch(getEventInstructor());
  }, [dispatch]);

  useEffect(() => {
    if (singleOnlineCourseError) {
      history.push("/admin/online-courses");
      dispatch({
        type: ONLINE_COURSES_SINGLE_FAIL,
        payload: false,
      });
    }
  }, [singleOnlineCourseError]);

  return (
    <div>
      <Meta title="Edit | Online Course | Eduberance" />
      {!onlineSingleCourseLoading && (
        <Breadcrumbs
          url={`/admin/online-courses/edit/${singleOnlineCourse?.title}`}
        />
      )}
      <Edit />
    </div>
  );
};

export default OnlineCourseEdit;
