import {
  COUNSELLOR_ADD_FAIL,
  COUNSELLOR_ADD_REQUEST,
  COUNSELLOR_ADD_SUCCESS,
} from "../constants/counsellorConstants";
import {
  STUDENT_LIST_FAIL,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
} from "../constants/userConstants";

const counsellorAddState = {
  counsellorAddLoading: false,
  counsellorAdd: null,
  counsellorAddError: false,
};

export const counsellorAddReducer = (state = counsellorAddState, action) => {
  switch (action.type) {
    case COUNSELLOR_ADD_REQUEST:
      return {
        counsellorAddLoading: true,
        counsellorAdd: null,
        counsellorAddError: false,
      };
    case COUNSELLOR_ADD_SUCCESS:
      return {
        counsellorAddLoading: false,
        counsellorAdd: action.payload,
        counsellorAddError: false,
      };
    case COUNSELLOR_ADD_FAIL:
      return {
        counsellorAddLoading: false,
        counsellorAddError: action.payload,
        counsellorAdd: null,
      };

    default:
      return state;
  }
};

export const studentListReducer = (
  state = { loading: true, students: [], error: null },
  action
) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return { ...state, loading: true, students: [], error: null };
    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload,
        error: null,
      };
    case STUDENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
