import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Skeleton.scss";

export default class SkeletonLoader extends Component {
  render() {
    const { count } = this.props;
    return (
      <SkeletonTheme baseColor="#F7F8FB" highlightColor="#fff">
        <Skeleton count={count} height={40} width="100%" />
      </SkeletonTheme>
    );
  }
}
