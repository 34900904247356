import classnames from "classnames";
import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Widget from "../../../Branch/Widget/Widget";
import CreateForm from "./CreateForm";

function Create() {
  const [activeTab, setActive] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="p-5">
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Create Tutor
                    </NavLink>
                  </NavItem>
                  {/* Remove NavItems for other roles */}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <CreateForm />
                  </TabPane>
                  {/* Remove TabPanes for other roles */}
                </TabContent>
              </div>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
