import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addSpeaker } from "../../../../actions/eventActions";
import SocialLink from "../../../../UI/Input/SocialLink/SocialLink";
import Widget from "../../../Branch/Widget/Widget";
import CreateForm from "./CreateForm";

var todayDate = new Date();

function Create({ history }) {
  const [speaker, setSpeaker] = useState({
    speakerName: "",
    designation: "",
    linkedInProfile: "",
    instaprofile: "",
    twitterProfile: "",
    fbProfile: "",
    createdBy: null,
    createdDate: todayDate,
  });

  const [speakerDescription, setSpeakerDescription] = useState("");
  const [activeTab, setActive] = useState("1");
  const [speakerImagePath, setSpeakerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };
  const speakerAddReducer = useSelector((state) => state.speakerAdd);
  const { speakerAddLoading } = speakerAddReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setSpeaker({ ...speaker, [event.target.name]: event.target.value });
  };

  const createSpeaker = (e) => {
    e.preventDefault();
    const speakerInfo = {
      speakerName: speaker.speakerName,
      designation: speaker.designation,
      speakerDescription: speakerDescription,
      linkedInProfile: speaker.linkedInProfile,
      twitterProfile: speaker.twitterProfile,
      fbProfile: speaker.fbProfile,
      instaprofile: speaker.instaprofile,
      speakerImagePath: speakerImagePath?.myFile,
      IsNewImage: speakerImagePath?.IsNewImage,
      ImageName: speakerImagePath?.ImageName,
      ImageType: speakerImagePath?.ImageType,
      createdBy: null,
      createdDate: todayDate,
    };

    dispatch(
      addSpeaker(speakerInfo, () => {
        history.push(`/admin/speakers`);
      })
    );
    setSpeakerImagePath({
      ...speakerImagePath,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form className="p-5" onSubmit={(event) => createSpeaker(event)}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Social Links
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <CreateForm
                      speaker={speaker}
                      setSpeaker={setSpeaker}
                      speakerImagePath={speakerImagePath}
                      setSpeakerImagePath={setSpeakerImagePath}
                      changeCreds={changeCreds}
                      speakerDescription={speakerDescription}
                      setSpeakerDescription={setSpeakerDescription}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <SocialLink
                      speaker={speaker}
                      changeCreds={changeCreds}
                      email={false}
                      website={false}
                    />
                  </TabPane>
                </TabContent>

                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={speakerAddLoading}
                      >
                        {speakerAddLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: speakerAddLoading,
                              invisible: !speakerAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
