export const PAGE_ADD_REQUEST = "PAGE_ADD_REQUEST";
export const PAGE_ADD_SUCCESS = "PAGE_ADD_SUCCESS";
export const PAGE_ADD_FAIL = "PAGE_ADD_FAIL";

export const PAGE_LIST_REQUEST = "PAGE_LIST_REQUEST";
export const PAGE_LIST_SUCCESS = "PAGE_LIST_SUCCESS";
export const PAGE_LIST_FAIL = "PAGE_LIST_FAIL";

export const PAGE_EDIT_REQUEST = "PAGE_EDIT_REQUEST";
export const PAGE_EDIT_SUCCESS = "PAGE_EDIT_SUCCESS";
export const PAGE_EDIT_FAIL = "PAGE_EDIT_FAIL";

export const PAGE_SINGLE_REQUEST = "PAGE_SINGLE_REQUEST";
export const PAGE_SINGLE_SUCCESS = "PAGE_SINGLE_SUCCESS";
export const PAGE_SINGLE_FAIL = "PAGE_SINGLE_FAIL";

export const PAGE_ACTIVE_REQUEST = "PAGE_ACTIVE_REQUEST";
export const PAGE_ACTIVE_SUCCESS = "PAGE_ACTIVE_SUCCESS";
export const PAGE_ACTIVE_FAIL = "PAGE_ACTIVE_FAIL";
