import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getALLInstructor } from "../../actions/onlineCourseActions";
import Meta from "../../components/Branch/Meta/Meta";
import InstructorTable from "../tables/components/InstructorTable/InstructorTable";
// import InstructorTable from "../tables/components/InstructorTable/InstructorTable";

const Instructor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getALLInstructor());
  }, [dispatch]);

  return (
    <Row>
      <Meta title="Instructors | Eduberance" />
      <Col className="pr-grid-col" xs={12} lg={12}>
        <InstructorTable tableName="Instructor List" />
      </Col>
    </Row>
  );
};
export default Instructor;
