import axios from "axios";
import { toast } from "react-toastify";
import {
  PAGE_ADD_REQUEST,
  PAGE_ADD_SUCCESS,
  PAGE_ADD_FAIL,
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
  PAGE_LIST_FAIL,
  PAGE_EDIT_REQUEST,
  PAGE_EDIT_SUCCESS,
  PAGE_EDIT_FAIL,
  PAGE_SINGLE_REQUEST,
  PAGE_SINGLE_SUCCESS,
  PAGE_SINGLE_FAIL,
  PAGE_ACTIVE_REQUEST,
  PAGE_ACTIVE_SUCCESS,
  PAGE_ACTIVE_FAIL,
} from "../constants/pageConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const listPage = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAGE_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Page/AllPage`,
      config
    );

    dispatch({
      type: PAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAGE_LIST_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const getPageById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PAGE_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Page/GetPageByID?id=${id}`,
      config
    );

    dispatch({
      type: PAGE_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAGE_SINGLE_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const addPage = (addInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: PAGE_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Page/Create`,
      addInfo,
      config
    );

    toast.dismiss();
    toast.success("Page created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: PAGE_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: PAGE_ADD_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};

export const editPage = (editInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: PAGE_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Page/Update`,
      editInfo,
      config
    );

    toast.dismiss();
    toast.success("Page edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: PAGE_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.warning("Sorry! something went wrong!", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: PAGE_EDIT_FAIL,
      payload: "Sorry! something went wrong",
    });
  }
};
export const pageActiveToggle = (pageActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: PAGE_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_CONTACT_BASE_URL}/api/Page/UpdateActiveInactive`,
      pageActiveInfo,
      config
    );

    toast.success(
      `Page ${
        pageActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: PAGE_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: PAGE_ACTIVE_FAIL,
      payload: true,
    });
  }
};
