import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

const LearningObjForm = (props) => {
  const [learningObjDetails, setLearningObjDetails] = useState([]);

  useEffect(() => {
    setLearningObjDetails(props.learningObj.learningObjectiveDetails);
  }, [props.learningObj, learningObjDetails]);

  return (
    <>
      {learningObjDetails &&
        learningObjDetails?.map((val, idx) => {
          let learnObjectiveName = `name-${idx}`;

          return (
            <Row className="mt-4" key={idx}>
              <Col className="mb-2 mb-sm-3 mb-md-0" md={12}>
                <FormGroup>
                  <Label for="learnObjectiveName">Name</Label>
                  <Input
                    maxLength={200}
                    type="text"
                    name="learnObjectiveName"
                    data-id={idx}
                    id={learnObjectiveName}
                    defaultValue={val.learnObjectiveName}
                    autoComplete="nothing"
                    rows="2"
                    cols="50"
                  />
                </FormGroup>
              </Col>
              <Col className="d-flex">
                {idx + 1 === learningObjDetails?.length && (
                  <Button
                    className="rounded-pill my-3 d-flex justify-content-between align-items-center mr-2"
                    style={{ height: "2.1rem" }}
                    color="primary"
                    onClick={() => props.add()}
                  >
                    <i className="fa fa-plus-circle mr-2" aria-hidden="true" />
                  </Button>
                )}

                {idx !== 0 && (
                  <>
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-between align-items-center"
                      style={{ height: "2.1rem" }}
                      color="danger"
                      onClick={() => props.delete(val)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          );
        })}
    </>
  );
};
export default LearningObjForm;
