import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import OnlineInstituteTable from "../../tables/components/OnlineInstituteTable/OnlineInstituteTable";

const OnlineInstitute = () => {
  return (
    <div>
      <Meta title="Online Institutes | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <OnlineInstituteTable tableName="Currency List" />
        </Col>
      </Row>
    </div>
  );
};

export default OnlineInstitute;
