import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { booleanStatus, status } from "../../../../data/institute/institute";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";

function EditForm({
  course,
  courseImagePath,
  setCourseImagePath,
  changeCreds,
  courseDescription,
  setCourseDescription,
  bannerImagePath,
  setBannerImagePath,
}) {
  const courseSingleReducer = useSelector((state) => state.courseSingle);

  const courseCategoryListReducer = useSelector(
    (state) => state.courseCategory
  );
  const { courseType } = courseCategoryListReducer;
  const { singleCourse } = courseSingleReducer;

  useEffect(() => {
    setCourseDescription(singleCourse?.courseDescription);
  }, [singleCourse]);

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseName">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.courseName}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="courseName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseTimeType">Course Time Type</Label>
          <Input
            maxLength={100}
            value={course.courseTimeType}
            onChange={changeCreds}
            type="text"
            name="courseTimeType"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseDuration">
            Course Duration <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.courseDuration}
            onChange={changeCreds}
            maxLength={10}
            required
            type="number"
            name="courseDuration"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseMode">Course Mode</Label>
          <Input
            value={course.courseMode}
            onChange={changeCreds}
            type="text"
            name="courseMode"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseCompletionAward">Completion Award</Label>
          <Input
            value={course.courseCompletionAward}
            onChange={changeCreds}
            type="text"
            name="courseCompletionAward"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseFee">
            Course Fee <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.courseFee}
            onChange={changeCreds}
            required
            type="number"
            name="courseFee"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseFeeLower">
            Lower Fee <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.courseFeeLower}
            onChange={changeCreds}
            type="number"
            required
            name="courseFeeLower"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="courseFeeHigher">
            Higher Fee <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.courseFeeHigher}
            onChange={changeCreds}
            type="number"
            required
            name="courseFeeHigher"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
        <FormGroup>
          <Label for="unit">
            Unit <span className="text-danger">*</span>
          </Label>
          <Input
            value={course.unit}
            onChange={changeCreds}
            type="number"
            required
            name="unit"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="type">
            Type <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="courseTypeID"
            id="type"
            required
            value={course.courseTypeID}
            onChange={changeCreds}
          >
            {courseType?.map((item, id) => (
              <option key={id} value={item.id}>
                {item?.courseType}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="status">
            Status <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="status"
            id="status"
            required
            value={course.status}
            onChange={changeCreds}
          >
            {status.map((item, id) => (
              <option key={id} value={item.id}>
                {item.id}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="isFeatured">
            Featured <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isFeatured"
            id="isFeatured"
            required
            value={course.isFeatured}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
        <FormGroup>
          <Label for="inception year">
            Active <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="isActive"
            id="isActive"
            required
            value={course.isActive}
            onChange={changeCreds}
          >
            {booleanStatus.map((item, index) => (
              <option value={item.status} key={index}>
                {item.title}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="courseShortDescription">Short Description</Label>
          <Input
            value={course.courseShortDescription}
            onChange={changeCreds}
            maxLength={500}
            type="textarea"
            name="courseShortDescription"
            autoComplete="nope"
            rows="4"
            cols="50"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
        <FormGroup>
          <Label for="instituteDescription">Description</Label>
          <CkEditor
            value={courseDescription}
            setDescription={setCourseDescription}
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={courseImagePath}
          setPostImage={setCourseImagePath}
        />
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={bannerImagePath}
          setPostImage={setBannerImagePath}
          imgTitle="Browse a cover image"
          max={400}
        />
      </Col>
    </Row>
  );
}

export default EditForm;
