import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import {
  Col,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  approveOnlineCourse,
  getAllRegisterStudents,
  onlineCourseList,
  onlineCourseToggle,
} from "../../../../actions/onlineCourseActions";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import searchIcon from "../../../../assets/tables/searchIcon.svg";
import Widget from "../../../../components/Branch/Widget/Widget";
import CustomModal from "../../../../UI/Modal/Modal";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import s from "../../Tables.module.scss";
import SearchInput from "../../../../helper/SearchInput";

var todayDate = new Date();

const StudentDiv = ({ studentData, loading }) => {
  return (
    <>
      {studentData && studentData?.length > 0 ? (
        <>
          <Table
            className={`table-striped table-borderless table-hover ${s.statesTable}`}
            responsive
          >
            {loading ? (
              <Skeleton count={5} />
            ) : (
              <>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {studentData !== null &&
                    studentData.map((item, index) => (
                      <tr key={uuidv4()}>
                        <td>{item.studentName}</td>
                        <td>{item.emailAddress}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </Table>
        </>
      ) : (
        <p class="headline-4 p-2">No user found</p>
      )}
    </>
  );
};

const OnlineCourseTable = function ({ tableName }) {
  const [onlineCourseTableList, SetOnlineCourseTableList] = useState(null);
  const [open, setOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const onlineCourseListReducer = useSelector(
    (state) => state.onlineCourseList
  );
  const { onlineCourseListLoading, allOnlineCourse } = onlineCourseListReducer;

  const registerStudentsRes = useSelector((state) => state.registerStudents);
  const { registerStudents, registerStudentsLoading } = registerStudentsRes;

  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    dispatch(onlineCourseList());
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (allOnlineCourse !== null) {
      SetOnlineCourseTableList(allOnlineCourse);
    }
  }, [onlineCourseListLoading, allOnlineCourse]);

  useEffect(() => {
    if (onlineCourseTableList !== null) {
      const firstTablePagesCounts = Math.ceil(
        onlineCourseTableList.length / pageSize
      );
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [onlineCourseTableList]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const onActive = (status, id) => {
    const tempList = [...onlineCourseTableList];
    tempList.map((item) => item.id === id && (item.isActive = !status));
    const foundCourse = tempList.find((element) => element.id === id);
    const selectedCrouse = {
      id: foundCourse.id,
      IsActive: foundCourse.isActive,
      ModifyBy: "",
      ModifiedDate: todayDate,
    };
    dispatch(onlineCourseToggle(selectedCrouse));
    SetOnlineCourseTableList(tempList);
  };

  const onModalToggle = (id, isUser) => {
    setOpen(!open);
    if (id) {
      dispatch(getAllRegisterStudents(id));
    }
  };
  const onApproveToggle = (id, isUser) => {
    setIsApprove(!isApprove);
  };

  const onApprove = () => {
    const info = {
      OnlineCourseID: approveId,
      IsApproved: true,
    };
    dispatch(
      approveOnlineCourse(info, () => {
        dispatch(onlineCourseList());
        setIsApprove(false);
      })
    );
  };
  const openApprove = (id) => {
    setApproveId(id);
    setIsApprove(!isApprove);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  return (
    <div>
      <CustomModal
        modal={open}
        className="modal-shrink"
        modalTitle="Registered Students"
        toggle={onModalToggle}
      >
        {registerStudentsLoading ? (
          "Please wait..."
        ) : (
          <StudentDiv
            studentData={registerStudents}
            loading={registerStudentsLoading}
          />
        )}
      </CustomModal>
      <CustomModal
        modal={isApprove}
        className="modal-shrink"
        modalTitle="Approve Course"
        isDoSomething={isApprove}
        onModalAction={onApprove}
        isDisabled={false}
        toggle={onApproveToggle}
      >
        <div>Are you sure you want to approve the course ?</div>
      </CustomModal>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchText}
                      handleSearch={handleSearch}
                      placeholder="Search Online Course"
                    />
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={cloudIcon}
                        alt="Cloud"
                      />
                    </a>
                    <a href="/#">
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={optionsIcon}
                        alt="Options"
                      />
                    </a>
                    <a href="/#">
                      <img src={funnelIcon} alt="Funnel" />
                    </a>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    {onlineCourseListLoading ? (
                      <Skeleton count={pageSize} />
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th className={s.checkboxCol}>
                              <div className="checkbox checkbox-primary">
                                <input
                                  className="styled"
                                  id="checkbox100"
                                  type="checkbox"
                                />
                                <label htmlFor="checkbox100" />
                              </div>
                            </th>
                            {/* <th className="w-25">Role</th> */}
                            <th className="w-25">Title</th>
                            <th className="w-15">Students</th>
                            <th className="w-25">Category Name</th>
                            <th className="w-15">Price</th>
                            <th className="w-25">Active</th>
                            {/* <th className="w-25">STATE</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {onlineCourseTableList !== null &&
                            onlineCourseTableList
                              ?.filter((item) =>
                                Object.values(item).some(
                                  (value) =>
                                    typeof value === "string" &&
                                    value
                                      .toLowerCase()
                                      .includes(searchText.toLowerCase())
                                )
                              )
                              ?.slice(
                                firstTableCurrentPage * pageSize,
                                (firstTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="checkbox checkbox-primary">
                                      <input
                                        id={item.id}
                                        className="styled"
                                        type="checkbox"
                                      />
                                      <Label for={item.id} />
                                    </div>
                                  </td>

                                  <td>{item.title}</td>
                                  <td
                                    onClick={() => onModalToggle(item.id)}
                                    className="cursor-pointer"
                                  >
                                    <Link to="#" className="table-action">
                                      {item?.noOfStudent}
                                    </Link>
                                  </td>
                                  <td>{item.courseCategoryName}</td>
                                  <td>{item.price}</td>
                                  <td>
                                    <ToggleButton
                                      value={item.isActive}
                                      onToggle={(e) => onActive(e, item.id)}
                                    />
                                  </td>

                                  <td className="d-flex  px-2 py-0 align-items-center h-100">
                                    {currentUser?.role === "superadmin" &&
                                      !item.isApproved && (
                                        <div>
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center justify-content-center table-action mr-2"
                                            onClick={() => openApprove(item.id)}
                                          >
                                            <AiOutlineCheckCircle size={25} />
                                          </Link>
                                        </div>
                                      )}

                                    <Link
                                      to={`/admin/online-courses/edit/${item.id}`}
                                      className="d-flex align-items-center justify-content-center table-action"
                                    >
                                      <i className="eva eva-edit mr-1"></i>
                                    </Link>
                                    <a
                                      href={`https://www.eduberance.com/online-courses/${item.slug}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="table-action"
                                    >
                                      <i className="eva eva-eye"></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                  {onlineCourseTableList !== null && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={firstTableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(firstTablePagesCount)].map((page, i) => (
                        <PaginationItem
                          active={i === firstTableCurrentPage}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => setFirstTablePage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={
                          firstTableCurrentPage >= firstTablePagesCount - 1
                        }
                      >
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OnlineCourseTable;
