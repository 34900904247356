import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { editTutor, listTutors } from "../../../../actions/tutorAction";

const EditForm = () => {
  const { id } = useParams();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const tutorDetails = useSelector((state) => state.tutor);
  const { tutorListLoading, tutorListError, tutorList } = tutorDetails;

  const tutorUpdate = useSelector((state) => state.tutor);
  const { tutorEditLoading, tutorEditError, tutorEditSuccess } = tutorUpdate;

  useEffect(() => {
    dispatch(listTutors());
  }, [dispatch]);

  useEffect(() => {
    if (tutorList && !tutorListLoading) {
      const tutor = tutorList.find((org) => org.id === id);
      if (tutor) {
        setEmail(tutor.email);
        setUsername(tutor.userName);
      }
    }
  }, [tutorList, tutorListLoading, id]);

  useEffect(() => {
    if (tutorEditSuccess) {
      history.push("/admin/tutors");
    }
  }, [tutorEditSuccess, history]);

  const validate = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!username) newErrors.username = "Username is required.";
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      newErrors.password =
        "Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const updatedTutor = {
        id,
        email,
        username,
        password,
      };
      dispatch(editTutor(updatedTutor));
    }
  };

  return (
    <div>
      {tutorListLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner color="primary" />
        </div>
      ) : tutorListError ? (
        <Alert color="danger">{tutorListError}</Alert>
      ) : (
        <form onSubmit={handleSubmit}>
          <Row className="gutter mt-4" md={12} lg={12}>
            <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
              <FormGroup>
                <Label for="email">
                  Email <span className="text-danger">*</span>
                </Label>
                <Input
                  type="email"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="nope"
                  invalid={!!errors.email}
                />
                {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
              </FormGroup>
            </Col>

            <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
              <FormGroup>
                <Label for="username">
                  Username <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  required
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="nope"
                  invalid={!!errors.username}
                />
                {errors.username && (
                  <FormFeedback>{errors.username}</FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={3}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="nope"
                  invalid={!!errors.password}
                />
                {errors.password && (
                  <FormFeedback>{errors.password}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          {tutorEditLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner color="primary" />
            </div>
          ) : tutorEditError ? (
            <Alert color="danger">{tutorEditError}</Alert>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="bg-widget d-flex justify-content-end">
                  <Button
                    className="bg-widget rounded-pill d-flex justify-content-center align-items-center w-25"
                    type="submit"
                    color="secondary-red"
                    disabled={tutorEditLoading}
                  >
                    {tutorEditLoading && (
                      <Spinner
                        className={classNames({
                          "position-relative": true,
                          visible: tutorEditLoading,
                          invisible: !tutorEditLoading,
                        })}
                        size="sm"
                      />
                    )}
                    <span className="ml-1">Update Tutor</span>
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </form>
      )}
    </div>
  );
};

export default EditForm;
