import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { editorConfig } from "../../../../UI/Input/CkEditor/CkEditor";

const CareerDataPoint = (props) => {
  const [dataPointDetails, setDataPointDetails] = useState([]);

  useEffect(() => {
    setDataPointDetails(props.dataPointDetails.dataPointDetails);
  }, [props.dataPointDetails, dataPointDetails]);

  return (
    <>
      {dataPointDetails?.map((val, idx) => {
        let question = `question-${idx}`;
        let answer = `answer-${idx}`;
        let dataPointID = `dataPointID-${idx}`;
        let selDataPointCat = `selDataPointCat-${idx}`;

        return (
          <>
            <Row className="mt-5" key={val.index}>
              <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
                <FormGroup>
                  <Label for="selDataPointCat">Category Name</Label>
                  <Input
                    type="select"
                    name="selDataPointCat"
                    id={selDataPointCat}
                    onChange={(e) => props.onCareerDataPointCat(e, val.index)}
                    defaultValue={val?.categoryName}
                    required
                    data-id={idx}
                  >
                    {!val.categoryName && <option selected>Select</option>}
                    {props.dataPointCategory &&
                      props?.dataPointCategory?.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>

              {val.categoryName && (
                <>
                  <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
                    <FormGroup>
                      <Label for="dataPointID">Data Point Name</Label>
                      <Input
                        type="select"
                        name="dataPointID"
                        id={dataPointID}
                        data-id={idx}
                        defaultValue={val.dataPointID}
                      >
                        {val.dataPoints &&
                          val?.dataPoints?.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.dataPointName}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </>
              )}

              {val.categoryName && (
                <>
                  <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
                    <FormGroup>
                      <Label for="question">Question</Label>
                      <Input
                        maxLength={255}
                        type="text"
                        name="question"
                        data-id={idx}
                        id={question}
                        autoComplete="nothing"
                        defaultValue={val.question}
                        rows="2"
                        cols="50"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="answer">Answer</Label>

                      <Editor
                        initialValue={val.answer}
                        cloudChannel="dev"
                        init={editorConfig}
                        apiKey={process.env.REACT_APP_EDITOR_KEY}
                        onChange={(e) => props.onTinyEditorChange(e, idx)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex">
                    {idx + 1 === dataPointDetails?.length && (
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-between align-items-center mr-2"
                        style={{ height: "2.1rem" }}
                        color="primary"
                        onClick={() => props.add()}
                      >
                        <i
                          className="fa fa-plus-circle mr-2"
                          aria-hidden="true"
                        />
                      </Button>
                    )}

                    {idx !== 0 && (
                      <>
                        <Button
                          className="rounded-pill my-3 d-flex justify-content-between align-items-center"
                          style={{ height: "2.1rem" }}
                          color="danger"
                          onClick={() => props.delete(val)}
                        >
                          <i className="fa fa-minus" aria-hidden="true" />
                        </Button>
                      </>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </>
        );
      })}
    </>
  );
};
export default CareerDataPoint;
