import axios from "axios";
import { toast } from "react-toastify";
import {
  COURSES_ADD_FAIL,
  COURSES_ADD_REQUEST,
  COURSES_ADD_SUCCESS,
  COURSES_CATEGORY_LIST_FAIL,
  COURSES_CATEGORY_LIST_REQUEST,
  COURSES_CATEGORY_LIST_SUCCESS,
  COURSES_EDIT_FAIL,
  COURSES_EDIT_REQUEST,
  COURSES_EDIT_SUCCESS,
  COURSES_LIST_FAIL,
  COURSES_LIST_REQUEST,
  COURSES_LIST_SUCCESS,
  COURSES_SINGLE_FAIL,
  COURSES_SINGLE_REQUEST,
  COURSES_SINGLE_SUCCESS,
  COURSES_ACTIVE_REQUEST,
  COURSES_ACTIVE_SUCCESS,
  COURSES_ACTIVE_FAIL,
} from "../constants/courseConstants";
import { getUserDetails } from "../helper/getUserDetail";

export const courseList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COURSES_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/SuperAdmin/SuperAdminAllCourse`,
      config
    );

    dispatch({
      type: COURSES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSES_LIST_FAIL,
      payload: true,
    });
  }
};

export const addCourse = (courseInfo, useCallback) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COURSES_ADD_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/SuperAdmin/CreateCourse`,
      courseInfo,
      config
    );

    toast.dismiss();
    toast.success("Course created successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COURSES_ADD_SUCCESS,
      payload: data,
    });
    useCallback();
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COURSES_ADD_FAIL,
      payload: true,
    });
  }
};

export const courseCategoryList = () => async (dispatch) => {
  try {
    dispatch({
      type: COURSES_CATEGORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/CourseType/SuperAdminCourseType`,
      config
    );

    dispatch({
      type: COURSES_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSES_CATEGORY_LIST_FAIL,
      payload: true,
    });
  }
};

export const editCourse = (editCourseInfo) => async (dispatch) => {
  try {
    toast.success("Please wait...", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COURSES_EDIT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/SuperAdmin/UpdateCourse`,
      editCourseInfo,
      config
    );

    toast.dismiss();

    toast.success("Course edited successfully", {
      position: toast.POSITION.RIGHT_CENTER,
    });

    dispatch({
      type: COURSES_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.dismiss();
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COURSES_EDIT_FAIL,
      payload: true,
    });
  }
};

export const getCourseById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSES_SINGLE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/SuperAdmin/SuperAdminGetCourseByID?id=${id}`,
      config
    );

    dispatch({
      type: COURSES_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSES_SINGLE_FAIL,
      payload: true,
    });
  }
};

export const courseActiveToggle = (courseActiveInfo) => async (dispatch) => {
  try {
    toast.dismiss();
    dispatch({
      type: COURSES_ACTIVE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getUserDetails().token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_COURSE_BASE_URL}/api/SuperAdmin/UpdateActiveInactive`,
      courseActiveInfo,
      config
    );

    toast.success(
      `Course ${
        courseActiveInfo.IsActive ? "activated" : "deactivated"
      } successfully`,
      {
        position: toast.POSITION.RIGHT_CENTER,
      }
    );

    dispatch({
      type: COURSES_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Sorry! something went wrong.", {
      position: toast.POSITION.RIGHT_CENTER,
    });
    dispatch({
      type: COURSES_ACTIVE_FAIL,
      payload: true,
    });
  }
};
