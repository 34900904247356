import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addNewCompany } from "../../../actions/companyActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import { CompanyForm } from "./CompanyForm";

var todayDate = new Date();

function CompanyAdd({ history }) {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyType: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    website: "",
    emailId: "",
    phone1: "",
    companyInceptionYear: "",
    companyShortDescription: "",
    companyTagLine: "",
    createdBy: "",
    createdDate: todayDate,
  });

  const [companyDescription, setCompanyDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const [companyImagePath, setCompanyImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setCompanyInfo({
      ...companyInfo,
      [event.target.name]: event.target.value,
    });
  };

  const companyAddedReducer = useSelector((state) => state.addCompany);
  const { companyAddLoading } = companyAddedReducer;

  const onCompanyAdd = (e) => {
    const established = new Date(companyInfo.companyInceptionYear);
    e.preventDefault();
    const info = {
      companyName: companyInfo.companyName,
      companyType: companyInfo.companyType,
      companyDescription: companyDescription,
      address1: companyInfo.address1,
      address2: companyInfo.address2,
      city: companyInfo.city,
      state: companyInfo.state,
      country: companyInfo.country,
      pin: companyInfo.pin,
      website: companyInfo.website,
      emailId: companyInfo.emailId,
      phone1: companyInfo.phone1,
      companyInceptionYear: established,
      companyTagLine: companyInfo.companyTagLine,
      companyImagePath: companyImagePath.myFile,
      isNewImage: companyImagePath.IsNewImage,
      imageName: companyImagePath.ImageName,
      imageType: companyImagePath.ImageType,
      companyShortDescription: companyInfo.companyShortDescription,
      createdBy: "",
      createdDate: todayDate,
    };

    dispatch(
      addNewCompany(info, () => {
        history.push(`/admin/companies`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Add | Company | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onCompanyAdd(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <CompanyForm
                      companyInfo={companyInfo}
                      setCompanyInfo={setCompanyInfo}
                      companyImagePath={companyImagePath}
                      setCompanyImagePath={setCompanyImagePath}
                      changeCreds={changeCreds}
                      companyDescription={companyDescription}
                      setCompanyDescription={setCompanyDescription}
                    />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={companyAddLoading}
                    >
                      {companyAddLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: companyAddLoading,
                            invisible: !companyAddLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CompanyAdd;
