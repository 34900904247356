import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

const PrerequisiteForm = (props) => {
  const [prerequisiteDetails, setPrerequisiteDetails] = useState([]);

  useEffect(() => {
    setPrerequisiteDetails(props.prerequisiteObj.prerequisiteDetails);
  }, [props.prerequisiteObj, prerequisiteDetails]);

  return (
    <>
      {prerequisiteDetails &&
        prerequisiteDetails?.map((val, idx) => {
          let prerequisiteName = `prerequisiteName-${idx}`;

          return (
            <Row className="mt-4" key={idx}>
              <Col className="mb-1 mb-sm-3 mb-md-0" md={12}>
                <FormGroup>
                  <Label for="prerequisiteName">Name</Label>
                  <Input
                    maxLength={200}
                    type="text"
                    name="prerequisiteName"
                    data-id={idx}
                    id={prerequisiteName}
                    defaultValue={val.prerequisiteName}
                    autoComplete="nothing"
                    rows="2"
                    cols="50"
                  />
                </FormGroup>
              </Col>
              <Col className="d-flex">
                {idx + 1 === prerequisiteDetails?.length && (
                  <Button
                    className="rounded-pill my-3 d-flex justify-content-between align-items-center mr-2"
                    style={{ height: "2.1rem" }}
                    color="primary"
                    onClick={() => props.add()}
                  >
                    <i className="fa fa-plus-circle mr-2" aria-hidden="true" />
                  </Button>
                )}

                {idx !== 0 && (
                  <>
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-between align-items-center"
                      style={{ height: "2.1rem" }}
                      color="danger"
                      onClick={() => props.delete(val)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          );
        })}
    </>
  );
};
export default PrerequisiteForm;
