import React from "react";
import Meta from "../../components/Branch/Meta/Meta";
import Create from "../../components/Main/Speaker/Create/Create";

const SpeakerForm = ({ history }) => {
  return (
    <div>
      <Meta title="Add | Speaker | Eduberance" />
      <Create history={history} />
    </div>
  );
};

export default SpeakerForm;
