import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addInstructor } from "../../../../actions/onlineCourseActions";
import Widget from "../../../Branch/Widget/Widget";
import EditForm from "../Edit/EditForm";

var todayDate = new Date();

function Create({ history }) {
  const [instructor, setInstructor] = useState({
    name: "",
    email: "",
    createdBy: null,
    createdDate: todayDate,
  });

  const [activeTab, setActive] = useState("1");
  const [instructorImagePath, setInstructorImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };
  const instructorAddReducer = useSelector((state) => state.instructorAdd);
  const { instructorAddLoading } = instructorAddReducer;

  const dispatch = useDispatch();

  const changeCreds = (event) => {
    setInstructor({ ...instructor, [event.target.name]: event.target.value });
  };

  const createInstructor = (e) => {
    e.preventDefault();
    const instructorInfo = {
      name: instructor.name,
      email: instructor.email,
      password: "123456@Ab",
      createdBy: null,
      createdDate: todayDate,
    };

    dispatch(
      addInstructor(instructorInfo, () => {
        history.push(`/admin/instructor`);
      })
    );
    setInstructorImagePath({
      ...instructorImagePath,
      IsNewImage: false,
    });
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form
                className="p-5"
                onSubmit={(event) => createInstructor(event)}
              >
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <EditForm
                      instructor={instructor}
                      setInstructor={setInstructor}
                      changeCreds={changeCreds}
                      isEdited={false}
                      isCreated={true}
                    />
                  </TabPane>
                </TabContent>

                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={instructorAddLoading}
                      >
                        {instructorAddLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: instructorAddLoading,
                              invisible: !instructorAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Create;
