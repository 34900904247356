import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModal = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className={props.className}
        style={{ zIndex: 999 }}
        modalClassName="custom-mdl"
      >
        <ModalHeader toggle={() => props.toggle()}>
          {props.modalTitle}
        </ModalHeader>
        <ModalBody> {props.children}</ModalBody>

        <ModalFooter>
          {props.isDoSomething && (
            <Button
              color="primary"
              disabled={props.isDisabled}
              onClick={() => props.onModalAction()}
            >
              Confirm
            </Button>
          )}
          <Button color="secondary" onClick={() => props.toggle()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomModal;
