import React from "react";
import Meta from "../../../components/Branch/Meta/Meta";
import { Col, Row } from "reactstrap";
import OrganisationTable from "../../tables/components/OrganisationTable/OrganisationTable";

export default function Organisations() {
  return (
    <div>
      <Meta title="Organisations | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <OrganisationTable tableName="Organisations List" />
        </Col>
      </Row>
    </div>
  );
}
