import React, { useState } from "react";
import { CustomInput, FormGroup, Label } from "reactstrap";
import "./ImageUpload.scss";

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const formatBytes = (bytes) => {
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2);
};

const ImageUpload = ({
  postImage,
  setPostImage,
  isLoading = false,
  imgTitle = "Browse a file",
  max = 200,
}) => {
  const [imgSizeError, setImgSizeError] = useState(false);

  const handleFileUpload = async (e) => {
    setImgSizeError(false);
    const file = e.target.files[0];

    if (file) {
      const uploadedImgSize = formatBytes(file?.size);

      if (parseInt(uploadedImgSize) > max) {
        setImgSizeError(`Image size should not be greater than ${max}`);
      } else {
        const base64 = await convertToBase64(file);
        const replacedBase64 = base64?.split("base64,");

        setPostImage({
          ...postImage,
          myFile: replacedBase64[1],
          ImageType: file?.type,
          ImageName: file?.name,
          IsNewImage: true,
        });
      }
    }
  };

  return (
    <FormGroup>
      <Label for="exampleCustomFileBrowser">
        {imgTitle}
        <span className="text-danger">({`Max. ${max}kb`})</span>
      </Label>
      <CustomInput
        type="file"
        id="exampleCustomFileBrowser"
        name="customFile"
        label="Choose a file!"
        accept=".jpeg, .png, .jpg"
        onChange={(e) => handleFileUpload(e)}
      />
      {!isLoading && !imgSizeError && (
        <>
          <span className={`uploaded-img float-left mr-2`}>
            {postImage.myFile !== null && postImage.myFile?.length > 0 && (
              <span>
                <img
                  src={
                    postImage.ImageName.length > 0
                      ? "data:image/png;base64," + postImage.myFile
                      : postImage.myFile
                  }
                  alt="edu-img"
                  width={"100%"}
                />
              </span>
            )}
            {(postImage.myFile === null || postImage.myFile?.length === 0) && (
              <span className={`uploaded-img-placeholder rounded float-left mr-2`}>
                {/* <img
                    src={max > 200 ? coverDummyBg : cardDummyBg}
                    alt="edu-img"
                    width={"100%"}
                  /> */}
                <div>
                  <span> {max > 200 ? "1920 x 600" : "388 x 238"} </span>
                </div>
              </span>
            )}
          </span>
        </>
      )}

      {imgSizeError && <div className="text-danger mt-2">{imgSizeError}</div>}
    </FormGroup>
  );
};

export default ImageUpload;
