export const INSTITUTE_LIST_REQUEST = "INSTITUTE_LIST_REQUEST";
export const INSTITUTE_LIST_SUCCESS = "INSTITUTE_LIST_SUCCESS";
export const INSTITUTE_LIST_FAIL = "INSTITUTE_LIST_FAIL";

export const INSTITUTE_CATEGORY_LIST_REQUEST =
  "INSTITUTE_CATEGORY_LIST_REQUEST";
export const INSTITUTE_CATEGORY_LIST_SUCCESS =
  "INSTITUTE_CATEGORY_LIST_SUCCESS";
export const INSTITUTE_CATEGORY_LIST_FAIL = "INSTITUTE_CATEGORY_LIST_FAIL";

export const INSTITUTE_ADD_REQUEST = "INSTITUTE_ADD_REQUEST";
export const INSTITUTE_ADD_SUCCESS = "INSTITUTE_ADD_SUCCESS";
export const INSTITUTE_ADD_FAIL = "INSTITUTE_ADD_FAIL";

export const INSTITUTE_SINGLE_REQUEST = "INSTITUTE_SINGLE_REQUEST";
export const INSTITUTE_SINGLE_SUCCESS = "INSTITUTE_SINGLE_SUCCESS";
export const INSTITUTE_SINGLE_FAIL = "INSTITUTE_SINGLE_FAIL";

export const INSTITUTE_EDIT_REQUEST = "INSTITUTE_EDIT_REQUEST";
export const INSTITUTE_EDIT_SUCCESS = "INSTITUTE_EDIT_SUCCESS";
export const INSTITUTE_EDIT_FAIL = "INSTITUTE_EDIT_FAIL";

export const INSTITUTE_ACTIVE_REQUEST = "INSTITUTE_ACTIVE_REQUEST";
export const INSTITUTE_ACTIVE_SUCCESS = "INSTITUTE_ACTIVE_SUCCESS";
export const INSTITUTE_ACTIVE_FAIL = "INSTITUTE_ACTIVE_FAIL";

export const ONLINE_INT_CATEGORY_SINGLE_REQUEST =
  "ONLINE_INT_CATEGORY_SINGLE_REQUEST";
export const ONLINE_INT_CATEGORY_SINGLE_SUCCESS =
  "ONLINE_INT_CATEGORY_SINGLE_SUCCESS";
export const ONLINE_INT_CATEGORY_SINGLE_FAIL =
  "ONLINE_INST_CATEGORY_SINGLE_FAIL";
