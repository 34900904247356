import React, { useEffect, useState } from "react";
import { Button, Col, Progress, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import gymIcon from "../../assets/dashboard/gymIcon.svg";
import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";
import heartViolet from "../../assets/dashboard/heartViolet.svg";
import heartYellow from "../../assets/dashboard/heartYellow.svg";
import meal1 from "../../assets/dashboard/meal-1.svg";
import meal2 from "../../assets/dashboard/meal-2.svg";
import meal3 from "../../assets/dashboard/meal-3.svg";
import statsPie from "../../assets/dashboard/statsPie.svg";
import therapyIcon from "../../assets/dashboard/therapyIcon.svg";
import upgradeImage from "../../assets/dashboard/upgradeImage.svg";
import user from "../../assets/user.svg";
import Meta from "../../components/Branch/Meta/Meta.js";
import Widget from "../../components/Branch/Widget/Widget.js";
import { dropdownItems } from "../../data/dropdown/dropdown.js";
import Dropdown from "../../UI/Dropdown/Dropdown";
import ApexActivityChart from "./components/ActivityChart.js";
import s from "./Dashboard.module.scss";

const Dashboard = ({ history }) => {
  return (
    <h1>Working on dashboard</h1>
  )
};

export default Dashboard;
