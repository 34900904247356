import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import CourseTable from "../../../pages/tables/components/CourseTable/CourseTable";

const Courses = () => {
  return (
    <div>
      <Meta title="Courses | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <CourseTable tableName="Course List" />
        </Col>
      </Row>
    </div>
  );
};

export default Courses;
