import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { editCareer } from "../../../../actions/careerActions";
import { getCareerDataPoints } from "../../../../helper/getUserDetail";
import Seo from "../../../Branch/Seo/Seo";
import Widget from "../../../Branch/Widget/Widget";
import CareerEditDataPoint from "./CareerEditDatapoint";
import EditForm from "./EditForm";

var todayDate = new Date();

let tempCatDataPoints = [];

function Edit() {
  const [career, setCareer] = useState({
    careerID: "",
    CareerName: "",
    CareerStreamID: 2,
    slug: "",
    CareerOptionSince: "",
    StartingSalary: "",
    CareerShortDescription: "",
    DisplayOrder: "",
    IsRecommended: true,
    IsTrending: true,
    status: 1,
    isFeatured: true,
    isActive: true,
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    ModifyBy: null,
    ModifiedDate: todayDate,
  });

  const [careerDescription, setCareerDescription] = useState("");
  const [activeTab, setActive] = useState("1");

  const [careerImagePath, setCareerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [bannerImagePath, setBannerImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const [dataPointCategory, setDataPointCategory] = useState(null);
  const [dataPointAll, setDataPointAll] = useState(null);
  const [dataPoints, setDataPoints] = useState(null);

  const [dataPoint, setDataPoint] = useState({
    dataPointDetails: [
      {
        index: Math.random(),
        question: "",
        answer: "",
        categoryName: null,
        dataPoints: [],
        selDataPointCat: "",
        dataPointID: "",
      },
    ],
  });

  const careerDataPointList = useSelector((state) => state.careerDataPoint);
  const { careerDataPointLoading, careerDatePointList, careerDataPointError } =
    careerDataPointList;

  const careerEditReducer = useSelector((state) => state.careerEdit);
  const { careerEditLoading } = careerEditReducer;

  const careerSingleReducer = useSelector((state) => state.singleCareer);
  const { singleCareerLoading, singleCareer } = careerSingleReducer;

  const onFilteredDataPoints = (singleCareer) => {
    if (singleCareer !== null) {
      singleCareer.forEach((item, index) => {
        var filteredDataPoints = dataPointAll?.filter(
          (subItem) => subItem.categoryName === item.categoryName
        );
        item.dataPoints = filteredDataPoints;
        item.index = Math.random();
      });
      setDataPoint({ dataPointDetails: singleCareer });
    }
  };

  useEffect(() => {
    if (singleCareer !== null) {
      setCareer({
        ...career,
        careerID: singleCareer?.careerID,
        CareerName: singleCareer?.careerName,
        CareerStreamID: singleCareer?.careerStreamID,
        slug: singleCareer?.slug,
        CareerOptionSince: singleCareer?.careerOptionSince,
        StartingSalary: singleCareer?.startingSalary,
        CareerShortDescription: singleCareer?.careerShortDescription,
        DisplayOrder: singleCareer?.displayOrder,
        IsRecommended: singleCareer?.isRecommended,
        IsTrending: singleCareer?.isTrending,
        status: singleCareer?.status,
        isFeatured: singleCareer?.isFeatured,
        isActive: singleCareer?.isActive,
        pageTitle: singleCareer?.pageTitle,
        metaTitle: singleCareer?.metaTitle,
        metaKeywords: singleCareer?.metaKeywords,
        metaDescription: singleCareer?.metaDescription,
        ModifyBy: null,
        ModifiedDate: todayDate,
      });

      setCareerDescription(singleCareer.careerDescription);

      if (singleCareer.dataPoints !== null) {
        onFilteredDataPoints(singleCareer?.dataPoints);
      }

      setCareerImagePath({
        ...careerImagePath,
        myFile: singleCareer?.careerImagePath,
      });

      setBannerImagePath({
        ...bannerImagePath,
        myFile: singleCareer?.bannerImagePath,
      });
    }
  }, [singleCareer]);

  const dataPointCatFilter = (props) => {
    dataPointAll.map((item, index) =>
      tempCatDataPoints.push(item.categoryName)
    );
    const distinctCategory = [...new Set(tempCatDataPoints)];
    setDataPointCategory(distinctCategory);
  };

  useEffect(() => {
    if (getCareerDataPoints() !== null) {
      setDataPointAll(getCareerDataPoints());
    }
  }, [careerDataPointError, careerDatePointList, careerDataPointLoading]);

  useEffect(() => {
    if (dataPointAll !== null) {
      dataPointCatFilter();
    }
  }, [dataPointAll]);

  const addNewRow = (e) => {
    setDataPoint((prevState) => ({
      dataPointDetails: [
        ...prevState.dataPointDetails,
        {
          index: Math.random(),
          question: "",
          answer: "",
          dataPointID: "",
          categoryName: null,
          dataPoints: [],
          selDataPointCat: "",
        },
      ],
    }));
  };

  const clickOnDelete = (record) => {
    setDataPoint({
      dataPointDetails: dataPoint.dataPointDetails.filter((r) => r !== record),
    });
  };

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const onCareerDataPointCat = (e, idx) => {
    var filteredDataPoints = dataPointAll.filter(
      (item) => item.categoryName === e.target.value
    );

    let dataPointDetails = [...dataPoint.dataPointDetails];
    dataPointDetails[e.target.dataset.id].dataPoints = filteredDataPoints;
    dataPointDetails[e.target.dataset.id].categoryName = e.target.value;

    dataPointDetails[e.target.dataset.id].dataPointID =
      filteredDataPoints[0]?.id;
    setDataPoint({
      dataPointDetails: dataPointDetails,
    });
  };

  const changeCreds = (event) => {
    setCareer({ ...career, [event.target.name]: event.target.value });
  };

  const handleChange = (event) => {
    let dataPointDetails = [...dataPoint.dataPointDetails];
    if (["question", "answer", "selDataPointCat"].includes(event.target.name)) {
      dataPointDetails = [...dataPoint.dataPointDetails];
      dataPointDetails[event.target.dataset.id][event.target.name] =
        event.target.value;
    }
    if (["dataPointID"].includes(event.target.name)) {
      dataPointDetails[event.target.dataset.id].dataPointID = Number(
        event.target.value
      );
    }
  };

  const onTinyEditorChange = (event, idx) => {
    let dataPointDetails = [...dataPoint.dataPointDetails];
    dataPointDetails[idx].answer = event.target.getContent();
  };

  const createInstitute = (e) => {
    e.preventDefault();

    const careerInfo = {
      careerID: career.careerID,
      CareerName: career.CareerName,
      CareerStreamID: parseInt(career.CareerStreamID),
      slug: career.slug.toLowerCase(),
      CareerOptionSince: parseInt(career.CareerOptionSince),
      CareerDescription: careerDescription,
      StartingSalary:
        parseInt(career.StartingSalary) === 0
          ? 1
          : parseInt(career.StartingSalary),
      CareerShortDescription: career.CareerShortDescription,
      DisplayOrder:
        parseInt(career.DisplayOrder) === 0 ? 1 : parseInt(career.DisplayOrder),
      IsRecommended: career.IsRecommended,
      IsTrending: JSON.parse(career.IsTrending),
      status: parseInt(career.status),
      isFeatured: JSON.parse(career.isFeatured),
      isActive: JSON.parse(career.isActive),
      careerImagePath: careerImagePath.myFile,
      IsNewImage: careerImagePath.IsNewImage,
      ImageName: careerImagePath.ImageName,
      ImageType: careerImagePath.ImageType,
      bannerImagePath: bannerImagePath?.myFile,
      isBannerNewImage: bannerImagePath?.IsNewImage,
      bannerImageName: bannerImagePath?.ImageName,
      bannerImageType: bannerImagePath?.ImageType,
      pageTitle: career.pageTitle,
      metaTitle: career.metaTitle,
      metaKeywords: career.metaKeywords,
      metaDescription: career.metaDescription,
      dataPointDetails: dataPoint.dataPointDetails,
      ModifyBy: null,
      ModifiedDate: todayDate,
    };

    dispatch(editCareer(careerInfo));
  };

  return (
    <Row>
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget className="">
              <Form
                className="p-5"
                onSubmit={(event) => createInstitute(event)}
                onChange={(e) => handleChange(e)}
              >
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        SEO
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Career Options
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <EditForm
                        career={career}
                        setCareer={setCareer}
                        careerImagePath={careerImagePath}
                        setCareerImagePath={setCareerImagePath}
                        changeCreds={changeCreds}
                        careerDescription={careerDescription}
                        setCareerDescription={setCareerDescription}
                        bannerImagePath={bannerImagePath}
                        setBannerImagePath={setBannerImagePath}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Seo seoMeta={career} changeCreds={changeCreds} />
                    </TabPane>
                    <TabPane tabId="3">
                      {!singleCareerLoading && (
                        <CareerEditDataPoint
                          changeCreds={changeCreds}
                          add={addNewRow}
                          delete={clickOnDelete}
                          dataPointDetails={dataPoint}
                          dataPointCategory={dataPointCategory}
                          onCareerDataPointCat={onCareerDataPointCat}
                          // dataPoints={dataPoints}
                          onTinyEditorChange={onTinyEditorChange}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={careerEditLoading}
                      >
                        {careerEditLoading && (
                          <Spinner
                            className={classnames({
                              "position-relative": true,
                              visible: careerEditLoading,
                              invisible: !careerEditLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Edit;
