export const adminNewItem = [
  {
    title: "Scholastic Form",
    iconClass: "eva eva-checkmark-circle-outline",
    link: "/admin/scholastic/add",
    name: "assessment",
  },
  {
    title: "Course",
    iconClass: "eva eva-book-outline",
    link: "/admin/course/add",
  },
  {
    title: "Institute",
    iconClass: "eva eva-layout-outline",
    link: "/admin/institutes/add",
  },
  {
    title: "User",
    iconClass: "eva eva-people-outline",
    link: "/admin/user/add",
  },
  {
    title: "Career",
    iconClass: "eva eva-clipboard-outline",
    link: "/admin/career/add",
  },
  {
    title: "Event",
    iconClass: "eva eva-calendar-outline",
    link: "/admin/events/add",
  },
  {
    title: "Speaker",
    iconClass: "eva eva-person-outline",
    link: "/admin/speakers/add",
  },
  {
    title: "Instructor",
    iconClass: "eva eva-person-outline",
    link: "/admin/instructor/add",
  },
  {
    title: "Counsellor",
    iconClass: "eva eva-person-outline",
    link: "/admin/counsellor/add",
    name: "counsellor",
  },
  {
    title: "Add Coupon",
    link: "/admin/coupon/addCoupon",
    name: "coupon",
    iconClass: "eva eva-pie-chart-outline",
  },
  {
    title: "Add Tutor",
    link: "/admin/tutors/addTutor",
    name: "tutors",
    iconClass: "eva eva-people-outline",
  },
  {
    title: "Add Organisation",
    link: "/admin/organisations/addOrganisation",
    name: "organisation",
  },

  {
    title: "Currency",
    iconClass: "eva eva-pricetags-outline",
    link: "/admin/currency/Add",
    name: "currency",
  },
  {
    title: "Online Course",
    iconClass: "eva eva-book-outline",
    link: "/admin/Online-courses/Add",
    name: "online Course",
  },
  {
    title: "Online Institute",
    iconClass: "eva eva-browser-outline",
    link: "/admin/online-institutes/Add",
    name: "online Institute",
  },
  {
    title: "Course Category",
    iconClass: "eva eva-grid-outline",
    link: "/admin/Online-course-categories/add",
    name: "course category",
  },
  {
    title: "Company",
    iconClass: "eva eva-smartphone-outline",
    link: "/admin/companies/add",
    name: "companies",
  },
  {
    title: "Pages",
    iconClass: "eva eva-browser-outline",
    link: "/admin/pages/add",
    name: "pages",
  },
  {
    title: "Blogs",
    iconClass: "eva eva-cube-outline",
    link: "/admin/blogs/add",
    name: "blogs",
  },
  {
    title: "Seo",
    iconClass: "eva eva-globe-outline",
    link: "/admin/seo/add",
    name: "seo",
  },
];
export const instructorNewItems = [
  {
    title: "Online Course",
    iconClass: "eva eva-book-outline",
    link: "/admin/Online-courses/Add",
    name: "online Course",
  },
];

export const adminSidebar = [
  // {
  //   title: "Dashboard",
  //   iconClass: "eva eva-home-outline",
  //   link: "/admin/dashboard",
  //   name: "dashboard",
  // },
  {
    title: "Scholastic",
    iconClass: "eva eva-checkmark-circle-outline",
    link: "/admin/scholastic",
    name: "scholastic",
  },
  // {
  //   title: "Courses",
  //   iconClass: "eva eva-book-outline",
  //   link: "/admin/courses",
  //   name: "courses",
  // },
  {
    title: "Institutes",
    iconClass: "eva eva-layout-outline",
    link: "/admin/institutes",
    name: "institutes",
  },
  {
    title: "Careers",
    iconClass: "eva eva-clipboard-outline",
    link: "/admin/careers",
    name: "careers",
  },
  {
    title: "Companies",
    iconClass: "eva eva-smartphone-outline",
    link: "/admin/companies",
    name: "companies",
  },
  {
    title: "Pages",
    iconClass: "eva eva-browser-outline",
    link: "/admin/pages",
    name: "careers",
  },
  {
    title: "Blogs",
    iconClass: "eva eva-cube-outline",
    link: "/admin/blogs",
    name: "blogs",
  },
  {
    title: "Seo",
    iconClass: "eva eva-globe-outline",
    link: "/admin/seo",
    name: "seo",
  },
  {
    title: "Contacts",
    iconClass: "eva eva-phone-outline",
    link: "/admin/contacts",
    name: "contacts",
  },
  {
    title: "Searches",
    iconClass: "eva eva-search-outline",
    link: "/admin/searches",
    name: "searches",
  },
  {
    title: "Coupon",
    link: "/admin/coupon",
    name: "coupon",
    iconClass: "eva eva-pie-chart-outline",
  },
  {
    title: "Tutor",
    link: "/admin/tutors",
    name: "tutors",
    iconClass: "eva eva-people-outline",
  },
  {
    title: "Organisation",
    link: "/admin/organisations",
    name: "organisation",
  },
];
export const instructorSidebarData = [
  {
    title: "Dashboard",
    iconClass: "eva eva-home-outline",
    link: "/admin/dashboard",
    name: "dashboard",
  },
];

export const eventSidebarItems = [
  {
    title: "Events",
    iconClass: "eva eva-calendar-outline",
    link: "/admin/events",
    name: "event",
  },
  {
    title: "Speakers",
    iconClass: "eva eva-person-outline",
    link: "/admin/speakers",
    name: "speaker",
  },
];
export const studentSidebarItems = [
  {
    title: "Students",
    iconClass: "eva eva-people-outline",
    link: "/admin/students",
    name: "student",
  },
  {
    title: "Admin",
    iconClass: "eva eva-person-outline",
    link: "/admin/admin-users",
    name: "speaker",
  },
];
export const onlineInstituteSidebarItems = [
  {
    title: "Courses",
    iconClass: "eva eva-book-outline",
    link: "/admin/Online-courses",
    name: "online course",
  },
  {
    title: "Instructors",
    iconClass: "eva eva-person-outline",
    link: "/admin/instructor",
    name: "instructors",
  },
  {
    title: "Currency",
    iconClass: "eva eva-pricetags-outline",
    link: "/admin/currency",
    name: "currency",
  },
  {
    title: "Institutes",
    iconClass: "eva eva-browser-outline",
    link: "/admin/online-institutes",
    name: "currency",
  },
  {
    title: "Categories",
    iconClass: "eva eva-grid-outline",
    link: "/admin/Online-course-categories",
    name: "currency",
  },
];

// add counsellor
export const counsellorNewItems = [
  {
    title: "Add Student",
    iconClass: "eva eva-people-outline",
    link: "/admin/counsellor/addStudent",
    name: "user",
  },
];

export const counsellorSidebar = [
  {
    title: "Students",
    iconClass: "eva eva-people-outline",
    link: "/admin/counsellor",
    name: "student",
  },
];
