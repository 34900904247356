import {
  PAGE_ADD_REQUEST,
  PAGE_ADD_SUCCESS,
  PAGE_ADD_FAIL,
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
  PAGE_LIST_FAIL,
  PAGE_EDIT_REQUEST,
  PAGE_EDIT_SUCCESS,
  PAGE_EDIT_FAIL,
  PAGE_SINGLE_REQUEST,
  PAGE_SINGLE_SUCCESS,
  PAGE_SINGLE_FAIL,
} from "../constants/pageConstants";

const pageListState = {
  pageLoading: false,
  pageList: null,
  pageListFail: false,
};

export const pageListReducer = (state = pageListState, action) => {
  switch (action.type) {
    case PAGE_LIST_REQUEST:
      return { pageLoading: true, pageList: null, pageListFail: false };
    case PAGE_LIST_SUCCESS:
      return {
        pageLoading: false,
        pageList: action.payload,
        pageListFail: false,
      };
    case PAGE_LIST_FAIL:
      return {
        pageLoading: false,
        pageListFail: action.payload,
        pageList: null,
      };
    default:
      return state;
  }
};
const singlePageState = {
  singlePageLoading: false,
  singlePage: null,
  singlePageFail: false,
};

export const singlePageReducer = (state = singlePageState, action) => {
  switch (action.type) {
    case PAGE_SINGLE_REQUEST:
      return {
        singlePageLoading: true,
        singlePage: null,
        singlePageFail: false,
      };
    case PAGE_SINGLE_SUCCESS:
      return {
        singlePageLoading: false,
        singlePage: action.payload,
        singlePageFail: false,
      };
    case PAGE_SINGLE_FAIL:
      return {
        singlePageLoading: false,
        singlePageFail: action.payload,
        singlePage: null,
      };

    default:
      return state;
  }
};

const pageEditState = {
  pageEditLoading: false,
  pageEdited: null,
  pageEditFail: false,
};

export const editPageReducer = (state = pageEditState, action) => {
  switch (action.type) {
    case PAGE_EDIT_REQUEST:
      return { pageEditLoading: true, pageEdited: null, pageEditFail: false };
    case PAGE_EDIT_SUCCESS:
      return {
        pageEditLoading: false,
        pageEdited: action.payload,
        pageEditFail: false,
      };
    case PAGE_EDIT_FAIL:
      return {
        pageEditLoading: false,
        pageEditFail: action.payload,
        pageEdited: null,
      };

    default:
      return state;
  }
};

const pageAddState = {
  pageAddLoading: false,
  pageAdded: null,
  pageAddFail: false,
};

export const addPageReducer = (state = pageAddState, action) => {
  switch (action.type) {
    case PAGE_ADD_REQUEST:
      return { pageAddLoading: true, pageAdded: null, pageAddFail: false };
    case PAGE_ADD_SUCCESS:
      return {
        pageAddLoading: false,
        pageAdded: action.payload,
        pageAddFail: false,
      };
    case PAGE_ADD_FAIL:
      return {
        pageAddLoading: false,
        pageAddFail: action.payload,
        pageAdded: null,
      };

    default:
      return state;
  }
};
