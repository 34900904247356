import React, { PureComponent } from "react";
import { FormGroup, Input, Label, Row, Spinner } from "reactstrap";

class SlugInput extends PureComponent {
  render() {
    const { label, value, type, onChange, name, ...props } = this.props;
    return (
      <FormGroup>
        <Label for="slug">
          {label} <span className="text-danger">*</span>
        </Label>
        <Input
          value={value}
          onChange={onChange}
          type={type}
          required
          name={name}
          maxLength={255}
          autoComplete="nothing"
          autoCapitalize="none"
          className="lowercase"
          {...props}
        />
      </FormGroup>
    );
  }
}

export default SlugInput;
