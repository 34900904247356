import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CkEditor from "../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../UI/Input/ImageUpload/ImageUpload";

const BlogForm = (props) => {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="title">
            Title <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.blogInfo.title}
            onChange={props.changeCreds}
            type="text"
            required
            name="title"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="shortDescription">
            Short Description <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.blogInfo.shortDescription}
            onChange={props.changeCreds}
            type="textarea"
            required
            name="shortDescription"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="Content">Content</Label>
          <CkEditor
            value={props.blogContent}
            setDescription={props.setBlogContent}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={props.blogImagePath}
          setPostImage={props.setBlogImagePath}
        />
      </Col>
    </Row>
  );
};
export default BlogForm;
