import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import { addBlog } from "../../../actions/blogActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Seo from "../../../components/Branch/Seo/Seo";
import Widget from "../../../components/Branch/Widget/Widget";
import BlogForm from "./BlogForm";

var todayDate = new Date();

function BlogAdd({ history }) {
  const [blogInfo, setBlogInfo] = useState({
    slug: "",
    title: "",
    shortDescription: "",
    pageTitle: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    createdBy: "",
    createdDate: todayDate,
  });

  const [blogContent, setBlogContent] = useState("");
  const [activeTab, setActive] = useState("1");

  const [blogImagePath, setBlogImagePath] = useState({
    myFile: "",
    ImageType: "",
    IsNewImage: false,
    ImageName: "",
  });

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setBlogInfo({
      ...blogInfo,
      [event.target.name]: event.target.value,
    });
  };

  const blogAddReducer = useSelector((state) => state.addBlog);
  const { blogAddLoading } = blogAddReducer;

  const onPageAdd = (e) => {
    e.preventDefault();
    const info = {
      slug: blogInfo.slug,
      title: blogInfo.title,
      shortDescription: blogInfo.shortDescription,
      featuredImage: blogImagePath.myFile,
      isNewImage: blogImagePath.IsNewImage,
      imageName: blogImagePath.ImageName,
      imageType: blogImagePath.ImageType,
      blogContent: blogContent,
      pageTitle: blogInfo.pageTitle,
      metaTitle: blogInfo.metaTitle,
      metaKeywords: blogInfo.metaKeywords,
      metaDescription: blogInfo.metaDescription,
      createdBy: "",
      createdDate: blogInfo.createdDate,
    };

    dispatch(addBlog(info, () => history.push(`/admin/blogs`)));
  };

  return (
    <Row>
      <Meta title="Add | Blog | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onPageAdd(e)} className="px-5 py-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "2" })}
                      onClick={() => toggle("2")}
                    >
                      SEO
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <BlogForm
                      blogInfo={blogInfo}
                      blogImagePath={blogImagePath}
                      setBlogImagePath={setBlogImagePath}
                      changeCreds={changeCreds}
                      blogContent={blogContent}
                      setBlogContent={setBlogContent}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Seo seoMeta={blogInfo} changeCreds={changeCreds} />
                  </TabPane>
                </TabContent>

                <Col sm={12}>
                  <div className="bg-widget d-flex justify-content-end">
                    <Button
                      className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                      type="submit"
                      color="secondary-red"
                      disabled={blogAddLoading}
                    >
                      {blogAddLoading && (
                        <Spinner
                          className={classNames({
                            "position-relative": true,
                            visible: blogAddLoading,
                            invisible: !blogAddLoading,
                          })}
                          size="sm"
                        />
                      )}
                      <span className="ml-1">Submit</span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default BlogAdd;
