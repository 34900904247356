import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CkEditor from "../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../UI/Input/ImageUpload/ImageUpload";

export const CompanyForm = (props) => {
  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" md={6}>
        <FormGroup>
          <Label for="companyName">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.companyInfo.companyName}
            onChange={props.changeCreds}
            type="text"
            required
            name="companyName"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={6}>
        <FormGroup>
          <Label for="companyType">Type</Label>
          <Input
            value={props.companyInfo.companyType}
            onChange={props.changeCreds}
            type="text"
            required
            name="companyType"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="city">City</Label>
          <Input
            value={props.companyInfo.city}
            onChange={props.changeCreds}
            type="text"
            required
            name="city"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="state">State</Label>
          <Input
            value={props.companyInfo.state}
            onChange={props.changeCreds}
            type="text"
            required
            name="state"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="country">Country</Label>
          <Input
            value={props.companyInfo.country}
            onChange={props.changeCreds}
            type="text"
            required
            name="country"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="pin">PIN</Label>
          <Input
            value={props.companyInfo.pin}
            onChange={props.changeCreds}
            min={1}
            type="number"
            required
            name="pin"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="emailId">Email</Label>
          <Input
            value={props.companyInfo.emailId}
            onChange={props.changeCreds}
            type="email"
            required
            name="emailId"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="phone1">Phone </Label>
          <Input
            value={props.companyInfo.phone1}
            onChange={props.changeCreds}
            type="number"
            min={1}
            required
            name="phone1"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="companyInceptionYear">Inception Year</Label>
          <Input
            value={props.companyInfo.companyInceptionYear}
            onChange={props.changeCreds}
            type="date"
            required
            name="companyInceptionYear"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="companyTagLine">Tag Line</Label>
          <Input
            value={props.companyInfo.companyTagLine}
            onChange={props.changeCreds}
            type="text"
            required
            name="companyTagLine"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={4}>
        <FormGroup>
          <Label for="website">Website Link</Label>
          <Input
            value={props.companyInfo.website}
            onChange={props.changeCreds}
            type="url"
            name="website"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="address1">Address 1</Label>
          <Input
            value={props.companyInfo.address1}
            onChange={props.changeCreds}
            type="textarea"
            required
            name="address1"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="address2">Address 2</Label>
          <Input
            value={props.companyInfo.address2}
            onChange={props.changeCreds}
            type="textarea"
            required
            name="address2"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="companyShortDescription">
            Short Description <span className="text-danger">*</span>
          </Label>
          <Input
            value={props.companyInfo.companyShortDescription}
            onChange={props.changeCreds}
            type="textarea"
            required
            name="companyShortDescription"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>

      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        <FormGroup>
          <Label for="Content">Description</Label>
          <CkEditor
            value={props.companyDescription}
            setDescription={props.setCompanyDescription}
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <ImageUpload
          postImage={props.companyImagePath}
          setPostImage={props.setCompanyImagePath}
        />
      </Col>
    </Row>
  );
};
