import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_STUDENT_ADD_REQUEST,
  ADMIN_STUDENT_ADD_SUCCESS,
  ADMIN_STUDENT_ADD_FAIL,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_SUCCESS,
  ADMIN_ADD_FAIL,
  INSTRUCTOR_ADD_REQUEST,
  INSTRUCTOR_ADD_SUCCESS,
  INSTRUCTOR_ADD_FAIL,
  EXAM_CREATOR_ADD_REQUEST,
  EXAM_CREATOR_ADD_SUCCESS,
  EXAM_CREATOR_ADD_FAIL,
  INSTRUCTOR_PASSWORD_UPDATE_REQUEST,
  INSTRUCTOR_PASSWORD_UPDATE_SUCCESS,
  INSTRUCTOR_PASSWORD_UPDATE_FAIL,
} from "../constants/userConstants";

const userLoginState = {
  loginLoading: false,
  loggedInUserInfo: null,
  loginError: false,
};

export const userLoginReducer = (state = userLoginState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loginLoading: true, loggedInUserInfo: null, loginError: false };
    case USER_LOGIN_SUCCESS:
      return {
        loginLoading: false,
        loggedInUserInfo: action.payload,
        loginError: false,
      };
    case USER_LOGIN_FAIL:
      return {
        loginLoading: false,
        loginError: action.payload,
        loggedInUserInfo: null,
      };
    case USER_LOGOUT:
      return { loginLoading: false, loggedInUserInfo: null, loginError: false };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

const userListState = { userListLoading: false, users: null, error: false };

export const userListReducer = (
  state = { users: userListState, loading: true },
  action
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { userListLoading: true, users: null, error: false };
    case USER_LIST_SUCCESS:
      return {
        userListLoading: false,
        success: true,
        users: action.payload,
        error: false,
      };
    case USER_LIST_FAIL:
      return { userListLoading: false, error: action.payload, users: null };

    default:
      return state;
  }
};

const adminState = {
  adminListLoading: false,
  adminList: null,
  adminListError: false,
};

export const adminListReducer = (state = adminState, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { adminListLoading: true, adminList: null, adminListError: false };
    case ADMIN_LIST_SUCCESS:
      return {
        adminListLoading: false,
        success: true,
        adminList: action.payload,
        adminListError: false,
      };
    case ADMIN_LIST_FAIL:
      return {
        adminListLoading: false,
        adminListError: action.payload,
        adminList: null,
      };

    default:
      return state;
  }
};

const studentAddState = {
  studentAddLoading: false,
  studentAdd: null,
  studentAddError: false,
};

export const addStudentByAdminReducer = (state = studentAddState, action) => {
  switch (action.type) {
    case ADMIN_ADD_REQUEST:
      return {
        studentAddLoading: true,
        studentAdd: null,
        studentAddError: false,
      };
    case ADMIN_ADD_SUCCESS:
      return {
        studentAddLoading: false,
        studentAdd: action.payload,
        studentAddError: false,
      };
    case ADMIN_ADD_FAIL:
      return {
        studentAddLoading: false,
        studentAddError: action.payload,
        studentAdd: null,
      };

    default:
      return state;
  }
};

const adminAddState = {
  adminAddLoading: false,
  adminAdd: null,
  adminAddError: false,
};

export const addAdminByAdminReducer = (state = adminAddState, action) => {
  switch (action.type) {
    case ADMIN_STUDENT_ADD_REQUEST:
      return {
        adminAddLoading: true,
        adminAdd: null,
        adminAddError: false,
      };
    case ADMIN_STUDENT_ADD_SUCCESS:
      return {
        adminAddLoading: false,
        adminAdd: action.payload,
        adminAddError: false,
      };
    case ADMIN_STUDENT_ADD_FAIL:
      return {
        adminAddLoading: false,
        studentAddError: action.payload,
        adminAdd: null,
      };

    default:
      return state;
  }
};
const instructorAddState = {
  instructorAddLoading: false,
  instructorAdd: null,
  instructorAddError: false,
};

export const addInstructorByAdminReducer = (
  state = instructorAddState,
  action
) => {
  switch (action.type) {
    case INSTRUCTOR_ADD_REQUEST:
      return {
        instructorAddLoading: true,
        instructorAdd: null,
        instructorAddError: false,
      };
    case INSTRUCTOR_ADD_SUCCESS:
      return {
        instructorAddLoading: false,
        instructorAdd: action.payload,
        instructorAddError: false,
      };
    case INSTRUCTOR_ADD_FAIL:
      return {
        instructorAddLoading: false,
        instructorAdd: action.payload,
        instructorAddError: null,
      };

    default:
      return state;
  }
};
const examCreatorState = {
  examCreatorAddLoading: false,
  examCreatorAdded: null,
  examCreatorAddError: false,
};

export const examCreatorAddReducer = (state = examCreatorState, action) => {
  switch (action.type) {
    case EXAM_CREATOR_ADD_REQUEST:
      return {
        examCreatorAddLoading: true,
        examCreatorAdded: null,
        examCreatorAddError: false,
      };
    case EXAM_CREATOR_ADD_SUCCESS:
      return {
        examCreatorAddLoading: false,
        examCreatorAdded: action.payload,
        examCreatorAddError: false,
      };
    case EXAM_CREATOR_ADD_FAIL:
      return {
        examCreatorAddLoading: false,
        examCreatorAdded: action.payload,
        examCreatorAddError: null,
      };

    default:
      return state;
  }
};
const updateUserPasswordState = {
  updatePasswordLoading: false,
  passwordUpdated: null,
  updatePasswordError: false,
};

export const updateIntPasswordReducer = (
  state = updateUserPasswordState,
  action
) => {
  switch (action.type) {
    case INSTRUCTOR_PASSWORD_UPDATE_REQUEST:
      return {
        updatePasswordLoading: true,
        passwordUpdated: null,
        updatePasswordError: false,
      };
    case INSTRUCTOR_PASSWORD_UPDATE_SUCCESS:
      return {
        updatePasswordLoading: false,
        passwordUpdated: action.payload,
        updatePasswordError: false,
      };
    case INSTRUCTOR_PASSWORD_UPDATE_FAIL:
      return {
        updatePasswordLoading: false,
        passwordUpdated: action.payload,
        examCreatorAddError: null,
      };

    default:
      return state;
  }
};
