import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import {
  Col,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { courseActiveToggle } from "../../../../actions/courseActions";
import cloudIcon from "../../../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../../../assets/tables/optionsIcon.svg";
import printerIcon from "../../../../assets/tables/printerIcon.svg";
import searchIcon from "../../../../assets/tables/searchIcon.svg";
import Widget from "../../../../components/Branch/Widget/Widget";
import Skeleton from "../../../../UI/Skeleton/Skeleton";
import s from "../../Tables.module.scss";
import SearchInput from "../../../../helper/SearchInput";

var todayDate = new Date();

const SpeakerTable = function ({ tableName }) {
  const [speakerList, setSpeakerList] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [firstTablePagesCount, setFirstTablePagesCount] = useState(1);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const speakerListReducer = useSelector((state) => state.speaker);
  const { speakerListLoading, speakers, speakerListError } = speakerListReducer;

  useEffect(() => {
    setPageSize(25);
  }, [dispatch]);

  useEffect(() => {
    if (speakers !== null) {
      setSpeakerList(speakers);
    }
  }, [speakerListLoading, speakers]);

  useEffect(() => {
    if (speakerList !== null) {
      const firstTablePagesCounts = Math.ceil(speakerList.length / pageSize);
      setFirstTablePagesCount(firstTablePagesCounts);
    }
  }, [speakerList]);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const onActive = (status, courseID) => {
    const tempList = [...speakerList];

    tempList.map(
      (item) => item.courseID === courseID && (item.isActive = !status)
    );

    const foundCourse = tempList.find(
      (element) => element.courseID === courseID
    );
    const selectedCrouse = {
      CourseID: foundCourse.courseID,
      IsActive: foundCourse.isActive,
      ModifyBy: "",
      ModifiedDate: todayDate,
    };

    dispatch(courseActiveToggle(selectedCrouse));
    setSpeakerList(tempList);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">{tableName}</div>
                  <div className="d-flex">
                    <SearchInput
                      searchText={searchText}
                      handleSearch={handleSearch}
                      placeholder="Search"
                    />
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={cloudIcon}
                        alt="Cloud"
                      />
                    </a>
                    <a href="/#">
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a href="/#">
                      <img
                        className="d-none d-sm-block"
                        src={optionsIcon}
                        alt="Options"
                      />
                    </a>
                    <a href="/#">
                      <img src={funnelIcon} alt="Funnel" />
                    </a>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    {speakerListLoading ? (
                      <Skeleton count={pageSize} />
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th className={s.checkboxCol}>
                              <div className="checkbox checkbox-primary">
                                <input
                                  className="styled"
                                  id="checkbox100"
                                  type="checkbox"
                                />
                                <label htmlFor="checkbox100" />
                              </div>
                            </th>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {speakerList !== null &&
                            speakerList
                              .filter((item) =>
                                Object.values(item).some(
                                  (value) =>
                                    typeof value === "string" &&
                                    value
                                      .toLowerCase()
                                      .includes(searchText.toLowerCase())
                                )
                              )
                              .slice(
                                firstTableCurrentPage * pageSize,
                                (firstTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="checkbox checkbox-primary">
                                      <input
                                        id={item.id}
                                        className="styled"
                                        type="checkbox"
                                      />
                                      <Label for={item.id} />
                                    </div>
                                  </td>

                                  <td>{item.speakerName}</td>
                                  <td>{item.designation}</td>

                                  <td className="d-flex justify-content-between">
                                    <Link
                                      to={`/admin/speakers/edit/${item.id}`}
                                      className="d-flex align-items-center justify-content-center table-action"
                                    >
                                      <i className="eva eva-edit mr-1"></i>
                                    </Link>
                                    {/* <a
                                      href={`https://www.eduberance.com/speakers/${item.slug}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="table-action"
                                    >
                                      <i className="eva eva-eye"></i>
                                    </a> */}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                  {speakerList !== null && (
                    <Pagination
                      className="pagination-borderless"
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={firstTableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(firstTablePagesCount)].map((page, i) => (
                        <PaginationItem
                          active={i === firstTableCurrentPage}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => setFirstTablePage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={
                          firstTableCurrentPage >= firstTablePagesCount - 1
                        }
                      >
                        <PaginationLink
                          onClick={(e) =>
                            setFirstTablePage(e, firstTableCurrentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SpeakerTable;
