import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getAllEvents } from "../../../actions/eventActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import EventTable from "../../tables/components/EventTable/EventTable";

const Event = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  return (
    <div>
      <Meta title="Events | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Widget>
            <EventTable />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};
export default Event;
