import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CkEditor from "../../../../UI/Input/CkEditor/CkEditor";
import ImageUpload from "../../../../UI/Input/ImageUpload/ImageUpload";

function EditForm(props) {
  const {
    instructorImage,
    setInstructorImage,
    instructor,
    changeCreds,
    description,
    setDescription,
    isEdited,
    isCreated,
  } = props;

  const instructorEditReducer = useSelector((state) => state.instructorEdit);
  const { instructorEditLoading } = instructorEditReducer;

  return (
    <Row className="gutter mb-2 mb-sm-4 mt-5">
      <Col className="mb-2 mb-sm-4 mb-md-0" md={12}>
        {isEdited && (
          <FormGroup>
            <Label for="title">
              Title <span className="text-danger">*</span>
            </Label>
            <Input
              value={instructor.title}
              onChange={changeCreds}
              type="text"
              maxLength={400}
              required
              name="title"
              autoComplete="nope"
            />
          </FormGroup>
        )}
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="name">
            Name <span className="text-danger">*</span>
          </Label>
          <Input
            value={instructor.name}
            onChange={changeCreds}
            type="text"
            maxLength={400}
            required
            name="name"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
        <FormGroup>
          <Label for="email">
            Email <span className="text-danger">*</span>
          </Label>
          <Input
            value={instructor.email}
            onChange={changeCreds}
            readOnly={isCreated ? false : true}
            type="email"
            maxLength={400}
            required
            name="email"
            autoComplete="nope"
          />
        </FormGroup>
      </Col>
      {isEdited && (
        <>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
            <FormGroup>
              <Label for="description">Description</Label>
              <CkEditor value={description} setDescription={setDescription} />
            </FormGroup>
          </Col>
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={6}>
            <ImageUpload
              postImage={instructorImage}
              setPostImage={setInstructorImage}
              isLoading={false}
            />
          </Col>
        </>
      )}
    </Row>
  );
}

export default EditForm;
