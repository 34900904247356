import React from "react";
import eduberanceBlackLogo from "../../../assets/instituteLogo/eduberance-logo-black.png";

class SofiaLogo extends React.Component {
  render() {
    return <img src={eduberanceBlackLogo} alt="Login" width="110" />;
  }
}

export default SofiaLogo;
