import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeActiveSidebarItem } from "../../../../actions/navigation.js";
import LinksGroup from "../LinksGroup/LinksGroup.js";

const AdminSidebar = (props) => {
  return (
    <>
      <LinksGroup
        onActiveSidebarItemChange={(activeItem) =>
          props.dispatch(changeActiveSidebarItem(activeItem))
        }
        activeItem={props.activeItem}
        header="Typography"
        isHeader
        iconName={<i className={"eva eva-text-outline"} />}
        link="/admin/typography"
        index="typography"
      />
      <LinksGroup
        onActiveSidebarItemChange={(activeItem) =>
          props.dispatch(changeActiveSidebarItem(activeItem))
        }
        activeItem={props.activeItem}
        header="Tables"
        isHeader
        iconName={<i className={"eva eva-grid-outline"} />}
        link="/admin/tables"
        index="tables"
      />
      <LinksGroup
        onActiveSidebarItemChange={(activeItem) =>
          props.dispatch(changeActiveSidebarItem(activeItem))
        }
        activeItem={props.activeItem}
        header="Notifications"
        isHeader
        iconName={<i className={"eva eva-bell-outline"} />}
        link="/admin/notifications"
        index="notifications"
      />

      <LinksGroup
        onActiveSidebarItemChange={(activeItem) =>
          props.dispatch(changeActiveSidebarItem(activeItem))
        }
        activeItem={props.activeItem}
        header="UI Elements"
        isHeader
        iconName={<i className={"eva eva-cube-outline"} />}
        link="/admin/uielements"
        index="uielements"
        childrenLinks={[
          {
            header: "Charts",
            link: "/admin/ui-elements/charts",
          },
          {
            header: "Icons",
            link: "/admin/ui-elements/icons",
          },
          {
            header: "Google Maps",
            link: "/admin/ui-elements/maps",
          },
        ]}
      />
    </>
  );
};

AdminSidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(AdminSidebar));
