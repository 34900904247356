import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { addOnlineInt } from "../../../actions/onlineCourseActions";
import Meta from "../../../components/Branch/Meta/Meta";
import Widget from "../../../components/Branch/Widget/Widget";
import SocialLink from "../../../UI/Input/SocialLink/SocialLink";

var todayDate = new Date();

function OnlineInstituteAdd({ history }) {
  const [onlineInstitute, setOnlineInstitute] = useState({
    id: "",
    name: "",
    title: "",
    description: "",
    linkedInProfile: "",
    twitterProfile: "",
    fbProfile: "",
    email: "",
    website: "",
    createdBy: null,
    createdDate: todayDate,
  });
  const [activeTab, setActive] = useState("1");
  const dispatch = useDispatch();

  const onlineIntAddReducer = useSelector((state) => state.onlineIntAdd);
  const { onlineIntAddLoading } = onlineIntAddReducer;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActive(tab);
    }
  };

  const changeCreds = (event) => {
    setOnlineInstitute({
      ...onlineInstitute,
      [event.target.name]: event.target.value,
    });
  };

  const onOnlineIntAdd = (e) => {
    e.preventDefault();
    const currencyInfo = {
      id: onlineInstitute?.id,
      name: onlineInstitute?.name,
      title: onlineInstitute?.title,
      description: onlineInstitute?.description,
      linkedInProfile: onlineInstitute?.linkedInProfile,
      twitterProfile: onlineInstitute?.twitterProfile,
      fbProfile: onlineInstitute?.fbProfile,
      email: onlineInstitute?.email,
      website: onlineInstitute?.website,
      createdBy: onlineInstitute?.createdBy,
      createdDate: onlineInstitute?.createdDate,
    };
    dispatch(
      addOnlineInt(currencyInfo, () => {
        history.push(`/admin/online-institutes`);
      })
    );
  };

  return (
    <Row>
      <Meta title="Add | Online Institute | Eduberance" />
      <Col className="pr-grid-col" xs={12}>
        <Row className="gutter mb-2 mb-sm-4">
          <Col className="mb-2 mb-sm-4 mb-md-0" xs={12}>
            <Widget>
              <Form onSubmit={(e) => onOnlineIntAdd(e)} className="px-5 py-3">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === "2" })}
                      onClick={() => toggle("2")}
                    >
                      Social Links
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row className="gutter mb-2 mb-sm-4 mt-5">
                      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                        <FormGroup>
                          <Label for="name">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={onlineInstitute.name}
                            onChange={changeCreds}
                            type="text"
                            maxLength={400}
                            required
                            name="name"
                            autoComplete="nope"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                        <FormGroup>
                          <Label for="title">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={onlineInstitute.title}
                            onChange={changeCreds}
                            type="text"
                            maxLength={400}
                            required
                            name="title"
                            autoComplete="nope"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={4}>
                        <FormGroup>
                          <Label for="email">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={onlineInstitute.email}
                            onChange={changeCreds}
                            type="text"
                            maxLength={400}
                            required
                            name="email"
                            autoComplete="nope"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="mb-2 mb-sm-4 mb-md-0" xs={12} md={12}>
                        <FormGroup>
                          <Label for="description">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={onlineInstitute.description}
                            onChange={changeCreds}
                            type="textarea"
                            maxLength={400}
                            required
                            name="description"
                            autoComplete="nope"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <SocialLink
                      speaker={onlineInstitute}
                      changeCreds={changeCreds}
                      email={false}
                      website={false}
                    />
                  </TabPane>
                </TabContent>

                <Row>
                  <Col sm={12}>
                    <div className="bg-widget d-flex justify-content-end">
                      <Button
                        className="rounded-pill my-3 d-flex justify-content-center align-items-center w-25"
                        type="submit"
                        color="secondary-red"
                        disabled={onlineIntAddLoading}
                      >
                        {onlineIntAddLoading && (
                          <Spinner
                            className={classNames({
                              "position-relative": true,
                              visible: onlineIntAddLoading,
                              invisible: !onlineIntAddLoading,
                            })}
                            size="sm"
                          />
                        )}
                        <span className="ml-1">Submit</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OnlineInstituteAdd;
