import React from "react";
import { Col, Row } from "reactstrap";
import Meta from "../../../components/Branch/Meta/Meta";
import InstituteTable from "../../tables/components/InstituteTable/InstituteTable";

const Institute = () => {
  return (
    <div>
      <Meta title="Institutes | Eduberance" />
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <InstituteTable tableName="Institute List" />
        </Col>
      </Row>
    </div>
  );
};

export default Institute;
